import React from 'react';
import { NavigateFunction } from 'react-router-dom';

import { PATHS } from 'routes';
import { FailToast } from 'components/toasts';
import { Container, MoreButton } from './CardTrackerFailToastStyles';

export const CardTrackerFailToast = (text: string, navigate: NavigateFunction) => {
  const onClick = () => navigate(PATHS.cardTracker);

  return FailToast(
    <Container>
      <div>{text}</div>
      <MoreButton onClick={onClick}>Click here for details</MoreButton>
    </Container>,
  );
};
