import { Reducer } from 'redux';

import * as Actions from './SearchBarActions.types';

export interface State {
  isLoading: boolean;
  searchValue: string;
}

export const initialState: State = {
  isLoading: false,
  searchValue: '',
};

const SearchBarReducer: Reducer<State, Actions.SearchBarActionTypes> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case Actions.SEARCH__CLEAR_VALUE: {
      return {
        ...state,
        searchValue: '',
      };
    }

    case Actions.SEARCH__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    case Actions.SEARCH__SET_VALUE: {
      return {
        ...state,
        searchValue: action.value,
      };
    }

    default:
      return state;
  }
};

export default SearchBarReducer;
