import React, { Fragment } from 'react';

import { Container, Label, ToggleInput } from './RadioButtonToggleStyles';

interface Props {
  data: {
    label: string;
    value: string;
  }[];
  value: {
    label: string;
    value: string;
  };
  onChange: (value: { value: string; label: string }) => void;
  id: string;
}

export const RadioButtonToggle: React.FC<Props> = ({
  data,
  onChange,
  value: selected,
  id,
}: Props) => {
  return (
    <Container>
      {data.map(({ value, label }, index) => (
        <Fragment key={`${id}-${index}`}>
          <ToggleInput
            key={`inp-${value}-${id}`}
            type="radio"
            id={`switch-${id}-${index}`}
            value={value}
            onChange={() => onChange({ value, label })}
            checked={selected.value === value}
          />
          <Label htmlFor={`switch-${id}-${index}`}>{label}</Label>
        </Fragment>
      ))}
    </Container>
  );
};
