import React from 'react';
import useRequestsTable from 'domains/admin-photo-verification/hooks/useRequestsTable';

import { GroupActionModals } from '../action-modals';
import Table from 'components/table';
import useDimensions from 'hooks/useDimensions';

export const RequestsTable: React.FC = () => {
  const {
    columns,
    data,
    isLoading,
    onSelect,
    selectedRequestsIds,
    toggleSelectAll,
  } = useRequestsTable();
  const { isInDesktop } = useDimensions();

  return (
    <Table
      columnsSpans={isInDesktop ? '60px 1fr 0.3fr 0.8fr' : '40px 0.8fr 0.3fr 0.8fr'}
      columns={columns}
      isLoading={isLoading}
      data={data}
      title="Employee"
      canSelect
      headerProps={{
        actions: <GroupActionModals showActions={selectedRequestsIds.size > 1} />,
        showActions: true,
      }}
      selectProps={{
        onSelect,
        selectedRows: [...selectedRequestsIds],
        toggleSelectAll,
      }}
      options={{ usePagination: true, pageSize: 50 }}
    />
  );
};
