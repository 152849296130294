import { httpsCallable, firestore } from 'data/firebase';
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

import { encodeToken } from 'utils/token.utils';
import dbLabels from 'data/db.labels';

import { EPhotoRequestStatus, IPhotoRequest } from 'types/photoRequest.types';
import { IPhotoReqTokenPayload } from 'types/token.types';

export default async (data: IPhotoReqTokenPayload | IPhotoReqTokenPayload[]) => {
  const users = Array.isArray(data) ? data : [data];

  const usersDetails = users.map((cardDetails) => {
    const token = encodeToken(cardDetails);
    const url = `${window.location.origin}/photo-verification/${token}`;

    return { ...cardDetails, url, token };
  });

  const promise = async () => {
    const ref = collection(firestore, dbLabels.photorequest);
    const requestObjects = await getDocs(
      query(
        ref,
        where(
          'employeeId',
          'in',
          usersDetails.map(({ employeeId }) => employeeId),
        ),
      ),
    ).then((ss) =>
      ss.docs.map((d) => ({ ...(d.data() as IPhotoRequest), id: d.id })),
    );

    const requestPromises = usersDetails.map((user) => {
      const requestData = {
        companyId: user.companyId,
        createdOn: new Date(),
        employeeId: user.employeeId,
        cardId: user.cardId,
        expirationDate: user.expirationDate,
        modifiedOn: new Date(),
        status: EPhotoRequestStatus.pending,
        token: user.token,
      };

      const existingRequest = requestObjects.find(
        (req) => req.employeeId === user.employeeId,
      );

      const employeeRef = doc(
        firestore,
        dbLabels.company,
        user.companyId,
        dbLabels.employeeCards,
        user.cardId,
      );

      if (
        existingRequest &&
        existingRequest.status !== EPhotoRequestStatus.accepted &&
        existingRequest.status !== EPhotoRequestStatus.expired
      ) {
        return Promise.all([
          updateDoc(doc(ref, existingRequest.id), requestData),
          updateDoc(employeeRef, { lastPhotoRequest: requestData.createdOn }),
        ]);
      }

      return Promise.all([
        setDoc(doc(ref), requestData),
        updateDoc(employeeRef, { lastPhotoRequest: requestData.createdOn }),
      ]);
    });

    return Promise.all(requestPromises);
  };

  const sendVerificationEmail = httpsCallable('sendPhotoVerificationEmail');

  await Promise.all([promise(), sendVerificationEmail({ usersDetails })]);
};
