import React from 'react';

export const PhotoVerification: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_322_233)">
        <path d="M10.8333 11.7183V18.3333H3.33331C3.33322 17.3874 3.53442 16.4524 3.92354 15.5902C4.31265 14.7281 4.88077 13.9586 5.59016 13.3329C6.29955 12.7072 7.13396 12.2397 8.03796 11.9613C8.94195 11.6829 9.89484 11.6001 10.8333 11.7183V11.7183ZM9.99998 10.8333C7.23748 10.8333 4.99998 8.59583 4.99998 5.83333C4.99998 3.07083 7.23748 0.833328 9.99998 0.833328C12.7625 0.833328 15 3.07083 15 5.83333C15 8.59583 12.7625 10.8333 9.99998 10.8333ZM14.8275 16.595L17.7733 13.6492L18.9525 14.8275L14.8275 18.9525L11.8808 16.0058L13.06 14.8275L14.8266 16.595H14.8275Z" />
      </g>
      <defs>
        <clipPath id="clip0_322_233">
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};
