import React from 'react';
import { Container, Filler } from './ProgressBarStyles';

interface Props {
  intent?: 'success' | 'danger';
  value: number; // From 0 to 100
}

export const ProgressBar: React.FC<Props> = ({
  intent = 'success',
  value,
}: Props) => (
  <Container>
    <Filler {...{ intent, value }} />
  </Container>
);
