import { auth, firestore } from 'data/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import dbLabels from 'data/db.labels';

import { Adapter } from 'data/types';
import { IUser } from 'types/user.types';

interface IArgs {
  password: string;
  userData: IUser;
}

export default async ({ password, userData }: IArgs): Promise<Adapter<IUser>> => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      userData.emailAddress,
      password,
    );

    await setDoc(doc(firestore, dbLabels.user, userCredential.user?.uid), userData);

    return { success: true };
  } catch (err) {
    const errorMessage = err as string;

    return { success: false, error: errorMessage };
  }
};
