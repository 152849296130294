import styled from 'styled-components';

interface IButton {
  dark?: boolean;
  intent?: 'primary' | 'secondary' | 'success' | 'danger' | 'warn';
  large?: boolean;
}

export const Container = styled.button<IButton>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  user-select: none;
  width: fit-content;
  height: fit-content;
  transition: all 0.2s;
  gap: 7px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-family: ${({ theme }) => theme.secondaryFont};
  font-size: ${({ large }) => (large ? 18 : 15)}px;
  font-weight: ${({ theme }) => theme.weightRegular};
  color: ${({ theme, dark, intent }) =>
    dark
      ? 'white'
      : intent === 'primary'
      ? theme.primaryYellow
      : intent === 'secondary'
      ? theme.secondaryViolet
      : intent === 'success'
      ? theme.primaryGreen
      : intent === 'danger'
      ? theme.colorDanger
      : theme.secondaryBlack};

  &:hover:not([disabled]) {
    transform: scale(1.05);
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  @media (max-width: ${(props) => props.theme.sizeRanges.tabletLarge.min}px) {
    font-size: 15px;
    line-height: 15px;
  }

  @media (max-width: ${(props) => props.theme.sizeRanges.tablet.min}px) {
    font-size: ${({ large }) => (large ? 17 : 12)}px;
    line-height: 13px;
  }

  @media (max-width: ${(props) => props.theme.widthMobile}) {
    font-size: 11px;
  }
`;
