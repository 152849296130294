import { applyMiddleware, StoreEnhancer, AnyAction } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

declare global {
  interface Window {
    devToolsExtension?: () => StoreEnhancer;
  }
}

const createMiddlewareEnhancer = () => {
  const middleware = [thunk];

  return applyMiddleware(...middleware);
};

const createEnhancers = () => {
  const enhancers = [];

  enhancers.push(createMiddlewareEnhancer());

  if (process.env.NODE_ENV === 'development') {
    const windowIfDefined = typeof window === 'undefined' ? null : window;
    if (windowIfDefined && windowIfDefined.devToolsExtension) {
      enhancers.push(windowIfDefined.devToolsExtension());
    }
  }

  return enhancers;
};

const store = configureStore({
  reducer: rootReducer,
  enhancers: createEnhancers(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, AnyAction>;

export default store;
