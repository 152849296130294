import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { useMutation } from 'react-query';
import { firestore, storage } from 'data/firebase';
import { useAppSelector } from 'state/hooks';

import { FailToast } from 'components/toasts';
import dbLabels from 'data/db.labels';

interface IArgs {
  onSuccess?: () => void;
}

export default (args?: IArgs) => {
  const companyId = useAppSelector(({ company }) => company.currentCompany?.id);

  const mutation = useMutation(
    async (image: File) => {
      const path = `/company-logo/${companyId}.png`;

      const photoRef = ref(storage, path);
      await uploadBytes(photoRef, image);

      const photoURL = await getDownloadURL(photoRef);

      const docRef = doc(firestore, dbLabels.company, companyId || '');
      await updateDoc(docRef, { logoURL: photoURL });
    },
    {
      onSuccess: () => {
        args?.onSuccess && args.onSuccess();
      },
      onError: () => {
        FailToast('Failed to send requests');
      },
    },
  );

  return { updateCompanyLogo: mutation.mutateAsync, ...mutation };
};
