import styled from 'styled-components';

interface ISync {
  isLoading: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  flex: 1;
  position: relative;
`;

export const EndContainer = styled.div`
  margin-left: auto;
  margin-right: 40px;
`;

export const Sync = styled.div<ISync>`
  margin-right: 10px;
  color: ${({ theme }) => theme.gray};
  cursor: pointer;
  margin-left: auto;
  position: absolute;
  right: 0;
  transition: all 1s;
  animation: rotate 400ms infinite linear;
  animation-play-state: paused;

  ${({ isLoading }) =>
    isLoading ? `animation-play-state: running;` : `transform: translateX(10px);`}

  &:hover {
    color: ${({ theme }) => theme.secondaryBlack};
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }
`;
