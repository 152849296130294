import styled from 'styled-components';

interface ICircle {
  type: 'active' | 'inactive';
}

export const Container = styled.div<ICircle>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  max-width: 310px;
  width: 100%;
  overflow: hidden;
  min-height: 80px;

  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.primaryBlack};
  background-color: ${({ theme, type }) =>
    type === 'active' ? theme.secondaryVioletTrans : theme.lightGrayBg};

  @media (max-width: ${({ theme }) => theme.sizeRanges.mobileLarge.max}px) {
    max-width: 100%;
  }
`;

export const StatsTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 15px;
  line-height: 15px;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const StatsValue = styled.div`
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 38px;
  line-height: 60px;
  color: ${({ theme }) => theme.primaryBlack};
`;
