import React, { ReactElement, useEffect, useState } from 'react';
import { useAppSelector } from 'state/hooks';
import { Loader } from 'components/loader';
import { LoadingContainer } from './TransitionWrapperStyles';

interface IProps {
  children: ReactElement;
  delay?: number;
}

export const TransitionWrapper: React.FC<IProps> = ({
  children,
  delay = 1000,
}: IProps) => {
  const [isPreparing, setIsPreparing] = useState(true);
  const currentCompanyId = useAppSelector(
    ({ company }) => company.currentCompany?.id,
  );

  useEffect(() => {
    !isPreparing && setIsPreparing(true);
    setTimeout(() => setIsPreparing(false), delay);
  }, [currentCompanyId]);

  if (isPreparing)
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );

  return children;
};
