import styled from 'styled-components';

export const BackBtnContainer = styled.div`
  i {
    cursor: pointer;
  }
`;

export const Inner = styled.div`
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.sizeRanges.tablet.max}px) {
    grid-template-columns: 1fr;
  }
`;

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 0.01fr;
  gap: 30px;
`;
