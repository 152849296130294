import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'state/hooks';
import { launchCardManagement } from 'state/card-management/CardManagementActions';
import useCards from 'domains/hooks/useCards';

import { PATHS } from 'routes';

import { Arrow } from 'components/icons';
import { CardUpdatesRow } from '../updates-list-row/CardUpdatesRow';
import { ContentBox } from 'domains/navigation';
import { Loader } from 'components/loader';

import { Empty, SeeAllRow } from './SummerizedCardsListStyles';

export const SummerizedCardsList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { filteredCards = [], isLoading } = useCards();

  const list = useMemo(() => filteredCards.slice(0, 6), [filteredCards]);

  const { t } = useTranslation();
  const tSeeAll = t('See All');
  const tNoData = t('No data available');

  useEffect(() => {
    dispatch(launchCardManagement());
  }, []);

  return (
    <ContentBox>
      {isLoading ? (
        <Empty>
          <Loader />
        </Empty>
      ) : list.length ? (
        list.map((card) => (
          <CardUpdatesRow cardInstance={card} key={`update#${card.id}`} />
        ))
      ) : (
        <Empty>{tNoData}</Empty>
      )}

      <SeeAllRow onClick={() => navigate(PATHS.cardManagement)}>
        <span>{tSeeAll}</span>
        <Arrow />
      </SeeAllRow>
    </ContentBox>
  );
};
