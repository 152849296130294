import React from 'react';
import { useAppSelector } from 'state/hooks';
import { useTranslation } from 'react-i18next';

import { formatName } from 'utils/user.utils';
import useDimensions from 'hooks/useDimensions';
import useToggleCardStatus from 'data/data-hooks/mutations/card/useToggleCardStatus';

import { AvatarCircle } from 'components/avatar-circle/AvatarCircle';
import { EditCardPanel } from 'domains/cards/components/edit-card-panel';
import { FlatButton } from 'components/buttons';

import { ListRowContainer } from '../../SharedStyles';
import { IEmployeeCard } from 'types/card.types';
import {
  Details,
  EmployeeContainer,
  Status,
  UserId,
  UserName,
} from './CardUpdatesRowStyles';
import { checkPhoto } from 'utils/photo.utils';

interface Props {
  cardInstance: IEmployeeCard;
}

export const CardUpdatesRow: React.FC<Props> = ({ cardInstance }: Props) => {
  const { t } = useTranslation();
  const company = useAppSelector(({ company }) => company.currentCompany);
  const allowDisable = company?.configuration?.allowDisable ?? false;

  const { mutateAsync: toggleStatus, isLoading } = useToggleCardStatus();
  const { isInMobile, isInTablet } = useDimensions();

  if (!cardInstance) return null;

  const { cardId, photoUrl, employeeNumber, employeeId, isActive } = cardInstance;

  const tActive = t('Active');
  const tInactive = t('Inactive');
  const tDisable = t('DISABLE');
  const tEnable = t('ENABLE');

  const changeStatus = () => {
    toggleStatus({ id: cardId, isCurrentlyActive: isActive });
  };

  return (
    <ListRowContainer>
      <EmployeeContainer>
        <AvatarCircle
          size={40}
          photoUrl={checkPhoto(photoUrl)}
          name={formatName(cardInstance) || ''}
        />
        <Details>
          <UserName>{formatName(cardInstance)}</UserName>
          <UserId>{employeeNumber || employeeId}</UserId>
        </Details>
      </EmployeeContainer>

      <Status isActive={isActive}>{isActive ? tActive : tInactive}</Status>

      <EditCardPanel cardInstance={cardInstance} />

      {!isInMobile && !isInTablet && allowDisable && (
        <FlatButton
          isLoading={isLoading}
          onClick={changeStatus}
          icon={isActive ? 'far fa-times-circle' : 'far fa-check-circle'}
        >
          {isActive ? tDisable : tEnable}
        </FlatButton>
      )}
    </ListRowContainer>
  );
};
