import React, { useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  toggleSelectAll,
  toggleSelectCard,
} from 'state/card-tracker/CardTrackerActions';

import { PaginationRow } from 'components/pagination';
import { TableHeader } from 'components/table';
import { Title } from './components/Title/Title';
import {
  TableBody,
  TableContent,
  TableWrapper,
  useAppTable,
} from 'components/table';

import { IDateRange } from 'types/shared.types';
import { ITableProps, TIstFilterActive } from 'components/table/types';
import { EGenerationStatus, ICardGeneration } from 'types/card.types';
import { Container, PaginationContainer } from './TrackerTableStyles';

interface Props extends ITableProps {
  headerProps: ITableProps['headerProps'] & {
    startDate: Date;
    endDate: Date;
    setCustomDateRange: ({ startDate, endDate }: IDateRange) => void;
  };
}

export const TrackerTable: React.FC<Props> = ({ headerProps, ...props }: Props) => {
  const [isFilterActive, setIsFilterActive] = useState<TIstFilterActive>({
    hireDate: false,
  });
  const { pageSize, selectedCardIds } = useAppSelector(
    ({ cardTracker }) => cardTracker,
  );

  const dispatch = useAppDispatch();
  const colSpans = '60px 1.4fr 1fr 1.1fr 0.9fr 25px';

  const failedCards = useMemo(
    () =>
      (props.data as ICardGeneration[]).filter(
        (card) =>
          card.passStatus !== 'invalid' &&
          (card.status === EGenerationStatus.failedToEmail ||
            card.status === EGenerationStatus.failedToGenerate),
      ),
    [props.data],
  );

  const toggleSelectAllCards = () => dispatch(toggleSelectAll(failedCards));
  const isAllSelected =
    failedCards.length && failedCards.every(({ id }) => selectedCardIds.has(id));

  const selectProps = useMemo<ITableProps['selectProps']>(
    () => ({
      onSelect: (card) => dispatch(toggleSelectCard(card.id)),
      isRowDisabled: (row) =>
        row.passStatus === 'invalid' ||
        (row.status !== EGenerationStatus.failedToEmail &&
          row.status !== EGenerationStatus.failedToGenerate),
      selectedRows: [...selectedCardIds],
    }),
    [selectedCardIds],
  );

  const {
    getPaginationProps,
    getTableBodyProps,
    getTableProps,
    headerGroups,
    onToggleSort,
    renderRows,
  } = useAppTable({
    ...props,
    columnsSpans: colSpans,
    canSelect: true,
    headerProps: { ...headerProps, showFilters: true },
    selectProps,
    options: {
      usePagination: true,
      pageSize,
      useFilters: true,
    },
  });

  return (
    <Container>
      <TableWrapper>
        <Title {...headerProps} data={props.data} />
        <TableContent {...getTableProps()}>
          <TableHeader
            headerGroups={headerGroups}
            isFilterActive={isFilterActive}
            onToggleSort={onToggleSort}
            rowsCount={(props.data || []).length}
            setIsFilterActive={setIsFilterActive}
            columnsSpans={colSpans}
            toggleSelectAll={toggleSelectAllCards}
            isAllSelected={isAllSelected || !!selectedCardIds.size}
            canSelectAll={!!failedCards.length}
            checkboxCol
          />

          <TableBody {...getTableBodyProps()}>{renderRows()}</TableBody>

          <PaginationContainer>
            <PaginationRow {...getPaginationProps()} />
          </PaginationContainer>
        </TableContent>
      </TableWrapper>
    </Container>
  );
};
