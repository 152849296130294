import React, { useMemo } from 'react';

import useAppTable from './hooks/useAppTable';
import { TableHeader } from './components/header';
import { PaginationRow } from 'components/pagination';

import { ITableProps, TIstFilterActive } from './types';
import {
  Content,
  Body,
  Container,
  Wrapper,
  PaginationContainer,
} from './TableStyles';
import { Title } from './components/Title/Title';

interface Props extends ITableProps {
  isFilterActive?: TIstFilterActive;
  setIsFilterActive?: (
    value: ((prev: TIstFilterActive) => TIstFilterActive) | boolean,
  ) => void;
}

export const Table: React.FC<Props> = ({
  headerProps: _headerProps,
  isFilterActive = {},
  setIsFilterActive,
  title,
  ..._props
}: Props) => {
  // This type of memoization prevents extra rerenders
  // if the whole object is passed to the dependancy array (e.g. [_props]),
  // it appears to trigger the useMemo fn more times than when each property is
  // passed individually
  type propsKey = keyof typeof _props;
  const props = useMemo(
    () => _props,
    [...Object.keys(_props).map((k) => _props[k as propsKey])],
  );
  type headerPropsKey = keyof typeof _headerProps;
  const headerProps = useMemo(
    () => _headerProps,
    [
      _headerProps
        ? [
            ...Object.keys(_headerProps).map(
              (k) => _headerProps[k as headerPropsKey],
            ),
          ]
        : [],
    ],
  );

  const {
    getPaginationProps,
    getTableBodyProps,
    getTableProps,
    headerGroups,
    onToggleSort,
    renderRows,
    usePagination,
  } = useAppTable(props);

  return (
    <Container>
      <Wrapper>
        {(title || headerProps) && <Title title={title} {...headerProps} />}
        <Content {...getTableProps()}>
          <TableHeader
            checkboxCol={props.canSelect}
            columnsSpans={props.columnsSpans}
            headerGroups={headerGroups}
            isFilterActive={isFilterActive}
            onToggleSort={onToggleSort}
            rowsCount={(props.data || []).length}
            setIsFilterActive={setIsFilterActive}
            {...(props.selectProps || {})}
          />

          <Body {...getTableBodyProps()}>{renderRows()}</Body>

          {usePagination && (
            <PaginationContainer>
              <PaginationRow {...getPaginationProps()} />
            </PaginationContainer>
          )}
        </Content>
      </Wrapper>
    </Container>
  );
};
