import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { clearSelection } from 'state/card-management/CardManagementActions';
import { useTheme } from 'styled-components';
import useToggleCardStatus from 'data/data-hooks/mutations/card/useToggleCardStatus';

import { ControlBarButton } from 'components/table-control-bar';
import { ConfirmDenyModal, Prompt } from 'components/modals';

import { Bold } from '../SharedStyles';

export const EnableAllModal: React.FC = () => {
  const [shouldShow, setShouldShow] = useState(false);
  const dispatch = useAppDispatch();

  const handleOpen = () => setShouldShow(true);
  const handleClose = () => setShouldShow(false);

  const theme = useTheme();
  const { selectedCardsIds } = useAppSelector(
    ({ cardManagement }) => cardManagement,
  );
  const count = selectedCardsIds.size;

  const { mutateAsync: toggleStatus, isLoading } = useToggleCardStatus({
    onSuccess: handleClose,
  });

  const onConfirm = () =>
    Promise.all(
      [...selectedCardsIds].map((id) =>
        toggleStatus({ id, isCurrentlyActive: false }),
      ),
    ).then(() => dispatch(clearSelection()));

  const { t } = useTranslation();
  const tEnableAll = t('Enable All');
  const tCards = t(`${count} card${count === 1 ? '' : 's'}`);
  const tPrompt = t(`You are going to enable `);

  return (
    <Fragment>
      <ControlBarButton onClick={handleOpen} isLoading={isLoading}>
        {tEnableAll}
      </ControlBarButton>

      <ConfirmDenyModal
        cancelLabel="Cancel"
        closeModal={handleClose}
        confirmLabel={tEnableAll}
        icon={
          <i
            style={{ fontSize: 30, color: theme.secondaryViolet }}
            className="far fa-check-circle"
          />
        }
        isLoading={isLoading}
        onConfirm={onConfirm}
        onDeny={handleClose}
        prompt={
          <Prompt>
            {tPrompt} <Bold>{tCards}</Bold>
          </Prompt>
        }
        shouldShow={shouldShow}
        intent="secondary"
      />
    </Fragment>
  );
};
