import React, { useEffect, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { DragPhoto } from './components/DragPhoto';

import { Instructions, PhotoPreview } from './PhotoPickerStyles';

type Props = {
  photoUrl?: string;
  setEditorRef: (editor: AvatarEditor | null) => AvatarEditor | null;
};

export const PhotoPicker: React.FC<Props> = ({ photoUrl, setEditorRef }: Props) => {
  const [isMoving, setIsMoving] = useState(false);
  const [image, setImage] = useState<File | string>('');

  useEffect(() => {
    if (photoUrl) setImage(photoUrl);
    else setImage('');
  }, [photoUrl]);

  return (
    <PhotoPreview>
      {!isMoving && (
        <Instructions>
          <DragPhoto />
          Drag to reposit
        </Instructions>
      )}
      <AvatarEditor
        height={320}
        image={image}
        ref={setEditorRef}
        width={320}
        crossOrigin="anonymous"
        scale={1.1}
        borderRadius={20}
        border={15}
        backgroundColor={'#fff'}
        onMouseMove={() => setIsMoving(true)}
        onMouseUp={() => setIsMoving(false)}
      />
    </PhotoPreview>
  );
};
