import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FlatButton } from 'components/buttons';
import { Container } from './ActionsStyles';

interface Props {
  canReset: boolean;
  isLoading: boolean;
  isTouched: boolean;
  onSubmit: () => void;
  reset: () => void;
}

export const Actions: React.FC<Props> = ({
  canReset,
  isLoading,
  isTouched,
  onSubmit,
  reset,
}: Props) => {
  const { t } = useTranslation();
  const tReset = t('Reset Colors');
  const tSave = t('Save Changes');

  return (
    <Container>
      {!!canReset && (
        <FlatButton
          isDisabled={isLoading}
          onClick={reset}
          icon="far fa-times-circle"
        >
          {tReset}
        </FlatButton>
      )}
      <Button
        intent="success"
        isDisabled={!isTouched}
        isLoading={isLoading}
        onClick={onSubmit}
        size="medium"
      >
        {tSave}
      </Button>
    </Container>
  );
};
