/* eslint-disable @typescript-eslint/no-explicit-any */
export const parseValue = (object: any, path: string) => {
  const split = path.split('.');
  let value = object;
  for (const key of split) {
    if (!value) return undefined;
    value = value[key];
  }

  return value;
};

/* eslint-disable @typescript-eslint/no-unused-vars */
export const stringifyObjectArray = (obj: any[]) =>
  obj
    .map((el) =>
      Object.entries(el)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([_, val]) => val)
        .join(','),
    )
    .sort()
    .join('-');
/* eslint-enable @typescript-eslint/no-unused-vars */

export const merge = <T1 = Record<string, any>, T2 = Record<string, any>>(
  obj1: T1,
  obj2: T2,
) => {
  const answer: Record<string, any> = {};

  for (const key in obj1) {
    if (answer[key] === undefined || answer[key] === null) answer[key] = obj1[key];
  }
  for (const key in obj2) {
    if (answer[key] === undefined || answer[key] === null) answer[key] = obj2[key];
  }
  return answer as T1 & T2;
};
