import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.primaryBlack};
  padding: 60px 85px 0px;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    padding: 25px 45px;
  }
`;

export const Border = styled.div`
  border: 2px solid ${({ theme }) => theme.primaryYellow};
  border-radius: 20px;
  padding: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.sizeRanges.desktop.min}px) {
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;
