import React from 'react';

export const Dashboard: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_322_223)">
        <path d="M2.50002 2.5H17.5C17.721 2.5 17.933 2.5878 18.0893 2.74408C18.2456 2.90036 18.3334 3.11232 18.3334 3.33333V16.6667C18.3334 16.8877 18.2456 17.0996 18.0893 17.2559C17.933 17.4122 17.721 17.5 17.5 17.5H2.50002C2.27901 17.5 2.06704 17.4122 1.91076 17.2559C1.75448 17.0996 1.66669 16.8877 1.66669 16.6667V3.33333C1.66669 3.11232 1.75448 2.90036 1.91076 2.74408C2.06704 2.5878 2.27901 2.5 2.50002 2.5ZM5.83335 10.8333V14.1667H7.50002V10.8333H5.83335ZM9.16669 5.83333V14.1667H10.8334V5.83333H9.16669ZM12.5 8.33333V14.1667H14.1667V8.33333H12.5Z" />
      </g>
      <defs>
        <clipPath id="clip0_322_223">
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};
