import styled from 'styled-components';

export const ContainerA = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  height: 10px;
  width: 10px;
`;

export const Path = styled.path`
  fill: ${({ theme }) => theme.primaryBlack};
`;
