import React from 'react';
import { Trans } from 'react-i18next';
import { HeaderGroup } from 'react-table';

import { Checkbox } from 'components/checkbox';
import ArrowIcon from 'components/table/icons/arrow/Arrow';

import { TIstFilterActive } from 'components/table/types';
import { TableRow } from '../row/RowStyles';
import {
  ArrowContainer,
  FilterIcon,
  Container,
  FilterContainer,
  HeaderCell,
  TextContainer,
} from './HeaderStyles';

interface ITableHeaderProps {
  canSelectAll?: boolean;
  checkboxCol?: boolean;
  columnsSpans?: string;
  isAllSelected?: boolean;
  isFilterActive?: TIstFilterActive;
  onToggleSort: () => void;
  rowsCount: number;
  selectedRows?: string[];
  setIsFilterActive?: (
    value: (prev: TIstFilterActive) => TIstFilterActive | TIstFilterActive,
  ) => void;
  toggleSelectAll?: () => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerGroups: HeaderGroup<any>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (row: any, e?: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TableHeader: React.FC<ITableHeaderProps> = ({
  canSelectAll = true,
  checkboxCol,
  columnsSpans,
  headerGroups,
  isAllSelected,
  isFilterActive,
  onToggleSort,
  rowsCount,
  selectedRows,
  setIsFilterActive,
  toggleSelectAll,
}: ITableHeaderProps) => {
  const toggleSort = (column: HeaderGroup) => {
    if (column.canSort) {
      onToggleSort();
      column.toggleSortBy(!column.isSortedDesc);
    }
  };

  const toggleFilter = (column: HeaderGroup) => {
    if (column.canFilter && setIsFilterActive) {
      setIsFilterActive((prev) => ({
        ...prev,
        [column.id]: !prev[column.id],
      }));
    }
  };

  const showCheckbox = checkboxCol && toggleSelectAll;

  return (
    <Container>
      {headerGroups.map((headerGroup, index) => (
        <TableRow
          {...headerGroup.getHeaderGroupProps()}
          key={index}
          length={headerGroup.headers.length}
          checkboxCol={checkboxCol}
          columnsSpans={columnsSpans}
          header
        >
          {headerGroup.headers.map((column, index) => (
            <HeaderCell
              checkboxCol={checkboxCol}
              {...column.getHeaderProps()}
              canFilter={column.canFilter}
              canSort={column.canSort}
              key={index}
            >
              {!!showCheckbox && column.id === 'checkbox' ? (
                <Checkbox
                  handleCheckClick={toggleSelectAll}
                  isSelected={
                    isAllSelected === undefined
                      ? !!rowsCount && !!selectedRows?.length
                      : isAllSelected
                  }
                  isDisabled={!canSelectAll || !rowsCount}
                  icon={isAllSelected || selectedRows?.length ? 'minus' : 'check'}
                />
              ) : (
                <TextContainer onClick={() => toggleSort(column)}>
                  <Trans>{column.render('Header')}</Trans>
                </TextContainer>
              )}
              {column.canSort && (
                <ArrowContainer onClick={() => toggleSort(column)}>
                  <ArrowIcon
                    isSorted={column.isSorted}
                    isSortedDesc={column.isSortedDesc || false}
                  />
                </ArrowContainer>
              )}
              {column.canFilter && (
                <>
                  <FilterContainer onClick={() => toggleFilter(column)}>
                    <FilterIcon
                      className="fas fa-filter"
                      color={isFilterActive?.[column.id] ? '#000000' : undefined}
                    />
                  </FilterContainer>

                  {isFilterActive?.[column.id] &&
                    column.render('Filter', { setIsFilterActive })}
                </>
              )}
            </HeaderCell>
          ))}
        </TableRow>
      ))}
    </Container>
  );
};
