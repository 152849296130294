import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'components/loader';

import { Container } from './FlatButtonStyles';

interface Props {
  children: React.ReactNode;
  dark?: boolean;
  icon?: string;
  intent?: 'primary' | 'secondary' | 'success' | 'danger' | 'warn';
  isDisabled?: boolean;
  isLoading?: boolean;
  large?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  type?: 'button' | 'submit' | 'reset';
}

export const FlatButton: React.FC<Props> = ({
  children,
  dark = false,
  icon,
  intent,
  isDisabled = false,
  isLoading = false,
  large = false,
  onClick,
  type = 'button',
}: Props) => {
  const { t } = useTranslation();

  const getContent = () => {
    if (typeof children === 'string') {
      const text = t(children);

      return text;
    }

    return children;
  };

  return (
    <Container
      dark={dark}
      disabled={isDisabled || isLoading}
      intent={intent}
      large={large}
      onClick={onClick}
      type={type}
    >
      {!isLoading && !!icon && <i className={icon} />}
      {isLoading ? <Loader size={5} /> : getContent()}
    </Container>
  );
};
