import styled from 'styled-components';

export const Container = styled.div`
  left: -85px;
  position: absolute;
  top: 30px;
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  z-index: 99999;

  .react-datepicker {
    border: none;
  }

  .react-datepicker__header {
    background-color: transparent;
    border: none;
    padding-bottom: 0px;
    font-family: ${({ theme }) => theme.secondaryFont};
    color: ${({ theme }) => theme.secondaryBlack};
    font-weight: ${({ theme }) => theme.weightSemiBold};
    font-size: 14px;
  }

  .react-datepicker__current-month {
    padding-bottom: 10px;
  }

  .react-datepicker__day--today:not(
      .react-datepicker__day--range-start,
      .react-datepicker__day--selecting-range-start,
      .react-datepicker__day--range-end,
      .react-datepicker__day--selecting-range-end
    ) {
    background-color: transparent !important;
    font-weight: ${({ theme }) => theme.weightSemiBold};
  }

  .react-datepicker__day--in-range:not(
      .react-datepicker__day--range-start,
      .react-datepicker__day--selecting-range-start,
      .react-datepicker__day--range-end,
      .react-datepicker__day--selecting-range-end
    ),
  .react-datepicker__day--in-selecting-range:not(
      react-datepicker__day--range-start,
      react-datepicker__day--selecting-range-start,
      react-datepicker__day--range-end,
      react-datepicker__day--selecting-range-end
    ) {
    color: ${({ theme }) => theme.secondaryBlack};
    background-color: ${({ theme }) => theme.secondaryVioletTrans}50;
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--selecting-range-start {
    color: white !important;
    background-color: ${({ theme }) => theme.secondaryViolet} !important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-end {
    color: white !important;
    background-color: ${({ theme }) => theme.secondaryViolet} !important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .react-datepicker__day--range-start.react-datepicker__day--range-end {
    border-radius: 0.3rem;
    background-color: ${({ theme }) => theme.secondaryViolet} !important;
    color: white;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${({ theme }) => theme.secondaryVioletTrans}70;
  }

  .react-datepicker__day--keyboard-selected:hover {
    background-color: ${({ theme }) => theme.secondaryVioletTrans};
  }

  .react-datepicker__day--outside-month {
    opacity: 0.75;
    color: ${({ theme }) => theme.gray};
  }
`;

export const Popup = styled.div`
  align-items: flex-end;
  background-color: white;
  display: flex;
  flex-direction: column;
  min-width: 92px;
  position: relative;
  z-index: 10;
  border-radius: 5px;
  padding: 10px;

  &:after {
    bottom: 100%;
    left: 70%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-bottom: 6px solid white;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px 0px;
`;
