export const SEARCH__CLEAR_VALUE = 'SEARCH__CLEAR_VALUE';
export const SEARCH__SET_IS_LOADING = 'SEARCH__SET_IS_LOADING';
export const SEARCH__SET_VALUE = 'SEARCH__SET_VALUE';

interface clearValueAction {
  type: typeof SEARCH__CLEAR_VALUE;
}

interface SetIsLoadingAction {
  type: typeof SEARCH__SET_IS_LOADING;
  isLoading: boolean;
}

interface SetValueAction {
  type: typeof SEARCH__SET_VALUE;
  value: string;
}

export type SearchBarActionTypes =
  | clearValueAction
  | SetIsLoadingAction
  | SetValueAction;

export type TClearValue = () => clearValueAction;
export type TSetIsLoading = (bool: boolean) => SetIsLoadingAction;
export type TSetValue = (val: string) => SetValueAction;
