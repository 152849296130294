import styled from 'styled-components';

interface IFilterBtn {
  isActive: boolean;
}

export const TitleAndActions = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  gap: 20px;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

export const FilterButton = styled.button<IFilterBtn>`
  min-width: 35px;
  border-radius: 50px;
  font-size: 12px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.primaryBlackTrans : 'transparent'};
  font-weight: ${({ theme, isActive }) =>
    isActive ? theme.weightBold : theme.weightRegular};
  cursor: pointer;
  padding: 7px;
  transistion: all 0.2 ease-out;

  ${({ isActive }) => isActive && `transform: scale(1.03);`};

  &:hover:not([disabled]) {
    transform: scale(1.03);
  }
`;
