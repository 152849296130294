import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { forgotPassword } from 'state/auth/AuthActions';
import useAppForm from 'hooks/useAppForm';

import { SubmitButton, FlatButton } from 'components/buttons';
import { Footer, PublicHeaderNav } from 'domains/navigation';
import { Input } from 'components/inputs';
import { FailToast, SuccessToast } from 'components/toasts';
import { PATHS } from 'routes';

import {
  Actions,
  Container,
  ContentContainer,
  Form,
  Title,
} from './ForgotPasswordStyles';

interface FormData {
  email: string;
}

export const ForgotPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading } = useAppSelector(({ auth }) => auth);

  const onSubmit = async () => {
    const { success, error: errorMsg } = await dispatch(forgotPassword(formData));
    if (success) SuccessToast('Reset link was sent to your email');
    else errorMsg && FailToast(errorMsg);
  };

  const { formData, formErrors, handleChange, handleSubmit } = useAppForm<FormData>(
    {
      initialValues: [{ key: 'email', required: true, value: '' }],
      onSubmit,
    },
    [],
  );

  const { t } = useTranslation();
  const buttonText = !isLoading ? t('Reset Password') : t('Loading...');
  const backText = t('Back to Log In');
  const headingText = t('Forgot Password');
  const paragraphText = t("We'll help you reset it");

  return (
    <Container>
      <PublicHeaderNav />

      <ContentContainer>
        <Title>{headingText}</Title>
        <h1>{paragraphText}</h1>

        <Form onSubmit={handleSubmit}>
          <Input
            error={formErrors.email}
            leadingIcon={<i className="fas fa-envelope" />}
            name="email"
            onChange={handleChange}
            placeholder="Email Address..."
            type="email"
            value={formData.email}
            success={!!formData.email && !formErrors.email}
          />
          <Actions>
            <FlatButton intent="primary" large onClick={() => navigate(PATHS.login)}>
              {backText}
            </FlatButton>
            <SubmitButton isLoading={isLoading} type="submit">
              {buttonText}
            </SubmitButton>
          </Actions>
        </Form>
      </ContentContainer>

      <Footer />
    </Container>
  );
};
