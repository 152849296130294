import { useMutation, useQueryClient } from 'react-query';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from 'data/firebase';
import { SuccessToast } from 'components/toasts';

import { useAppSelector } from 'state/hooks';

import { ITemplate } from 'types/emailTemplates.types';
import dbLabels from 'data/db.labels';

interface IArgs {
  onSuccess?: () => void;
}

export default (args?: IArgs) => {
  const currentCompany = useAppSelector(({ company }) => company.currentCompany);
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async ({ id, header, bodyText }: ITemplate) => {
      while (!currentCompany) continue;

      const tempRef = doc(
        firestore,
        dbLabels.company,
        currentCompany.id,
        dbLabels.templates,
        id,
      );

      await updateDoc(tempRef, { header, bodyText });
    },
    {
      onSuccess: async () => {
        SuccessToast('Template updated successfully');
        await queryClient.refetchQueries(['email_temps']);
        args?.onSuccess && (await args.onSuccess());
      },
    },
  );

  return { updateTemplate: mutation.mutateAsync, ...mutation };
};
