import styled from 'styled-components';

interface IIcon {
  danger?: boolean;
}

export const Container = styled.div``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const Icon = styled.i<IIcon>`
  font-size: 18px;
  color: ${({ theme, danger }) => (danger ? theme.colorDanger : theme.primaryGreen)};
`;
