import styled from 'styled-components';

export const Container = styled.div`
  height: 85px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 85px;
  background-color: ${({ theme }) => theme.primaryBlack};

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    padding: 0px 45px;
  }

  @media (max-width: ${(props) => props.theme.sizeRanges.tabletLarge.min}px) {
    padding: 20px;
  }
`;

export const ButtonContainer = styled.div`
  height: 40px;
  width: 120px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TopMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;
