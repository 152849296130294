import * as Actions from './AdminVerficationActions.types';

export const removeFromSelection: Actions.TRemoveFromSelectionAction = (id) => ({
  type: Actions.APV__REMOVE_FROM_SELECTION,
  id,
});

export const setIsLoading: Actions.TSetIsLoading = (isLoading) => ({
  isLoading,
  type: Actions.APV__SET_IS_LOADING,
});

export const toggleRequestSelection: Actions.TtoggleRequestSelection = (id) => ({
  type: Actions.APV__TOGGLE_EMPLOYEE_SELECTION,
  id,
});

export const toggleSelection: Actions.TToggleSelection = (allRequests) => ({
  type: Actions.APV__TOGGLE_SELECTION,
  allRequests,
});
