import React from 'react';
import { Cell } from 'react-table';

import { AvatarCircle } from 'components/avatar-circle/AvatarCircle';
import useDimensions from 'hooks/useDimensions';

import { ICardGeneration } from 'types/card.types';
import {
  AvatarContainer,
  Details,
  Row,
  UserId,
  UserName,
} from './PersonDetailsCellStyles';

interface Props extends Cell {
  value: ICardGeneration;
}

export const PersonDetailsCell: React.FC<Props> = React.memo(
  ({ value: card }: Props) => {
    const { PersonId, employeeNumber, PersonPhotoUrl, PersonName } = card;
    const { isInMobile, isInTablet } = useDimensions();

    return (
      <Row>
        {!isInMobile && !isInTablet && (
          <AvatarContainer>
            <AvatarCircle
              size={40}
              photoUrl={PersonPhotoUrl}
              name={card ? PersonName : ''}
            />
          </AvatarContainer>
        )}
        <Details>
          <Row>
            <UserName>{PersonName}</UserName>
          </Row>
          <UserId>{employeeNumber || PersonId}</UserId>
        </Details>
      </Row>
    );
  },
);
