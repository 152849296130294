import { Theme } from 'assets/styles';
import React from 'react';

export const Filter: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 256 256"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth={0}
      strokeDasharray="none"
      strokeLinecap="butt"
      strokeLinejoin="miter"
      strokeMiterlimit={10}
      fill="none"
      fillRule="nonzero"
      opacity={1}
      transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
    >
      <path
        d="M 85.813 59.576 H 55.575 c -1.657 0 -3 -1.343 -3 -3 s 1.343 -3 3 -3 h 30.237 c 1.657 0 3 1.343 3 3 S 87.47 59.576 85.813 59.576 z"
        stroke="none"
        strokeWidth={1}
        strokeDasharray="none"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill={Theme.secondaryBlack}
        fillRule="nonzero"
        opacity={1}
        transform=" matrix(1 0 0 1 0 0)"
        strokeLinecap="round"
      />
      <path
        d="M 48.302 66.849 c -5.664 0 -10.272 -4.608 -10.272 -10.272 c 0 -5.665 4.608 -10.273 10.272 -10.273 c 5.665 0 10.273 4.608 10.273 10.273 C 58.575 62.24 53.967 66.849 48.302 66.849 z M 48.302 52.303 c -2.356 0 -4.272 1.917 -4.272 4.273 c 0 2.355 1.917 4.272 4.272 4.272 c 2.356 0 4.273 -1.917 4.273 -4.272 C 52.575 54.22 50.658 52.303 48.302 52.303 z"
        stroke="none"
        strokeWidth={1}
        strokeDasharray="none"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill={Theme.secondaryBlack}
        fillRule="nonzero"
        opacity={1}
        transform=" matrix(1 0 0 1 0 0) "
        strokeLinecap="round"
      />
      <path
        d="M 41.029 59.576 H 4.188 c -1.657 0 -3 -1.343 -3 -3 s 1.343 -3 3 -3 h 36.842 c 1.657 0 3 1.343 3 3 S 42.686 59.576 41.029 59.576 z"
        stroke="none"
        strokeWidth={1}
        strokeDasharray="none"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill={Theme.secondaryBlack}
        fillRule="nonzero"
        opacity={1}
        transform=" matrix(1 0 0 1 0 0) "
        strokeLinecap="round"
      />
      <path
        d="M 85.813 36.424 h -57.79 c -1.657 0 -3 -1.343 -3 -3 s 1.343 -3 3 -3 h 57.79 c 1.657 0 3 1.343 3 3 S 87.47 36.424 85.813 36.424 z"
        stroke="none"
        strokeWidth={1}
        strokeDasharray="none"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill={Theme.secondaryBlack}
        fillRule="nonzero"
        opacity={1}
        transform=" matrix(1 0 0 1 0 0) "
        strokeLinecap="round"
      />
      <path
        d="M 20.75 43.697 c -5.665 0 -10.273 -4.608 -10.273 -10.273 s 4.608 -10.273 10.273 -10.273 s 10.273 4.608 10.273 10.273 S 26.414 43.697 20.75 43.697 z M 20.75 29.151 c -2.356 0 -4.273 1.917 -4.273 4.273 s 1.917 4.273 4.273 4.273 s 4.273 -1.917 4.273 -4.273 S 23.105 29.151 20.75 29.151 z"
        stroke="none"
        strokeWidth={1}
        strokeDasharray="none"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill={Theme.secondaryBlack}
        fillRule="nonzero"
        opacity={1}
        transform=" matrix(1 0 0 1 0 0) "
        strokeLinecap="round"
      />
      <path
        d="M 13.477 36.424 H 4.188 c -1.657 0 -3 -1.343 -3 -3 s 1.343 -3 3 -3 h 9.289 c 1.657 0 3 1.343 3 3 S 15.133 36.424 13.477 36.424 z"
        stroke="none"
        strokeWidth={1}
        strokeDasharray="none"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill={Theme.secondaryBlack}
        fillRule="nonzero"
        opacity={1}
        transform=" matrix(1 0 0 1 0 0) "
        strokeLinecap="round"
      />
      <path
        d="M 57.637 13.273 H 4.188 c -1.657 0 -3 -1.343 -3 -3 s 1.343 -3 3 -3 h 53.449 c 1.657 0 3 1.343 3 3 S 59.294 13.273 57.637 13.273 z"
        stroke="none"
        strokeWidth={1}
        strokeDasharray="none"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill={Theme.secondaryBlack}
        fillRule="nonzero"
        opacity={1}
        transform=" matrix(1 0 0 1 0 0) "
        strokeLinecap="round"
      />
      <path
        d="M 64.909 20.546 c -5.664 0 -10.272 -4.608 -10.272 -10.273 S 59.245 0 64.909 0 c 5.665 0 10.273 4.608 10.273 10.273 S 70.574 20.546 64.909 20.546 z M 64.909 6 c -2.355 0 -4.272 1.917 -4.272 4.273 s 1.917 4.273 4.272 4.273 c 2.356 0 4.273 -1.917 4.273 -4.273 S 67.266 6 64.909 6 z"
        stroke="none"
        strokeWidth={1}
        strokeDasharray="none"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill={Theme.secondaryBlack}
        fillRule="nonzero"
        opacity={1}
        transform=" matrix(1 0 0 1 0 0) "
        strokeLinecap="round"
      />
      <path
        d="M 85.813 13.273 h -13.63 c -1.657 0 -3 -1.343 -3 -3 s 1.343 -3 3 -3 h 13.63 c 1.657 0 3 1.343 3 3 S 87.47 13.273 85.813 13.273 z"
        stroke="none"
        strokeWidth={1}
        strokeDasharray="none"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill={Theme.secondaryBlack}
        fillRule="nonzero"
        opacity={1}
        transform=" matrix(1 0 0 1 0 0) "
        strokeLinecap="round"
      />
      <path
        d="M 85.813 82.728 h -57.79 c -1.657 0 -3 -1.343 -3 -3 s 1.343 -3 3 -3 h 57.79 c 1.657 0 3 1.343 3 3 S 87.47 82.728 85.813 82.728 z"
        stroke="none"
        strokeWidth={1}
        strokeDasharray="none"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill={Theme.secondaryBlack}
        fillRule="nonzero"
        opacity={1}
        transform=" matrix(1 0 0 1 0 0) "
        strokeLinecap="round"
      />
      <path
        d="M 20.75 90 c -5.665 0 -10.273 -4.608 -10.273 -10.272 c 0 -5.665 4.608 -10.273 10.273 -10.273 s 10.273 4.608 10.273 10.273 C 31.022 85.392 26.414 90 20.75 90 z M 20.75 75.454 c -2.356 0 -4.273 1.917 -4.273 4.273 c 0 2.355 1.917 4.272 4.273 4.272 s 4.273 -1.917 4.273 -4.272 C 25.022 77.371 23.105 75.454 20.75 75.454 z"
        stroke="none"
        strokeWidth={1}
        strokeDasharray="none"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill={Theme.secondaryBlack}
        fillRule="nonzero"
        opacity={1}
        transform=" matrix(1 0 0 1 0 0) "
        strokeLinecap="round"
      />
      <path
        d="M 13.477 82.728 H 4.188 c -1.657 0 -3 -1.343 -3 -3 s 1.343 -3 3 -3 h 9.289 c 1.657 0 3 1.343 3 3 S 15.133 82.728 13.477 82.728 z"
        stroke="none"
        strokeWidth={1}
        strokeDasharray="none"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill={Theme.secondaryBlack}
        fillRule="nonzero"
        opacity={1}
        transform=" matrix(1 0 0 1 0 0) "
        strokeLinecap="round"
      />
    </g>
  </svg>
);
