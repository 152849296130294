import styled from 'styled-components';

export const Container = styled.form`
  position: relative;
  width: 100%;
`;

export const Inner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 500px;
  max-width: 550px;
  max-height: 550px;

  overflow: hidden;

  #webcam {
    border-radius: inherit;
  }
`;

export const WebcamContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 340px;
  height: 340px;
  max-width: 550px;
  max-height: 550px;
  z-index: 2;

  overflow: hidden;

  #webcam {
    border-radius: inherit;
  }
`;

export const Canvas = styled.canvas`
  z-index: 2;
  width: 50vw;
  height: 50vw;
  width: 340px;
  height: 340px;
`;

export const LoaderContianer = styled.div`
  z-index: 0;
`;

export const Actions = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 20px);
  z-index: 2;
  margin: 5px 10px;
`;
