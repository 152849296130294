import { auth } from 'data/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

import { Adapter } from 'data/types';

interface IForgotUserPasswordDataArgs {
  email: string;
}

export default async ({ email }: IForgotUserPasswordDataArgs): Promise<Adapter> => {
  try {
    await sendPasswordResetEmail(auth, email);

    return { success: true };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (err.code !== 'auth/user-not-found') {
      const errorMessage = 'Something went wrong with trying to reset your password';
      return { success: false, error: errorMessage };
    } else {
      const errorMessage = "Email doesn't exist";
      return { success: false, error: errorMessage };
    }
  }
};
