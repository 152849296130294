import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FlatButton } from 'components/buttons';
import Modal from '..';

import { IButtonProps } from 'components/buttons/button/Button';
import {
  ButtonContainer,
  IconContainer,
  Inner,
  Prompt,
  PromptContianer,
} from './ConfirmDenyModalStyles';

interface Props {
  cancelLabel?: string;
  closeModal: (e: React.MouseEvent) => void;
  confirmLabel?: string;
  icon?: JSX.Element;
  intent?: IButtonProps['intent'];
  isLoading?: boolean;
  onConfirm: (e: React.MouseEvent) => void;
  onDeny?: (e: React.MouseEvent) => void;
  prompt: string | JSX.Element;
  shouldShow: boolean;
}

export const ConfirmDenyModal: React.FC<Props> = ({
  cancelLabel = 'No',
  closeModal,
  confirmLabel = 'Yes',
  icon,
  intent = 'dark',
  isLoading = false,
  onConfirm,
  onDeny,
  prompt,
  shouldShow,
}: Props) => {
  const { t } = useTranslation();
  const tQuestion = typeof prompt === 'string' ? t(prompt) : '';
  const tYes = t(confirmLabel);
  const tNo = t(cancelLabel);

  return (
    <Modal
      handleClose={isLoading ? () => null : closeModal}
      shouldShow={shouldShow}
      width="400px"
    >
      <Inner>
        <PromptContianer>
          {icon && <IconContainer>{icon}</IconContainer>}
          {typeof prompt === 'string' ? <Prompt>{tQuestion}</Prompt> : prompt}
        </PromptContianer>
        <ButtonContainer>
          {onDeny && (
            <FlatButton
              isDisabled={isLoading}
              icon="far fa-times-circle"
              onClick={onDeny}
            >
              {tNo}
            </FlatButton>
          )}
          <Button isLoading={isLoading} onClick={onConfirm} intent={intent}>
            {tYes}
          </Button>
        </ButtonContainer>
      </Inner>
    </Modal>
  );
};
