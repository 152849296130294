import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justofy-content: center;
  gap: 10px;
  padding: 20px 0px;
  border-bottom: 1px solid #fff5;
  margin-bottom: 20px;
`;

export const Initials = styled.div`
  border: 2px solid white;
  border-radius: 10px;
  font-size: 15px;
  height: 30px;
  width: 30px;
  color: white;
  text-align: center;
  line-height: 26px;
`;

export const CompanyName = styled.div`
  gap: 10px;
  color: white;
  display: flex;
  fill: white;
  font-size: 16px;
`;
