import React from 'react';

import { EmployeeDetails } from 'components/table/cells/employee-details/EmployeeDetails';
import { CellProps, Column } from 'react-table';

import { ActionsCell } from '../components/cells/actions-cell/ActionsCell';
import { DateSentCell } from '../components/cells/date-sent-cell/DateSentCell';

import { ICompanyPhotoRequest } from 'types/photoRequest.types';

export default [
  {
    accessor: (row) => row,
    disableFilters: true,
    Header: 'Name',
    Cell: ({ cell: { value } }: CellProps<Record<string, never>>) => {
      return <EmployeeDetails employee={value} showRoleBadge={false} />;
    },
  },
  {
    accessor: 'createdOn',
    disableFilters: true,
    Header: 'Sent',
    Cell: DateSentCell,
  },
  {
    accessor: (row) => row,
    disableFilters: true,
    Header: '',
    id: 'actions',
    Cell: ActionsCell,
  },
] as Column<ICompanyPhotoRequest>[] as Column[];
