import { Reducer } from 'redux';
import { endOfDay, startOfDay, subMonths } from 'date-fns';

import { EPassStatusFilter } from 'types/card.types';
import * as Actions from './CardManagementActions.types';
import { TFilter } from 'types/shared.types';

export interface State {
  endDate: Date;
  filter: TFilter;
  isLoading: boolean;
  pageSize: number;
  passStatusFilter: EPassStatusFilter;
  selectedCardsIds: Set<string>;
  startDate: Date;
}

const today = endOfDay(new Date());
const monthAgo = startOfDay(subMonths(today, 1));
export const initialState: State = {
  endDate: today,
  filter: 'all',
  isLoading: false,
  pageSize: 20,
  passStatusFilter: EPassStatusFilter.All,
  selectedCardsIds: new Set(),
  startDate: monthAgo,
};

const cardManagementReducer: Reducer<State, Actions.CardsManagementActionTypes> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case Actions.CARDS__CLEAR_SELECTION: {
      return {
        ...initialState,
        selectedCardsIds: new Set(),
      };
    }

    case Actions.CARDS__LAUNCH: {
      return {
        ...initialState,
        pageSize: state.pageSize,
      };
    }

    case Actions.CARDS__UPDATE_FILTER: {
      return {
        ...state,
        filter: action.filter,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    }

    case Actions.CARDS__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    case Actions.CARDS__SET_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.size,
      };
    }

    case Actions.CARDS__SET_PASS_STATUS_FILTER: {
      return {
        ...state,
        passStatusFilter: action.status,
      };
    }

    case Actions.CARDS__TOGGLE_CARD_SELECTION: {
      const currentlySelected = [...state.selectedCardsIds];
      const isAlreadySelected = currentlySelected.find((id) => id === action.id);
      const newselectedCards = isAlreadySelected
        ? currentlySelected.filter((id) => id !== action.id)
        : [...currentlySelected, action.id];

      return {
        ...state,
        selectedCardsIds: new Set(newselectedCards),
      };
    }

    case Actions.CARDS__TOGGLE_SELECTION: {
      return {
        ...state,
        selectedCardsIds: state.selectedCardsIds.size
          ? new Set()
          : new Set(action.allCards.map(({ id }) => id)),
      };
    }

    default:
      return state;
  }
};

export default cardManagementReducer;
