import React, { useEffect, useRef, useState } from 'react';
import { addMinutes, isBefore } from 'date-fns';
import { CellProps } from 'react-table';
import { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';

import { ProgressBar } from 'components/progress-bar/ProgressBar';

import { EGenerationStatus, ICardGeneration } from 'types/card.types';
import { Container, Icon, Row } from './GenerationStatusCellStyles';

interface Props extends CellProps<ICardGeneration, ICardGeneration> {
  value: ICardGeneration;
}

export const GenerationStatusCell: React.FC<Props> = ({ value }: Props) => {
  const { id, status, modifiedOn } = value;
  const theme = useTheme();

  const [progress, setProgress] = useState(0);
  const [currentStatus, setCurrentStatus] = useState(status);

  const timer = useRef<NodeJS.Timeout>();
  const getCurrentStatus = () => {
    if (
      (status === EGenerationStatus.pending ||
        status === EGenerationStatus.sendingEmail) &&
      isBefore(addMinutes(modifiedOn, 5), new Date()) // If 5 minutes have passed without resolution
    ) {
      setCurrentStatus(EGenerationStatus.failedToGenerate);
    } else setCurrentStatus(status);
  };

  const increaseProgress = () => {
    setProgress((p) => (p < 60 ? p + 2 : p));
  };

  useEffect(() => {
    if (progress >= 60) clearInterval(timer.current);
  }, [progress]);

  useEffect(() => {
    getCurrentStatus();

    if (status === EGenerationStatus.pending) {
      clearInterval(timer.current);
      timer.current = setInterval(increaseProgress, 500);
      setProgress(20);
    }
    if (status === EGenerationStatus.sendingEmail) setProgress(80);

    return () => clearInterval(timer.current);
  }, [status]);

  switch (currentStatus) {
    case EGenerationStatus.pending:
      return <ProgressBar key={id} value={progress} />;

    case EGenerationStatus.sendingEmail:
      return <ProgressBar key={id} value={progress} />;

    case EGenerationStatus.failedToEmail:
    case EGenerationStatus.failedToGenerate:
      return (
        <Row>
          <Icon
            data-tip
            data-for={`status-dlt-${id}`}
            danger
            className="fas fa-exclamation-circle"
          />
          <div>Failed</div>

          <ReactTooltip
            id={`status-dlt-${id}`}
            place="top"
            effect="solid"
            backgroundColor={theme.colorInactive}
            className="tool-tip"
          >
            {currentStatus === EGenerationStatus.failedToEmail
              ? 'Failed to send email'
              : 'Failed to generate card'}
          </ReactTooltip>
        </Row>
      );

    case EGenerationStatus.completed:
      return (
        <Row>
          <Icon className="fas fa-check-circle" />
          <div>Completed</div>
        </Row>
      );

    default:
      return <Container>{status}</Container>;
  }
};
