import styled from 'styled-components';

interface IAvatarWrapper {
  count: number;
}

interface IAvatarContainer {
  order: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

export const AvatarsWrapper = styled.div<IAvatarWrapper>`
  position: relative;
  height: 30px;
  width: ${({ count }) => (!count ? 0 : count === 1 ? 30 : count === 2 ? 45 : 60)}px;
  transition: width 200ms;
`;

export const AvatarCircleContainer = styled.div<IAvatarContainer>`
  position: absolute;
  top: 0;
  left: ${({ order }) => order * 15}px;

  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.gray};
  box-shadow: -2px 1px 1px rgba(0, 0, 0, 0.1);

  transition: display 200ms;
`;

export const Initials = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.secondaryBlack};
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 12px;
  text-transform: uppercase;
`;

export const Label = styled.span`
  font-size: 13px;
  font-weight: 600;
  margin-right: 20px;
  color: ${({ theme }) => theme.secondaryBlack};
`;
