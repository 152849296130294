import * as Actions from './EmailTemplatesActions.types';

export const setIsLoading: Actions.TSetIsLoading = (isLoading) => ({
  isLoading,
  type: Actions.TEMPS__SET_IS_LOADING,
});

export const setCurrentTemplate: Actions.TSetCurrentTemplate = (templateId) => ({
  templateId,
  type: Actions.TEMPS__SET_CURRENT_TEMP,
});
