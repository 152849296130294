import styled from 'styled-components';

interface IContainer {
  size: string | number;
}

export const Container = styled.div<IContainer>`
  height: ${({ size }) => (typeof size === 'string' ? size : `${size}px`)};
  width: ${({ size }) => (typeof size === 'string' ? size : `${size}px`)};
  position: relative;
  border-radius: 100%;
  overflow: hidden;
  transition: all 0.25s ease-out;
  background-color: ${({ theme }) => theme.primaryGreen};
`;

export const Photo = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
`;

export const Initials = styled.div<IContainer>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: ${({ theme }) => theme.primaryGreen};
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: ${({ size }) => (typeof size === 'string' ? '14' : size * 0.35)}px;
  text-transform: uppercase;
`;
