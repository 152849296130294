import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'state/hooks';
import useAppForm, { TUseFormSubmit } from 'hooks/useAppForm';
import useGetTemplates from 'data/data-hooks/queries/templates/useGetTemplates';
import useMutateTemplate from 'data/data-hooks/mutations/templates/useMutateTemplate';

import { SubmitButton } from 'components/buttons';
import { Input, TextArea } from 'components/inputs';
import {
  ContentBox,
  ContentBoxContainer,
  ContentBoxHeader,
  ContentBoxTitle,
} from 'domains/navigation';

import { ITemplate } from 'types/emailTemplates.types';
import { Actions, Description, Inner, Name, Tip } from './TemplateFormStyles';
import { Empty } from '../../EmailTemplatesStyles';

export const TemplateForm: React.FC = () => {
  const { t } = useTranslation();
  const tTitle = t('Details');
  const tPlaceholder = t('Select a template to review');
  const tSave = t('Save');

  const { currentTemplateId } = useAppSelector(({ templates }) => templates);
  const { templates = [] } = useGetTemplates();
  const { updateTemplate } = useMutateTemplate();
  const currentTemplate = templates.find(({ id }) => id === currentTemplateId);

  const handleSave: TUseFormSubmit<ITemplate> = (
    data,
    { resetForm, setFormData },
  ) => {
    return updateTemplate(data).then(() => {
      resetForm();
      setFormData(data);
    });
  };

  const { formData, handleChange, handleSubmit, isDirty, resetForm, isSubmitting } =
    useAppForm<ITemplate>(
      {
        initialValues: [
          { key: 'id', required: false, value: currentTemplate?.id || '' },
          { key: 'header', required: true, value: currentTemplate?.header || '' },
          {
            key: 'bodyText',
            required: true,
            value: currentTemplate?.bodyText || '',
          },
        ],
        onSubmit: handleSave,
      },
      [],
    );

  useEffect(() => {
    resetForm();
  }, [currentTemplate?.id]);

  return (
    <ContentBoxContainer>
      <ContentBoxHeader>
        <ContentBoxTitle>{tTitle}</ContentBoxTitle>
      </ContentBoxHeader>
      <ContentBox>
        {currentTemplate ? (
          <Inner onSubmit={handleSubmit}>
            <Name>{currentTemplate.name} template</Name>
            <div>
              <Description>{currentTemplate.description}</Description>
              <Tip>
                {
                  "* Tip: {{employee_name}} can be used as a placeholder for the employee's full name."
                }
              </Tip>
            </div>
            <Input
              name="header"
              onChange={handleChange}
              label="Email header"
              value={formData.header}
            />
            <TextArea
              name="bodyText"
              onChange={handleChange}
              label="Email body text"
              value={formData.bodyText}
            />
            <Actions>
              <SubmitButton
                isLoading={isSubmitting}
                isDisabled={!isDirty}
                type="submit"
              >
                {tSave}
              </SubmitButton>
            </Actions>
          </Inner>
        ) : (
          <Empty>{tPlaceholder}</Empty>
        )}
      </ContentBox>
    </ContentBoxContainer>
  );
};
