import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardTrackingProvider } from 'contexts/CardTrackingContext';
import { CardGenerationsList } from './components/card-generations-list/CardGenerationsList';
import { TrackerTopContainer } from './components/tracker-top-container/TrackerTopContainer';
import { TransitionWrapper } from 'components/transition-wrapper/TransitionWrapper';

import { PageContentContainer, PageTitle } from 'domains/navigation';
import { PageHeader } from './CardTrackerStyles';

export const CardTracker: React.FC = () => {
  const { t } = useTranslation();
  const tTitle = t('Card Tracker');

  return (
    <TransitionWrapper>
      <CardTrackingProvider>
        <PageContentContainer>
          <PageHeader>
            <PageTitle>{tTitle}</PageTitle>
          </PageHeader>

          <TrackerTopContainer />

          <CardGenerationsList />
        </PageContentContainer>
      </CardTrackingProvider>
    </TransitionWrapper>
  );
};
