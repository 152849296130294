import styled from 'styled-components';

export const Container = styled.div`
  padding: 0px 85px 60px;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    padding: 0px 45px 25px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 60px;
  margin-top: 40px;

  @media (max-width: ${({ theme }) => theme.sizeRanges.desktop.min}px) {
    grid-template-columns: 100%;
    row-gap: 20px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-style: normal;
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.secondaryBlack};
`;

export const Text = styled.div`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-style: normal;
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.secondaryBlack};
`;
