import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useMutatePhotoRequest from 'data/data-hooks/mutations/photo-request/useMutatePhotoRequest';
import useAppForm, { TUseFormSubmit } from 'hooks/useAppForm';

import { Button, FlatButton } from 'components/buttons';
import { InactiveAlert } from 'components/icons';
import { Prompt } from 'components/modals';
import { SuccessToast } from 'components/toasts';
import { TextArea } from 'components/inputs';
import Modal from 'components/modals';

import { ICompanyPhotoRequest } from 'types/photoRequest.types';
import {
  Bold,
  ButtonContainer,
  IconContainer,
  Inner,
  PromptContianer,
  TextAreaContainer,
} from './DenyRequestModalStyles';

interface Props {
  handleClose: () => void;
  onConfirm?: () => void;
  requests: ICompanyPhotoRequest[];
  shouldShow: boolean;
}

export const DenyRequestModal: React.FC<Props> = ({
  handleClose,
  onConfirm,
  requests,
  shouldShow,
}: Props) => {
  const { denyRequests, isDenying } = useMutatePhotoRequest({
    onDenySuccess: async () => {
      await (onConfirm && onConfirm());
      SuccessToast(tSuccess);
      handleClose();
    },
  });
  const count = requests.length;
  const handleConfirm: TUseFormSubmit<{
    reason: string;
  }> = ({ reason }) => denyRequests({ requests, reason });

  const { formData, formErrors, handleChange, handleSubmit, resetForm } =
    useAppForm<{
      reason: string;
    }>(
      {
        initialValues: [{ key: 'reason', required: true, value: '' }],
        onSubmit: handleConfirm,
      },
      [],
    );

  useEffect(() => {
    resetForm();
  }, [shouldShow]);

  const { t } = useTranslation();
  const tDenyPhoto = t('Deny Photo');
  const tCancel = t('Cancel');
  const tSuccess = t(`Request${count > 1 ? 's' : ''} denied successfully`);

  return (
    <Modal
      handleClose={isDenying ? () => null : handleClose}
      shouldShow={shouldShow}
      width="400px"
    >
      <Inner onSubmit={handleSubmit}>
        <PromptContianer>
          <IconContainer>
            <InactiveAlert dark />
          </IconContainer>
          <Prompt>
            {t('are you sure you want to ')}
            <Bold>{t('Deny ')}</Bold>{' '}
            {t(`uploaded photo${count === 1 ? '' : 's'} and `)}
            <Bold>{t('send an email to resubmit?')}</Bold>{' '}
          </Prompt>
          <TextAreaContainer>
            <TextArea
              placeholder="Reason"
              name="reason"
              value={formData.reason}
              error={formErrors.reason}
              onChange={handleChange}
            />
          </TextAreaContainer>
        </PromptContianer>
        <ButtonContainer>
          <FlatButton
            isDisabled={isDenying}
            icon="far fa-times-circle"
            onClick={handleClose}
          >
            {tCancel}
          </FlatButton>
          <Button
            isDisabled={!formData.reason}
            isLoading={isDenying}
            type="submit"
            intent="dark"
          >
            {tDenyPhoto}
          </Button>
        </ButtonContainer>
      </Inner>
    </Modal>
  );
};
