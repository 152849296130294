import React from 'react';
import { Theme } from 'assets/styles';

interface Props {
  green?: boolean;
}

export const Card: React.FC<Props> = ({ green }: Props) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2"
        y="1"
        width="30"
        height="32"
        fill={green ? Theme.primaryGreen : 'none'}
      />
      <rect
        x="1"
        y="1"
        width="32"
        height="32"
        rx="3"
        stroke={Theme.primaryBlack}
        strokeWidth="2"
      />
      <path
        d="M10.3333 12.1H10.0333V12.4V15.8V16.1H10.3333H13.6667H13.9667V15.8V12.4V12.1H13.6667H10.3333ZM7.3 9.3H16.7V18.9H7.3V9.3ZM26.7 22.9V25.7H7.3V22.9H26.7ZM20.6333 9.3H26.7V12.1H20.6333V9.3ZM20.6333 16.1H26.7V18.9H20.6333V16.1Z"
        fill={Theme.primaryBlack}
        stroke={green ? Theme.primaryGreen : 'none'}
        strokeWidth="0.6"
      />
    </svg>
  );
};
