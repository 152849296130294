import React from 'react';
import { Theme } from 'assets/styles';

export const Email: React.FC = () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1 2C13.9659 2.65993 13.9659 3.34007 14.1 4H2.511L10.061 10.662L15.11 6.142C15.536 6.669 16.068 7.108 16.673 7.427L10.072 13.338L2 6.216V18H18V7.9C18.6599 8.03406 19.3401 8.03406 20 7.9V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V3C0 2.73478 0.105357 2.48043 0.292893 2.29289C0.48043 2.10536 0.734784 2 1 2H14.1ZM19 6C18.606 6 18.2159 5.9224 17.8519 5.77164C17.488 5.62087 17.1573 5.3999 16.8787 5.12132C16.6001 4.84274 16.3791 4.51203 16.2284 4.14805C16.0776 3.78407 16 3.39397 16 3C16 2.60603 16.0776 2.21593 16.2284 1.85195C16.3791 1.48797 16.6001 1.15726 16.8787 0.87868C17.1573 0.600104 17.488 0.379125 17.8519 0.228361C18.2159 0.0775973 18.606 -5.87054e-09 19 0C19.7956 1.18561e-08 20.5587 0.31607 21.1213 0.87868C21.6839 1.44129 22 2.20435 22 3C22 3.79565 21.6839 4.55871 21.1213 5.12132C20.5587 5.68393 19.7956 6 19 6Z"
        fill={Theme.primaryBlack}
      />
    </svg>
  );
};
