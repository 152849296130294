import { auth } from 'data/firebase';
import { updatePassword } from 'firebase/auth';
import { FirebaseError } from '@firebase/util';
import { parseError } from 'utils/errors.utils';
import { Adapter } from 'data/types';

interface IChangeUserPasswordDataArgs {
  oldPassword: string;
  newPassword: string;
}

export default async ({
  newPassword,
}: IChangeUserPasswordDataArgs): Promise<Adapter> => {
  try {
    const user = auth.currentUser;
    if (!user) throw 'User not found';
    updatePassword(user, newPassword);

    return { success: true };
  } catch (err) {
    if (err instanceof FirebaseError && err.code === 'auth/wrong-password') {
      return { success: false, error: 'auth/wrong-password' };
    }

    const errorMessage = parseError(err);

    return { success: false, error: errorMessage };
  }
};
