import { useAppSelector } from 'state/hooks';
import { UserRolesEnum } from 'types/user.types';

export default () => {
  const { user, role } = useAppSelector(({ auth }) => auth);
  const userRole = user?.role;

  return {
    features: role?.features,
    isAdmin: userRole === UserRolesEnum.admin,
    isEmployee: userRole === UserRolesEnum.employee,
    isSuperAdmin: userRole === UserRolesEnum.superAdmin,
    isHRAdmin: userRole === UserRolesEnum.HRAdmin,
    userRole,
  };
};
