import { useEffect, useState } from 'react';
import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  Query,
} from 'firebase/firestore';

import { parseError } from 'utils/errors.utils';

type TCollectionRef = CollectionReference<DocumentData>;
type TDocumentRef = DocumentReference<DocumentData>;
type TQueryRef = Query<DocumentData>;
type TDocumentResponse = DocumentSnapshot<DocumentData>;

const extractBaseData = (doc: TDocumentResponse) => {
  const data = doc.data();
  if (!data || !doc.exists) return undefined;

  const result: typeof data = {};
  for (const [key, value] of Object.entries(data)) {
    if (!data[key] || data[key] === null) result[key] = undefined;
    else result[key] = value;
  }

  return {
    ...result,
    id: doc.id,
    ...(data.modifiedOn && { modifiedOn: data.modifiedOn.toDate() }),
    ...(data.createdOn && { createdOn: data.createdOn.toDate() }),
  };
};

export default <
  TDataType = DocumentData,
  TError = unknown,
  TData = TDataType,
  TQueryKey extends QueryKey = QueryKey,
>(
  keys: TQueryKey,
  firebaseRef: TCollectionRef | TDocumentRef | TQueryRef,
  options?: Omit<
    UseQueryOptions<DocumentData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >,
): UseQueryResult<TData, string> => {
  const func = () => {
    if (firebaseRef.type === 'document') {
      return getDoc(firebaseRef).then((snapshot) => {
        if (snapshot.exists()) return extractBaseData(snapshot);
        else return {};
      });
    }

    return getDocs(firebaseRef).then((snapshot) => {
      return (snapshot.docs || []).map(extractBaseData).filter((elem) => !!elem);
    });
  };

  const { error, ...results } = useQuery<DocumentData, TError, TData, TQueryKey>(
    keys,
    func,
    { keepPreviousData: true, refetchOnMount: false, ...options },
  );

  const [queryError, setQueryError] = useState<string | null>(null);

  useEffect(() => {
    setQueryError(error ? parseError(error) : null);
  }, [error]);

  useEffect(() => {
    const { isFetched, refetch } = results;

    if (!isFetched) refetch();
  }, []);

  return {
    ...results,
    error: queryError,
  } as UseQueryResult<TData, string>;
};
