import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from 'state/hooks';
import { parseValue } from 'utils/utils';

import { AccessRules } from 'types/role.types';
import { Container, Label, MainLinkContainer } from './NavLinkStyles';

interface Props {
  iconComponent: React.FC;
  isMenuOpen: boolean;
  label: string;
  accessRules?: AccessRules[] | AccessRules.openAccess;
  to?: string;
}

const NavLink: React.FC<Props> = ({
  iconComponent: Icon,
  isMenuOpen,
  label,
  accessRules = AccessRules.openAccess,
  to,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { role } = useAppSelector(({ auth }) => auth);

  const hasPermission = useMemo(() => {
    if (accessRules === AccessRules.openAccess) return true;
    if (!role) return false;
    for (const rule of accessRules) {
      const value = parseValue(role.features, rule);
      if (value) return true;
    }
    return false;
  }, [role]);

  const checkActive = () => !!to && location.pathname.startsWith(to);

  const isActive = checkActive();

  const handleClick = () => {
    if (!to) return;
    navigate(to);
  };

  if (!hasPermission) return null;

  return (
    <Container isDisplayed={true} isShownMobile={isMenuOpen}>
      <MainLinkContainer onClick={handleClick} isActive={isActive}>
        <Icon />
        <Label>{label}</Label>
      </MainLinkContainer>
    </Container>
  );
};

export default NavLink;
