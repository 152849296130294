import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 18px;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryGreen};
  text-align: left;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;
