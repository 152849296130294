import React, { useMemo } from 'react';

import { useEmployeeCards, usePhotoRequests } from 'contexts';

import { DashboardStatsSlide } from '../dashboard-stats-slide/DashboardStatsSlide';

import { useAppSelector } from 'state/hooks';
import { Container } from './StatsGridStyles';

export const StatsGrid: React.FC = () => {
  const { employeeCards = [], isLoading } = useEmployeeCards();
  const { requests = [], accepted = [] } = usePhotoRequests();
  const { currentCompany } = useAppSelector(({ company }) => company);

  const activeCards = useMemo(
    () => employeeCards.filter((c) => c.isActive && c.isPassDownloaded),
    [employeeCards],
  );
  const inActiveCards = useMemo(
    () => employeeCards.filter((c) => !c.isActive || !c.isPassDownloaded),
    [employeeCards],
  );

  const userType = currentCompany?.isSchool ? 'students' : 'employees';

  return (
    <Container>
      <DashboardStatsSlide
        isLoading={isLoading}
        type={userType}
        value={employeeCards.length}
      />
      <DashboardStatsSlide
        isLoading={isLoading}
        type="cards-active"
        value={activeCards.length}
      />
      <DashboardStatsSlide
        isLoading={isLoading}
        type="cards-inactive"
        value={inActiveCards.length}
      />
      <DashboardStatsSlide
        isLoading={isLoading}
        type="requests"
        value={requests.length - accepted.length}
      />
    </Container>
  );
};
