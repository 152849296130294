import React from 'react';

import { useCardTracker } from 'contexts/CardTrackingContext';
import { StatsSlide } from 'components/stats-slide/StatsSlide';

import { Container } from './TrackerTopContainerStyles';

export const TrackerTopContainer: React.FC = () => {
  const { inQueue } = useCardTracker();

  return (
    <Container>
      <StatsSlide
        icon="queue"
        text="cards in queue"
        type="active"
        value={inQueue.length}
      />
    </Container>
  );
};
