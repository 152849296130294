import React from 'react';
import { usePhotoRequests } from 'contexts';

import { StatsSlide } from '../../../../components/stats-slide/StatsSlide';
import { Container } from './PhotosStatsStyles';

export const PhotosStats: React.FC = () => {
  const { uploaded, waitingForPhoto } = usePhotoRequests();

  return (
    <Container>
      <StatsSlide
        icon="photo"
        text="Photos uploaded"
        type="active"
        value={uploaded.length}
      />
      <StatsSlide
        icon="alert"
        text="waiting for photo"
        type="inactive"
        value={waitingForPhoto.length}
      />
    </Container>
  );
};
