import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';

import {
  setPhoto,
  setStepIndex,
} from 'state/photo-verification/PhotoVerificationActions';
import { useAppDispatch } from 'state/hooks';
import useCamera from 'domains/photo-id-upload/hooks/useCamera';

import { SubmitButton, FlatButton } from 'components/buttons';
import { Camera } from '../../icons/Camera';
import { FailToast } from 'components/toasts';
import { Loader } from 'components/loader';

import {
  Actions,
  Canvas,
  Container,
  Inner,
  LoaderContianer,
  WebcamContainer,
} from './TakePhotoStepStyles';

export const TakePhotoStep: React.FC = () => {
  const dispatch = useAppDispatch();

  const onPhotoCapture = (photoUrl: string) => {
    dispatch(setPhoto(photoUrl));
  };

  const { turnOnCamera, takePhoto, webcamRef } = useCamera({
    onPhotoCapture,
  });

  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const videoConstraints = {
    facingMode: 'user',
    width: 340,
    height: 340,
    aspectRatio: 1,
  };

  const handleReturn = () => dispatch(setStepIndex(1));

  const handleUserMediaError = () => {
    const alertText = t('There was a problem accessing your camera');
    FailToast(alertText);
  };

  const { t } = useTranslation();
  const tCapture = t('Capture');
  const tReturn = t('Return');

  return (
    <Container>
      <Canvas ref={canvasRef} />
      <Inner>
        <LoaderContianer>
          <Loader />
        </LoaderContianer>

        <WebcamContainer>
          <Webcam
            id="webcam"
            ref={webcamRef}
            audio={false}
            videoConstraints={videoConstraints}
            mirrored={true}
            screenshotFormat="image/jpeg"
            screenshotQuality={1}
            onUserMedia={turnOnCamera}
            onUserMediaError={handleUserMediaError}
          />
        </WebcamContainer>

        <Actions>
          <FlatButton dark onClick={handleReturn}>
            {tReturn}
          </FlatButton>
          <SubmitButton
            leadingIcon={<Camera />}
            intent="secondary"
            onClick={takePhoto}
          >
            {tCapture}
          </SubmitButton>
        </Actions>
      </Inner>
    </Container>
  );
};
