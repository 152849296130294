import React from 'react';
import { RequestDemo } from '../request-demo/RequestDemo';

import { Border, Container } from './NextStepsStyles';

export const NextSteps: React.FC = () => {
  return (
    <Container>
      <Border>
        <h1>Take the next step!</h1>
        <RequestDemo />
      </Border>
    </Container>
  );
};
