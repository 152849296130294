import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { CardConfiguration } from 'domains/cards/card-configuration/CardConfiguration';
import { CardManagement } from 'domains/cards/card-management/CardManagement';

import { PATHS } from './paths';
import { RolesProvider } from './components/roles-provider';
import accessRules from './components/roles-provider/assets/accessRules';

export const CardManagementRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path=""
        element={<RolesProvider accessRules={accessRules.cardManagement} />}
      >
        <Route index element={<CardManagement />} />
        <Route
          path={'/' + PATHS.cardConfiguration.split('/').pop()}
          element={<CardConfiguration />}
        />
      </Route>
    </Routes>
  );
};
