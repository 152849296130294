import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { logout } from 'state/auth/AuthActions';
import { setToken } from 'state/create-account/CreateAccountActions';
import { formatName } from 'utils/user.utils';

import { CreateAccountErrPage } from './components/error-page/ErrorPage';
import { Footer, PublicHeaderNav } from 'domains/navigation';
import { Form } from './components/form/Form';
import { Loader } from 'components/loader';

import {
  Container,
  ContentContainer,
  LoaderContainer,
  Title,
} from './CreateAccountStyles';

export const CreateAccount: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const params = useParams<'token'>();
  const { token } = params;

  const { userData, error } = useAppSelector(({ createAccount }) => createAccount);
  const { t } = useTranslation();
  const headingText = t(`Hello${userData ? ` ${formatName(userData)}` : ''}!`);
  const paragraphText = t('Enter your password below');

  useEffect(() => {
    token && dispatch(setToken(token)).then(() => setIsLoading(false));
  }, [token]);

  useEffect(() => {
    dispatch(logout());
  }, []);

  return (
    <Container>
      <PublicHeaderNav />

      <ContentContainer>
        {isLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <>
            <Title>{headingText}</Title>

            {error ? (
              <CreateAccountErrPage />
            ) : (
              <>
                <h1>{paragraphText}</h1>
                <Form />
              </>
            )}
          </>
        )}
      </ContentContainer>

      <Footer />
    </Container>
  );
};
