import { useMemo, useState } from 'react';
import { startCase } from 'lodash';

import { useAppSelector } from 'state/hooks';
import useAppForm from 'hooks/useAppForm';

import { IDateRange } from 'types/shared.types';

interface Args {
  filters: { id: string; value: unknown }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilter: (columnId: string, updater: any) => void;
}

export default ({ filters, setFilter }: Args) => {
  const [shouldShow, setShouldShow] = useState(false);
  const handleOpen = () => setShouldShow(true);
  const handleClose = () => setShouldShow(false);

  const { currentCompany, orgLevels } = useAppSelector(({ company }) => company);
  const showOrgLevels = currentCompany?.configuration.orgLevels;

  const initialValues = useMemo(() => {
    const getInitialVal = (colId: string) =>
      filters.find(({ id }) => id === colId)?.value || 'all';

    let values = [
      {
        key: 'hireDate',
        required: false,
        value: filters.find(({ id }) => id === 'hireDate')?.value,
      },
      { key: 'status', required: true, value: getInitialVal('status') },
      { key: 'passStatus', required: true, value: getInitialVal('passStatus') },
      { key: 'photoStatus', required: true, value: getInitialVal('photoStatus') },
    ];

    if (showOrgLevels) {
      values = [
        ...values,
        { key: 'orgLevel1', required: false, value: getInitialVal('orgLevel1') },
        { key: 'orgLevel2', required: true, value: getInitialVal('orgLevel2') },
        { key: 'orgLevel3', required: true, value: getInitialVal('orgLevel3') },
        { key: 'orgLevel4', required: true, value: getInitialVal('orgLevel4') },
      ];
    }

    return values as {
      key:
        | 'hireDate'
        | 'status'
        | 'passStatus'
        | 'photoStatus'
        | 'orgLevel1'
        | 'orgLevel2'
        | 'orgLevel3'
        | 'orgLevel4';
      value?: IDateRange | string;
      required: boolean;
    }[];
  }, [filters, currentCompany]);

  const { formData, handleSubmit, setValue } = useAppForm<{
    hireDate?: IDateRange;
    status: string;
    passStatus: string;
    photoStatus: string;
    orgLevel2?: string;
    orgLevel1?: string;
    orgLevel3?: string;
    orgLevel4?: string;
  }>(
    {
      initialValues,
      onSubmit: (formData) => {
        for (const [id, value] of Object.entries(formData)) {
          setFilter(id, value);
        }

        setShouldShow(false);
      },
    },
    [filters],
  );


  const getOrgLevelValues = (lvl: number) => [
    { label: 'All', value: 'all' },
    ...orgLevels
      .filter(({ level }) => level === lvl)
      .sort((a, b) => a.description.localeCompare(b.description))
      .map(({ description, code }) => ({ label: description, value: code })),
  ];

  const data = {
    passStatus: [
      { label: 'All', value: 'all' },
      { label: 'Downloaded', value: 'downloaded' },
      { label: 'Deleted', value: 'deleted' },
      { label: 'Unknown', value: 'unknown' },
    ],
    cardStatus: [
      { label: 'All', value: 'all' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ],
    photoStatus: [
      { label: 'All', value: 'all' },
      { label: 'No Photo', value: 'noPhoto' },
      { label: 'Photo Uploaded', value: 'photoUploaded' },
    ],
    orgLevel1: getOrgLevelValues(1),
    orgLevel2: getOrgLevelValues(2),
    orgLevel3: getOrgLevelValues(3),
    orgLevel4: getOrgLevelValues(4),
  };

  const isFilteringAcive = useMemo(() => {
    return (
      !!formData.hireDate?.startDate ||
      !!formData.hireDate?.endDate ||
      formData.status !== 'all' ||
      formData.passStatus !== 'all' ||
      formData.photoStatus !== 'all' ||
      (formData.orgLevel1 && formData.orgLevel1 !== 'all') ||
      (formData.orgLevel2 && formData.orgLevel2 !== 'all') ||
      (formData.orgLevel3 && formData.orgLevel3 !== 'all') ||
      (formData.orgLevel4 && formData.orgLevel4 !== 'all')
    );
  }, [formData]);

  const parseValue = (value: string) => ({ value, label: startCase(value) });
  const resetFilters = () => {
    setValue('hireDate', { startDate: undefined, endDate: undefined });
    setValue('status', 'all');
    setValue('passStatus', 'all');
    setValue('photoStatus', 'all');
    setValue('orgLevel1', 'all');
    setValue('orgLevel2', 'all');
    setValue('orgLevel3', 'all');
    setValue('orgLevel4', 'all');
  };

  return {
    data,
    formData,
    handleClose,
    handleOpen,
    handleSubmit,
    isFilteringAcive,
    parseValue,
    resetFilters,
    setValue,
    shouldShow,
    showOrgLevels,
  };
};
