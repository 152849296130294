import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';

import { FlatButton, Button } from 'components/buttons';
import { IDateRange } from 'types/shared.types';
import { TIstFilterActive } from 'components/table/types';
import { Actions, Container, Popup } from './DateFilterStyles';

export interface Props {
  column: {
    filterValue?: Partial<IDateRange>;
    id: string;
    setFilter: (filterValue?: Partial<IDateRange>) => void;
  };
  setIsFilterActive: (
    value: ((prev: TIstFilterActive) => TIstFilterActive) | boolean,
  ) => void;
}

export const DateFilter: React.FC<Props> = ({
  column,
  setIsFilterActive,
}: Props) => {
  const { t } = useTranslation();
  const tReset = t('Clear');
  const tApply = t('Apply');

  const { id, filterValue, setFilter } = column;

  const [startDate, setStartDate] = useState<Date | undefined>(
    filterValue?.startDate,
  );
  const [endDate, setEndDate] = useState<Date | undefined>(filterValue?.endDate);

  const handleChange = ([start, end]: [Date, Date]) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleClose = () => setIsFilterActive((prev) => ({ ...prev, [id]: false }));

  const onApply = () => {
    setFilter({ startDate, endDate });
    handleClose();
  };

  const onClear = () => {
    setFilter({ startDate: undefined, endDate: undefined });
    handleClose();
  };

  return (
    <Container>
      <Popup>
        <ReactDatePicker
          selectsRange
          onChange={handleChange}
          startDate={startDate}
          endDate={endDate}
          inline
        />
        <Actions>
          <FlatButton
            isDisabled={!startDate || !endDate}
            onClick={onClear}
            icon="far fa-times-circle"
          >
            {tReset}
          </FlatButton>
          <Button
            isDisabled={!startDate || !endDate}
            intent="success"
            onClick={onApply}
          >
            {tApply}
          </Button>
        </Actions>
      </Popup>
    </Container>
  );
};
