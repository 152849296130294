import styled from 'styled-components';

interface ICircle {
  type: 'employees' | 'students' | 'cards-active' | 'requests' | 'cards-inactive';
}

export const Container = styled.div`
  width: 100%;
  height: 140px;
  border: 1px solid ${({ theme }) => theme.primaryBlack};
  border-radius: 20px;
  background-color: white;
  overflow: hidden;
`;

export const Circle = styled.div<ICircle>`
  position: absolute;
  top: -17px;
  left: -17px;
  width: 74px;
  height: 74px;
  border-radius: 100%;
  opacity: 0.3;

  background-color: ${({ theme, type }) => {
    switch (type) {
      case 'cards-active':
        return theme.primaryGreen;
      case 'cards-inactive':
        return theme.colorDanger;
      case 'requests':
        return theme.primaryYellow;
      case 'employees':
        return theme.secondaryViolet;
      case 'students':
        return theme.secondaryViolet;
      default:
        return theme.secondaryViolet;
    }
  }};
`;

export const CircleContainer = styled.div`
  position: relative;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 5px;
  width: 100%;
  height: 100%;
`;

export const StatsValue = styled.div`
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 60px;
  line-height: 60px;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const StatsTitle = styled.div`
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 15px;
  line-height: 15px;
  color: ${({ theme }) => theme.primaryBlack};
  text-transform: uppercase;
`;
