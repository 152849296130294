import { Reducer } from 'redux';
import { ICompany, IOrgLevel } from 'types/company.types';

import * as Actions from './CompanyActions.types';

export interface State {
  isLoading: boolean;
  companiesList: ICompany[];
  currentCompany?: ICompany;
  orgLevels: IOrgLevel[];
}

export const initialState: State = {
  isLoading: false,
  companiesList: [],
  orgLevels: [],
};

const CompanyReducer: Reducer<State, Actions.CompanyActionTypes> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case Actions.CMPNY__SET_COMPANIES_LIST: {
      return {
        ...state,
        companiesList: action.list,
      };
    }

    case Actions.CMPNY__SET_CURRENT_COMPANY: {
      return {
        ...state,
        currentCompany: action.company,
        orgLevels: action.orgLevels,
      };
    }

    case Actions.CMPNY__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    default:
      return state;
  }
};

export default CompanyReducer;
