import styled from 'styled-components';

interface ISvg {
  isActive?: boolean;
  isDisabled?: boolean;
}

export const Svg = styled.svg<ISvg>`
  transition: all 0.2s;

  ${({ isActive }) =>
    isActive &&
    `
      transform: rotateZ(180deg); 
   `}

  ${({ isDisabled }) =>
    isDisabled &&
    `
      opacity: 0.5; 
   `}
`;
