import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { ICard, EPassStatusFilter } from 'types/card.types';
import { TFilter } from 'types/shared.types';

export const CARDS__CLEAR_SELECTION = 'CARDS__CLEAR_SELECTION';
export const CARDS__LAUNCH = 'CARDS__LAUNCH';
export const CARDS__SET_IS_LOADING = 'CARDS__SET_IS_LOADING';
export const CARDS__SET_PAGE_SIZE = 'CARDS__SET_PAGE_SIZE';
export const CARDS__SET_PASS_STATUS_FILTER = 'CARDS__SET_PASS_STATUS_FILTER';
export const CARDS__TOGGLE_CARD_SELECTION = 'CARDS__TOGGLE_CARD_SELECTION';
export const CARDS__TOGGLE_SELECTION = 'CARDS__TOGGLE_SELECTION';
export const CARDS__UPDATE_FILTER = 'CARDS__UPDATE_FILTER';

interface ClearSelectionAction {
  type: typeof CARDS__CLEAR_SELECTION;
}

interface LaunchCardManagementAction {
  type: typeof CARDS__LAUNCH;
}

interface SetFilterAction {
  type: typeof CARDS__UPDATE_FILTER;
  filter: TFilter;
  startDate: Date;
  endDate: Date;
}

interface SetIsLoadingAction {
  type: typeof CARDS__SET_IS_LOADING;
  isLoading: boolean;
}

interface SetPageSizeAction {
  type: typeof CARDS__SET_PAGE_SIZE;
  size: number;
}

interface SetPassStatusFilterAction {
  type: typeof CARDS__SET_PASS_STATUS_FILTER;
  status: EPassStatusFilter;
}

interface ToggleCardSelectionAction {
  type: typeof CARDS__TOGGLE_CARD_SELECTION;
  id: string;
}

interface ToggleSelectionAction {
  type: typeof CARDS__TOGGLE_SELECTION;
  allCards: ICard[];
}

export type CardsManagementActionTypes =
  | ClearSelectionAction
  | LaunchCardManagementAction
  | SetFilterAction
  | SetIsLoadingAction
  | SetPageSizeAction
  | SetPassStatusFilterAction
  | ToggleCardSelectionAction
  | ToggleSelectionAction;

export type TSetDateRange = (args: {
  filter: TFilter;
  startDate?: Date;
  endDate?: Date;
}) => ThunkAction<void, RootState, unknown, CardsManagementActionTypes>;

export type TLaunchCardManagement = () => ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  CardsManagementActionTypes
>;

export type TSetIsLoading = (isLoading: boolean) => SetIsLoadingAction;

export type TSetPageSize = (size: number) => SetPageSizeAction;

export type TSetPassStatusFilter = (
  status: EPassStatusFilter,
) => SetPassStatusFilterAction;

export type TToggleCardSelection = (id: string) => ToggleCardSelectionAction;

export type TToggleSelection = (AllCards: ICard[]) => ToggleSelectionAction;

export type TClearSelection = () => ClearSelectionAction;
