import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'state/hooks';
import { ReactPortal } from 'utils/portal.utils';
import { FlatButton } from 'components/buttons';
import { Panel } from './components/panel/Panel';

import { IEmployeeCard } from 'types/card.types';

interface Props {
  cardInstance: IEmployeeCard;
}

export const EditCardPanel: React.FC<Props> = React.memo(
  ({ cardInstance }: Props) => {
    const [shouldShow, setShouldShow] = useState(false);
    const handleOpen = () => setShouldShow(true);
    const handleClose = () => setShouldShow(false);

    const { t } = useTranslation();
    const tEdit = t('EDIT');

    const company = useAppSelector(({ company }) => company.currentCompany);
    const allowEdit = company?.configuration?.allowEdit ?? false;

    return (
      <Fragment>
        {allowEdit && (
          <FlatButton onClick={handleOpen} icon="fas fa-pen" intent="secondary">
            {tEdit}
          </FlatButton>
        )}
        <ReactPortal wrapperId={`card-panel-${cardInstance.id}`}>
          {shouldShow && <Panel {...{ shouldShow, handleClose, cardInstance }} />}
        </ReactPortal>
      </Fragment>
    );
  },
);
