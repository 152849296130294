import { httpsCallable, storage } from 'data/firebase';
import { getDownloadURL, ref } from 'firebase/storage';
import { format, isBefore } from 'date-fns';
import { formatName } from 'utils/user.utils';
import { parseError } from 'utils/errors.utils';

import { Adapter } from '../../types';
import { ICompany } from 'types/company.types';
import { IEmployeeCard } from 'types/card.types';

interface ISendCardArgs {
  company: ICompany;
  cardInstance: IEmployeeCard;
  reactivate?: boolean;
}

interface IPayload {
  CompanyId: string;
  CompanyLogoText: string;
  CompanyLogoUrl: string;
  CompanyName: string;
  Description: string;
  EmailAddress: string;
  EmployeeHireDate: string;
  employeeNumber: string;
  EmployeeTitle: string;
  ExpirationDate?: Date;
  Id: string;
  PersonId: string;
  PersonName: string;
  PersonPhotoUrl: string;
  QrCodeId: string;
}

export default async ({
  reactivate = false,
  cardInstance,
  company,
}: ISendCardArgs): Promise<Adapter> => {
  try {
    const defaultAvatarPath = 'default.png';
    const defaultAvatarUrl = await getDownloadURL(ref(storage, defaultAvatarPath));
    const defaultLogoPath = 'digi-prnts-logo.png';
    const defaultLogoUrl = await getDownloadURL(ref(storage, defaultLogoPath));

    const updatePass = _updatePass(
      company,
      defaultAvatarUrl,
      defaultLogoUrl,
      reactivate,
    );

    await updatePass(cardInstance);

    return { success: true };
  } catch (err) {
    const stringError = parseError(err);

    return { success: false, error: stringError };
  }
};

const _updatePass =
  (
    company: ICompany,
    defaultAvatarUrl: string,
    defaultLogoUrl: string,
    reactivate = false,
  ) =>
  async (cardInstance: IEmployeeCard) => {
    const updateDigitalPass = httpsCallable('updateDigitalPass');

    if (!cardInstance.employeeNumber) throw 'Missing data';
    const payload: Partial<IPayload> = {
      CompanyId: company.id,
      CompanyLogoText: company.companyName,
      CompanyLogoUrl: company.logoURL || defaultLogoUrl,
      CompanyName: company.companyName,
      Description: 'Digi Prnt Employee Id Card',
      EmailAddress: cardInstance.emailAddress,
      employeeNumber: cardInstance.employeeNumber,
      EmployeeTitle: cardInstance.title,
      Id: cardInstance.cardId,
      PersonId: cardInstance.employeeId,
      PersonName: formatName(cardInstance),
      PersonPhotoUrl: cardInstance.photoUrl || defaultAvatarUrl,
      QrCodeId: cardInstance.cardId,
      ...(cardInstance.hireDate && {
        EmployeeHireDate: format(cardInstance.hireDate, 'yyyy-MM-dd'),
      }),
    };

    if (reactivate) {
      payload['ExpirationDate'] = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1),
      );
    } else if (
      cardInstance &&
      (!cardInstance.isActive ||
        (!!cardInstance.terminationDate &&
          isBefore(cardInstance.terminationDate, new Date())))
    ) {
      payload['ExpirationDate'] = cardInstance.terminationDate;
    }

    const { data } = await updateDigitalPass(payload);

    if (!data.success) throw 'Failed to update card(s)';
  };
