import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  display: flex;
  min-height: 100vh;
  width: 100vw;

  flex-direction: row;
`;

export const ContentBox = styled.div`
  min-height: 100vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const Inner = styled.div`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  max-width: calc(100vw - 250px);

  @media (max-width: ${({ theme }) => theme.sizeRanges.desktop.min}px) {
    max-width: 100vw;
  }
`;

export const SidePanelContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 250px;
  gap: 30px;
  background-color: ${({ theme }) => theme.primaryBlack};

  display: block;

  @media (max-width: ${({ theme }) => theme.sizeRanges.desktop.min}px) {
    display: none;
  }
`;
