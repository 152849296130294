import styled, { css } from 'styled-components';

interface ITableData {
  full?: boolean;
}

interface ITableRow {
  columnsSpans?: string;
  length?: number;
  checkboxCol?: boolean;
  header?: boolean;
}

export const Data = styled.td<ITableData>`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 14px;
  line-height: 20px;
  padding: 18px 20px 18px 0;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${({ full }) =>
    full &&
    css`
      grid-column: 1/-1;
      justify-self: center;
      text-align: center;
    `}

  &:last-of-type {
    padding-right: 0;
  }


    padding: 10px 15px 10px 0;
  }
`;

export const Message = styled.div`
  color: ${({ theme }) => theme.primaryBlack}30;
  font-size: 16px;
  font-weight: ${({ theme }) => theme.weightSemiBold};
`;

export const SubMessage = styled.div`
  color: ${({ theme }) => theme.primaryBlackTrans};
  font-size: 16px;
`;

export const TableRow = styled.tr<ITableRow>`
  min-height: ${({ header }) => (header ? '40px' : '60px')};
  border-bottom: 1px solid ${({ theme }) => theme.backgroundColor};
  padding: 10px 20px;
  align-items: center;
  background-color: transparent;
  justify-items: start;
  width: 100%;
  display: grid;
  grid-template-columns: ${({ columnsSpans, length = 1, checkboxCol }) =>
    columnsSpans
      ? columnsSpans
      : checkboxCol
      ? `60px ${new Array(length - 1)
          .fill(0)
          .map(() => '1fr')
          .join(' ')}`
      : new Array(length)
          .fill(0)
          .map(() => '1fr')
          .join(' ')};

  @media (max-width: ${({ theme }) => theme.sizeRanges.mobileLarge.max}px) {
    padding: 5px 10px;
  }
`;

export const EmptyRow = styled.tr`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
