import React from 'react';
import { Loader } from 'components/loader';
import { useTranslation } from 'react-i18next';

import { Container, LeadingIconContainer } from './ButtonStyles';

export interface IButtonProps {
  children: React.ReactNode;
  fill?: boolean;
  intent?: 'primary' | 'secondary' | 'success' | 'danger' | 'warn' | 'dark';
  isDisabled?: boolean;
  isLoading?: boolean;
  leadingIcon?: JSX.Element;
  onClick?: (e: React.MouseEvent) => void;
  transparent?: boolean;
  type?: 'button' | 'submit' | 'reset';
  size?: 'small' | 'large' | 'medium';
}

export const Button: React.FC<IButtonProps> = ({
  children,
  fill = false,
  intent,
  isDisabled = false,
  isLoading = false,
  leadingIcon,
  onClick,
  size = 'small',
  transparent = false,
  type = 'button',
}: IButtonProps) => {
  const { t } = useTranslation();

  const getContent = () => {
    if (typeof children === 'string') {
      return t(children);
    }
    return children;
  };

  return (
    <Container
      disabled={isDisabled || isLoading}
      fill={fill ? 'true' : undefined}
      intent={intent}
      size={size}
      onClick={onClick}
      transparent={transparent}
      type={type}
    >
      {!isLoading && !!leadingIcon && (
        <LeadingIconContainer>{leadingIcon}</LeadingIconContainer>
      )}
      {isLoading ? (
        <Loader
          dark={intent === 'success'}
          size={size === 'large' ? 11 : size === 'medium' ? 9 : 7}
        />
      ) : (
        getContent()
      )}
    </Container>
  );
};
