import { firestore, httpsCallable, storage } from 'data/firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { parseError } from 'utils/errors.utils';
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import dbLabels from 'data/db.labels';

import { Adapter } from '../../types';
import { IPhotoReqTokenPayload } from 'types/token.types';
import { EPhotoRequestStatus } from 'types/photoRequest.types';

interface IUploadPhotoArgs {
  photo: File;
  userData: IPhotoReqTokenPayload;
}

export default async ({ photo, userData }: IUploadPhotoArgs): Promise<Adapter> => {
  try {
    const { employeeId, companyId } = userData;
    if (!companyId) throw 'Invalid company ID';

    const photoRef = ref(storage, `photo-id-requests/${companyId}/${photo.name}`);
    await uploadBytes(photoRef, photo);

    const photoUrl = await getDownloadURL(photoRef);

    const queryRef = query(
      collection(firestore, dbLabels.photorequest),
      where('employeeId', '==', employeeId),
    );
    const requestObject = await getDocs(queryRef);

    await updateDoc(
      doc(firestore, dbLabels.photorequest, requestObject.docs[0].id),
      {
        photoUrl,
        modifiedOn: new Date(),
        status: EPhotoRequestStatus.uploaded,
      },
    );

    const sendNotification = httpsCallable('sendNotification');

    sendNotification({
      type: 'photo_upload',
      employeeId,
      companyId,
    });

    return { success: true };
  } catch (err) {
    const stringError = parseError(err);

    return { success: false, error: stringError };
  }
};
