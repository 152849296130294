import React from 'react';
import { Cell } from 'react-table';

import { Loader } from 'components/loader';

import { EGenerationStatus, ICardGeneration } from 'types/card.types';
import { Icon, Container } from './PassStatusCellStyles';

interface Props extends Cell {
  value: ICardGeneration;
}

export const PassStatusCell: React.FC<Props> = React.memo(
  ({ value: card }: Props) => {
    const { status, passStatus } = card;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const seconds = Math.floor((new Date() - card.modifiedOn) / 1000);

    if (status === EGenerationStatus.completed && seconds < 10) {
      return <Loader size={5} />; // wait untill all changes are fetched from firestore
    }

    return (
      <Container>
        {passStatus === 'invalid' ? (
          <>
            <Icon isPassDeleted className="fas fa-times-circle" />
            <div>Invalid</div>
          </>
        ) : !passStatus || status !== EGenerationStatus.completed ? (
          <></>
        ) : passStatus === 'deleted' ? (
          <>
            <Icon isPassDeleted className="fas fa-minus-circle" />
            <div>Deleted</div>
          </>
        ) : passStatus === 'downloaded' ? (
          <>
            <Icon isPassDownloaded className="fas fa-cloud-download-alt" />
            <div>Downloaded</div>
          </>
        ) : (
          <>
            <Icon className="fas fa-envelope" />
            <div>Received</div>
          </>
        )}
      </Container>
    );
  },
);
