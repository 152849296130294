import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'state/hooks';
import useMutatePhotoRequest from 'data/data-hooks/mutations/photo-request/useMutatePhotoRequest';

import { Card } from 'components/icons';
import { ConfirmDenyModal, Prompt } from 'components/modals';

import { ICompanyPhotoRequest } from 'types/photoRequest.types';
import { Bold } from 'domains/SharedStyles';

interface Props {
  handleClose: () => void;
  onConfirm?: () => void;
  requests: ICompanyPhotoRequest[];
  shouldShow: boolean;
}

export const SendRemindersModal: React.FC<Props> = ({
  handleClose,
  onConfirm,
  requests,
  shouldShow,
}: Props) => {
  const { currentCompany } = useAppSelector(({ company }) => company);
  const { isSendingReminder, sendReminder } = useMutatePhotoRequest({
    onSendReminderSuccess: async () => {
      await (onConfirm && onConfirm());
      handleClose();
    },
  });

  const count = requests.length;
  const handleConfirm = () => sendReminder(requests);

  const { t } = useTranslation();
  const tPrompt = t(`You are going to send reminder emails to`);
  const entity = currentCompany?.isSchool ? 'students' : 'employees';
  const tUsers = t(`${count} ${entity}`);

  return (
    <ConfirmDenyModal
      closeModal={handleClose}
      shouldShow={shouldShow}
      onConfirm={handleConfirm}
      prompt={
        <Prompt>
          {tPrompt} <Bold>{tUsers}</Bold>
        </Prompt>
      }
      intent="success"
      onDeny={handleClose}
      cancelLabel="Cancel"
      confirmLabel="Send Reminders"
      isLoading={isSendingReminder}
      icon={<Card green />}
    />
  );
};
