import React from 'react';

import { SubmitButton, FlatButton } from 'components/buttons';
import { PATHS } from 'routes';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { useNavigate } from 'react-router-dom';

import { ButtonContainer, Container, TopMenu } from './HeaderNavStyles';

export const PublicHeaderNav: React.FC = () => {
  const navigate = useNavigate();
  const showLoginBtn =
    location.pathname === PATHS.home || location.pathname === PATHS.contactUs;
  const showContactUsBtn =
    location.pathname === PATHS.home || location.pathname !== PATHS.contactUs;

  return (
    <Container>
      <Logo onClick={() => navigate(PATHS.home)} style={{ cursor: 'pointer' }} />
      <TopMenu>
        {showContactUsBtn && (
          <FlatButton dark onClick={() => navigate(PATHS.contactUs)}>
            Contact Us
          </FlatButton>
        )}
        {!!showLoginBtn && (
          <ButtonContainer>
            <SubmitButton onClick={() => navigate(PATHS.login)} transparent>
              Login
            </SubmitButton>
          </ButtonContainer>
        )}
      </TopMenu>
    </Container>
  );
};
