import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatName } from 'utils/user.utils';
import { useAppSelector } from 'state/hooks';
import { useEmployeeCards } from 'contexts';
import useDimensions from 'hooks/useDimensions';
import useRequestPhoto from 'data/data-hooks/mutations/photo-request/useRequestPhoto';

import { ConfirmDenyModal, Prompt } from 'components/modals';
import { Photo, RequestPhoto } from 'components/icons';
import { SuccessToast } from 'components/toasts';

import { Bold } from '../../card-management/components/card-updates/components/action-modals/SharedStyles';
import { IconButtonWrapper } from '../sharedStyles';

interface Props {
  children: (props: { handleOpen: () => void; isLoading: boolean }) => JSX.Element;
  onSuccess?: () => void;
  selectedCardsIds: string | string[];
}

export const RequestPhotoModal: React.FC<Props> = ({
  children,
  onSuccess,
  selectedCardsIds: _selectedCardsIds,
}: Props) => {
  const selectedCardsIds = Array.isArray(_selectedCardsIds)
    ? _selectedCardsIds
    : [_selectedCardsIds];
  const count = selectedCardsIds.length;

  const [shouldShow, setShouldShow] = useState(false);
  const { isInMobile } = useDimensions();

  const handleOpen = () => setShouldShow(true);
  const handleClose = () => setShouldShow(false);
  const { currentCompany } = useAppSelector(({ company }) => company);
  const { employeeCards = [] } = useEmployeeCards();

  const { sendPhotoRequests, isLoading } = useRequestPhoto({
    onSuccess: () => {
      SuccessToast('Photo Request has been sent successfully');
      handleClose();
    },
  });

  const onConfirm = () => {
    sendPhotoRequests(selectedCardsIds).then(() => onSuccess && onSuccess());
  };

  const { t } = useTranslation();
  const tRequestPhoto = t(`Request photo${count === 1 ? '' : 's'}`);
  const tPrompt = t(`You are going to request photo${count === 1 ? '' : 's'} from`);
  const entity = currentCompany?.isSchool ? 'student' : 'employee';

  const tUsers = (() => {
    if (count === 1) {
      const employee = employeeCards.find(({ id }) => id === selectedCardsIds[0]);
      if (employee) return `${formatName(employee)}`;
    }
    return t(`${count} ${entity}${count === 1 ? '' : 's'}`);
  })();

  return (
    <Fragment>
      {!isInMobile ? (
        children({ handleOpen, isLoading })
      ) : (
        <IconButtonWrapper>
          <RequestPhoto />
        </IconButtonWrapper>
      )}

      <ConfirmDenyModal
        cancelLabel="Cancel"
        closeModal={handleClose}
        confirmLabel={tRequestPhoto}
        icon={<Photo green />}
        isLoading={isLoading}
        onConfirm={onConfirm}
        onDeny={handleClose}
        prompt={
          <Prompt>
            {tPrompt} <Bold>{tUsers}</Bold>
          </Prompt>
        }
        shouldShow={shouldShow}
      />
    </Fragment>
  );
};
