import React from 'react';
import { Outlet } from 'react-router-dom';

import { PrivateHeaderNav } from '../private-header-nav/PrivateHeaderNav';
import { SidePanel } from '../side-panel/SidePandel';

import {
  Container,
  ContentBox,
  Inner,
  SidePanelContainer,
} from './PageLayoutStyles';

interface Props {
  children?: React.ReactChild[] | React.ReactChild;
}

const PageLayout: React.FC<Props> = ({ children }: Props) => {
  return (
    <Container>
      <SidePanelContainer>
        <SidePanel />
      </SidePanelContainer>

      <ContentBox>
        <PrivateHeaderNav />

        <Inner>{children ? children : <Outlet />}</Inner>
      </ContentBox>
    </Container>
  );
};

export default PageLayout;
