import { firestore, httpsCallable } from 'data/firebase';
import { collection, doc, writeBatch } from 'firebase/firestore';

import { parseError } from 'utils/errors.utils';
import dbLabels from 'data/db.labels';

import { Adapter } from '../../types';
import { ICompany } from 'types/company.types';
import { EGenerationStatus, ICardGeneration } from 'types/card.types';

interface ISendCardArgs {
  company: ICompany;
  cards: ICardGeneration[];
  refetch?: () => Promise<void>;
}

export default async ({
  cards,
  company,
  refetch,
}: ISendCardArgs): Promise<Adapter> => {
  try {
    await updateStatusInStore(cards, company);
    if (refetch) await refetch();

    const pendingCards = cards.filter(
      (c) => !c.downloadUrlApple || !c.downloadUrlGoogle,
    );

    const failedToEmail = cards.filter(
      (c) =>
        c.status !== EGenerationStatus.completed &&
        !!c.downloadUrlApple &&
        !!c.downloadUrlGoogle,
    );

    new Promise<
      | {
          success?: boolean | undefined;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          results?: any;
        }
      | undefined
    >((resolve) => {
      if (pendingCards.length) {
        if (company.isSchool) {
          const generateSchoolPass = httpsCallable('generateSchoolPass');
          generateSchoolPass({
            CompanyId: company.id,
            cards: pendingCards,
          }).then((response) => {
            if (!response.data.success || !response.data.results)
              throw 'Failed to send card(s)';

            resolve(response.data);
          });
        } else {
          const generateDigitalIds = httpsCallable('generateDigitalIds');
          generateDigitalIds({
            CompanyId: company.id,
            cards: pendingCards,
          }).then((response) => {
            if (!response.data.success || !response.data.results)
              throw 'Failed to send card(s)';

            resolve(response.data);
          });
        }
      } else {
        resolve({ success: false });
      }
    }).then(async (data) => {
      let toSendEmails: unknown[] = [];
      if (data?.results) toSendEmails = [...data.results];
      toSendEmails = [
        ...toSendEmails,
        ...failedToEmail.map((card) => ({
          emailAddress: card.EmailAddress,
          PersonName: card.PersonName,
          downloadUrlApple: card.downloadUrlApple,
          downloadUrlGoogle: card.downloadUrlGoogle,
          firestoreId: card.id,
          id: card.cardId,
        })),
      ];

      /* send emails */
      const sendGeneratedCardEmail = httpsCallable('sendGeneratedCardEmail');
      await sendGeneratedCardEmail({
        companyId: company.id,
        results: toSendEmails,
        config: company.configuration.emailSettings || {},
        isStudent: !!company.isSchool,
      });
    });

    return { success: true };
  } catch (err) {
    const stringError = parseError(err);

    return { success: false, error: stringError };
  }
};

const updateStatusInStore = async (cards: ICardGeneration[], company: ICompany) => {
  const cardsRef = collection(
    firestore,
    dbLabels.company,
    company.id,
    dbLabels.cardTrackers,
  );

  let _writeBatch = writeBatch(firestore);
  for (const [index, card] of cards.entries()) {
    const ref = doc(cardsRef, card.id);
    const updates =
      !card.downloadUrlApple || !card.downloadUrlGoogle
        ? {
            status: EGenerationStatus.pending,
            PersonPhotoUrl: card.PersonPhotoUrl, // Update with the latest employee photo
            modifiedOn: new Date(),
          }
        : {
            status: EGenerationStatus.sendingEmail,
            PersonPhotoUrl: card.PersonPhotoUrl, // Update with the latest employee photo
            modifiedOn: new Date(),
          };

    _writeBatch.update(ref, updates);

    if (index % 499 === 0 || index == cards.length - 1) {
      await _writeBatch.commit().finally(() => {
        _writeBatch = writeBatch(firestore);
      });
    }
  }
};
