import { Reducer } from 'redux';
import { IRole } from 'types/role.types';
import { IUser } from 'types/user.types';

import * as Actions from './AuthActions.types';

export interface State {
  error: string;
  isAuthenticated: boolean;
  isLoading: boolean;
  isLoadingUser: boolean;
  role?: IRole;
  user?: IUser;
}

export const initialState: State = {
  error: '',
  isAuthenticated: false,
  isLoading: false,
  isLoadingUser: true,
};

const authReducer: Reducer<State> = (
  state: State = initialState,
  action: Actions.AuthActionTypes,
): State => {
  switch (action.type) {
    case Actions.AUTH__CLEAR_ERROR: {
      return {
        ...state,
        error: '',
      };
    }

    case Actions.AUTH__LOGIN: {
      return {
        ...state,
        isAuthenticated: true,
      };
    }

    case Actions.AUTH__LOGOUT: {
      return {
        ...initialState,
      };
    }

    case Actions.AUTH__FAIL: {
      return {
        ...state,
        error: action.error,
      };
    }

    case Actions.AUTH__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    case Actions.AUTH__SET_IS_LOADING_USER: {
      return {
        ...state,
        isLoadingUser: action.isLoadingUser,
      };
    }

    case Actions.AUTH__SET_USER_DATA: {
      return {
        ...state,
        user: action.user,
        role: action.role,
        isAuthenticated: true,
      };
    }

    default:
      return state;
  }
};

export default authReducer;
