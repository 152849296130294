import React from 'react';
import { ChromePicker, ColorChangeHandler } from 'react-color';
import { useTranslation } from 'react-i18next';

import { Container, Label, PickerContainer } from './ColorInputStyles';

interface Props {
  value?: string;
  label?: string;
  onChange: ColorChangeHandler;
}

export const ColorInput: React.FC<Props> = ({
  value = '#000000',
  label,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const tLabel = label ? t(label) : '';

  return (
    <Container>
      {label && <Label>{tLabel}</Label>}
      <PickerContainer>
        <ChromePicker
          color={value}
          onChange={onChange}
          disableAlpha
          styles={{
            default: {
              saturation: { height: 100, paddingBottom: '40%' },
            },
          }}
        />
      </PickerContainer>
    </Container>
  );
};
