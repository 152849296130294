import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 14px;
  line-height: 21px;
  color: white;
  justify-self: flex-end;
  align-self: flex-end;
  background-color: ${({ theme }) => theme.primaryBlack};
  padding: 40px 85px;
  width: 100%;
  margin-top: auto;

  * {
    opacity: 0.6;
  }

  @media (max-width: ${({ theme }) => theme.sizeRanges.tabletLarge.min}px) {
    padding: 20px;
  }

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 11px;
  }
`;

export const LinksContaier = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  flex-grow: 1;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    gap: 10px;
    margin-left: 15px;
  }
`;
