import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 600px;
`;

export const TextArea = styled.textarea`
  height: 100%;
  max-height: 100%;
  border-radius: 30px;
  resize: none;
  border: 2px solid ${({ theme }) => theme.backgroundColor};
  padding: 20px;
  width: 100%;
`;
