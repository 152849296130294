import React from 'react';

import { AvatarCircle } from 'components/avatar-circle/AvatarCircle';
import { formatName } from 'utils/user.utils';
import { RoleBadge } from '../components/role-badge/RoleBadge';
import useDimensions from 'hooks/useDimensions';

import { IEmployee } from 'types/employee.types';
import {
  AvatarContainer,
  Badge,
  Details,
  Row,
  UserId,
  UserName,
} from './EmployeeDetailsStyles';
import { PassStatusCell } from './components/pass-status-cell/PassStatusCell';
import { checkPhoto } from 'utils/photo.utils';

interface Props {
  employee: IEmployee & { isPassDownloaded: boolean; isPassDeleted: boolean };
  showRoleBadge?: boolean;
}

export const EmployeeDetails: React.FC<Props> = React.memo(
  ({ employee, showRoleBadge = true }: Props) => {
    const {
      employeeId,
      employeeNumber,
      id,
      isPassDeleted,
      isPassDownloaded,
      photoUrl,
    } = employee;
    const { isInMobile, isInTablet } = useDimensions();

    return (
      <Row>
        {!isInMobile && !isInTablet && (
          <AvatarContainer>
            <AvatarCircle
              size={40}
              photoUrl={
                window.location.pathname.includes('verify-photos')
                  ? photoUrl
                  : checkPhoto(photoUrl)
              }
              name={employee ? formatName(employee) : ''}
            />
            <Badge>
              <PassStatusCell digiPass={{ id, isPassDownloaded, isPassDeleted }} />
            </Badge>
          </AvatarContainer>
        )}
        <Details>
          <Row>
            <UserName>{formatName(employee)}</UserName>
            {showRoleBadge && employee.emailAddress && (
              <RoleBadge
                emailAddress={employee.emailAddress ? employee.emailAddress : ''}
              />
            )}
          </Row>
          <UserId>{employeeNumber || employeeId}</UserId>
        </Details>
      </Row>
    );
  },
);
