import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const Details = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.div`
  font-weight: ${({ theme }) => theme.weightExtraBold};
  font-size: 14px;
  line-height: 20px;
`;

export const UserId = styled.div`
  font-size: 12px;
  line-height: 17px;
`;

export const AvatarContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 2px;
`;

export const Badge = styled.span`
  position: absolute;
  bottom: -7px;
  left: -2px;
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color: white;
  font-size: 20px;
`;
