import React from 'react';
import { CellProps, Column } from 'react-table';
import { format } from 'date-fns';
import { t } from 'i18next';
import { EmployeeDetails } from 'components/table/cells/employee-details/EmployeeDetails';

import { INotification } from 'types/notification.types';
import { Message, MessageContainer, Status } from '../NotificationsPageStyles';

export default [
  {
    accessor: (row) => format(row.createdOn, 'MM/dd/yyyy KK:mm a'),
    disableFilters: true,
    Header: 'Date/Time',
    id: 'time',
  },
  {
    accessor: (row) => row.employee,
    Cell: (props: CellProps<INotification>) =>
      props.value && <EmployeeDetails employee={props.value} />,
    disableFilters: true,
    Header: 'from',
  },
  {
    accessor: 'message',
    Cell: ({ cell: { value } }: CellProps<Record<string, never>>) => (
      <MessageContainer>
        <Message>{value}</Message>
      </MessageContainer>
    ),
    disableFilters: true,
    Header: 'Message',
  },
  {
    accessor: 'status',
    disableFilters: true,
    Header: 'status',
    Cell: ({ cell: { value } }: CellProps<Record<string, never>>) => (
      <Status status={value}>
        {value === 'seen' ? `${t('Read')}` : `${t('New')}`}
      </Status>
    ),
  },
] as Column<INotification>[] as Column[];
