import React, { useCallback, useMemo } from 'react';
import { useCardTracker } from 'contexts';

import useSearchBar from 'hooks/useSearchBar';
import { includes } from 'utils/text.utils';

import COLUMNS from '../../assets/columns';

import { Container } from './CardGenerationsListStyles';

import { TrackerTable } from './components/table/TrackerTable';
import { IDateRange, TFilter } from 'types/shared.types';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { isAfter, isBefore } from 'date-fns';
import { ICardGeneration } from 'types/card.types';
import { setPageSize, updateFilter } from 'state/card-tracker/CardTrackerActions';

export const CardGenerationsList: React.FC = () => {
  const dispatch = useAppDispatch();

  const { generatedCards, isLoading } = useCardTracker();
  const { searchValue } = useSearchBar();
  const { startDate, endDate, filter } = useAppSelector(
    ({ cardTracker }) => cardTracker,
  );

  const shouldAppear = useCallback(
    (card: ICardGeneration) => {
      let shouldAppear = true;
      if (filter !== 'all') {
        shouldAppear &&=
          isBefore(startDate, card.createdOn) && isAfter(endDate, card.createdOn);
      }

      if (shouldAppear)
        return (
          !searchValue ||
          includes(card.PersonName, searchValue) ||
          includes(card.PersonId, searchValue) ||
          includes(card.employeeNumber, searchValue) ||
          includes(card.EmailAddress, searchValue)
        );

      return false;
    },
    [filter, startDate, endDate, searchValue],
  );

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => {
    if (filter === 'all' && !searchValue) {
      return generatedCards;
    }

    return generatedCards.filter((card) => {
      return shouldAppear(card);
    });
  }, [filter, startDate, endDate, generatedCards, searchValue]);

  const setActiveFilter = (filter: TFilter) => dispatch(updateFilter({ filter }));

  const handleFilterChange = useCallback((e: React.MouseEvent) => {
    const filter = (e.target as HTMLButtonElement).name as TFilter;
    setActiveFilter(filter);
  }, []);

  const onPageSizeChange = useCallback(
    (size: number) => dispatch(setPageSize(size)),
    [],
  );

  const setCustomDateRange = useCallback(({ startDate, endDate }: IDateRange) => {
    setActiveFilter('custom');
    dispatch(updateFilter({ filter: 'custom', startDate, endDate }));
  }, []);

  return (
    <Container>
      <TrackerTable
        columns={columns}
        data={data}
        isLoading={isLoading}
        onPageSizeChange={onPageSizeChange}
        headerProps={{
          activeFilter: filter as TFilter,
          endDate,
          handleFilterChange,
          setCustomDateRange,
          startDate,
        }}
      />
    </Container>
  );
};
