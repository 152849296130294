import styled from 'styled-components';

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Inner = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`;
