import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 70px;
  background-color: ${({ theme }) => theme.primaryBlack};
  height: 100vh;

  @media only screen and (max-width: ${({ theme }) =>
      theme.sizeRanges.mobileLarge.max}px) {
    gap: 20px;
  }
`;

export const FourOhFour = styled.div`
  text-align: center;
  width: 100vw;
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 100px;
  line-height: 100px;
  color: ${({ theme }) => theme.primaryGreen};
`;

export const MainText = styled.div`
  color: white;
  font-size: 50px;
  font-weight: ${({ theme }) => theme.weightExtraBold};
  width: 100%;
  line-height: 65px;

  @media only screen and (max-width: ${({ theme }) =>
      theme.sizeRanges.tabletLarge.max}px) {
    font-size: 40px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.sizeRanges.mobileLarge.max}px) {
    font-size: 40px;
    line-height: 45px;
  }
`;

export const SubText = styled.div`
  color: white;
  font-size: 20px;
  width: 100%;
  margin-bottom: 25px;

  @media only screen and (max-width: ${({ theme }) =>
      theme.sizeRanges.tabletLarge.max}px) {
    font-size: 18px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.sizeRanges.mobileLarge.max}px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const TextContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0px 90px;
  gap: 30px;

  @media only screen and (max-width: ${({ theme }) =>
      theme.sizeRanges.tabletLarge.max}px) {
    padding: 10px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.sizeRanges.mobileLarge.max}px) {
    padding: 0px;
    text-align: center;
    margin-top: 20px;
    gap: 15px;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  width: 260px;

  @media only screen and (max-width: ${({ theme }) =>
      theme.sizeRanges.mobileLarge.max}px) {
    margin: auto;
    margin-bottom: 70px;
  }
`;
