import styled from 'styled-components';

interface IButton {
  isDisabled: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 30px;
  margin-right: 15px;
`;

export const SwiperButton = styled.i<IButton>`
  color: ${({ theme }) => theme.secondaryViolet};
  cursor: pointer;

  ${({ isDisabled }) => isDisabled && `opacity:0.5;`}
`;
