import styled from 'styled-components';

interface IButtonContainer {
  isDisabled?: boolean;
}

export const Container = styled.div`
  .tooltip {
    padding: 0px;
    background: white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    border: 1px solid ${({ theme }) => theme.primaryBlack};
    border-radius: 10px;
    z-index: 10;

    &::before {
      width: Opx !important;
      height: Opx !important;
      border-left: 6px solid transparent !important;
      border-right: 6px solid transparent !important;
      border-bottom: 6px solid ${({ theme }) => theme.primaryBlack} !important;
      top: -6px !important;
    }

    &::after {
      width: Opx !important;
      height: Opx !important;
      margin-left: -9px !important;
      top: -4.5px !important;
      border-left: 5px solid transparent !important;
      border-right: 5px solid transparent !important;
      border-bottom: 5px solid white !important;
    }

    &.__react_component_tooltip.show {
      opacity: 1 !important;
    }

    /* positioning of the tooltip arrow */
    &::before,
    &::after {
      left: 80% !important;
    }
  }

  .__react_component_tooltip {
    opacity: 1 !important;
  }
`;

export const IconContainer = styled.div`
  margin-right: 8px;
  display: flex;
`;

export const ButtonContainer = styled.button<IButtonContainer>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  width: 24px;
  height: 24px;
`;

export const ToolTipItem = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.secondaryVioletTrans};
  }

  &:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-of-type {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`;

export const ToolTipItems = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
`;

export const ToolTipText = styled.label`
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 13px;
  color: ${({ theme }) => theme.primaryBlack};
  cursor: pointer;
`;
