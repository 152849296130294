import React from 'react';
import { Theme } from 'assets/styles';

export const Speed: React.FC = () => (
  <svg
    width="69"
    height="64"
    viewBox="0 0 69 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.387 0C29.8066 0.00175166 29.2512 0.224207 28.8429 0.618319C28.434 1.01243 28.2057 1.54551 28.2082 2.1002C28.2131 3.24984 29.1859 4.18054 30.3869 4.18518H32.1343V7.64291C26.0496 9.00509 20.4987 12.1737 16.34 16.7776C15.5539 17.6516 15.6552 18.9694 16.5664 19.7244C17.4795 20.4799 18.8607 20.3848 19.6509 19.5119C24.4895 14.1548 31.5319 11.076 38.931 11.076C53.022 11.076 64.3935 21.9512 64.3935 35.4354C64.3935 48.9195 53.0221 59.8112 38.931 59.8112C31.5316 59.8112 24.4891 56.7366 19.6509 51.3795C18.8618 50.5049 17.4806 50.408 16.5664 51.1629C15.6522 51.9179 15.5509 53.2386 16.34 54.1132C22.0079 60.388 30.2637 64 38.9308 64C55.386 64 68.768 51.1804 68.768 35.4345C68.768 21.9207 58.9089 10.5788 45.7116 7.63422V4.18531H47.4546C48.035 4.18706 48.5923 3.9687 49.0036 3.57808C49.4156 3.18689 49.6481 2.65556 49.6506 2.1003C49.653 1.5427 49.4223 1.00729 49.0097 0.612594C48.5978 0.218482 48.0375 -0.00222684 47.4546 0.000104915L30.387 0ZM36.5091 4.18521H41.3366V6.98078C40.542 6.92005 39.7419 6.89086 38.9313 6.89086C38.1202 6.89086 37.3109 6.92239 36.509 6.98486L36.5091 4.18521ZM52.4066 20.4477C51.8292 20.4466 51.275 20.6638 50.8643 21.052L42.1148 29.4347C41.1573 28.9659 40.074 28.699 38.9321 28.699C35.067 28.699 31.8744 31.7369 31.8744 35.4345C31.8744 39.1322 35.067 42.1865 38.9321 42.1865C42.7971 42.1865 45.9726 39.1322 45.9726 35.4345C45.9726 34.3421 45.6937 33.3057 45.2036 32.3896L53.9658 24.0192C54.376 23.6245 54.6055 23.0891 54.6018 22.5321C54.5987 21.9757 54.3632 21.4426 53.9481 21.052C53.538 20.6637 52.9832 20.4465 52.4059 20.4471L52.4066 20.4477ZM38.7918 32.889C38.8382 32.8867 38.8858 32.889 38.9328 32.889C39.6194 32.889 40.2352 33.1196 40.7014 33.5102C40.7393 33.5564 40.7795 33.6013 40.821 33.6445C40.8613 33.679 40.9022 33.7117 40.9449 33.7426C41.3532 34.1887 41.5943 34.7772 41.5943 35.4346C41.5943 36.871 40.4335 38.0014 38.9327 38.0014C37.432 38.0014 36.2498 36.871 36.2498 35.4346C36.2498 34.0433 37.3581 32.9555 38.7918 32.889Z"
      fill="#42BD6A"
    />
    <path
      d="M8.79508 28.3807C8.3825 27.9861 8.1518 27.4506 8.15424 26.893C8.15668 26.3378 8.38922 25.8065 8.80118 25.4153C9.21254 25.0246 9.76975 24.8063 10.3502 24.8086L8.79508 28.3807ZM8.79508 28.3807C9.20704 28.7749 9.76732 28.9956 10.3502 28.9938H22.8567L8.79508 28.3807ZM10.3485 25.2086H10.3502L22.8567 25.2086L22.8583 25.2086C23.3382 25.2067 23.7955 25.3874 24.1302 25.7053C24.4651 26.0233 24.6507 26.4514 24.6526 26.8948C24.6545 27.3396 24.4707 27.7708 24.1353 28.0917L24.4118 28.3807L24.1352 28.0917C23.8 28.4125 23.34 28.5953 22.8579 28.5938H22.8567L10.3502 28.5938L10.349 28.5938C9.86685 28.5953 9.40688 28.4125 9.07159 28.0917L9.07159 28.0917C8.73614 27.7708 8.55229 27.3396 8.55424 26.8948C8.55619 26.4514 8.74171 26.0233 9.07661 25.7053C9.41141 25.3874 9.86865 25.2067 10.3485 25.2086ZM2.19443 33.7507H2.19598H14.7017C15.6993 33.7544 16.4774 34.5232 16.4813 35.4368V35.4369C16.4832 35.8795 16.3013 36.3087 15.969 36.629L15.9688 36.6292C15.6367 36.9497 15.1813 37.1338 14.7015 37.1359L2.19593 37.1359L2.19473 37.1359C1.71263 37.1374 1.25266 36.9546 0.917367 36.6338L0.917361 36.6338C0.581914 36.3129 0.398068 35.8817 0.400015 35.4369L0.400015 35.4369C0.403989 34.5181 1.19069 33.7469 2.19443 33.7507ZM22.8593 45.678H22.8581L10.3516 45.6779L10.3504 45.678C9.87015 45.6794 9.41279 45.4986 9.07802 45.1807C8.74308 44.8626 8.5576 44.4346 8.55565 43.9918C8.5537 43.5463 8.73761 43.1151 9.073 42.7943L8.79649 42.5052L9.07301 42.7943C9.4083 42.4735 9.86813 42.2908 10.35 42.2927H10.3516L22.8581 42.2927L22.8597 42.2927C23.3415 42.2908 23.8014 42.4735 24.1367 42.7943L24.4132 42.5052L24.1367 42.7943C24.4721 43.1152 24.656 43.5463 24.654 43.9912C24.6521 44.4346 24.4665 44.8626 24.1316 45.1807C23.7969 45.4986 23.3395 45.6794 22.8593 45.678Z"
      fill={Theme.secondaryViolet}
      stroke="#EFEFEF"
      strokeWidth="0.8"
    />
  </svg>
);
