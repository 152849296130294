export interface IUser {
  companyId: string;
  emailAddress: string;
  firstName: string;
  id: string;
  lastName: string;
  photoUrl?: string;
  role: UserRolesEnum;
}

export enum UserRolesEnum {
  admin = 'Admin',
  employee = 'Employee',
  HRAdmin = 'HRAdmin',
  superAdmin = 'SuperAdmin',
}

export interface IPendingAccount {
  employeeId: string;
  expiresOn: Date;
  isCreated: boolean;
  token: string;
}
