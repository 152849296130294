import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { includes } from 'utils/text.utils';
import { TemplateRow } from '../template-row/TemplateRow';
import useGetTemplates from 'data/data-hooks/queries/templates/useGetTemplates';
import useSearchBar from 'hooks/useSearchBar';

import {
  ContentBox,
  ContentBoxContainer,
  ContentBoxHeader,
  ContentBoxTitle,
} from 'domains/navigation';
import { Empty } from '../../EmailTemplatesStyles';

export const TemplatesTable: React.FC = () => {
  const { t } = useTranslation();
  const tTitle = t('Template');
  const tPlaceholder = t('No templates available currently');

  const { templates = [] } = useGetTemplates();
  const { searchValue = '' } = useSearchBar();

  const temps = useMemo(
    () =>
      templates.filter(
        ({ name, bodyText, description, header }) =>
          includes(header, searchValue) ||
          includes(name, searchValue) ||
          includes(bodyText, searchValue) ||
          includes(description, searchValue),
      ),
    [templates, searchValue],
  );

  return (
    <ContentBoxContainer>
      <ContentBoxHeader>
        <ContentBoxTitle>{tTitle}</ContentBoxTitle>
      </ContentBoxHeader>
      <ContentBox>
        {temps.length ? (
          temps.map((template, i) => (
            <TemplateRow template={template} key={`temp-${i}`} />
          ))
        ) : (
          <Empty>{tPlaceholder}</Empty>
        )}
      </ContentBox>
    </ContentBoxContainer>
  );
};
