import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 100%;
  flex-grow: 1;

  .chrome-picker {
    height: 190px;
    box-shadow: none !important;
  }
`;

export const PickerContainer = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 10px !important;
  overflow: hidden;
  border: 2px solid ${({ theme }) => theme.primaryBlack};
  box-shadow: 0px 10px 20px ${({ theme }) => theme.primaryBlack}25;
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryBlack};
  opacity: 0.4;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 10px;
  }
`;
