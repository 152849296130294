import styled from 'styled-components';

interface IContainer {
  isSelected?: boolean;
}

export const Container = styled.div<IContainer>`
  min-height: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.backgroundColor};
  font-weight: ${({ theme }) => theme.weightSemiBold};
  font-size: 16px;
  padding: 10px 20px;
  align-items: center;
  background-color: transparent;
  justify-items: start;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  ${({ theme, isSelected }) =>
    isSelected &&
    `background-color: ${theme.secondaryViolet + '08'};
    font-weight: ${theme.weightBold};
    `}

  &:hover {
    background-color: ${({ theme }) => theme.secondaryViolet + '10'};
  }

  @media (max-width: ${({ theme }) => theme.sizeRanges.mobileLarge.max}px) {
    padding: 5px 10px;
  }
`;
