import styled from 'styled-components';

interface IPanel {
  shouldShow: boolean;
}

export const Container = styled.div<IPanel>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${({ shouldShow }) => (shouldShow ? '100vw' : '0')};
  z-index: 1000;
`;
export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
`;

export const Body = styled.div<IPanel>`
  position: absolute;
  height: 100vh;
  width: 200% !important;
  max-width: 560px;
  background-color: white;
  top: 0;
  right: ${({ shouldShow }) => (shouldShow ? 0 : '-560px')};
  z-index: 1000;
  box-shadow: -10px 0px 44px rgba(0, 0, 0, 0.25);
  transition: all 0.5s ease-in-out;
  overflow: scroll;

  @media (max-width: ${({ theme }) => theme.sizeRanges.mobileLarge.max}px) {
    width: 100vw !important;
  }
`;

export const TitleHead = styled.div`
  height: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBlack}10;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 15px;
  padding: 30px;
`;

export const Inner = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const Details = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 30px;
  gap: 10px;
`;

export const ModificationDate = styled.div`
  font-size: 12px;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 10px;
  margin-left: 10px;
`;

export const DateLabel = styled.span`
  opacity: 0.4;
`;

export const Actions = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
