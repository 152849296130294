import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { formatName } from 'utils/user.utils';
import { timeAgo } from 'utils/date.utils';
import useGetNotifications from 'data/data-hooks/queries/useGetNotifications';
import useMutateNotifications from 'data/data-hooks/mutations/notifications/useMutateNotifications';

import { AvatarCircle } from 'components/avatar-circle/AvatarCircle';
import { Arrow, Notifications } from 'components/icons';
import { TooltipContainer } from 'components/menu-tootip';
import { PATHS } from 'routes';

import {
  ButtonContainer,
  NotificationItems,
  ToolTipItems,
  MessageContainer,
  EmptyElement,
  Time,
  EmployeeName,
  Message,
  Dot,
  SeeAllRow,
} from './NotificationsDropdownStyles';

export const NotificationsDropdown: React.FC = () => {
  const [toolTipWidth, setToolTipWidth] = useState<number>(0);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const tNoDataText = t('No notification messages yet');
  const tSeeAll = t('See All');

  const { notifications = [] } = useGetNotifications();
  const { markAsSeen } = useMutateNotifications();
  const toolTipInner = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    toolTipInner.current && setToolTipWidth(toolTipInner.current.clientWidth || 0);
  }, [toolTipInner.current]);

  const handleAfterHide = () => markAsSeen(notifications);

  const renderNotifications = () => {
    if (!notifications.length) return <EmptyElement>{tNoDataText}</EmptyElement>;

    return (
      <Fragment>
        {notifications
          .slice(0, 5)
          .map(({ message, createdOn, employee, id, status }) => (
            <NotificationItems key={`option-${id}`}>
              <AvatarCircle
                size={24}
                name={employee ? formatName(employee) : undefined}
                photoUrl={employee?.photoUrl}
              />
              <MessageContainer>
                <EmployeeName>{employee ? formatName(employee) : ''}</EmployeeName>
                <Message>{t(message)}</Message>
              </MessageContainer>
              <Time>
                {timeAgo(createdOn)}
                {status !== 'seen' && <Dot />}
              </Time>
            </NotificationItems>
          ))}

        <SeeAllRow onClick={() => navigate(PATHS.notifications)}>
          <span>{tSeeAll}</span>
          <Arrow />
        </SeeAllRow>
      </Fragment>
    );
  };

  return (
    <TooltipContainer>
      <ButtonContainer data-event="click" data-for="notifications" data-tip>
        <Notifications
          dark
          full={!!notifications.find(({ status }) => status !== 'seen')}
        />
      </ButtonContainer>

      <ReactTooltip
        afterHide={handleAfterHide}
        backgroundColor="#fff"
        className="tooltip"
        clickable={true}
        effect="solid"
        globalEventOff="click"
        id={'notifications'}
        offset={{ left: toolTipWidth / 2 - 85 }}
        place="bottom"
        scrollHide={true}
      >
        <ToolTipItems ref={toolTipInner}>{renderNotifications()}</ToolTipItems>
      </ReactTooltip>
    </TooltipContainer>
  );
};
