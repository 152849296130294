import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setToken } from 'state/photo-verification/PhotoVerificationActions';

import { Footer, PublicHeaderNav } from 'domains/navigation';
import { Loader } from 'components/loader';

import { EditPhotoStep } from './components/edit-photo-step/EditPhotoStep';
import { ErrorPage } from './components/error-page/ErrorPage';
import { PhotoUploadStep } from './components/photo-upload-step/PhotoUploadStep';
import { TakePhotoStep } from './components/take-photo-step/TakePhotoStep';
import { ThankYouPage } from './components/thank-you-page/ThankYouPage';

import {
  Container,
  ContentContainer,
  LoaderContainer,
  Title,
} from './PhotoIdUploadStyles';

export const PhotoIdUpload: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams<'token'>();
  const { token } = params;

  const { stepIndex, isLoading, userData } = useAppSelector(
    ({ photoVerification }) => photoVerification,
  );

  const { t } = useTranslation();
  const tWelcome = t('Welcome');
  const paragraphText = t('Please submit your photo ID');

  useEffect(() => {
    token && dispatch(setToken(token));
  }, [token]);

  return (
    <Container>
      <PublicHeaderNav />

      {stepIndex === -1 ? (
        <ErrorPage />
      ) : stepIndex === 5 ? (
        <ThankYouPage />
      ) : (
        <ContentContainer>
          <Title>{`${tWelcome}${
            userData ? ' ' + userData.employeeName : ''
          }!`}</Title>
          <h1>{paragraphText}</h1>

          {!!(!stepIndex || isLoading) && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
          {stepIndex === 1 && <PhotoUploadStep />}
          {stepIndex === 2 && <TakePhotoStep />}
          {stepIndex === 3 && <EditPhotoStep />}
        </ContentContainer>
      )}

      <Footer />
    </Container>
  );
};
