import React from 'react';
import { Digital, Quality, Security, Speed } from './icons';

import { Container, Inner, Section, Text, Title } from './WhyUsStyles';

export const WhyUs: React.FC = () => {
  return (
    <Container>
      <h2>Why Us</h2>
      <Inner>
        <Section>
          <Quality />
          <Title>Quality</Title>
          <Text>
            No need to print physical employee ID cards. Easier to manage, generate
            and distribute to employees.
          </Text>
        </Section>
        <Section>
          <Speed />
          <Title>Speed</Title>
          <Text>
            Quickly manage employee digital ID cards remotely via our card management
            system
          </Text>
        </Section>
        <Section>
          <Security />
          <Title>Security</Title>
          <Text>
            Our digital cards are encrypted in transit and stored securly in the
            cloud
          </Text>
        </Section>
        <Section>
          <Digital />
          <Title>Digital</Title>
          <Text>Verify, activate, and deactivate employee cards digitially.</Text>
        </Section>
      </Inner>
    </Container>
  );
};
