import React from 'react';

import { Check } from 'pages/landing/icons/Check';
import { ColoredBox } from '../colored-box/ColoredBox';

import { Col, Container, Grid, Row, Text, Title } from './SecurityStyles';

export const Security: React.FC = () => {
  return (
    <Container>
      <ColoredBox color="green">
        <h2>Data security you can rely on </h2>
        <Grid>
          <Row>
            <Check />
            <Col>
              <Title>Secure Authentication</Title>
              <Text>Best practices used in authentication</Text>
            </Col>
          </Row>
          <Row>
            <Check />
            <Col>
              <Title>Servers hosted in Secure Cloud Platform</Title>
              <Text>It is reliable, safe and of high quality</Text>
            </Col>
          </Row>
          <Row>
            <Check />
            <Col>
              <Title>Data Encryption</Title>
              <Text>256 bit encryption when data is in transit</Text>
            </Col>
          </Row>
        </Grid>
      </ColoredBox>
    </Container>
  );
};
