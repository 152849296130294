import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;

  @media (max-width: ${({ theme }) => theme.widthTablet}) {
    height: 360px;
  }
`;
