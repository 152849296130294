import styled from 'styled-components';

export const Inner = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr;
  align-items: center;
  padding: 0px 10px;

  @media only screen and (max-width: ${({ theme }) =>
      theme.sizeRanges.desktop.min}px) {
    grid-template-columns: 1fr 60px;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const Controllers = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  padding-right: 10px;
`;
