import { Checkbox } from 'components/checkbox';
import React from 'react';
import { CellProps, Column } from 'react-table';

type TUseColumnArgs = {
  accessor?: string;
  canSelect?: boolean;
  columns: Column[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isRowDisabled?: (row: any) => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect?: (row: any, e?: React.ChangeEvent<HTMLInputElement>) => void;
  selectedRows?: string[];
};

export const useColumns = ({
  accessor = 'id',
  canSelect = false,
  columns,
  isRowDisabled,
  onSelect,
  selectedRows = [],
}: TUseColumnArgs): Column[] => {
  let allColumns = columns;

  if (canSelect && onSelect) {
    const checkboxCol: Column = {
      id: 'checkbox',
      Header: '',
      accessor: (row) => row,
      disableSortBy: true,
      disableFilters: true,
      // eslint-disable-next-line react/display-name
      /* eslint-disable @typescript-eslint/no-explicit-any */
      Cell: ({
        cell: { value, row },
        ...props
      }: CellProps<Record<string, never>, any>) => {
        return (
          <Checkbox
            handleCheckClick={(e) => onSelect(value, e)}
            isSelected={selectedRows.includes(value[accessor])}
            isRowDisabled={isRowDisabled}
            {...props}
            row={row.original}
          />
        );
      },
    };

    allColumns = [checkboxCol, ...allColumns];
  }

  return allColumns;
};
