export const TEMPS__SET_IS_LOADING = 'TEMPS__SET_IS_LOADING';
export const TEMPS__SET_CURRENT_TEMP = 'TEMPS__SET_CURRENT_TEMP';

interface SetIsLoadingAction {
  type: typeof TEMPS__SET_IS_LOADING;
  isLoading: boolean;
}

interface SetCurrentTempAction {
  type: typeof TEMPS__SET_CURRENT_TEMP;
  templateId: string;
}

export type EmailTemplatesActionTypes = SetCurrentTempAction | SetIsLoadingAction;

export type TSetIsLoading = (isLoading: boolean) => SetIsLoadingAction;

export type TSetCurrentTemplate = (templateId: string) => SetCurrentTempAction;
