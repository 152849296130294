import { firestore, httpsCallable } from 'data/firebase';
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { addWeeks } from 'date-fns';

import { parseError } from 'utils/errors.utils';
import { encodeToken } from 'utils/token.utils';
import { formatName } from 'utils/user.utils';
import dbLabels from 'data/db.labels';

import { Adapter } from 'data/types';
import { ICompany } from 'types/company.types';
import { IUser } from 'types/user.types';

interface IArgs {
  company: ICompany;
  employeeId: string;
  user: Omit<IUser, 'id'>;
}

export default async ({ company, employeeId, user }: IArgs): Promise<Adapter> => {
  try {
    const token = encodeToken(user);
    const url = `${window.location.origin}/create-account/${token}`;
    const companyId = company.id;
    const collectionRef = collection(firestore, dbLabels.pendingAccount);

    const documentExists = await getDocs(
      query(
        collectionRef,
        where('employeeId', '==', employeeId),
        where('companyId', '==', companyId),
      ),
    ).then((snapshot) => {
      if (snapshot.docs.length) {
        const doc = snapshot.docs[0];
        return { ...doc.data(), id: doc.id };
      }
    });

    if (!documentExists) {
      await addDoc(collectionRef, {
        companyId,
        employeeId,
        expiresOn: addWeeks(new Date(), 1),
        isCreated: false,
        token,
      });
    } else {
      await updateDoc(doc(collectionRef, documentExists.id), {
        token,
        expiresOn: addWeeks(new Date(), 1),
        isCreated: false,
      });
    }

    const sendEmail = httpsCallable('sendEmail');

    const body = `Dear ${formatName(user)},\n You were added to ${
      company.companyName
    }! \n Please follow the link below to finalize your account. \n \n ${url}`;

    await sendEmail({
      to: user.emailAddress,
      from: 'info@digiprnt.com',
      subject: `${company.companyName} - Account Creation`,
      html: body
        ?.split('\n')
        .map((paragraph) => `<p>${paragraph}</p>`)
        .join(''),
    });

    return { success: true };
  } catch (err) {
    const errorMessage = parseError(err);

    return { error: errorMessage, success: false };
  }
};
