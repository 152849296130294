import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper';

import { useTheme } from 'styled-components';
import { useTestimonials } from 'data/data-hooks/queries/corporate';
import useDimensions from 'hooks/useDimensions';

import { TestimonialSlide } from './components/testimonial-slide/TestimonialSlide';
import { SwiperButtons } from './components/swiper-buttons/SwiperButtons';

import { Container } from './TestimonialsStyles';

export const Testimonials: React.FC = () => {
  const [slidesPerView, setSlidesPerView] = useState(2);

  const theme = useTheme();
  const { dimensions } = useDimensions();
  const { testimonials } = useTestimonials();

  useEffect(() => {
    setSlidesPerView((dimensions.width || 0) < theme.sizeRanges.desktop.min ? 1 : 2);
  }, [dimensions.width]);

  return (
    <Container>
      <h2>Testimonials</h2>
      <Swiper
        spaceBetween={30}
        slidesPerView={slidesPerView}
        modules={[Scrollbar]}
        scrollbar={{}}
      >
        {testimonials.map((data, index) => (
          <SwiperSlide key={`slide-${index}`}>
            <TestimonialSlide {...data} />
          </SwiperSlide>
        ))}
        <SwiperButtons />
      </Swiper>
    </Container>
  );
};
