import { useEffect, useMemo } from 'react';

import { usePhotoRequests } from 'contexts';
import {
  removeFromSelection,
  toggleRequestSelection,
  toggleSelection,
} from 'state/admin-verification/AdminVerficationActions';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import useSearchBar from 'hooks/useSearchBar';

import { formatName } from 'utils/user.utils';
import COLUMNS from '../assets/columns';

import { ICompanyPhotoRequest } from 'types/photoRequest.types';

export default () => {
  const dispatch = useAppDispatch();

  const { searchValue } = useSearchBar();
  const { isLoading, notAccepted } = usePhotoRequests();
  const { selectedRequestsIds } = useAppSelector(
    ({ adminVerification }) => adminVerification,
  );

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(
    () =>
      notAccepted.filter(
        (req) =>
          req.emailAddress &&
          formatName(req).toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [notAccepted, searchValue],
  );

  const onSelect = (row: ICompanyPhotoRequest) =>
    dispatch(toggleRequestSelection(row.id));
  const removeSelection = (row: ICompanyPhotoRequest) =>
    dispatch(removeFromSelection(row.id));
  const toggleSelectAll = () => dispatch(toggleSelection(data));

  useEffect(() => {
    const removedIds = [...selectedRequestsIds].filter(
      (id) => !data.find((req) => req.id === id),
    );

    for (const id of removedIds) removeSelection({ id } as ICompanyPhotoRequest);
  }, [data.length]);

  return {
    columns,
    data,
    isLoading,
    onSelect,
    selectedRequestsIds,
    toggleSelectAll,
  };
};
