import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SubmitButton } from 'components/buttons';
import { PublicHeaderNav } from 'domains/navigation';

import {
  ButtonContainer,
  Container,
  FourOhFour,
  MainText,
  SubText,
  TextContainer,
} from './PageNotFoundStyles';

export const PageNotFound: React.FC = () => {
  const { t } = useTranslation();
  const tMain = t(`Oops... Looks Like You're Lost`);
  const tSub = t(
    `This page doesn't exist or was removed. We suggest you return to homepage.`,
  );
  const tBtn = t('Go To Homepage');

  const navigate = useNavigate();
  const goToHomePage = () => navigate('..');

  return (
    <Container>
      <PublicHeaderNav />
      <FourOhFour>404</FourOhFour>

      <TextContainer>
        <MainText>{tMain}</MainText>
        <SubText>{tSub}</SubText>
        <ButtonContainer>
          <SubmitButton onClick={goToHomePage}>{tBtn}</SubmitButton>
        </ButtonContainer>
      </TextContainer>
    </Container>
  );
};
