import { Theme } from 'assets/styles';
import styled from 'styled-components';

interface ICard {
  backgroundColor?: string;
  foregroundColor?: string;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Card = styled.div<ICard>`
  padding: 0px 0px 30px;
  width: 360px;
  max-height: 450px;
  height: 100%;
  box-shadow: 0px 15px 20px -6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  background: ${({ backgroundColor = Theme.primaryGreen }) =>
    `linear-gradient(90deg, ${backgroundColor}90 14.06%, ${backgroundColor} 100%)`};
  color: ${({ foregroundColor = '#000' }) => foregroundColor};
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 10px 20px 10px;
`;

export const TopContainer = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 25px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Logo = styled.img`
  height: 55px;
  width: 100px;
`;

export const FullName = styled.div`
  font-size: 25px;
  line-height: 27px;
`;

export const Company = styled.div`
  font-size: 15px;
  line-height: 20px;
`;

export const Title = styled.div`
  font-size: 10px;
  line-height: 15px;
  font-weight: ${({ theme }) => theme.weightBold};
  text-transform: uppercase;
`;

export const QRContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
