import React from 'react';
import { useNavigate } from 'react-router-dom';

import { SubmitButton } from 'components/buttons';
import { PATHS } from 'routes';

import { Actions, Container } from './ErrorPageStyles';

export const CreateAccountErrPage: React.FC = () => {
  const navigate = useNavigate();
  const login = () => navigate(PATHS.login);

  return (
    <Container>
      <h1>Your password was already set successfully</h1>
      <Actions>
        <SubmitButton onClick={login}>Log in</SubmitButton>
      </Actions>
    </Container>
  );
};
