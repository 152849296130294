import React from 'react';
import moment from 'moment';
import { CellProps, Column } from 'react-table';
import { t } from 'i18next';

import { ActionsCell } from '../components/actions-cell/ActionsCell';
import { EmployeeDetails } from 'components/table/cells/employee-details/EmployeeDetails';

import { Status } from '../CardUpdatesListStyles';
import { DateFilter } from 'components/table/filters';
import { IDateRange } from 'types/shared.types';
import { IEmployeeCard } from 'types/card.types';

export default [
  {
    accessor: (row) => row,
    Cell: ({ cell: { value } }: CellProps<Record<string, never>>) => {
      return <EmployeeDetails employee={value} />;
    },
    disableFilters: true,
    Header: 'Name',
    id: 'name',
    disableSortBy: true,
  },
  {
    accessor: 'emailAddress',
    id: 'emailAddress',
    disableFilters: true,
    Header: 'Email',
    disableSortBy: true,
  },
  {
    accessor: 'title',
    disableFilters: true,
    Header: 'Title',
    id: 'title',
    disableSortBy: true,
  },
  {
    accessor: (row) => (row.hireDate ? moment(row.hireDate).unix() : undefined), // convert it to unix for sorting
    // disableFilters: false,
    Header: 'Hire Date',
    id: 'hireDate',
    disableSortBy: false,
    Cell: ({ cell: { value } }: CellProps<Record<string, never>>) => {
      return value ? moment.unix(value).utc().format('YYYY-MM-DD') : 'N/A';
    },
    Filter: DateFilter,
    filter: (rows, _, { startDate, endDate }: IDateRange) => {
      return rows.filter((row) => {
        if (!startDate || !endDate) return true;
        if (!row.original.hireDate) return false;

        const hireDate = moment(row.original.hireDate);
        return hireDate >= moment(startDate) && hireDate <= moment(endDate);
      });
    },
  },
  {
    accessor: 'isActive',
    disableFilters: true,
    Header: 'status',
    id: 'status',
    Cell: ({ cell: { value } }: CellProps<Record<string, never>>) => (
      <Status isActive={value}>
        {value ? `${t('Active')}` : `${t('Inactive')}`}
      </Status>
    ),
    disableSortBy: true,
    filter: (rows, _, value: 'all' | 'active' | 'inactive') => {
      return rows.filter(({ original }) => {
        if (value === 'all') return true;
        if (value === 'active') return !!original.isActive;
        return !original.isActive;
      });
    },
  },
  {
    accessor: (row) => row,
    Cell: ActionsCell,
    disableFilters: true,
    disableSortBy: true,
    Header: 'Actions',
    id: 'actions',
  },
  {
    // This is a hidden column added for sorting
    accessor: (row) => (row.modifiedOn ? moment(row.modifiedOn).unix() : undefined),
    id: 'modifiedOn',
    Cell: () => <></>,
  },
  {
    // This is a hidden column added for filtering
    accessor: (row) => row,
    id: 'passStatus',
    Cell: () => <></>,
    filter: (rows, _, value: 'all' | 'downloaded' | 'deleted' | 'unknown') => {
      return rows.filter(({ original }) => {
        if (value === 'all') return true;
        if (value === 'downloaded')
          return original.isPassDownloaded && !original.isPassDeleted;
        if (value === 'deleted') return original.isPassDeleted;
        return !original.isPassDeleted && !original.isPassDownloaded;
      });
    },
  },
  {
    // This is a hidden column added for filtering
    accessor: (row) => row,
    id: 'photoStatus',
    Cell: () => <></>,
    filter: (rows, _, value: 'all' | 'noPhoto' | 'photoUploaded') => {
      return rows.filter(({ original }) => {
        if (value === 'all') return true;
        if (value === 'noPhoto') return !original.photoUrl;
        return !!original.photoUrl;
      });
    },
  },
  {
    // This is a hidden column added for filtering
    accessor: (row) => row,
    id: 'orgLevel1',
    Cell: () => <></>,
    filter: (rows, _, value: string) => {
      return rows.filter(({ original }) => {
        if (value === 'all') return true;
        return original.orgLevel1Code === value;
      });
    },
  },
  {
    // This is a hidden column added for filtering
    accessor: (row) => row,
    id: 'orgLevel2',
    Cell: () => <></>,
    filter: (rows, _, value: 'all' | 'noPhoto' | 'photoUploaded') => {
      return rows.filter(({ original }) => {
        if (value === 'all') return true;
        return original.orgLevel2Code === value;
      });
    },
  },
  {
    // This is a hidden column added for filtering
    accessor: (row) => row,
    id: 'orgLevel3',
    Cell: () => <></>,
    filter: (rows, _, value: 'all' | 'noPhoto' | 'photoUploaded') => {
      return rows.filter(({ original }) => {
        if (value === 'all') return true;
        return original.orgLevel3Code === value;
      });
    },
  },
  {
    // This is a hidden column added for filtering
    accessor: (row) => row,
    id: 'orgLevel4',
    Cell: () => <></>,
    filter: (rows, _, value: 'all' | 'noPhoto' | 'photoUploaded') => {
      return rows.filter(({ original }) => {
        if (value === 'all') return true;
        return original.orgLevel4Code === value;
      });
    },
  },
] as Column<IEmployeeCard>[] as Column[];
