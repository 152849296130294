import React, { Dispatch, SetStateAction } from 'react';
import AvatarEditor from 'react-avatar-editor';

import { useAppSelector } from 'state/hooks';
import useGetCardConfig from 'domains/hooks/useCardConfig';

import { ColorInput } from 'components/inputs';
import { Loader } from 'components/loader';
import { PhotoPickerSection } from '../photo-picker-section/PhotoPickerSection';

import { ICardConfig } from 'types/card.types';
import { Container, Inner, LoaderContainer } from './SettingsSectionStyles';
import { checkPhoto } from 'utils/photo.utils';

interface Props {
  config?: ICardConfig;
  setEditorRef: (editor: AvatarEditor | null) => AvatarEditor | null;
  setImageDidChange: Dispatch<SetStateAction<boolean>>;
  updateConfig: (data: Partial<ICardConfig>) => void;
}

export const SettingsSection: React.FC<Props> = ({
  config,
  setEditorRef,
  setImageDidChange,
  updateConfig,
}: Props) => {
  const { currentCompany } = useAppSelector(({ company }) => company);
  const { isLoading } = useGetCardConfig();

  return (
    <Container>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <Inner>
          <ColorInput
            value={config?.backgroundColor}
            label="Background Color"
            onChange={(color) => updateConfig({ backgroundColor: color.hex })}
          />
          <ColorInput
            value={config?.foregroundColor}
            label="Font"
            onChange={(color) => updateConfig({ foregroundColor: color.hex })}
          />
          <PhotoPickerSection
            setEditorRef={setEditorRef}
            setImageDidChange={setImageDidChange}
            photoUrl={checkPhoto(currentCompany?.logoURL)}
          />
        </Inner>
      )}
    </Container>
  );
};
