import styled from 'styled-components';

interface IContainer {
  isDisplayed: boolean;
  isShownMobile: boolean;
}

interface IMainLinkContainer {
  isActive?: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
`;

export const MainLinkContainer = styled.div<IMainLinkContainer>`
  align-items: center;
  gap: 10px;
  color: white;
  display: flex;
  fill: white;
  font-size: 14px;
  :hover {
    fill: ${({ theme }) => theme.secondaryViolet};
    color: ${({ theme }) => theme.secondaryViolet};
  }

  ${({ isActive = true, theme }) =>
    isActive &&
    `
        fill: ${theme.secondaryViolet};
        color: ${theme.secondaryViolet};
        font-weight: ${theme.weightBold};
    `}
`;

export const Label = styled.div`
  font-size: 16px;
  z-index: 5;
`;
