import { useAppSelector } from 'state/hooks';
import { useMutation, useQueryClient } from 'react-query';

import { FailToast } from 'components/toasts';
import retryCardGeneration from 'data/adapters/cards/retryCardGeneration';

import { ICardGeneration } from 'types/card.types';

interface IArgs {
  onSuccess?: () => void;
  onError?: () => void;
}

export default (args?: IArgs) => {
  const { currentCompany } = useAppSelector(({ company }) => company);
  const queryClient = useQueryClient();

  const mutation = useMutation(
    async (cards: ICardGeneration | ICardGeneration[]) => {
      const list = Array.isArray(cards) ? cards : [cards];
      if (!currentCompany) return;

      await retryCardGeneration({
        cards: list,
        company: currentCompany,
        refetch: () => queryClient.refetchQueries(['card_generations']),
      });
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(['card_generations']);
        args?.onSuccess && args.onSuccess();
      },
      onError: () => {
        queryClient.refetchQueries(['card_generations']);
        args?.onError && args.onError();
        FailToast('Failed to send card(s)');
      },
    },
  );

  return {
    ...mutation,
    resendFailedCard: mutation.mutateAsync,
  };
};
