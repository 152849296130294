import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { CardManagementRoutes } from './cardManagement.routes';

import { AdminPhotoVerification } from 'domains/admin-photo-verification/AdminPhotoVerification';
import { CardTracker } from 'domains/cards/card-tracker/CardTracker';
import { ContactUs } from 'pages/contact-us/ContactUs';
import { CreateAccount } from 'domains/auth/create-account/CreateAccount';
import { Dashboard } from 'domains/dashboard/Dashboard';
import { EmailTemplates } from 'domains/email-templates/EmailTemplates';
import { ForgotPassword } from 'domains/auth/forgot-password/ForgotPassword';
import { LandingPage } from 'pages/landing/LandingPage';
import { Login } from 'domains/auth/login/Login';
import { NotificationsPage } from 'domains/notifications/notifications-page/NotificationsPage';
import { PageNotFound } from 'pages/page-not-found/PageNotFound';
import { PhotoIdUpload } from 'domains/photo-id-upload/PhotoIdUpload';
import { PrivacyPolicy } from 'pages/privacy-policy/PrivacyPolicy';
import { TermsOfService } from 'pages/terms-of-service/TermsOfService';

import { PATHS } from './paths';
import { PrivatePage } from 'pages/private-page';
import { RolesProvider } from './components/roles-provider';
import accessRules from './components/roles-provider/assets/accessRules';

export const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={PATHS.contactUs} element={<ContactUs />} />
        <Route path={PATHS.forgotPassword} element={<ForgotPassword />} />
        <Route path={PATHS.home} element={<LandingPage />} />
        <Route path={PATHS.login} element={<Login />} />
        <Route path={PATHS.createAccount} element={<CreateAccount />} />
        <Route path={PATHS.pageNotFound} element={<PageNotFound />} />
        <Route path={PATHS.photoIdUpload} element={<PhotoIdUpload />} />
        <Route path={PATHS.privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={PATHS.termsOfService} element={<TermsOfService />} />

        <Route path="/">
          <Route index element={<Navigate replace to={PATHS.home} />} />

          <Route element={<PrivatePage />}>
            <Route
              path={`${PATHS.dashboard}/*`}
              element={
                <RolesProvider accessRules={accessRules.dashboard}>
                  <Dashboard />
                </RolesProvider>
              }
            />
            <Route
              path={`${PATHS.cardManagement}/*`}
              element={<CardManagementRoutes />}
            />
            <Route
              path={PATHS.cardTracker}
              element={
                <RolesProvider accessRules={accessRules.cardManagement}>
                  <CardTracker />
                </RolesProvider>
              }
            />
            <Route
              path={`${PATHS.adminPhotoVerification}/*`}
              element={
                <RolesProvider accessRules={accessRules.photoVerification}>
                  <AdminPhotoVerification />
                </RolesProvider>
              }
            />
            <Route
              path={PATHS.notifications}
              element={
                <RolesProvider accessRules={accessRules.notifications}>
                  <NotificationsPage />
                </RolesProvider>
              }
            />
            <Route path={PATHS.emailTemplates} element={<EmailTemplates />} />
            <Route path={PATHS.settings} element={<>settings</>} />
          </Route>
        </Route>

        <Route path="*" element={<Navigate replace to={PATHS.pageNotFound} />} />
      </Routes>
    </BrowserRouter>
  );
};
