import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Inner = styled.div`
  height: 80px;
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`;

export const SearchBarContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;

  @media (max-width: ${({ theme }) => theme.sizeRanges.tablet.min}px) {
    gap: 15px;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: ${({ theme }) => theme.sizeRanges.tablet.min}px) {
    gap: 15px;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const UserName = styled.span`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryBlack};
`;

const appear = keyframes`
  0% { height: 50%; opacity: 0; }
  100% { height: 100%;opacity: 1; }
`;

export const MobilePanel = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  gap: 30px;
  background-color: ${({ theme }) => theme.primaryBlack};
  margin: 0px 20px 20px;
  border-radius: 20px;
  animation: ${appear} 0.3s linear forwards;
  overflow: hidden;

  display: none;

  @media (max-width: ${({ theme }) => theme.sizeRanges.desktop.min}px) {
    display: block;
  }
`;

export const HamburgerContainer = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.sizeRanges.desktop.min}px) {
    display: block;
  }
`;
