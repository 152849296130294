import { useAppDispatch, useAppSelector } from 'state/hooks';
import { clearValue, setValue } from 'state/search-bar/SearchBarActions';

export default () => {
  const dispatch = useAppDispatch();
  const { searchValue } = useAppSelector(({ searchBar }) => searchBar);

  const updateValue = (val: string) => dispatch(setValue(val));
  const clear = () => dispatch(clearValue());

  return { searchValue, setValue: updateValue, clear };
};
