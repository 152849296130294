import { Adapter } from '../types';
import { httpsCallable } from 'data/firebase';
import { parseError } from 'utils/errors.utils';

interface IContactUsArgs {
  body?: string;
  cc: string | string[];
  company: string;
  email: string;
  subject?: string;
  to: string;
}

export default async ({
  body,
  cc,
  company,
  email,
  subject,
  to,
}: IContactUsArgs): Promise<Adapter> => {
  try {
    const sendEmail = httpsCallable('sendEmail');

    await sendEmail({
      to: to,
      from: email,
      subject: `${company}${subject ? ' - ' + subject : ''}`,
      html: body
        ?.split('\n')
        .map((paragraph) => `<p>${paragraph}</p>`)
        .join(''),
      ...(cc && { cc: Array.isArray(cc) ? cc : [cc] }),
    });

    return { success: true };
  } catch (err) {
    const stringError = parseError(err);

    return { success: false, error: stringError };
  }
};
