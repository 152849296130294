import React from 'react';
import { DemoImg } from './components/demo-img/DemoImg';
import { RequestDemo } from '../request-demo/RequestDemo';

import {
  Container,
  LeftInner,
  MainText,
  Section,
  SubText,
  Text,
  Title,
} from './HeroStyles';

export const Hero: React.FC = () => {
  return (
    <Container>
      <Section>
        <LeftInner>
          <Text>
            <SubText>Simple. Effective. Accurate.</SubText>
            <Title>{`Digital ID Card App & Management System`}</Title>
            <MainText>
              An all-in-one digital identity solution for issuing and managing
              digital ID cards
            </MainText>
          </Text>

          <RequestDemo />
        </LeftInner>
      </Section>
      <Section>
        <DemoImg />
      </Section>
    </Container>
  );
};
