import React from 'react';
import { BeatLoader } from 'react-spinners';
import { useTheme } from 'styled-components';

interface Props {
  color?: string;
  dark?: boolean;
  isLoading?: boolean;
  size?: number;
}

export const Loader: React.FC<Props> = ({
  color,
  dark = false,
  isLoading = true,
  size = 15,
}: Props) => {
  const theme = useTheme();

  return (
    <BeatLoader
      color={color || (dark ? '#fff' : theme?.primaryGreen || '#42BD6A')} // hardcoded color for Mozilla firefox
      loading={isLoading}
      size={size}
    />
  );
};
