import styled from 'styled-components';
import { INotification } from 'types/notification.types';

interface IStatus {
  status: INotification['status'];
}

export const Status = styled.div<IStatus>`
  padding: 10px 20px;
  border-radius: 50px;
  width: fit-content;
  height: fit-content;
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 12px;
  line-height: 16px;

  ${({ theme, status }) =>
    status === 'seen'
      ? `
        background-color: ${theme.gray}20;
        color: ${theme.gray};
      `
      : `
        background-color: ${theme.secondaryViolet}20;
        color: ${theme.secondaryViolet};
      `}

  @media (max-width: ${({ theme }) => theme.sizeRanges.tablet.max}px) {
    padding: 5px 10px;
    font-size: 10px;
  }
`;

export const MessageContainer = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: break-spaces;
  padding: 10px 20px 10px 0px;
`;

export const Message = styled.div`
  max-width: 100%;
`;

export const Actions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px 10px;
`;
