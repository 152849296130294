import { useState } from 'react';
import { useAppSelector } from 'state/hooks';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useEmployeeCards } from 'contexts';
import sendDigitalIds from 'data/adapters/cards/sendDigitalIds';
import submitIdsToGenerate from 'data/adapters/cards/submitIdsToGenerate';

import { FailToast } from 'components/toasts';
import { CardTrackerFailToast } from 'components/toasts/card-tracker-toasts';

interface IArgs {
  onSuccess?: () => void;
}

export default (args?: IArgs) => {
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const { currentCompany } = useAppSelector(({ company }) => company);
  const { employeeCards = [] } = useEmployeeCards();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation(
    async ({
      cardIds,
      updatedPhotos,
      onEmailsSent,
    }: {
      cardIds: string | string[];
      updatedPhotos?: {[key: string]: string };
      onEmailsSent?: (response: { success: boolean }) => void;
    }) => {
      if (!employeeCards.length || !currentCompany) throw 'Invalid data';

      const employeeCardsFiltered = employeeCards.filter(({ cardId }) =>
        cardIds.includes(cardId),
      )?.map(item => {
        if(!updatedPhotos || !updatedPhotos?.[item.employeeId]){
          return item
        }
        return {
          ...item,
          photoUrl: updatedPhotos?.[item.employeeId]
        }
      });
      const { data, success, error } = await submitIdsToGenerate({
        cards: employeeCardsFiltered,
        company: currentCompany,
      });

      await Promise.all([
        queryClient.refetchQueries(['card_generations']),
        queryClient.refetchQueries(['employee_cards'], { exact: false }),
      ]);

      if (error === '@Error/some-failed-cards' && !!data?.submittedCards.length) {
        CardTrackerFailToast('Failed to send some cards', navigate);
      } else if (!success || error || !data?.submittedCards.length) {
        throw error;
      }

      setIsSendingEmail(true);
      sendDigitalIds({
        cards: data.submittedCards,
        company: currentCompany,
        queryClient,
        onEmailsSent: (res) => onEmailsSent && onEmailsSent(res),
      }).finally(() => {
        setIsSendingEmail(false);
        queryClient.refetchQueries(['employee_cards']);
        queryClient.refetchQueries(['card_generations']);
      });
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(['card_generations']);
        args?.onSuccess && args.onSuccess();
      },
      onError: () => {
        queryClient.refetchQueries(['card_generations']);
        FailToast('Failed to send card(s)');
      },
    },
  );

  return {
    ...mutation,
    sendCardId: mutation.mutateAsync,
    isSendingEmail,
    isGeneratingCard: mutation.isLoading,
    isLoading: mutation.isLoading || isSendingEmail,
  };
};
