import React, { useEffect, useState } from 'react';
import Select, { OptionProps, OptionsType, OptionTypeBase } from 'react-select';

import DropdownIndicator from './components/dropdown-indicator/DropdownIndicator';
import Option from './components/option/Option';
import ValueContainer from './components/value-container/ValueContainer';

import useDimensions from 'hooks/useDimensions';
import getSelectOptions from './utils/getSelectOptions';
import handleOnChange from './utils/handleOnChange';

import DropdownStyles, {
  Border,
  Container,
  ErrorMessage,
  InnerTopContainer,
  Label,
  TopContainer,
} from './SelectDropdownStyles';
import { useTranslation } from 'react-i18next';

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface GenericOption extends OptionTypeBase {
  label: string;
  value: any;
}

interface Props {
  closeMenuOnSelect?: boolean;
  data: OptionTypeBase[] | (string | number)[];
  error?: string;
  fill?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  label?: string;
  labelAccessor?: string;
  name?: string;
  onChange: (value: any) => void;
  placeholder?: string;
  removeSelected?: boolean;
  value?: string | GenericOption | OptionsType<GenericOption> | null;
  valueAccessor?: string;
}

export const SelectDropdown: React.FC<Props> = ({
  data: rawData,
  closeMenuOnSelect = false,
  error,
  fill = false,
  isDisabled = false,
  isMulti = false,
  label = '',
  labelAccessor = 'label',
  name = '',
  onChange,
  placeholder = '',
  removeSelected = false,
  value = null,
  valueAccessor = 'value',
}: Props) => {
  const [selectedOption, setSelectedOption] = useState<OptionTypeBase | null>(null);
  const { ref, dimensions } = useDimensions();
  const { t } = useTranslation();

  let data: OptionTypeBase[] = [];
  if (
    rawData.length &&
    (typeof rawData[0] === 'string' || typeof rawData[0] === 'number')
  ) {
    data = rawData.map((val) => ({
      [labelAccessor]: val,
      [valueAccessor]: val,
    }));
  } else {
    data = rawData as OptionTypeBase[];
  }

  useEffect(() => {
    if (typeof value === 'string') setSelectedOption({ label: value, value });
    else setSelectedOption(value);
  }, [value]);

  const selectOptions = getSelectOptions(data, labelAccessor, valueAccessor);

  const dropdownChangeCallback = (
    newValue: GenericOption | OptionsType<GenericOption> | null,
  ) => {
    onChange(newValue || null);
    setSelectedOption(newValue);
  };

  const onSelect = (
    newValue: OptionTypeBase | OptionsType<OptionTypeBase> | null,
    { action }: { action: unknown },
  ) => {
    handleOnChange({
      newValue: newValue as GenericOption | OptionsType<GenericOption> | null, // same thing but typescript complains without this
      action,
      callback: dropdownChangeCallback,
    });
  };

  const getOptionValue = (x: OptionTypeBase) => x.value;

  const WrappedOption = (props: OptionProps<OptionTypeBase, boolean>) => {
    return <Option {...props} dimensions={dimensions} />;
  };

  const components = {
    DropdownIndicator,
    ValueContainer,
    Option: WrappedOption,
  };

  const tError = error && t(error);
  const tLabel = t(label);
  const tPlaceholder = t(placeholder);

  return (
    <Container ref={ref} fillWidth={fill}>
      {(label || error) && (
        <TopContainer>
          <Label htmlFor={name}>{tLabel}</Label>

          {error && (
            <InnerTopContainer>
              <ErrorMessage>{tError}</ErrorMessage>
            </InnerTopContainer>
          )}
        </TopContainer>
      )}
      <Border error={error}>
        <Select
          closeMenuOnSelect={closeMenuOnSelect || !isMulti}
          closeMenuOnScroll={true}
          components={components}
          getOptionValue={getOptionValue}
          hideSelectedOptions={false}
          isDisabled={isDisabled}
          isMulti={isMulti}
          isSearchable={false}
          menuPortalTarget={document.body}
          onChange={onSelect}
          options={selectOptions}
          placeholder={tPlaceholder}
          removeSelected={!!removeSelected}
          styles={DropdownStyles(isDisabled)}
          value={selectedOption}
          menuPlacement="auto"
        />
      </Border>
    </Container>
  );
};
