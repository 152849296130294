import styled from 'styled-components';

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  margin-left: 10px;
`;

export const RoleUpdateButton = styled.div`
  padding: 5px 10px;
  width: fit-content;
  background-color: ${({ theme }) => theme.colorDanger};
  border-radius: 5px;
  transition: all 0.2s;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7px;

  font-size: 12px;
  color: white;

  &:hover:not([disabled]) {
    transform: scale(1.05);
  }
`;
