import styled from 'styled-components';

interface IValue {
  bold?: boolean;
}

interface IStatus {
  isActive: boolean;
}

export const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  row-gap: 30px;
`;

export const Container = styled.div`
  white-space: pre-line !important;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Inner = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15%;
  row-gap: 25px;
  max-width: 500px;

  & + & {
    padding-top: 15px;
    border-top: 1px solid ${({ theme }) => theme.lightGrayBg}50;
  }

  @media (max-width: ${({ theme }) => theme.sizeRanges.mobileLarge.max}px) {
    grid-template-columns: 1fr;
  }
`;

export const LoaderContainer = styled.div`
  margin: auto;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: calc(250px - 20%);
`;

export const Label = styled.div`
  font-size: 13px;
  opacity: 0.4;

  @media (max-width: ${({ theme }) => theme.sizeRanges.mobileLarge.max}px) {
    font-size: 13px;
    line-height: 13px;
  }
`;

export const Value = styled.div<IValue>`
  font-size: 16px;
  line-height: 20px;
  font-weight: ${({ theme, bold }) =>
    bold ? theme.weightBold : theme.weightRegular};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 20px;
  word-break: break-word;

  @media (max-width: ${({ theme }) => theme.sizeRanges.mobileLarge.max}px) {
    font-size: 15px;
    line-height: 15px;
  }
`;

export const Status = styled.div<IStatus>`
  padding: 7px 20px;
  border-radius: 50px;
  width: fit-content;
  height: fit-content;
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 12px;
  line-height: 16px;

  ${({ theme, isActive }) =>
    isActive
      ? `
        background-color: ${theme.primaryGreen}20;
        color: ${theme.primaryGreen};
      `
      : `
        background-color: ${theme.colorDanger}20;
        color: ${theme.colorDanger};
      `}

  @media (max-width: ${({ theme }) => theme.sizeRanges.tablet.max}px) {
    padding: 5px 10px;
    font-size: 10px;
  }
`;

export const EmailJobIDValue = styled.div<IValue>`
  font-size: 13px;
  line-height: 20px;
  font-weight: ${({ theme, bold }) =>
    bold ? theme.weightBold : theme.weightRegular};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 20px;
  word-break: break-word;

  @media (max-width: ${({ theme }) => theme.sizeRanges.mobileLarge.max}px) {
    font-size: 15px;
    line-height: 15px;
  }
`;
