import React, { Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';
import { Toaster } from 'react-hot-toast';

import { getUserAuth } from 'state/auth/AuthActions';
import { useAppDispatch, useAppSelector } from 'state/hooks';

import { AppRoutes } from 'routes';
import { EmployeeCardsProvider } from 'contexts/EmployeeCardsContext';
import { GlobalStyles, Theme } from './assets/styles';
import { Loader } from 'components/loader';
import LoadingPage from 'pages/loading-page/LoadingPage';

import { Inner, LoadingContainer, Page } from 'AppStyles';

import './i18n';
import 'chart.js/auto';
import 'swiper/css';
import 'swiper/css/scrollbar';
import './CropperSliderstyles.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment-timezone';

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  const ENV = process.env.NODE_ENV;
  const queryClient = new QueryClient();
  const isLoadingUser = useAppSelector(({ auth }) => auth.isLoadingUser);
  const isLoadingCompany = useAppSelector(({ company }) => company.isLoading);

  useEffect(() => {
    dispatch(getUserAuth());
  }, [history]);

  return (
    <Suspense
      fallback={
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      }
    >
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        <Toaster />

        <QueryClientProvider client={queryClient}>
          <EmployeeCardsProvider>
            <Page>
              <LoadingPage isVisible={isLoadingUser || isLoadingCompany} />

              <Inner isVisible={!isLoadingUser && !isLoadingCompany}>
                {<AppRoutes />}
              </Inner>
            </Page>
          </EmployeeCardsProvider>

          {ENV === 'development' && <ReactQueryDevtools />}
        </QueryClientProvider>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
