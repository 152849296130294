import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'state/hooks';
import {
  setPhoto,
  setStepIndex,
} from 'state/photo-verification/PhotoVerificationActions';

import { SubmitButton, FlatButton } from 'components/buttons';
import { FileInput } from 'components/inputs';
import { Camera } from '../../icons/Camera';

import { Actions } from '../SharedStyles';
import { Container } from './PhotoUploadStepStyles';

export const PhotoUploadStep: React.FC = () => {
  const dispatch = useAppDispatch();
  const [file, setFile] = useState<File | null>();

  const { t } = useTranslation();
  const tOr = t('or');
  const tReturn = t('Return');
  const tEditPhoto = t('Edit Photo');
  const tTakeAphoto = t('Take a photo');

  const handleTakePhoto = () => dispatch(setStepIndex(2));

  const goToEditPhoto = () => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener(
        'load',
        () => reader.result && dispatch(setPhoto(reader.result.toString())),
        false,
      );
    }
  };

  return (
    <Container>
      <FileInput value={file} onChange={setFile} label="Please upload your photo" />
      {file ? (
        <Actions>
          <FlatButton dark onClick={() => setFile(null)}>
            {tReturn}
          </FlatButton>
          <SubmitButton
            onClick={goToEditPhoto}
            isDisabled={!file || (file as File).size / 1024 / 1024 > 5}
          >
            {tEditPhoto}
          </SubmitButton>
        </Actions>
      ) : (
        <Fragment>
          {tOr}
          <SubmitButton
            onClick={handleTakePhoto}
            leadingIcon={<Camera />}
            intent="secondary"
          >
            {tTakeAphoto}
          </SubmitButton>
        </Fragment>
      )}
    </Container>
  );
};
