import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Bold = styled.span`
  font-weight: ${({ theme }) => theme.weightBold};
`;

export const H1 = styled.h1`
  padding-top: 80px;
`;

export const H2 = styled.h2`
  font-family: ${({ theme }) => theme.primaryFont};
  font-style: italic;
  font-weight: ${({ theme }) => theme.weightSemiBold};
  font-size: 30px;
  line-height: 40px;
  color: ${({ theme }) => theme.secondaryViolet};
  margin-top: 40px;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    margin-bottom: 10px;
    font-size: 25px;
    line-height: 25px;
  }

  @media (max-width: ${(props) => props.theme.widthMobile}) {
    font-size: 20px;
    line-height: 20px;
  }
`;

export const Inner = styled.div`
  @media only screen and (min-width: ${({ theme }) =>
      theme.sizeRanges.mobile.min}px) {
    padding: 0px 20px;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizeRanges.tablet.min}px) {
    padding: 0px 40px;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.sizeRanges.desktop.min}px) {
    padding: 0px 113px;
  }
`;

export const Li = styled.li`
  margin-bottom: 10px;
`;

export const P = styled.p`
  padding-bottom: 15px;
  padding-top: 15px;
`;

export const Ul = styled.ul`
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
`;
