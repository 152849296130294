import { firestore, httpsCallable, storage } from 'data/firebase';
import { doc, updateDoc, writeBatch } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { addDays } from 'date-fns';
import { useMutation, useQueryClient } from 'react-query';

import { useAppSelector } from 'state/hooks';
import { useEmployeeCards, usePhotoRequests } from 'contexts';

import dbLabels from 'data/db.labels';
import sendRequestReminder from 'data/adapters/photo-ids/sendRequestReminder';
import sendResubmissionEmail from 'data/adapters/photo-ids/sendResubmissionEmail';
import updateDigitalIds from 'data/adapters/cards/updateDigitalIds';
import useGenerateIds from 'data/data-hooks/mutations/card/useGenerateIds';

import { FailToast, SuccessToast } from 'components/toasts';
import { formatName } from 'utils/user.utils';

import { IPhotoReqTokenPayload } from 'types/token.types';
import {
  EPhotoRequestStatus,
  ICompanyPhotoRequest,
  IPhotoRequest,
} from 'types/photoRequest.types';

interface IArgs {
  onAprroveSuccess?: () => void;
  onDeleteSuccess?: () => void;
  onDenySuccess?: () => void;
  onSendReminderSuccess?: () => void;
}

export default (args?: IArgs) => {
  const { currentCompany } = useAppSelector(({ company }) => company);
  const { employeeCards } = useEmployeeCards();
  const { shouldSendIDs, setShouldSendIDs } = usePhotoRequests();
  const expirationPeriod =
    currentCompany?.configuration.photoRequestSettings?.expirationPeriod || 10;
  const queryClient = useQueryClient();

  const getRequestRef = (requestId: string) => {
    return doc(firestore, dbLabels.photorequest, requestId);
  };

  const refreshRequests = async () => {
    return new Promise((resolve) =>
      setTimeout(async () => {
        await queryClient.refetchQueries(['photo_reqs']);
        resolve('');
      }, 1500),
    ); // wait for changes to reflect on the server
  };

  const { sendCardId } = useGenerateIds();

  const { mutateAsync: approveRequests, isLoading: isApproving } = useMutation(
    'approve_req',
    async (requests: IPhotoRequest | IPhotoRequest[]) => {
      if (!currentCompany) return;

      const list = Array.isArray(requests) ? requests : [requests];
      const updatedPhotos: {[key: string]: string} = {}

      const cardIds = await Promise.all(
        list.map(async (request) => {
          const cardInstance = employeeCards.find(
            ({ employeeId }) => employeeId === request.employeeId,
          );
          if (!cardInstance) throw 'Invalid card ID';

          const copyPhoto = httpsCallable('approvePhotoId');
          await copyPhoto({
            employeeId: request.employeeId,
            companyId: request.companyId,
          });

          const path = `photo-id/${request.companyId}/${request.employeeId}.png`;
          const photoUrl = await getDownloadURL(ref(storage, path));

          const cardRef = doc(
            firestore,
            dbLabels.company,
            request.companyId,
            dbLabels.employeeCards,
            cardInstance.id,
          );
          await updateDoc(cardRef, { photoUrl });
          updatedPhotos[request.employeeId] = photoUrl
          while (!currentCompany) continue;

          await updateDigitalIds({
            cardInstance,
            company: currentCompany,
          });

          const requestRef = getRequestRef(request.id);
          await updateDoc(requestRef, {
            status: EPhotoRequestStatus.accepted,
            modifiedOn: new Date(),
          });

          return cardInstance.cardId;
        }),
      );

      if (shouldSendIDs) {
        await sendCardId({ cardIds, updatedPhotos });
      }
    },
    {
      onSuccess: async () => {
        await refreshRequests();
        setShouldSendIDs(false);
        args?.onAprroveSuccess && args.onAprroveSuccess();
      },
      onError: async () => {
        FailToast('Failed to update request status');
      },
    },
  );

  const { mutateAsync: denyRequests, isLoading: isDenying } = useMutation(
    'deny_req',
    async ({
      requests,
      reason,
    }: {
      requests: ICompanyPhotoRequest | ICompanyPhotoRequest[];
      reason: string;
    }) => {
      const list = Array.isArray(requests) ? requests : [requests];

      const data = list.reduce((res, req) => {
        return [
          ...res,
          {
            companyId: req.companyId,
            emailAddress: req.emailAddress,
            employeeName: formatName(req),
            expirationDate: addDays(new Date(), expirationPeriod),
            employeeId: req.employeeId,
            cardId: req.cardId || '',
            requestId: req.id,
          },
        ];
      }, [] as (IPhotoReqTokenPayload & { requestId: string })[]);

      const { success, error } = await sendResubmissionEmail(data, reason);
      if (!success) throw error;

      return Promise.all(
        data.map(({ requestId }) =>
          updateDoc(getRequestRef(requestId), {
            status: EPhotoRequestStatus.rejected,
            modifiedOn: new Date(),
          }),
        ),
      );
    },
    {
      onSuccess: async () => {
        await refreshRequests();
        args?.onDenySuccess && args.onDenySuccess();
      },
      onError: () => {
        FailToast('Failed to update request status');
      },
    },
  );

  const { mutateAsync: sendReminder, isLoading: isSendingReminder } = useMutation(
    'req_reminder',
    async (requests: ICompanyPhotoRequest | ICompanyPhotoRequest[]) => {
      const list = Array.isArray(requests) ? requests : [requests];

      return sendRequestReminder(
        list.map((request) => ({
          companyId: request.companyId,
          emailAddress: request.emailAddress,
          employeeName: formatName(request),
          token: request.token,
        })),
      );
    },
    {
      onSuccess: async () => {
        SuccessToast('Reminder sent successfully');
        args?.onSendReminderSuccess && args.onSendReminderSuccess();
      },
      onError: () => {
        FailToast('Failed to send reminder(s)');
      },
    },
  );

  const { mutateAsync: removeRequests, isLoading: isRemoving } = useMutation(
    'remove_reminder',
    async (args: IPhotoRequest | IPhotoRequest[]) => {
      const requests = Array.isArray(args) ? args : [args];
      const _writeBatch = writeBatch(firestore);

      requests.forEach(({ id }) => {
        const ref = doc(firestore, dbLabels.photorequest, id);
        _writeBatch.delete(ref);
      });
      return _writeBatch.commit();
    },
    {
      onSuccess: async () => {
        await refreshRequests();
        args?.onDeleteSuccess && args.onDeleteSuccess();
      },
      onError: () => {
        FailToast('Failed to delete request(s)');
      },
    },
  );

  return {
    approveRequests,
    denyRequests,
    isApproving,
    isDenying,
    isRemoving,
    isSendingReminder,
    removeRequests,
    sendReminder,
  };
};
