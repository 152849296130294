import React from 'react';
import { useTheme } from 'styled-components';

interface Props {
  dark?: boolean;
  full?: boolean;
}

export const Notifications: React.FC<Props> = ({
  dark = false,
  full = false,
}: Props) => {
  const theme = useTheme();

  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6667 13.1667H17.3333V14.8334H0.666656V13.1667H2.33332V7.33335C2.33332 5.56524 3.0357 3.86955 4.28594 2.61931C5.53619 1.36907 7.23188 0.666687 8.99999 0.666687C10.7681 0.666687 12.4638 1.36907 13.714 2.61931C14.9643 3.86955 15.6667 5.56524 15.6667 7.33335V13.1667ZM6.49999 16.5H11.5V18.1667H6.49999V16.5Z"
        fill={dark ? '#07081F' : undefined}
      />
      {full && (
        <circle
          cx="14"
          cy="3.33333"
          r="4.33333"
          fill={theme.secondaryViolet}
          stroke={theme.backgroundColor}
          strokeWidth="2"
        />
      )}
    </svg>
  );
};
