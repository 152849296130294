import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useSearchBar from 'hooks/useSearchBar';
import useThrottle from 'hooks/useThrottle';

import { SearchBarInput } from 'components/search-bar';
import ClearButton from './components/clear-button/ClearButton';
import SearchIcon from './assets/search-icon';

import { Container, InputContainer } from './SearchBarStyles';

interface ISearchBarProps {
  clearSearchText?: () => void;
  isDisabled?: boolean;
  placeholder: string;
}

const SearchBar: React.FC<ISearchBarProps> = ({
  clearSearchText,
  isDisabled = false,
  placeholder,
}: ISearchBarProps) => {
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const location = useLocation();
  const { setValue, clear } = useSearchBar();

  const [isInFocus, setIsInFocus] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  useThrottle(searchText, (value: string) => setValue(value), 350);

  const clearSearch = () => {
    setSearchText('');
    clearSearchText && clearSearchText();
  };

  const clearValue = () => {
    clear();
    setSearchText('');
  };

  useEffect(() => {
    clearValue();
  }, [location]);

  return (
    <Container>
      <InputContainer isInFocus={isInFocus} isDisabled={isDisabled}>
        {searchText.length ? <ClearButton onClick={clearSearch} /> : <SearchIcon />}
        <SearchBarInput
          disabled={isDisabled}
          isInFocus={isInFocus}
          onChange={(e) => setSearchText(e.target.value)}
          onFocus={() => setIsInFocus(true)}
          onBlur={() => setIsInFocus(false)}
          placeholder={placeholder}
          ref={ref}
          value={searchText}
        />
      </InputContainer>
    </Container>
  );
};

export default SearchBar;
