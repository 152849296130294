import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, InactiveAlert, Photo } from 'components/icons';
import { Loader } from 'components/loader';

import { Container, StatsTitle, StatsValue } from './StatsSlideStyles';

interface Props {
  icon: 'card' | 'photo' | 'alert' | 'queue';
  isLoading?: boolean;
  text: string;
  type: 'active' | 'inactive';
  value: number;
}

export const StatsSlide: React.FC<Props> = ({
  icon,
  isLoading = false,
  text,
  type,
  value,
}: Props) => {
  const { t } = useTranslation();
  const tText = t(text);

  const getIcon = () => {
    switch (icon) {
      case 'card':
        return <Card />;
      case 'photo':
        return <Photo />;
      case 'alert':
        return <InactiveAlert />;
      case 'queue':
        return <i className="fas fa-tasks" />;
      default:
        return <></>;
    }
  };

  return (
    <Container type={type}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <StatsTitle>
            {getIcon()}
            {tText.toUpperCase()}
          </StatsTitle>
          <StatsValue>{value}</StatsValue>
        </>
      )}
    </Container>
  );
};
