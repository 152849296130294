import styled from 'styled-components';

interface IButton {
  fill?: string;
  intent?: 'primary' | 'secondary' | 'success' | 'danger' | 'warn' | 'dark';
  size?: 'small' | 'large' | 'medium';
  transparent?: boolean;
}

export const Container = styled.button<IButton>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 18px;
  padding: 15px 40px;
  z-index: 1;
  user-select: none;
  width: fit-content;
  line-height: 20px;
  height: 100%;
  max-height: 50px;
  transition: all 0.2s;
  min-width: min(100%, 230px);

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-family: ${({ theme }) => theme.secondaryFont};
  font-size: 18px;
  font-weight: ${({ theme }) => theme.weightRegular};
  background-color: ${({ theme, transparent, intent }) =>
    transparent
      ? 'transparent'
      : intent === 'primary'
      ? theme.primaryYellow
      : intent === 'secondary'
      ? theme.secondaryViolet
      : intent === 'success'
      ? theme.primaryGreen
      : intent === 'dark'
      ? theme.primaryBlack
      : intent === 'danger'
      ? theme.colorDanger
      : theme.primaryYellow};
  color: ${({ theme, transparent, intent }) => {
    if (transparent) {
      if (intent === 'primary') return theme.primaryYellow;
      if (intent === 'secondary') return theme.secondaryViolet;
      if (intent === 'success') return theme.primaryGreen;
      if (intent === 'dark') return theme.primaryBlack;
      return 'white';
    }
    if (intent) return 'white';
    return theme.primaryBlack;
  }};
  border-radius: 60px;
  border: 1px solid
    ${({ theme, transparent, intent }) => {
      if (intent === 'primary') return theme.primaryYellow;
      if (intent === 'secondary') return theme.secondaryViolet;
      if (intent === 'success') return theme.primaryGreen;
      if (intent === 'dark') return theme.primaryBlack;
      if (intent === 'danger') return theme.colorDanger;
      if (transparent) return 'white';
      return theme.primaryYellow;
    }};

  ${({ theme, size }) =>
    size === 'small' &&
    `
      max-height: 40px;
      min-width: min(100%, 150px);
      padding: 10px 20px;
      font-size: 12px;
      line-height: 15px;
      font-weight: ${theme.weightBold};
      min-width: min(100%, 100px);
    `}

  ${({ theme, size }) =>
    size === 'medium' &&
    `
      max-height: 45px;
      min-width: min(100%, 150px);
      padding: 13px 25px;
      font-size: 14px;
      line-height: 15px;
      font-weight: ${theme.weightBold};
      min-width: min(100%, 100px);
    `}

  ${({ fill }) => !!fill && `width: 100%;`}

  &:hover:not([disabled]) {
    transform: scale(1.02);
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  > svg {
    margin-right: -20px;
  }

  @media (max-width: ${(props) => props.theme.sizeRanges.tabletLarge.min}px) {
    font-size: 11px;
    line-height: 15px;
    padding: 7px 20px;
    gap: 10px;
    min-width: min(100%, 200px);
  }

  @media (max-width: ${(props) => props.theme.widthMobile}) {
    min-width: min(100%, 100px);
  }
`;

export const LeadingIconContainer = styled.div`
  margin: 0px 5px 0px -20px;
`;
