import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { IPhotoReqTokenPayload } from 'types/token.types';

export const PV__SET_IS_LOADING = 'PV__SET_IS_LOADING';
export const PV__SET_IS_NAME_INVALID = 'PV__SET_IS_NAME_INVALID';
export const PV__SET_PHOTO = 'PV__SET_PHOTO';
export const PV__SET_STEP = 'PV__SET_STEP';
export const PV__SET_TOKEN = 'PV__SET_TOKEN';
export const PV__UPLOAD_PHOTO = 'PV__UPLOAD_PHOTO';
export const PV__VERIFY_TOKEN = 'PV__VERIFY_TOKEN';

interface SetIsLoadingAction {
  type: typeof PV__SET_IS_LOADING;
  isLoading: boolean;
}

interface SetIsNameInvalidAction {
  type: typeof PV__SET_IS_NAME_INVALID;
  isInvalid: boolean;
}

interface SetPhotoAction {
  type: typeof PV__SET_PHOTO;
  photo: string;
}

interface SetStepAction {
  type: typeof PV__SET_STEP;
  step: number;
  isExpired?: boolean;
}

interface SetTokenAction {
  type: typeof PV__SET_TOKEN;
  token: string;
}

interface UploadPhotoAction {
  type: typeof PV__UPLOAD_PHOTO;
}

interface VerifyTokenAction {
  type: typeof PV__VERIFY_TOKEN;
  data: IPhotoReqTokenPayload;
}

export type PhotoVerificationActionTypes =
  | SetIsLoadingAction
  | SetIsNameInvalidAction
  | SetPhotoAction
  | SetStepAction
  | SetTokenAction
  | UploadPhotoAction
  | VerifyTokenAction;

export type TLaunchPhotoUpload = (
  token: string,
) => ThunkAction<void, RootState, unknown, PhotoVerificationActionTypes>;

export type TSetPhoto = (photo: string) => SetPhotoAction;

export type TSetStepIndex = (step: number) => SetStepAction;

export type TSetToken = (
  token: string,
) => ThunkAction<void, RootState, unknown, PhotoVerificationActionTypes>;

export type TSetIsLoading = (isLoading: boolean) => SetIsLoadingAction;

export type TSetIsNameInvalid = (isInvalid: boolean) => SetIsNameInvalidAction;

export type TUploadPhoto = (
  photo: File,
) => ThunkAction<void, RootState, unknown, PhotoVerificationActionTypes>;
