import React, { useState } from 'react';
import { Container, TextArea } from './TextAreaStyles';
import { Input, Label, TopContainer } from '../SharedStyles';
import { useTranslation } from 'react-i18next';

interface Props {
  disabled?: boolean;
  error?: string;
  label?: string;
  maxLength?: number;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder?: string;
  success?: boolean;
  value?: string;
}

export const TextAreaComponent: React.FC<Props> = ({
  disabled,
  error,
  label = '',
  maxLength,
  name,
  onChange,
  placeholder = '...',
  success,
  value: controlledValue,
}: Props) => {
  const [value, setValue] = useState('');
  const { t } = useTranslation();

  const tError = error && t(error);
  const tLabel = t(label);
  const tPlaceholder = t(placeholder);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setValue(e.target.value);
    onChange && onChange(e);
  };

  return (
    <Container>
      {/* label */}
      {label && (
        <TopContainer>
          <Label htmlFor={name}>{tLabel}</Label>
        </TopContainer>
      )}

      <Input
        as={TextArea}
        disabled={disabled}
        error={tError}
        maxLength={maxLength}
        name={name}
        onChange={handleChange}
        placeholder={tPlaceholder}
        success={success}
        value={controlledValue === undefined ? value : controlledValue}
      />
    </Container>
  );
};
