import { addDays } from 'date-fns';
import { useMutation, useQueryClient } from 'react-query';

import { useAppSelector } from 'state/hooks';
import { useEmployeeCards } from 'contexts';
import sendPhotoIdRequest from 'data/adapters/photo-ids/sendPhotoIdRequest';

import { FailToast } from 'components/toasts';
import { formatName } from 'utils/user.utils';

import { IPhotoReqTokenPayload } from 'types/token.types';

interface IArgs {
  onSuccess?: () => void;
}

export default (args?: IArgs) => {
  const queryClient = useQueryClient();
  const { currentCompany } = useAppSelector(({ company }) => company);
  const { employeeCards = [] } = useEmployeeCards();
  const expirationPeriod =
    currentCompany?.configuration.photoRequestSettings?.expirationPeriod || 10;

  const { mutateAsync: sendPhotoRequests, ...results } = useMutation(
    async (cardsIds: string[]) => {
      const data = [...cardsIds].reduce((result, cardId) => {
        const cardInstance = employeeCards.find((card) => card.cardId === cardId);

        if (cardInstance)
          return [
            ...result,
            {
              employeeId: cardInstance.employeeId,
              cardId: cardInstance.id,
              companyId: currentCompany?.id || '',
              emailAddress: cardInstance.emailAddress || '',
              employeeName: formatName(cardInstance),
              expirationDate: addDays(new Date(), expirationPeriod),
            },
          ];
        return result;
      }, [] as IPhotoReqTokenPayload[]);

      return sendPhotoIdRequest(data);
    },
    {
      onSuccess: async () => {
        args?.onSuccess && args.onSuccess();
        await queryClient.refetchQueries(['photo_reqs']);
      },
      onError: () => {
        FailToast('Failed to send requests');
      },
    },
  );

  return { sendPhotoRequests, ...results };
};
