import { Plugin } from 'chart.js';

export default {
  id: 'labelsLine',
  afterDraw: (chart) => {
    const {
      ctx,
      chartArea: { left, width, height },
      data: { datasets, labels: labelsList },
    } = chart;
    const halfWidth = width / 2;
    const labels = labelsList as string[];

    datasets.forEach((_, i) => {
      chart.getDatasetMeta(i).data.forEach((datapoint, j) => {
        const label = labels[j];
        const value = datasets[i].data[j];
        if (value === 0) return;

        const { x, y } = datapoint.tooltipPosition();
        const longestWord = ctx.measureText(
          label
            .split(' ')
            .reduce((champ, contender) =>
              contender.length > champ.length ? contender : champ,
            ),
        );

        const yStart = Math.min(y, height * 0.6);
        let xStart = x;
        let xEnd = 0;

        // line
        if (x < halfWidth) {
          xEnd = left + (50 + longestWord.width / 2);
          xStart = xEnd + 25;
        } else {
          xStart = width - 95;
          xEnd = width - longestWord.width - 35;
        }

        ctx.beginPath();
        ctx.moveTo(xStart, yStart);
        ctx.lineTo(xEnd, yStart);
        ctx.strokeStyle = '#fff';
        ctx.stroke();

        // text
        let xTextStart = xEnd;
        let yTextStart = yStart + 5;

        if (x < halfWidth) xTextStart -= longestWord.width + `${value}`.length;
        else xTextStart += 10;

        ctx.fillStyle = '#fff';
        ctx.font = 'bold 20px Poppins';
        ctx.fillText(`${value}`, xTextStart, yTextStart);

        yTextStart += 20;
        ctx.fillStyle = '#fff6';
        ctx.font = '12px Poppins';
        const words = label.split(' ');
        words.map((word, index) =>
          ctx.fillText(word, xTextStart, yTextStart + 12 * index),
        );
      });
    });
  },
} as Plugin;
