import styled from 'styled-components';

export const Container = styled.form`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 550px;
  width: 100%;
`;

export const InfoTextContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  margin: 0px auto;
  text-align: center;
  max-width: 650px;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 30px;
  line-height: 45px;
  color: white;
`;

export const Info = styled.div`
  font-size: 15px;
  line-height: @0px;
  color: white;
`;

export const Actions = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
