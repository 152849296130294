import { firestore } from 'data/firebase';
import { collection } from 'firebase/firestore';
import { useAppSelector } from 'state/hooks';
import useAppQuery from 'hooks/useAppQuery';
import dbLabels from 'data/db.labels';

import { ITemplate } from 'types/emailTemplates.types';

export default () => {
  const companyId = useAppSelector(({ company }) => company.currentCompany?.id);
  const tempsRef = collection(
    firestore,
    dbLabels.company,
    companyId || '',
    dbLabels.templates,
  );

  const query = useAppQuery<ITemplate[]>(['email_temps'], tempsRef);

  return { templates: query.data, ...query };
};
