import { firestore } from 'data/firebase';
import { collection } from 'firebase/firestore';
import useAppQuery from 'hooks/useAppQuery';
import dbLabels from 'data/db.labels';
import { ITestimonial } from 'types/testimonial.types';

export default () => {
  const testimonialsRef = collection(firestore, dbLabels.testimonials);
  const query = useAppQuery<ITestimonial[]>(['testimonials'], testimonialsRef);

  return { testimonials: query.data || [], ...query };
};
