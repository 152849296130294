import styled from 'styled-components';

export const Container = styled.div`
  padding: 60px 85px;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    padding: 25px 45px;
  }

  .swiper-scrollbar-horizontal {
    background: white;
    max-width: calc(100% - 100px);
  }

  .swiper-scrollbar-drag {
    background: ${({ theme }) => theme.secondaryViolet};
  }
`;
