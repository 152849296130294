import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 60px 85px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => `background: ${theme.primaryBlack};
  background: -webkit-linear-gradient(top, ${theme.primaryBlack} 380px, ${theme.backgroundColor} 5px);
  background: -o-linear-gradient(bottom, ${theme.primaryBlack} 380px, ${theme.backgroundColor} 5px);
  background: -moz-linear-gradient(bottom, ${theme.primaryBlack} 380px, ${theme.backgroundColor} 5px);
  background: linear-gradient(to bottom, ${theme.primaryBlack} 380px, ${theme.backgroundColor} 5px);`}

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    padding: 25px 45px;
  }
`;

export const CardTitle = styled.div`
  font-family: ${({ theme }) => theme.primaryFont};
  font-style: italic;
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 30px;
  line-height: 40px;
  color: ${({ theme }) => theme.primaryBlack};
  margin-bottom: 40px;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 30px;
  }
`;

export const CardText = styled.div`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-style: normal;
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.secondaryBlack};

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 13px;
    line-height: 20px;
  }
`;

export const CardSubheading = styled.div`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-style: normal;
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 18px;
  line-height: 28px;
  color: ${({ theme }) => theme.secondaryBlack};
`;

export const Bold = styled.b`
  font-weight: ${({ theme }) => theme.weightSemiBold};
`;

export const Grid = styled.div`
  margin-top: 60px;
  display: grid;
  grid-template-columns: calc(50% - 15px) calc(50% - 15px);
  grid-template-rows: auto;
  column-gap: 30px;
  row-gap: 30px;
  grid-template-areas:
    'violet violet'
    'green yellow';

  max-width: ${({ theme }) => theme.sizeRanges.desktop.max}px;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    grid-template-columns: 90vw;
    grid-template-rows: auto;
    row-gap: 30px;
    grid-template-areas:
      'violet'
      'violet'
      'green'
      'yellow';
  }
`;

export const Card1 = styled.div`
  grid-area: violet;
`;

export const Card2 = styled.div`
  grid-area: green;
`;

export const Card3 = styled.div`
  grid-area: yellow;
`;

export const MainTitle = styled.h1`
  font-size: 40px;
`;

export const SubText = styled.div`
  max-width: 580px;
  font-family: ${({ theme }) => theme.secondaryFont};
  font-style: normal;
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 20px;
  line-height: 28px;
  color: white;
  opacity: 0.6;
  margin: 20px;
`;
