import React from 'react';
import { Navigate, NavigateProps, Outlet, useOutletContext } from 'react-router-dom';
import { useAppSelector } from 'state/hooks';

import { PATHS } from 'routes';
import { RolesProvider } from 'routes/components/roles-provider';
import { Loader } from 'components/loader';
import LoadingPage from 'pages/loading-page/LoadingPage';
import PageLayout from 'domains/navigation/page-layout/PageLayout';

import { AccessRules } from 'types/role.types';
import { LoadingContainer } from './PrivatePageStyles';

type Props = {
  accessRules?: AccessRules[] | AccessRules.openAccess;
  onUnauthorized?: NavigateProps;
};

export const PrivatePage: React.FC<Props> = ({
  accessRules = AccessRules.openAccess,
  onUnauthorized,
}: Props) => {
  const { isAuthenticated, isLoadingUser } = useAppSelector(({ auth }) => auth);
  const currentCompany = useAppSelector(({ company }) => company.currentCompany);
  const context = useOutletContext();

  if (!isLoadingUser && !isAuthenticated) {
    return <Navigate to={PATHS.login} />;
  }

  if (!currentCompany) {
    return <LoadingPage isVisible />;
  }

  return (
    <RolesProvider accessRules={accessRules} onUnauthorized={onUnauthorized}>
      <PageLayout>
        <React.Suspense
          fallback={
            <LoadingContainer>
              <Loader />
            </LoadingContainer>
          }
        >
          <Outlet context={context} />
        </React.Suspense>
      </PageLayout>
    </RolesProvider>
  );
};
