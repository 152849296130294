import styled from 'styled-components';

interface ISvg {
  dark?: boolean;
}

export const Svg = styled.svg<ISvg>`
  fill: ${({ theme, dark }) => (dark ? 'white' : theme.primaryBlack)};
  height: 20px;
  width: 20px;
  cursor: pointer;
`;
