import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoTextContainer, Title } from '../SharedStyles';

export const ThankYouPage: React.FC = () => {
  const { t } = useTranslation();
  const tThankYou = t('😊 Thank you!');
  const tText = t("You'll receive your Digital ID card via email");

  return (
    <InfoTextContainer>
      <Title>{tThankYou}</Title>
      <h1>{tText}</h1>
    </InfoTextContainer>
  );
};
