import React from 'react';
import { Footer, PublicHeaderNav } from 'domains/navigation';
import { Bold, Container, H1, H2, Inner, Li, P, Ul } from './TermsOfServiceStyles';

export const TermsOfService: React.FC = () => {
  return (
    <Container>
      <PublicHeaderNav />

      <Inner>
        <H1>Terms and Conditions</H1>
        <P>Last updated: June 07, 2022</P>
        <P>
          Please read these terms and conditions carefully before using Our Service.
        </P>
        <H1>Interpretation and Definitions</H1>
        <H2>Interpretation</H2>
        <P>
          The words of which the initial letter is capitalized have meanings defined
          under the following conditions. The following definitions shall have the
          same meaning regardless of whether they appear in singular or in plural.
        </P>
        <H2>Definitions</H2>
        <P>For the purposes of these Terms and Conditions:</P>
        <Ul>
          <Li>
            <P>
              <Bold>Application</Bold> means the software program provided by the
              Company downloaded by You on any electronic device, named Digi Prnt.
            </P>
          </Li>
          <Li>
            <P>
              <Bold>Application Store</Bold> means the digital distribution service
              operated and developed by Apple Inc. (Apple App Store) or Google Inc.
              (Google Play Store) in which the Application has been downloaded.
            </P>
          </Li>
          <Li>
            <P>
              <Bold>Affiliate</Bold> means an entity that controls, is controlled by
              or is under common control with a party, where
              {'"control"'} means ownership of 50% or more of the shares, equity
              interest or other securities entitled to vote for election of directors
              or other managing authority.
            </P>
          </Li>
          <Li>
            <P>
              <Bold>Account</Bold> means a unique account created for You to access
              our Service or parts of our Service.
            </P>
          </Li>
          <Li>
            <P>
              <Bold>Country</Bold> refers to: Texas, United States.
            </P>
          </Li>
          <Li>
            <P>
              <Bold>Company</Bold> (referred to as either {'"the Company"'},{'" "'}
              {'"We"'},{'"Us"'} or {'"Our"'} in this Agreement) refers to Digi Prnt,
              Inc., 1920 McKinny Avenue 7th Floor Dallas TX 75201.
            </P>
          </Li>
          <Li>
            <P>
              <Bold>Device</Bold> means any device that can access the Service such
              as a computer, a cellphone or a digital tablet.
            </P>
          </Li>
          <Li>
            <P>
              <Bold>Feedback</Bold> means feedback, innovations or suggestions sent
              by You regarding the attributes, performance or features of our
              Service.
            </P>
          </Li>
          <Li>
            <P>
              <Bold>Service</Bold> refers to the Application or the Website or both.
            </P>
          </Li>
          <Li>
            <P>
              <Bold>Terms and Conditions</Bold> (also referred as {'"Terms"'}) mean
              these Terms and Conditions that form the entire agreement between You
              and the Company regarding the use of the Service.
            </P>
          </Li>
          <Li>
            <P>
              <Bold>Third-party Social Media Service</Bold> means any services or
              content (including data, information, products or services) provided by
              a third-party that may be displayed, included or made available by the
              Service.
            </P>
          </Li>
          <Li>
            <P>
              <Bold>Website</Bold> refers to Digi Prnt , accessible from{' '}
              <a href="https://digiprnt.com">www.digiprnt.com</a>.
            </P>
          </Li>
          <Li>
            <P>
              <Bold>You</Bold> means the individual accessing or using the Service,
              or the company, or other legal entity on behalf of which such
              individual is accessing or using the Service, as applicable.
            </P>
          </Li>
        </Ul>
        <H1>Acknowledgment</H1>
        <P>
          These are the Terms and Conditions governing the use of this Service and
          the agreement that operates between You and the Company. These Terms and
          Conditions set out the rights and obligations of all users regarding the
          use of the Service.
        </P>
        <P>
          Your access to and use of the Service is conditioned on Your acceptance of
          and compliance with these Terms and Conditions. These Terms and Conditions
          apply to all visitors, users and others who access or use the Service.
        </P>
        <P>
          By accessing or using the Service You agree to be bound by these Terms and
          Conditions. If You disagree with any part of these Terms and Conditions
          then You may not access the Service.
        </P>
        <P>
          You represent that you are over the age of 18. The Company does not permit
          those under 18 to use the Service.
        </P>
        <P>
          Your access to and use of the Service is also conditioned on Your
          acceptance of and compliance with the Privacy Policy of the Company. Our
          Privacy Policy describes Our policies and procedures on the collection, use
          and disclosure of Your personal information when You use the Application or
          the Website and tells You about Your privacy rights and how the law
          protects You. Please read Our Privacy Policy carefully before using Our
          Service.
        </P>
        <H1>User Accounts</H1>
        <P>
          When You create an account with Us, You must provide Us information that is
          accurate, complete, and current at all times. Failure to do so constitutes
          a breach of the Terms, which may result in immediate termination of Your
          account on Our Service.
        </P>
        <P>
          You are responsible for safeguarding the password that You use to access
          the Service and for any activities or actions under Your password, whether
          Your password is with Our Service or a Third-Party Social Media Service.
        </P>
        <P>
          You agree not to disclose Your password to any third party. You must notify
          Us immediately upon becoming aware of any breach of security or
          unauthorized use of Your account.
        </P>
        <P>
          You may not use as a username the name of another person or entity or that
          is not lawfully available for use, a name or trademark that is subject to
          any rights of another person or entity other than You without appropriate
          authorization, or a name that is otherwise offensive, vulgar or obscene.
        </P>
        <H1>Intellectual Property</H1>
        <P>
          The Service and its original content (excluding Content provided by You or
          other users), features and functionality are and will remain the exclusive
          property of the Company and its licensors.
        </P>
        <P>
          The Service is protected by copyright, trademark, and other laws of both
          the Country and foreign countries.
        </P>
        <P>
          Our trademarks and trade dress may not be used in connection with any
          product or service without the prior written consent of the Company.
        </P>
        <H1>Your Feedback to Us</H1>
        <P>
          You assign all rights, title and interest in any Feedback You provide the
          Company. If for any reason such assignment is ineffective, You agree to
          grant the Company a non-exclusive, perpetual, irrevocable, royalty free,
          worldwide right and license to use, reproduce, disclose, sub-license,
          distribute, modify and exploit such Feedback without restriction.
        </P>
        <H1>Links to Other Websites</H1>
        <P>
          Our Service may contain links to third-party web sites or services that are
          not owned or controlled by the Company.
        </P>
        <P>
          The Company has no control over, and assumes no responsibility for, the
          content, privacy policies, or practices of any third party web sites or
          services. You further acknowledge and agree that the Company shall not be
          responsible or liable, directly or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with the use of or
          reliance on any such content, goods or services available on or through any
          such web sites or services.
        </P>
        <P>
          We strongly advise You to read the terms and conditions and privacy
          policies of any third-party web sites or services that You visit.
        </P>
        <H1>Termination</H1>
        <P>
          We may terminate or suspend Your Account immediately, without prior notice
          or liability, for any reason whatsoever, including without limitation if
          You breach these Terms and Conditions.
        </P>
        <P>
          Upon termination, Your right to use the Service will cease immediately. If
          You wish to terminate Your Account, You may simply discontinue using the
          Service.
        </P>
        <H1>Limitation of Liability</H1>
        <P>
          Notwithstanding any damages that You might incur, the entire liability of
          the Company and any of its suppliers under any provision of this Terms and
          Your exclusive remedy for all of the foregoing shall be limited to the
          amount actually paid by You through the Service or 100 USD if You{' '}
          {"haven't"} purchased anything through the Service.
        </P>
        <P>
          To the maximum extent permitted by applicable law, in no event shall the
          Company or its suppliers be liable for any special, incidental, indirect,
          or consequential damages whatsoever (including, but not limited to, damages
          for loss of profits, loss of data or other information, for business
          interruption, for personal injury, loss of privacy arising out of or in any
          way related to the use of or inability to use the Service, third-party
          software and/or third-party hardware used with the Service, or otherwise in
          connection with any provision of this Terms), even if the Company or any
          supplier has been advised of the possibility of such damages and even if
          the remedy fails of its essential purpose.
        </P>
        <P>
          Some states do not allow the exclusion of implied warranties or limitation
          of liability for incidental or consequential damages, which means that some
          of the above limitations may not apply. In these states, each {"party's"}{' '}
          liability will be limited to the greatest extent permitted by law.
        </P>
        <H1>
          {'"AS IS"'} and {'"AS AVAILABLE"'} Disclaimer
        </H1>
        <P>
          The Service is provided to You {'"AS IS"'} and {'"AS AVAILABLE"'} and with
          all faults and defects without warranty of any kind. To the maximum extent
          permitted under applicable law, the Company, on its own behalf and on
          behalf of its Affiliates and its and their respective licensors and service
          providers, expressly disclaims all warranties, whether express, implied,
          statutory or otherwise, with respect to the Service, including all implied
          warranties of merchantability, fitness for a particular purpose, title and
          non-infringement, and warranties that may arise out of course of dealing,
          course of performance, usage or trade practice. Without limitation to the
          foregoing, the Company provides no warranty or undertaking, and makes no
          representation of any kind that the Service will meet Your requirements,
          achieve any intended results, be compatible or work with any other
          software, applications, systems or services, operate without interruption,
          meet any performance or reliability standards or be error free or that any
          errors or defects can or will be corrected.
        </P>
        <P>
          Without limiting the foregoing, neither the Company nor any of the
          {"company's"} provider makes any representation or warranty of any kind,
          express or implied: (i) as to the operation or availability of the Service,
          or the information, content, and materials or products included thereon;
          (ii) that the Service will be uninterrupted or error-free; (iii) as to the
          accuracy, reliability, or currency of any information or content provided
          through the Service; or (iv) that the Service, its servers, the content, or
          e-mails sent from or on behalf of the Company are free of viruses, scripts,
          trojan horses, worms, malware, timebombs or other harmful components.
        </P>
        <P>
          Some jurisdictions do not allow the exclusion of certain types of
          warranties or limitations on applicable statutory rights of a consumer, so
          some or all of the above exclusions and limitations may not apply to You.
          But in such a case the exclusions and limitations set forth in this section
          shall be applied to the greatest extent enforceable under applicable law.
        </P>
        <H1>Governing Law</H1>
        <P>
          The laws of the Country, excluding its conflicts of law rules, shall govern
          this Terms and Your use of the Service. Your use of the Application may
          also be subject to other local, state, national, or international laws.
        </P>
        <H1>Disputes Resolution</H1>
        <P>
          If You have any concern or dispute about the Service, You agree to first
          try to resolve the dispute informally by contacting the Company.
        </P>
        <H1>For European Union (EU) Users</H1>
        <P>
          If You are a European Union consumer, you will benefit from any mandatory
          provisions of the law of the country in which you are resident in.
        </P>
        <H1>United States Legal Compliance</H1>
        <P>
          You represent and warrant that (i) You are not located in a country that is
          subject to the United States government embargo, or that has been
          designated by the United States government as a {'"terrorist supporting"'}{' '}
          country, and (ii) You are not listed on any United States government list
          of prohibited or restricted parties.
        </P>
        <H1>Severability and Waiver</H1>
        <H2>Severability</H2>
        <P>
          If any provision of these Terms is held to be unenforceable or invalid,
          such provision will be changed and interpreted to accomplish the objectives
          of such provision to the greatest extent possible under applicable law and
          the remaining provisions will continue in full force and effect.
        </P>
        <H2>Waiver</H2>
        <P>
          Except as provided herein, the failure to exercise a right or to require
          performance of an obligation under these Terms shall not effect a{' '}
          {"party's"} ability to exercise such right or require such performance at
          any time thereafter nor shall the waiver of a breach constitute a waiver of
          any subsequent breach.
        </P>
        <H1>Translation Interpretation</H1>
        <P>
          These Terms and Conditions may have been translated if We have made them
          available to You on our Service. You agree that the original English text
          shall prevail in the case of a dispute.
        </P>
        <H1>Changes to These Terms and Conditions</H1>
        <P>
          We reserve the right, at Our sole discretion, to modify or replace these
          Terms at any time. If a revision is material We will make reasonable
          efforts to provide at least 30 {"days'"} notice prior to any new terms
          taking effect. What constitutes a material change will be determined at Our
          sole discretion.
        </P>
        <P>
          By continuing to access or use Our Service after those revisions become
          effective, You agree to be bound by the revised terms. If You do not agree
          to the new terms, in whole or in part, please stop using the website and
          the Service.
        </P>
        <H1>Contact Us</H1>
        <P>
          If you have any questions about these Terms and Conditions, You can contact
          us:
        </P>
        <Ul>
          <Li>
            <P>By email: services@digiprnt.com</P>
          </Li>
        </Ul>
      </Inner>

      <Footer />
    </Container>
  );
};
