import { Theme } from 'assets/styles';
import { StylesConfig, OptionTypeBase } from 'react-select';
import styled from 'styled-components';

interface IBorder {
  error?: string;
}

interface IContainer {
  fillWidth?: boolean;
}

export const Border = styled.div<IBorder>`
  border: 1px solid
    ${({ error, theme }) => (error ? theme.colorDanger : 'transparent')};
  border-radius: 20px;
`;

export const Container = styled.div<IContainer>`
  width: 100%;
  max-width: ${({ fillWidth }) => (fillWidth ? '100%' : '320px')};
`;

const DropdownStyles = (
  isDisabled: boolean,
): StylesConfig<OptionTypeBase, boolean> => ({
  container: (provided) => ({
    ...provided,
    width: '100%',
    zIndex: 2,
    minWidth: 0,
    ...(isDisabled && { opacity: 0.5 }),
  }),

  control: (provided) => ({
    ...provided,
    border: `1px solid ${Theme.lightGrayBg}`,
    boxShadow: 'none',
    '&:hover': {
      border: `1px solid ${Theme.secondaryViolet}`,
    },
    borderRadius: 20,
    backgroundColor: '#fff',
    cursor: 'pointer',
    minHeight: 20,
    padding: '5px 10px',
    color: Theme.secondaryBlack,
    fontSize: 12,
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  multiValue: () => ({
    display: 'none',
  }),

  clearIndicator: () => ({
    display: 'none',
  }),

  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),

  menu: (provided) => ({
    ...provided,
    borderRadius: 20,
    zIndex: 9999,
    overflow: 'hidden',
  }),

  menuList: (provided) => ({
    ...provided,
    padding: '0px 0px 0px 0px',
    maxHeight: 210,
  }),

  valueContainer: (provided) => ({
    ...provided,
    width: '100%',
    padding: 0,
  }),
  input: () => ({
    display: 'none',
  }),

  singleValue: () => ({
    display: 'none',
  }),

  option: (_, state) => {
    const { data, options } = state;

    const isDistrictOption = !!data.schools;

    const index = options?.findIndex((val: OptionTypeBase) => val.id === data.id);

    const isFirst = index === 0;

    const showTopBorder = !isFirst && isDistrictOption;

    return {
      borderTop: showTopBorder ? `3px solid ${Theme.lightGrayBg}` : 'none',
      cursor: 'pointer',
      display: 'flex',
      padding: '0px 16px 0px 16px',
      // color: 'black',
      '&:hover': {
        backgroundColor: `${Theme.backgroundColor}`,
      },
      '&:first-of-type': {
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      },
      '&:last-of-type': {
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
      },
    };
  },
});

export const ErrorMessage = styled.div`
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colorDanger};

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 14px;
  line-height: 14px;
  font-weight: ${({ theme }) => theme.weightSemiBold};
  color: ${({ theme }) => theme.lightGrayBg};

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 15px;
  }
`;

export const InnerTopContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const TopContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
`;

export default DropdownStyles;
