import styled from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 80px;

  @media (max-width: ${({ theme }) => theme.sizeRanges.mobileLarge.max}px) {
    flex-direction: column;
    height: 180px;
  }
`;
