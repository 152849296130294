import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ConfirmDenyModal, Prompt } from 'components/modals';
import { SuccessToast } from 'components/toasts';
import useMutatePhotoRequest from 'data/data-hooks/mutations/photo-request/useMutatePhotoRequest';

import { IPhotoRequest } from 'types/photoRequest.types';
import { Bold } from 'domains/SharedStyles';

interface Props {
  handleClose: () => void;
  onConfirm?: () => void;
  requests: IPhotoRequest[];
  shouldShow: boolean;
}

export const DeleteRequestModal: React.FC<Props> = ({
  handleClose,
  onConfirm,
  requests,
  shouldShow,
}: Props) => {
  const { removeRequests, isRemoving } = useMutatePhotoRequest({
    onDeleteSuccess: async () => {
      await (onConfirm && onConfirm());
      SuccessToast(tSuccess);
      handleClose();
    },
  });
  const count = requests.length;
  const handleConfirm = () => removeRequests(requests);

  const theme = useTheme();
  const { t } = useTranslation();
  const tSure = t('Are you sure you want to');
  const tThis = t(`delete ${count === 1 ? 'this request' : count + ' requests'}?`);
  const tSuccess = t(`Request${count > 1 ? 's' : ''} removed successfully`);

  return (
    <ConfirmDenyModal
      closeModal={handleClose}
      shouldShow={shouldShow}
      onConfirm={handleConfirm}
      prompt={
        <Prompt>
          {tSure} <Bold>{tThis}</Bold>
        </Prompt>
      }
      intent="danger"
      onDeny={handleClose}
      cancelLabel="Cancel"
      confirmLabel="Delete Request"
      isLoading={isRemoving}
      icon={
        <i
          className="far fa-trash-alt"
          style={{ color: theme.colorDanger, fontSize: 30 }}
        />
      }
    />
  );
};
