import { parseError } from 'utils/errors.utils';
import { launchCompanies } from 'state/company/CompanyActions';
import { removeCurrentCompany } from 'utils/company.utils';
import changeUserPassword from 'data/adapters/auth/changeUserPassword';
import forgotUserPassword from 'data/adapters/auth/forgotUserPassword';
import getLoggedInUser from 'data/adapters/auth/getLoggedInUser';
import loginUser from 'data/adapters/auth/loginUser';
import logoutUser from 'data/adapters/auth/logoutUser';

import * as Actions from './AuthActions.types';

export const login: Actions.TLogin =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch(setIsLoading(true));

    try {
      const { success, error } = await loginUser({ email, password });
      if (!success) throw error;

      const { success: getAuthSuccess, error: getAuthError } = await dispatch(
        getUserAuth(),
      );

      if (!getAuthSuccess) {
        throw getAuthError;
      }

      dispatch({
        type: Actions.AUTH__LOGIN,
      });
      return { success: true };
    } catch (err) {
      const stringError = typeof err === 'string' ? err : 'error';

      dispatch({
        type: Actions.AUTH__FAIL,
        error: stringError,
      });

      setTimeout(() => dispatch(clearError()), 5000);
      return { success: false, error: stringError };
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const logout: Actions.TAuthThunkAction = () => async (dispatch) => {
  try {
    dispatch(setIsLoadingUser(true));

    const { success, error } = await logoutUser();

    if (!success) {
      throw error;
    }

    removeCurrentCompany();
    dispatch({
      type: Actions.AUTH__LOGOUT,
    });
  } catch (err) {
    const stringError = parseError(err);

    dispatch({
      type: Actions.AUTH__FAIL,
      error: stringError,
    });

    setTimeout(() => dispatch(clearError()), 5000);
  } finally {
    dispatch(setIsLoadingUser(false));
  }
};

export const getUserAuth: Actions.TGetUserAuth = () => async (dispatch) => {
  dispatch(setIsLoadingUser(true));

  try {
    const { data, error, success } = await getLoggedInUser();

    if (!data || !success) {
      throw error;
    }
    const { user, role } = data;

    await dispatch(launchCompanies(user));
    dispatch({
      user,
      role,
      type: Actions.AUTH__SET_USER_DATA,
    });

    return { success: true };
  } catch (err) {
    dispatch({
      type: Actions.AUTH__FAIL,
      error: '',
    });

    return {
      success: false,
      error:
        typeof err === 'string' ? err : 'Something went wrong with getting the user',
    };
  } finally {
    dispatch(setIsLoadingUser(false));
  }
};

export const forgotPassword: Actions.TForgotPassword =
  ({ email }) =>
  async (dispatch) => {
    dispatch(setIsLoading(true));

    try {
      const { success, error } = await forgotUserPassword({
        email,
      });

      if (!success) {
        throw error;
      }

      dispatch({
        type: Actions.AUTH__FORGOT_PASSWORD,
      });

      return { success: true };
    } catch (err) {
      const stringError = typeof err === 'string' ? err : 'error';
      dispatch({
        type: Actions.AUTH__FAIL,
        error: stringError,
      });

      setTimeout(() => dispatch(clearError()), 5000);

      return { success: false, error: stringError };
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const updatePassword: Actions.TUpdateUserPassword =
  ({ oldPassword, newPassword }) =>
  async (dispatch) => {
    dispatch(setIsLoading(true));

    try {
      const { success, error } = await changeUserPassword({
        oldPassword,
        newPassword,
      });

      if (!success) {
        throw error;
      }

      dispatch({
        type: Actions.AUTH__UPDATE_PASSWORD,
      });

      return { success: true };
    } catch (err) {
      const stringError = typeof err === 'string' ? err : 'error';
      dispatch({
        type: Actions.AUTH__FAIL,
        error: stringError,
      });

      setTimeout(() => dispatch(clearError()), 5000);

      return { success: false, error: stringError };
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const clearError: Actions.TClearError = () => ({
  type: Actions.AUTH__CLEAR_ERROR,
});

export const setIsLoading: Actions.TSetIsLoading = (isLoading) => ({
  isLoading,
  type: Actions.AUTH__SET_IS_LOADING,
});

export const setIsLoadingUser: Actions.TSetIsLoadingUser = (isLoadingUser) => ({
  isLoadingUser,
  type: Actions.AUTH__SET_IS_LOADING_USER,
});
