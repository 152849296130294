import { auth } from 'data/firebase';

interface ILogoutUserData {
  error?: string;
  success: boolean;
}

export default async (): Promise<ILogoutUserData> => {
  try {
    await auth.signOut();

    return { success: true };
  } catch (err) {
    const errorMessage = 'Something went wrong with logging out of your account';

    return { error: errorMessage, success: false };
  }
};
