import React, { useMemo } from 'react';
import { ChartData, Plugin } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import { useEmployeeCards } from 'contexts';

import { Loader } from 'components/loader';
import { Theme } from 'assets/styles';
import chartLabelsLinePlugin from './plugins/chartLabelsLine.plugin';

import { AnyObject } from 'chart.js/types/basic';
import { ChartKeys, Color, Container, Key, Title } from './ActiveCardsChartStyles';

export const ActiveCardsChart: React.FC = () => {
  const { t } = useTranslation();
  const { employeeCards = [], isLoading } = useEmployeeCards();
  const activeCards = useMemo(
    () => employeeCards.filter((c) => c.isActive && c.isPassDownloaded),
    [employeeCards],
  );
  const inActiveCards = useMemo(
    () => employeeCards.filter((c) => !c.isActive || !c.isPassDownloaded),
    [employeeCards],
  );

  const tTitle = t('ACTIVE VS INACTIVE CARDS');
  const tActive = t('Active cards');
  const tInactive = t('Inactive cards');

  const chartData: ChartData<'doughnut', number[], string> = {
    labels: ['Active cards', 'Inactive cards'],
    datasets: [
      {
        data: [activeCards.length, inActiveCards.length],
        backgroundColor: [Theme.primaryGreen, Theme.secondaryViolet],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Container>
      <Title>{tTitle}</Title>

      {isLoading ? (
        <Loader />
      ) : (
        <Doughnut
          data={chartData}
          plugins={[chartLabelsLinePlugin as Plugin<'doughnut', AnyObject>]}
          options={{
            aspectRatio: 2,
            cutout: '60%',
            offset: 15,
            plugins: { legend: { display: false } },
            layout: { padding: { bottom: 5 } },
          }}
        />
      )}

      <ChartKeys>
        <Key>
          <Color fill="violet" />
          {tInactive}
        </Key>
        <Key>
          <Color fill="green" />
          {tActive}
        </Key>
      </ChartKeys>
    </Container>
  );
};
