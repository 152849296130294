import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'components/loader';
import { Container, Circle, Label, Wrapper } from './ToggleSwitchStyles';

interface Props {
  id?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isOn: boolean;
  labels?: {
    on: string;
    off: string;
  };
  onChange?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const ToggleSwitch: React.FC<Props> = ({
  id,
  isDisabled = false,
  isLoading = false,
  isOn,
  labels,
  onChange,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      {isLoading ? (
        <Loader size={5} />
      ) : (
        <Fragment>
          <Container
            id={id}
            isOn={isOn}
            isDisabled={isDisabled}
            onClick={(e) => !isDisabled && onChange && onChange(e)}
          >
            <Circle isOn={isOn} />
          </Container>

          {labels && (
            <Label isOn={isOn}>{isOn ? t(labels.on) : t(labels.off)}</Label>
          )}
        </Fragment>
      )}
    </Wrapper>
  );
};

export default ToggleSwitch;
