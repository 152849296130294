import styled from 'styled-components';

interface IContainer {
  isAdmin: boolean;
  isHR: boolean;
}

export const Container = styled.div<IContainer>`
  position: relative;
  height: 131px;
  width: 131px;
  border-radius: 100%;
  border: 3px solid
    ${({ theme, isAdmin, isHR }) =>
      isAdmin ? theme.secondaryViolet : isHR ? theme.primaryGreen : 'white'};
`;

export const IconContainer = styled.div<IContainer>`
  position: absolute;
  top: 0;
  left: 10px;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: ${({ theme, isAdmin, isHR }) =>
    isAdmin ? theme.secondaryViolet : isHR ? theme.primaryGreen : 'transparent'};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;

  ${({ isAdmin, isHR }) =>
    !(isAdmin || isHR) &&
    `
    display: none;
    `}
`;

export const UploadButton = styled.div`
  position: absolute;
  bottom: 0;
  right: 5px;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.primaryGreen};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border: 2px solid white;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.04);
  }
`;

export const FileField = styled.label`
  ::-webkit-file-upload-button {
    visibility: hidden;
    width: 0px;
    padding: 0px;
    margin: 0px;
  }
  ::file-selector-button {
    visibility: hidden;
    width: 0px;
    padding: 0px;
    margin: 0px;
  }
  ::-ms-browse {
    visibility: hidden;
    width: 0px;
    padding: 0px;
    margin: 0px;
  }

  content: 'Upload Image File';
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  user-select: none;
  width: fit-content;
  height: fit-content;
  transition: all 0.2s;
  gap: 7px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-family: ${({ theme }) => theme.secondaryFont};
  font-size: 15px;
  font-weight: ${({ theme }) => theme.weightRegular};
  color: white;

  input {
    display: none;
  }

  &:hover {
    transform: scale(1.03);
  }

  @media (max-width: ${(props) => props.theme.widthMobile}) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-end;
`;
