import { doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useMutation } from 'react-query';
import { firestore, storage } from 'data/firebase';

import { useEmployeeCards } from 'contexts';
import { useAppSelector } from 'state/hooks';
import updateDigitalIds from 'data/adapters/cards/updateDigitalIds';

import { SuccessToast } from 'components/toasts';
import dbLabels from 'data/db.labels';

import { IEmployeeCard } from 'types/card.types';

interface IArgs {
  cardInstance: IEmployeeCard;
  onSuccess?: () => void;
}

interface IMutateArgs {
  photo: File;
}

export default (args?: IArgs) => {
  const company = useAppSelector(({ company }) => company.currentCompany);
  const { refetch } = useEmployeeCards();

  const mutation = useMutation(
    async ({ photo }: IMutateArgs) => {
      if (!args?.cardInstance) throw 'Invalid uid';
      while (!company) continue;

      const path = `photo-id/${company.id}/${args?.cardInstance.employeeId}.png`;
      const photoRef = ref(storage, path);
      await uploadBytes(photoRef, photo);

      const photoUrl = await getDownloadURL(photoRef);

      const cardRef = doc(
        firestore,
        dbLabels.company,
        company.id,
        dbLabels.employeeCards,
        args?.cardInstance.id,
      );

      await updateDoc(cardRef, { photoUrl });

      while (!company) continue;

      await updateDigitalIds({
        cardInstance: args?.cardInstance,
        company,
      });
    },
    {
      onSuccess: async () => {
        await Promise.all([refetch(), args?.onSuccess && args.onSuccess()]);
        SuccessToast('Photo uploaded succesfully');
      },
    },
  );

  return { updatePhoto: mutation.mutateAsync, ...mutation };
};
