import React, { createContext, useContext } from 'react';
import useGetEmployeeCards from 'data/data-hooks/queries/useGetEmployeeCards';
import { IEmployeeCard } from 'types/card.types';
import { RefetchOptions, RefetchQueryFilters } from 'react-query';

const EmployeeCardsContext = createContext<{
  employeeCards: IEmployeeCard[];
  error: string | null;
  isLoading: boolean;
  refetch: (
    options?: (RefetchOptions & RefetchQueryFilters<unknown>) | undefined,
  ) => Promise<unknown>;
}>({
  employeeCards: [],
  error: null,
  isLoading: false,
  refetch: async () => null,
});

export const useEmployeeCards = () => {
  return useContext(EmployeeCardsContext);
};

export const EmployeeCardsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { employeeCards, error, isLoading, refetch } = useGetEmployeeCards();

  return (
    <EmployeeCardsContext.Provider
      value={{
        employeeCards,
        error,
        isLoading,
        refetch,
      }}
    >
      {children}
    </EmployeeCardsContext.Provider>
  );
};
