import { firestore } from 'data/firebase';
import useAppQuery from 'hooks/useAppQuery';
import dbLabels from 'data/db.labels';
import { ICorporateSettings } from 'types/corporate.types';
import { doc } from 'firebase/firestore';

export default () => {
  const settingsRef = doc(firestore, dbLabels.corporate, dbLabels.settings);

  const query = useAppQuery<ICorporateSettings>(['corp_settings'], settingsRef);

  return { settings: query.data, ...query };
};
