import React, { useMemo } from 'react';
import { useEmployeeCards } from 'contexts';
import { StatsSlide } from '../../../../../components/stats-slide/StatsSlide';
import { Container } from './CardsStatsStyles';

export const CardsStats: React.FC = () => {
  const { employeeCards = [] } = useEmployeeCards();
  const activeCards = useMemo(
    () => employeeCards.filter((c) => c.isActive && c.isPassDownloaded),
    [employeeCards],
  );
  const inActiveCards = useMemo(
    () => employeeCards.filter((c) => !c.isActive || !c.isPassDownloaded),
    [employeeCards],
  );

  return (
    <Container>
      <StatsSlide
        icon="card"
        text="cards active"
        type="active"
        value={activeCards.length}
      />
      <StatsSlide
        icon="alert"
        text="cards inactive"
        type="inactive"
        value={inActiveCards.length}
      />
    </Container>
  );
};
