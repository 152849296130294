import styled from 'styled-components';

export const PageTitle = styled.h3`
  font-family: ${({ theme }) => theme.primaryFont};
  line-height: 60px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

export const PageContentContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0px 30px 40px;
  display: flex;
  flex-direction: column;
`;

export const ContentBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  height: 100%;
`;

export const ContentBoxHeader = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ContentBoxTitle = styled.div`
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const ContentBox = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  min-height: 300px;
`;
