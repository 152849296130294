import { useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import moment from 'moment';

import { useEmployeeCards } from 'contexts';
import useUpdateEmployeePhoto from 'data/data-hooks/mutations/employee/useUpdateEmployeePhoto';
import useUserRole from 'hooks/useUserRole';

import { IEmployeeCard } from 'types/card.types';

interface IArgs {
  cardInstance: IEmployeeCard;
}

export default ({ cardInstance }: IArgs) => {
  const [shouldShow, setShouldShow] = useState(false);
  const [photo, setPhoto] = useState<string>();
  const [isProcessingPhoto, setProcessingPhoto] = useState(false);
  const handleOpen = () => setShouldShow(true);
  const handleClose = () => setShouldShow(false);

  let _editorRef: AvatarEditor | null = null;
  const setEditorRef = (editor: AvatarEditor | null) => (_editorRef = editor);

  const { employeeCards = [] } = useEmployeeCards();
  const { updatePhoto, isLoading: isUploading } = useUpdateEmployeePhoto({
    cardInstance,
  });

  const { features } = useUserRole();
  const canGenerateCard = !!features?.hasCardManagementAccess.canGenerateCard;
  const canRequestPhoto = !!features?.hasCardManagementAccess.canRequestPhoto;
  const canUpdatePhoto = !!features?.hasCardManagementAccess?.canUploadPhoto;

  const card = employeeCards.find(({ id }) => id === cardInstance.cardId);

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateUTC = moment.utc(card?.modifiedOn);
  const lastMod = card?.modifiedOn
    ? dateUTC.tz(tz).format('MM/DD/YYYY hh:mm a')
    : 'N/A';
  const lastGeneratedUTC = moment.utc(card?.currentCardInstance?.createdOn);
  const lastGenerated = card?.currentCardInstance?.createdOn
    ? lastGeneratedUTC.tz(tz).format('MM/DD/YYYY hh:mm a')
    : 'N/A';
  const lastRequestedUTC = moment.utc(card?.lastPhotoRequest);
  const lastRequested = card?.lastPhotoRequest
    ? lastRequestedUTC.tz(tz).format('MM/DD/YYYY hh:mm a')
    : 'N/A';

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      setProcessingPhoto(true);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files?.[0]);
      reader.addEventListener(
        'load',
        () => {
          setProcessingPhoto(false);
          reader.result && setPhoto(reader.result.toString());
        },
        false,
      );
    }
  };

  const closeEditingModal = () => setPhoto(undefined);
  const confirmPhoto = () => {
    if (_editorRef) {
      let photo: File;
      const canvas = _editorRef.getImage();
      canvas.toBlob((blob: Blob | null) => {
        if (blob) {
          photo = new File([blob], `${cardInstance.employeeId || 'photo-id'}.png`, {
            type: 'image/png',
            lastModified: new Date().getTime(),
          });

          updatePhoto({ photo }).then(() => setPhoto(undefined));
        }
      });
    }
  };

  return {
    canGenerateCard,
    canRequestPhoto,
    canUpdatePhoto,
    closeEditingModal,
    confirmPhoto,
    handleClose,
    handleOpen,
    handleUpload,
    isUploading: isUploading || isProcessingPhoto,
    lastGenerated,
    lastMod,
    lastRequested,
    photo,
    setEditorRef,
    shouldShow,
    showEditingModal: !!photo,
  };
};
