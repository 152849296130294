import React from 'react';
import moment from 'moment';

import { CellProps, Column } from 'react-table';
import { format } from 'date-fns';

import { ActionsCell } from '../components/card-generations-list/components/cells/actions-cell/ActionsCell';
import { GenerationStatusCell } from '../components/card-generations-list/components/cells/generation-status-cell/GenerationStatusCell';
import { GenerationStatusFilter } from '../components/card-generations-list/components/filters/generation-status-filter/GenerationStatusFilter';
import { PassStatusCell } from '../components/card-generations-list/components/cells/pass-status-cell/PassStatusCell';
import { PassStatusFilter } from '../components/card-generations-list/components/filters/pass-status-filter/PassStatusFilter';
import { PersonDetailsCell } from '../components/card-generations-list/components/cells/person-details-cell/PersonDetailsCell';

import { EGenerationStatus, ICardGeneration } from 'types/card.types';

export default [
  {
    accessor: (row) => row,
    disableFilters: true,
    Header: 'Name',
    Cell: PersonDetailsCell,
  },
  {
    accessor: (row) => moment(row.createdOn).unix(),
    disableFilters: true,
    Header: 'Date/Time',
    id: 'time',
    Cell: ({ value }: CellProps<Record<string, never>, number>) => (
      <>{format(value * 1000, 'MM/dd/yyyy KK:mm a')}</>
    ),
  },
  {
    accessor: (row) => row,
    Header: 'Generation Status',
    Filter: GenerationStatusFilter as unknown,
    filter: (rows, _, value?: 'all' | 'completed' | 'failed' | 'inProgress') => {
      return rows.filter((row) => {
        if (!value || value === 'all') return true;
        if (value === 'completed')
          return row.original.status === EGenerationStatus.completed;
        if (value === 'failed') {
          return (
            row.original.status === EGenerationStatus.failedToEmail ||
            row.original.status === EGenerationStatus.failedToGenerate
          );
        }
        if (value === 'inProgress') {
          return (
            row.original.status === EGenerationStatus.pending ||
            row.original.status === EGenerationStatus.sendingEmail
          );
        }
        return false;
      });
    },
    Cell: GenerationStatusCell,
  },
  {
    accessor: (row) => row,
    Header: 'Pass Status',
    Cell: PassStatusCell,
    Filter: PassStatusFilter,
    filter: (
      rows,
      _,
      value?: 'all' | 'received' | 'downloaded' | 'deleted' | 'invalid' | 'unknown',
    ) => {
      return rows.filter((row) => {
        if (!value || value === 'all') return true;
        if (value === 'unknown') return !row.original.passStatus;

        return row.original.passStatus === value;
      });
    },
  },
  {
    accessor: (row) => row,
    Header: '',
    id: 'actions',
    Cell: ActionsCell,
    disableFilters: true,
  },
] as Column<ICardGeneration>[] as Column[];
