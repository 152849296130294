import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 45px;
`;

export const Inner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const IconContainer = styled.div`
  width: 30px;
  height: 30px;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const Prompt = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 15px;
  line-height: 25px;
  text-transform: uppercase;
  max-width: 100%;
  word-break: break-word;
  white-space: pre-line;
`;

export const Bold = styled.span`
  font-weight: ${({ theme }) => theme.weightBold};
`;

export const PromptContianer = styled.div`
  padding: 0px 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
