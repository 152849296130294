import React from 'react';
import { Svg } from './ArrowStyles';

interface Props {
  dark?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
}

export const Arrow: React.FC<Props> = ({
  dark = false,
  isActive = false,
  isDisabled = false,
}: Props) => (
  <Svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor="pointer"
    isActive={isActive}
    isDisabled={isDisabled}
  >
    <g clipPath="url(#clip0_206_152)">
      <path
        d="M12 15L7.75702 10.757L9.17202 9.34299L12 12.172L14.828 9.34299L16.243 10.757L12 15Z"
        fill={dark ? '#fff' : '#23222A'}
      />
    </g>
    <defs>
      <clipPath id="clip0_206_152">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);
