import React from 'react';
import { PATHS } from 'routes';
import { SuccessToast } from 'components/toasts';
import { Container, MoreButton } from './CardTrackerSuccessToastStyles';
import { NavigateFunction } from 'react-router-dom';

export const CardTrackerSuccessToast = (
  text: string,
  navigate: NavigateFunction,
) => {
  const onClick = () => navigate(PATHS.cardTracker);

  return SuccessToast(
    <Container>
      <div>{text}</div>
      <MoreButton onClick={onClick}>Click here for details</MoreButton>
    </Container>,
  );
};
