import { firestore } from 'data/firebase';
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';

import { parseError } from 'utils/errors.utils';
import { Adapter } from '../../types';
import dbLabels from 'data/db.labels';

interface IArgs {
  token: string;
}

export default async ({ token }: IArgs): Promise<Adapter<Date>> => {
  try {
    const ref = query(
      collection(firestore, dbLabels.pendingAccount),
      where('token', '==', token),
    );
    const snapshot = await getDocs(ref);

    if (!snapshot.docs.length) throw 'Request does not exist';

    const pendingAccountId = snapshot.docs[0].id;

    await updateDoc(doc(firestore, dbLabels.pendingAccount, pendingAccountId), {
      isCreated: true,
    });

    return { success: true };
  } catch (err) {
    const stringError = parseError(err);

    return { success: false, error: stringError };
  }
};
