import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'state/hooks';
import useToggleCardStatus from 'data/data-hooks/mutations/card/useToggleCardStatus';

import { FailToast } from 'components/toasts';
import { Loader } from 'components/loader';
import { ToggleSwitch } from 'components/toggle-switch';

import useUserRole from 'hooks/useUserRole';
import { formatName } from 'utils/user.utils';
import { IEmployeeCard } from 'types/card.types';
import {
  Container,
  EmailJobIDValue,
  Group,
  Inner,
  Label,
  LoaderContainer,
  Status,
  Value,
} from './ContactDetailsStyles';

interface Props {
  cardInstance: IEmployeeCard;
  isLoading?: boolean;
}

export const ContactDetails: React.FC<Props> = ({
  cardInstance,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const tFullName = t('Full Name');
  const tStatus = t('Status');
  const tID = t('ID');
  const tJobTitle = t('Title');
  const tEmail = t('Email');
  const tActive = t('Active');
  const tInactive = t('Inactive');
  const tOrg1 = t('Organization Level 1');
  const tOrg2 = t('Organization Level 2');
  const tOrg3 = t('Organization Level 3');
  const tOrg4 = t('Organization Level 4');

  const { employeeId, title, emailAddress } = cardInstance;

  const { features } = useUserRole();
  const canDisable = !!features?.hasCardManagementAccess.canDisable;
  const { mutateAsync: toggleStatus, isLoading: isToggling } = useToggleCardStatus();

  const company = useAppSelector(({ company }) => company.currentCompany);
  const allowDisable = company?.configuration?.allowDisable ?? false;

  const changeStatus = () => {
    if (!cardInstance) return FailToast('Something went wrong!');
    toggleStatus({
      id: cardInstance.cardId,
      isCurrentlyActive: cardInstance.isActive,
    });
  };

  if (isLoading || isToggling)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  return (
    <Container>
      <Inner>
        <Group>
          <Label>{tFullName}</Label>
          <Value bold>{formatName(cardInstance) || 'N/A'}</Value>
        </Group>
        <Group>
          <Label>{tStatus}</Label>

          {!allowDisable && (
            <Status isActive={cardInstance?.isActive ?? false}>
              {cardInstance?.isActive ? tActive : tInactive}
            </Status>
          )}

          {allowDisable && (
            <Value>
              {cardInstance ? (
                <ToggleSwitch
                  isOn={!!cardInstance.isActive}
                  labels={{ off: tInactive, on: tActive }}
                  onChange={changeStatus}
                  isLoading={isLoading}
                  isDisabled={!canDisable}
                />
              ) : (
                'N/A'
              )}
            </Value>
          )}
        </Group>
        <Group>
          <Label>{tID}</Label>
          <EmailJobIDValue>#{employeeId}</EmailJobIDValue>
        </Group>
        <Group>
          <Label>{tJobTitle}</Label>
          <EmailJobIDValue>{title || 'N/A'}</EmailJobIDValue>
        </Group>
        <Group>
          <Label>{tEmail}</Label>
          <EmailJobIDValue>{emailAddress || 'N/A'}</EmailJobIDValue>
        </Group>
      </Inner>

      {company?.configuration.orgLevels && (
        <Inner>
          <Group>
            <Label>{tOrg1}</Label>
            <EmailJobIDValue>
              {cardInstance.orgLevel1Description || 'N/A'}
            </EmailJobIDValue>
          </Group>
          <Group>
            <Label>{tOrg2}</Label>
            <EmailJobIDValue>
              {cardInstance.orgLevel2Description || 'N/A'}
            </EmailJobIDValue>
          </Group>
          <Group>
            <Label>{tOrg3}</Label>
            <EmailJobIDValue>
              {cardInstance.orgLevel3Description || 'N/A'}
            </EmailJobIDValue>
          </Group>
          <Group>
            <Label>{tOrg4}</Label>
            <EmailJobIDValue>
              {cardInstance.orgLevel4Description || 'N/A'}
            </EmailJobIDValue>
          </Group>
        </Inner>
      )}
    </Container>
  );
};
