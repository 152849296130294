import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from 'components/inputs';
import { FailToast, SuccessToast } from 'components/toasts';
import requestDemo from 'data/adapters/requestDemo';

export const RequestDemo: React.FC = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [requestingDemo, setRequestingDemo] = useState(false);

  const { t } = useTranslation();
  const incorrectEmail = t('Incorrect email format');
  const mustBeFilledText = t('Must be filled');

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setEmail(e.target.value);

    if (e.target.value) {
      const isValid = /\S+@\S+\.\S+/.test(e.target.value);
      setError(isValid ? '' : incorrectEmail);
    } else setError('');
  };

  const onSubmit = async (email: string) => {
    setRequestingDemo(true);

    if (!email) {
      setError(mustBeFilledText);
    } else {
      const { success } = await requestDemo({ email });

      if (success) SuccessToast('Our team recieved your request!');
      else FailToast('An error occurred while sending request');
    }

    setRequestingDemo(false);
  };

  return (
    <Input
      button={{
        text: 'Request  Demo',
        onClick: onSubmit,
        isLoading: requestingDemo,
        isDisabled: requestingDemo,
      }}
      error={error}
      onChange={handleChange}
      success={!!email && !error}
      value={email}
      placeholder="Email Address..."
      type="email"
    />
  );
};
