import React from 'react';

import { MenuToolTip } from 'components/menu-tootip';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { updateCurrentCompany } from 'state/company/CompanyActions';
import useUserRole from 'hooks/useUserRole';

import { CompanyName, Container, Initials } from './CompanyPickerStyles';

export const CompanyPicker: React.FC = () => {
  const { currentCompany, companiesList } = useAppSelector(({ company }) => company);
  const { isSuperAdmin } = useUserRole();
  const dispatch = useAppDispatch();

  const getInitials = () => {
    if (!currentCompany) return '';

    return (currentCompany.companyName || '')
      .split(' ')
      .slice(0, 2)
      .map((n) => n[0])
      .join('');
  };

  const onCompanyChange = (companyId: string) =>
    dispatch(updateCurrentCompany(companyId));

  return (
    <Container>
      <Initials>{getInitials()}</Initials>
      <CompanyName>{currentCompany?.companyName}</CompanyName>
      {isSuperAdmin && companiesList.length > 1 && (
        <MenuToolTip
          menuOptions={companiesList
            .filter(({ id }) => id !== currentCompany?.id)
            .map((company) => ({
              label: company.companyName,
              onClick: () => onCompanyChange(company.id),
            }))}
          id="companies"
          dark
        />
      )}
    </Container>
  );
};
