import { Adapter } from '../types';
import { httpsCallable } from 'data/firebase';
import { parseError } from 'utils/errors.utils';

interface IRequestDemoArgs {
  email: string;
}

export default async ({ email }: IRequestDemoArgs): Promise<Adapter> => {
  try {
    const sendEmail = httpsCallable('sendEmail');

    await sendEmail({
      to: 'services@safermgmt.com',
      from: email,
      cc: ['brian@safermgmt.com', 'fred@safermgmt.com'],
      subject: `Digi Prnt Demo Request`,
      html: `${email} has requested a Digi Prnt demo.`,
    });

    return { success: true };
  } catch (err) {
    const stringError = parseError(err);

    return { success: false, error: stringError };
  }
};
