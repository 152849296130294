import { httpsCallable } from 'data/firebase';
import { parseError } from 'utils/errors.utils';
import { QueryClient } from 'react-query';

import { Adapter } from '../../types';
import { ICompany } from 'types/company.types';
import { ICardToGenerate } from 'types/card.types';

interface ISendCardArgs {
  company: ICompany;
  cards: ICardToGenerate[];
  onEmailsSent?: (response: { success: boolean }) => void;
  queryClient?: QueryClient;
}

export default async ({
  cards,
  company,
  onEmailsSent,
  queryClient,
}: ISendCardArgs): Promise<Adapter> => {
  try {
    let data;
    if (company.isSchool) {
      const generateSchoolPass = httpsCallable('generateSchoolPass');
      const response = await generateSchoolPass({
        CompanyId: company.id,
        cards,
      });
      data = response.data;
    } else {
      const generateDigitalIds = httpsCallable('generateDigitalIds');
      const response = await generateDigitalIds({
        CompanyId: company.id,
        cards,
      });
      data = response.data;
    }

    if (!data.success || !data.results) throw 'Failed to send card(s)';
    if (queryClient) await queryClient.refetchQueries(['card_generations']);

    /* send emails */
    const sendGeneratedCardEmail = httpsCallable('sendGeneratedCardEmail');
    sendGeneratedCardEmail({
      companyId: company.id,
      results: data.results,
      config: company.configuration.emailSettings || {},
      isStudent: !!company.isSchool,
    })
      .then(({ data }) => {
        if (data.success) {
          onEmailsSent && onEmailsSent({ success: true });
        } else throw '';
      })
      .catch(() => onEmailsSent && onEmailsSent({ success: false }));

    return { success: true };
  } catch (err) {
    const stringError = parseError(err);

    return { success: false, error: stringError };
  }
};
