import styled, { keyframes } from 'styled-components';

interface IAvatarContainer {
  index: number;
  count: number;
}

export const Container = styled.div`
  height: 600px;

  @media (max-height: 1000px) {
    height: calc(100vh - 290px);
  }

  @media (max-height: 790px) {
    min-height: 500px;
    height: 500px;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const Inner = styled.div`
  padding: 20px;
  height: 100%;
  width: 100%;
`;

export const ImagesContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const appear = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const AvatarContainer = styled.div<IAvatarContainer>`
  position: absolute;
  animation: ${appear} 0.2s linear forwards;
  box-shadow: 0px 20px 30px ${({ theme }) => theme.primaryBlack}25;
  border-radius: 100%;

  ${({ index, count }) => {
    if (count === 1 || index === 2)
      return `
        top: calc(50% - 175px);
        left: calc(50% - 175px);
    `;
    if (count === 2 || index < 2) {
      const width = (count === 2 ? 250 : 200) / 2;
      if (index === 0)
        return `
        top: calc(50% - ${width}px);
        left: 0;
        `;
      return `
        top: calc(50% - ${width}px);
        right: 0;
      `;
    }
  }}

  @media (max-width: ${({ theme }) => theme.sizeRanges.mobileLarge.max}px) {
    ${({ index, count }) => {
      if (count === 1 || index === 2)
        return `
        top: calc(50% - 130px);
        left: calc(50% - 130px);
    `;
    }}
  }
`;

export const Empty = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.lightGrayBg};
`;

export const Footer = styled.div`
  height: 55px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px 10px;
  z-index: 999;
  background-color: white;

  border-top: 1px solid ${({ theme }) => theme.backgroundColor};
  margin-top: auto;
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
