import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'state/hooks';
import { InfoTextContainer, Title } from '../SharedStyles';

export const ErrorPage: React.FC = () => {
  const { isExpired } = useAppSelector(({ photoVerification }) => photoVerification);

  const { t } = useTranslation();
  const tHeader = isExpired
    ? t('This page has expired')
    : t('Something went wrong!');
  const tText = t('Please ask your administrator to send a new request');

  return (
    <InfoTextContainer>
      <h1>{tHeader}</h1>
      <Title>{tText}</Title>
    </InfoTextContainer>
  );
};
