import React from 'react';
import { Container, Bar, BarContainer } from './HamburgerIconStyles';

interface Props {
  onClick: () => void;
}

const HamburgerIcon: React.FC<Props> = ({ onClick }: Props) => {
  return (
    <Container onClick={onClick}>
      <BarContainer>
        <Bar />
      </BarContainer>
      <BarContainer>
        <Bar />
      </BarContainer>
      <BarContainer>
        <Bar />
      </BarContainer>
    </Container>
  );
};

export default HamburgerIcon;
