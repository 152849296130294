import React from 'react';
import { OptionTypeBase, ValueContainerProps, components } from 'react-select';
import { Container, Text, ChildrenContainer } from './ValueContainerStyles';
import useDimensions from 'hooks/useDimensions';

const ValueContainer: React.FC<ValueContainerProps<OptionTypeBase, boolean>> = (
  props: ValueContainerProps<OptionTypeBase, boolean>,
) => {
  const { ref, dimensions } = useDimensions();

  const { selectProps, children } = props;
  const value = selectProps?.value as OptionTypeBase;

  if (!value || value.length === 0) {
    return (
      <components.ValueContainer {...props}>
        <Container ref={ref}>{children}</Container>
      </components.ValueContainer>
    );
  }

  const displayLabel =
    Array.isArray(value) && value.length
      ? value.map(({ label }) => label).join(', ')
      : value.label || '';
  const maxTextWidth = (dimensions.width || 20) - 20;

  return (
    <components.ValueContainer {...props}>
      <Container ref={ref}>
        <Text maxWidth={maxTextWidth}>
          {displayLabel || 'N/A'}
          <ChildrenContainer>{children}</ChildrenContainer>
        </Text>
      </Container>
    </components.ValueContainer>
  );
};

export default ValueContainer;
