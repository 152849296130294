import styled from 'styled-components';

interface IIcon {
  isPassDownloaded?: boolean;
  isPassDeleted?: boolean;
}

export const Container = styled.div`
  .tool-tip {
    padding: 5px;
  }
`;

export const Icon = styled.i<IIcon>`
  font-size: 15px;
  color: ${({ theme, isPassDownloaded, isPassDeleted }) =>
    isPassDownloaded
      ? theme.primaryGreen
      : isPassDeleted
      ? theme.colorInactive
      : theme.gray};
`;

export const ToolTipContent = styled.div`
  font-size: 12px;
  color: white;
`;
