import styled from 'styled-components';

interface IElement {
  isSelected: boolean;
}

export const Container = styled.div`
  left: 80px;
  position: absolute;
  top: 30px;
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  z-index: 99999;
`;

export const Popup = styled.div`
  align-items: flex-end;
  background-color: white;
  display: flex;
  flex-direction: column;
  min-width: 92px;
  position: relative;
  z-index: 10;
  border-radius: 5px;

  &:after {
    bottom: 100%;
    left: 70%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    border-bottom: 6px solid white;
  }
`;

export const Element = styled.div<IElement>`
  height: 40px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.lightGrayBg};

  &:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-of-type {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.secondaryVioletTrans}20;
  }

  ${({ theme, isSelected }) =>
    !!isSelected && `background-color: ${theme.secondaryVioletTrans}60`} !important;
`;
