import { AccessRules } from 'types/role.types';

const {
  canDisableCard,
  canEditCard,
  canGenerateCard,
  canRequestPhoto,
  hasDashboardAccess,
  hasEmailTemplatesAccess,
  hasNotifications,
  hasPhotoVerification,
} = AccessRules;

export default {
  cardManagement: [canDisableCard, canEditCard, canGenerateCard, canRequestPhoto],
  dashboard: [hasDashboardAccess],
  emailTemplates: [hasEmailTemplatesAccess],
  notifications: [hasNotifications],
  photoVerification: [hasPhotoVerification],
};
