import React from 'react';
import { useTranslation } from 'react-i18next';

import { compareUserRole } from 'utils/user.utils';
import { useAppSelector } from 'state/hooks';
import getPendingAccount from 'data/data-hooks/queries/user/getPendingAccount';
import useGetUser from 'data/data-hooks/queries/user/useGetUser';
import useUpdateUserRole from 'data/data-hooks/mutations/user/useUpdateUserRole';
import useUserRole from 'hooks/useUserRole';

import { Loader } from 'components/loader';

import { UserRolesEnum } from 'types/user.types';
import { IEmployee } from 'types/employee.types';
import { Actions, RoleUpdateButton } from './RoleActionsStyles';

interface Props {
  employee: IEmployee;
}

export const RoleActions: React.FC<Props> = ({ employee }: Props) => {
  const { t } = useTranslation();
  const tAddAdmin = t('Make admin');
  const tRemoveAdmin = t('Remove as admin');
  const tAddHR = t('Make HR admin');
  const tRemoveHR = t('Remove as HR admin');

  const { user: currentUser } = useAppSelector(({ auth }) => auth);
  const { isSuperAdmin, userRole } = useUserRole();
  const {
    user,
    isLoading: isLoadingUser,
    refetch,
  } = useGetUser({ emailAddress: employee.emailAddress });
  const {
    pendingAccount,
    isLoading: isLoadingAccount,
    refetch: recheckPendingAcc,
  } = getPendingAccount({
    employeeId: employee.employeeId,
  });
  const { updateRole, isLoading } = useUpdateUserRole({
    onSuccess: () => Promise.all([refetch(), recheckPendingAcc()]),
  });

  const onRoleUpdate = (role: UserRolesEnum) => {
    updateRole({ user, employee, role });
  };

  if (isLoading || isLoadingUser || isLoadingAccount || !userRole) {
    return <Loader size={7} />;
  }

  const rank = user ? compareUserRole(userRole, user.role) : 1;
  if (
    (user && currentUser && user?.id === currentUser?.id) ||
    (!!pendingAccount && !pendingAccount?.isCreated) ||
    rank <= 0
  ) {
    return null;
  }

  const isAlreadyAdmin = user?.role === UserRolesEnum.admin;
  const isAlreadyHR = user?.role === UserRolesEnum.HRAdmin;

  return (
    <Actions>
      <RoleUpdateButton
        onClick={() =>
          onRoleUpdate(isAlreadyAdmin ? UserRolesEnum.employee : UserRolesEnum.admin)
        }
      >
        <i className={isAlreadyAdmin ? 'fas fa-user-slash' : 'fas fa-user-tie'} />
        {isAlreadyAdmin ? tRemoveAdmin : tAddAdmin}
      </RoleUpdateButton>

      {!!isSuperAdmin && (
        <RoleUpdateButton
          onClick={() =>
            onRoleUpdate(
              isAlreadyHR ? UserRolesEnum.employee : UserRolesEnum.HRAdmin,
            )
          }
        >
          <i className={isAlreadyHR ? 'fas fa-user-slash' : 'fas fa-user-tie'} />
          {isAlreadyHR ? tRemoveHR : tAddHR}
        </RoleUpdateButton>
      )}
    </Actions>
  );
};
