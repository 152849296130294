import React, { MouseEvent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseButton } from 'components/buttons';
import { Loader } from 'components/loader';

import {
  Container,
  ContentContainer,
  HeaderText,
  ModalContent,
  Overlay,
  TopContainer,
} from './ModalStyles';

interface Props {
  children: ReactNode;
  dark?: boolean;
  handleClose: ((e: React.MouseEvent) => void) | (() => void);
  headerMessage?: string;
  isLoading?: boolean;
  shouldShow: boolean;
  width?: string;
}

export const Modal: React.FC<Props> = ({
  children,
  dark = false,
  handleClose,
  headerMessage = '',
  isLoading = false,
  shouldShow = false,
  width = '430px',
}: Props) => {
  const { t } = useTranslation();
  const onClose = (e: React.MouseEvent) => {
    if (isLoading) return;
    return handleClose(e);
  };

  return (
    <Container shouldShow={shouldShow}>
      <Overlay onClick={onClose} />
      <ContentContainer width={width} dark={dark}>
        <TopContainer>
          <HeaderText>{t(headerMessage)}</HeaderText>
          <CloseButton onClick={onClose} dark={dark} />
        </TopContainer>

        <ModalContent>{isLoading ? <Loader /> : children}</ModalContent>
      </ContentContainer>
    </Container>
  );
};
