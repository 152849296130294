import { useCallback, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { updateFilter } from 'state/card-management/CardManagementActions';
import useSearchBar from 'hooks/useSearchBar';

import { IDateRange, TFilter } from 'types/shared.types';

export default () => {
  const dispatch = useAppDispatch();
  const { searchValue } = useSearchBar();
  const { startDate, endDate } = useAppSelector(
    ({ cardManagement }) => cardManagement,
  );
  const [activeFilter, setActiveFilter] = useState('all');

  const handleFilterChange = useCallback((e: React.MouseEvent) => {
    const filter = (e.target as HTMLButtonElement).name as TFilter;
    onFilterUpdate(filter);
  }, []);

  const onFilterUpdate = (filter: TFilter) => {
    setActiveFilter(filter);
    setTimeout(() => {
      dispatch(updateFilter({ filter }));
    }, 1);
  };

  const setCustomDateRange = useCallback(({ startDate, endDate }: IDateRange) => {
    setActiveFilter('custom');
    dispatch(updateFilter({ filter: 'custom', startDate, endDate }));
  }, []);

  useEffect(() => {
    searchValue && onFilterUpdate('all');
  }, [searchValue]);

  return {
    activeFilter,
    endDate,
    handleFilterChange,
    setCustomDateRange,
    startDate,
  };
};
