import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { ICompany, IOrgLevel } from 'types/company.types';
import { IUser } from 'types/user.types';

export const CMPNY__FAIL = 'CMPNY__FAIL';
export const CMPNY__SET_COMPANIES_LIST = 'CMPNY__SET_COMPANIES_LIST';
export const CMPNY__SET_CURRENT_COMPANY = 'CMPNY__SET_CURRENT_COMPANY';
export const CMPNY__SET_IS_LOADING = 'CMPNY__SET_IS_LOADING';

interface CompanyFailAction {
  error: string;
  type: typeof CMPNY__FAIL;
}

interface SetCompaniesListAction {
  type: typeof CMPNY__SET_COMPANIES_LIST;
  list: ICompany[];
}

interface SetCurrentComapanyAction {
  type: typeof CMPNY__SET_CURRENT_COMPANY;
  company: ICompany;
  orgLevels: IOrgLevel[];
}

interface SetIsLoadingAction {
  type: typeof CMPNY__SET_IS_LOADING;
  isLoading: boolean;
}

export type CompanyActionTypes =
  | CompanyFailAction
  | SetCompaniesListAction
  | SetCurrentComapanyAction
  | SetIsLoadingAction;

export type TGetCompaniesList = () => ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  CompanyActionTypes
>;

export type TLaunchCompanies = (
  user: IUser,
) => ThunkAction<Promise<void>, RootState, unknown, CompanyActionTypes>;

export type TSetCurrentCompany = (
  id?: string,
) => ThunkAction<Promise<void>, RootState, unknown, CompanyActionTypes>;

export type TSetIsLoading = (bool: boolean) => SetIsLoadingAction;
