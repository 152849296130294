import React from 'react';
import { Theme } from 'assets/styles';

export const Hide: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer' }}
  >
    <g clipPath="url(#clip0_96_568)">
      <path
        d="M12 3C17.392 3 21.878 6.88 22.819 12C21.879 17.12 17.392 21 12 21C6.60803 21 2.12203 17.12 1.18103 12C2.12103 6.88 6.60803 3 12 3ZM12 19C14.0395 18.9996 16.0184 18.3068 17.6129 17.0352C19.2074 15.7635 20.3229 13.9883 20.777 12C20.3213 10.0133 19.205 8.24 17.6107 6.97003C16.0163 5.70005 14.0383 5.00853 12 5.00853C9.96173 5.00853 7.98372 5.70005 6.38941 6.97003C4.79509 8.24 3.6788 10.0133 3.22303 12C3.67713 13.9883 4.7927 15.7635 6.38717 17.0352C7.98164 18.3068 9.96056 18.9996 12 19ZM12 16.5C10.8066 16.5 9.66196 16.0259 8.81805 15.182C7.97414 14.3381 7.50003 13.1935 7.50003 12C7.50003 10.8065 7.97414 9.66193 8.81805 8.81802C9.66196 7.97411 10.8066 7.5 12 7.5C13.1935 7.5 14.3381 7.97411 15.182 8.81802C16.0259 9.66193 16.5 10.8065 16.5 12C16.5 13.1935 16.0259 14.3381 15.182 15.182C14.3381 16.0259 13.1935 16.5 12 16.5ZM12 14.5C12.6631 14.5 13.299 14.2366 13.7678 13.7678C14.2366 13.2989 14.5 12.663 14.5 12C14.5 11.337 14.2366 10.7011 13.7678 10.2322C13.299 9.76339 12.6631 9.5 12 9.5C11.337 9.5 10.7011 9.76339 10.2323 10.2322C9.76342 10.7011 9.50003 11.337 9.50003 12C9.50003 12.663 9.76342 13.2989 10.2323 13.7678C10.7011 14.2366 11.337 14.5 12 14.5Z"
        fill={Theme.primaryBlack}
      />
    </g>
    <defs>
      <clipPath id="clip0_96_568">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
