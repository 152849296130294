import styled from 'styled-components';

interface IBtn {
  isDisabled?: boolean;
}

export const Container = styled.button<IBtn>`
  color: ${({ theme }) => theme.secondaryBlack};
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.secondaryBlack};
  border-radius: 60px;
  height: 30px;
  padding: 5px 10px;
  width: fit-content;
  font-size: 13px;
  font-weight: 600;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 0.9)};
  transition: all 0.2s;

  display: flex;
  flex-direction: row;
  gap: 10px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover:not([disabled]) {
    opacity: 1;
    transform: scale(1.02);
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.sizeRanges.tablet.min}px) {
    font-size: 9px;
    height: 20px;
    padding: 2px 9px;
  }
`;

export const LeadingIconContainer = styled.div``;
