import styled from 'styled-components';

interface IFilterBtn {
  isActive: boolean;
}

export const TitleAndActions = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  gap: 10px;

  @media (max-width: ${({ theme }) => theme.sizeRanges.mobileLarge.max}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;

    > :nth-child(1) {
      grid-column: 1 / span 2;
    }
  }
`;

export const HeaderBtnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  flex-grow: 1;
`;

export const HeaderButton = styled.button<IFilterBtn>`
  min-width: 35px;
  border-radius: 50px;
  font-size: 12px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.primaryBlackTrans : 'transparent'};
  font-weight: ${({ theme, isActive }) =>
    isActive ? theme.weightBold : theme.weightRegular};
  cursor: pointer;
  padding: 7px;
  transition: all 0.2 ease-out;

  ${({ isActive }) => isActive && `transform: scale(1.03);`};

  &:hover:not([disabled]) {
    transform: scale(1.03);
  }
`;
