import { useMutation } from 'react-query';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from 'data/firebase';

import { useAppSelector } from 'state/hooks';
import { useEmployeeCards } from 'contexts';
import dbLabels from 'data/db.labels';
import updateDigitalIds from 'data/adapters/cards/updateDigitalIds';

import { FailToast } from 'components/toasts';
import { IEmployeeCard } from 'types/card.types';

interface IMutateArgs {
  id: string;
  isCurrentlyActive?: boolean;
}

interface IArgs {
  onSuccess?: () => void;
}

export default (args?: IArgs) => {
  const { currentCompany } = useAppSelector(({ company }) => company);
  const { refetch } = useEmployeeCards();

  const data = useMutation(
    'card_status',
    async ({ id, isCurrentlyActive }: IMutateArgs) => {
      while (!currentCompany) continue;
      if (!currentCompany.id) throw new Error('Invalid company ID');

      const cardRef = doc(
        firestore,
        dbLabels.company,
        currentCompany.id,
        dbLabels.employeeCards,
        id,
      );

      await updateDoc(cardRef, {
        isActive: !isCurrentlyActive,
        modifiedOn: new Date(),
      });
      const card = await getDoc(cardRef).then((doc) => doc.data() as IEmployeeCard);

      await updateDigitalIds({
        cardInstance: card,
        company: currentCompany,
        reactivate: !isCurrentlyActive,
      });
    },
    {
      onSuccess: async () => {
        await refetch();
        args?.onSuccess && args.onSuccess();
      },
      onError: () => {
        FailToast('Failed to update card status');
      },
    },
  );

  return data;
};
