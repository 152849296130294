import { useMutation } from 'react-query';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { updateCurrentCompany } from 'state/company/CompanyActions';
import updateCardsConfig from 'data/adapters/cards/updateCardsConfig';

import { FailToast } from 'components/toasts';
import { ICardConfig } from 'types/card.types';

interface IArgs {
  onSuccess?: () => void;
}

export default ({ onSuccess }: IArgs) => {
  const { currentCompany } = useAppSelector(({ company }) => company);
  const dispatch = useAppDispatch();

  const mutation = useMutation(
    async (config: ICardConfig) =>
      updateCardsConfig({
        cardConfig: config,
        companyId: currentCompany?.id,
        companyConfig: currentCompany?.configuration,
      }),
    {
      onSuccess: async () => {
        if (onSuccess) await onSuccess();
        dispatch(updateCurrentCompany());
      },
      onError: () => {
        FailToast('Failed to send requests');
      },
    },
  );

  return { updateCardConfig: mutation.mutateAsync, ...mutation };
};
