export interface IRole {
  features: {
    hasCardManagementAccess: {
      canDisable: boolean;
      canEdit: boolean;
      canGenerateCard: boolean;
      canRequestPhoto: boolean;
      canUploadPhoto: boolean;
    };
    hasDashboardAccess: boolean;
    hasEmailTemplatesAccess: boolean;
    hasNotifications: boolean;
    hasPhotoVerification: boolean;
  };
}

export enum AccessRules {
  canDisableCard = 'hasCardManagementAccess.canDisable',
  canEditCard = 'hasCardManagementAccess.canEdit',
  canGenerateCard = 'hasCardManagementAccess.canGenerateCard',
  canRequestPhoto = 'hasCardManagementAccess.canRequestPhoto',
  hasDashboardAccess = 'hasDashboardAccess',
  hasEmailTemplatesAccess = 'hasEmailTemplatesAccess',
  hasNotifications = 'hasNotifications',
  hasPhotoVerification = 'hasPhotoVerification',
  openAccess = 'openAccess',
}
