import { Notifications } from 'components/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PATHS } from 'routes';
import accessRules from 'routes/components/roles-provider/assets/accessRules';
import labels from './assets/labels';

import * as Icons from './components/icons';

import NavLink from './components/nav-link/NavLink';

import { Container, Inner } from './NavOptionsStyles';

interface Props {
  isMenuOpen?: boolean;
}

const NavPanel: React.FC<Props> = ({ isMenuOpen = false }: Props) => {
  const { t } = useTranslation();

  const tCardManagement = t(labels.cardManagement);
  const tCardTracker = t(labels.cardTracker);
  const tDashboard = t(labels.dashboard);
  const tEmailTemplates = t(labels.EmailTemplates);
  const tNotifications = t(labels.Notifications);
  const tPhotoVerification = t(labels.PhotoVerification);

  return (
    <Container isMenuOpen={isMenuOpen}>
      <Inner>
        <NavLink
          iconComponent={Icons.Dashboard}
          isMenuOpen={isMenuOpen}
          label={tDashboard}
          accessRules={accessRules.dashboard}
          to={PATHS.dashboard}
        />
        <NavLink
          iconComponent={Icons.CardManagement}
          isMenuOpen={isMenuOpen}
          label={tCardManagement}
          accessRules={accessRules.cardManagement}
          to={PATHS.cardManagement}
        />
        <NavLink
          iconComponent={Icons.CardTracker}
          isMenuOpen={isMenuOpen}
          label={tCardTracker}
          accessRules={accessRules.cardManagement}
          to={PATHS.cardTracker}
        />
        <NavLink
          iconComponent={Icons.PhotoVerification}
          isMenuOpen={isMenuOpen}
          label={tPhotoVerification}
          accessRules={accessRules.photoVerification}
          to={PATHS.adminPhotoVerification}
        />
        <NavLink
          iconComponent={Notifications}
          isMenuOpen={isMenuOpen}
          label={tNotifications}
          accessRules={accessRules.notifications}
          to={PATHS.notifications}
        />
        <NavLink
          iconComponent={Icons.EmailTemplate}
          isMenuOpen={isMenuOpen}
          label={tEmailTemplates}
          accessRules={accessRules.emailTemplates}
          to={PATHS.emailTemplates}
        />
      </Inner>
    </Container>
  );
};

export default NavPanel;
