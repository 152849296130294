import { Reducer } from 'redux';
import { IUser } from 'types/user.types';

import * as Actions from './CreateAccountActions.types';

export interface State {
  error?: string;
  isExpired?: boolean;
  isLoading: boolean;
  token?: string;
  userData?: IUser;
}

export const initialState: State = {
  isLoading: false,
};

const CreateAccountReducer: Reducer<State, Actions.PhotoVerificationActionTypes> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case Actions.ACC__SET_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    case Actions.ACC__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    case Actions.ACC__SET_TOKEN: {
      return {
        ...state,
        token: action.token,
      };
    }

    case Actions.ACC__SET_USER_DATA: {
      return {
        ...state,
        userData: action.data,
      };
    }

    default:
      return state;
  }
};

export default CreateAccountReducer;
