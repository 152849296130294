import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  height: 20px;
  display: flex;
  flex-direction: column;

  &:hover {
    height: 28px;
  }

  transition: height 0.1s ease-in-out;
`;

export const BarContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const Bar = styled.div`
  width: 26px;
  height: 4px;
  border-radius: 999px;
  background-color: ${({ theme }) => theme.primaryBlack};
`;
