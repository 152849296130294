import { useEffect, useState } from 'react';

export default function useThrottle<T>(
  value: T,
  callback?: (v: T) => void,
  interval = 500,
): T {
  const [isFirstRender, SetIsFirstRender] = useState(true);
  const [throttledValue, setThrottledValue] = useState<T>(value);

  useEffect(() => {
    if (isFirstRender) return;

    const delayDebounceFn = setTimeout(() => {
      callback && callback(value);
      setThrottledValue(value);
    }, interval);

    return () => clearTimeout(delayDebounceFn);
  }, [value, interval]);

  useEffect(() => {
    SetIsFirstRender(false);
  }, []);

  return throttledValue;
}
