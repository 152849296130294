import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Column, useFilters, usePagination, useSortBy, useTable } from 'react-table';

import { Row } from '../components/row';

import { ITableProps } from '../types';
import { useColumns } from './useColumns';

type hook = typeof useSortBy | typeof usePagination | typeof useFilters;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
export default <T extends Object = any>({
  canSelect = false,
  columns: inputColumns,
  columnsSpans,
  data,
  defaultSortBy,
  filters = [],
  hiddenColumns = [],
  isLoading = false,
  onChange = () => null,
  onPageSizeChange,
  options,
  placeholder = undefined,
  prepareRow: _prepareRow = (row) => row,
  selectProps,
}: ITableProps<T>) => {
  const [isSorting, setIsSorting] = useState(false);
  const onToggleSort = useCallback(() => setIsSorting(true), []);

  const allColumns = useMemo<Column[]>(
    () =>
      useColumns({
        columns: inputColumns,
        canSelect,
        ...(selectProps || {}),
      }),
    [inputColumns, selectProps],
  );

  const hooks: hook[] = [];
  if (options?.useFilters) hooks.push(useFilters);
  if (defaultSortBy) hooks.push(useSortBy);
  if (options?.usePagination) hooks.push(usePagination);
  const sortee = useMemo(
    () => (defaultSortBy ? [{ id: defaultSortBy, desc: true }] : []),
    [defaultSortBy],
  );

  const tableInstance = useTable(
    {
      columns: allColumns,
      data,
      disableSortRemove: true,
      initialState: {
        sortBy: sortee,
        pageSize: options?.pageSize || 5,
        pageIndex: options?.pageIndex || 0,
        hiddenColumns,
        filters,
      },
      pageCount: options?.pageCount || -1,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetFilters: false,
      autoResetRowState: false,
      disableMultiSort: true,
    },
    ...hooks,
  );

  const {
    canNextPage,
    canPreviousPage,
    columns,
    filteredRows,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    page,
    pageCount,
    prepareRow,
    previousPage,
    rows,
    setFilter,
    setPageSize,
    state,
  } = tableInstance;
  const { pageIndex, sortBy } = state;

  useEffect(() => {
    setIsSorting(false);
  }, [sortBy]);

  useEffect(() => {
    onChange(tableInstance);
  }, [tableInstance.state]);

  useEffect(() => {
    gotoPage && gotoPage(0);
  }, [data.length, (data[0] as any)?.id]);

  useEffect(() => {
    options?.pageSize && setPageSize(options.pageSize);
  }, [options?.pageSize]);

  const renderRows = useCallback(() => {
    const dataRows = options?.usePagination ? page : rows;

    if (isLoading || isSorting || !dataRows.length) {
      return <Row loading={isLoading || isSorting} placeholder={placeholder} />;
    }

    return (
      <Fragment>
        {dataRows.map((r, index) => {
          const row = _prepareRow(r);
          prepareRow(row);

          return (
            <Row
              checkboxCol={canSelect && !!selectProps?.onSelect}
              columnsSpans={columnsSpans}
              key={`${index} - ${
                (row.original as any)?.id ? (row.original as any)?.id : row.id
              }`}
              row={row}
            />
          );
        })}
      </Fragment>
    );
  }, [
    canSelect,
    columnsSpans,
    isLoading,
    isSorting,
    options?.usePagination,
    page,
    placeholder,
    rows,
    selectProps?.onSelect,
  ]);

  const getPaginationProps = useCallback(
    (extraItems: JSX.Element[] = []) => {
      return {
        canNextPage: options?.canNextPage || canNextPage,
        canPreviousPage: options?.canPreviousPage || canPreviousPage,
        extraItems: extraItems,
        fetchNextPage: options?.fetchNextPage || options?.fetchNextPage,
        gotoPage: options?.gotoPage || gotoPage,
        loading: options?.loading || isLoading,
        onPageSizeChange: onPageSizeChange,
        pageCount: options?.pageCount || pageCount,
        pageIndex: options?.pageIndex || pageIndex,
        pageSize: options?.pageSize || options?.pageSize,
        previousPage: options?.previousPage || previousPage,
        data,
        selectedRows: selectProps?.selectedRows,
        showSelectedNumber: canSelect,
      };
    },
    [
      canNextPage,
      canPreviousPage,
      gotoPage,
      isLoading,
      onPageSizeChange,
      options,
      pageCount,
      pageIndex,
      previousPage,
    ],
  );

  return {
    columns,
    filteredRows,
    getPaginationProps,
    getTableBodyProps: useCallback(getTableBodyProps, [
      getTableBodyProps().toString(),
    ]),
    getTableProps: useCallback(getTableProps, [getTableProps().toString()]),
    headerGroups,
    isSorting,
    onToggleSort,
    pageIndex,
    prepareRow,
    renderRows,
    rows: options?.usePagination ? page : rows,
    setFilter,
    usePagination: !!options?.usePagination,
    state,
  };
};
