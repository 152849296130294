import React from 'react';
import { setCurrentTemplate } from 'state/email-templates/EmailTemplatesActions';
import { useAppDispatch, useAppSelector } from 'state/hooks';

import { ITemplate } from 'types/emailTemplates.types';
import { Container } from './TemplateRowStyles';

interface Props {
  template: ITemplate;
}

export const TemplateRow: React.FC<Props> = ({ template }: Props) => {
  const Dispatch = useAppDispatch();
  const onSelect = () => Dispatch(setCurrentTemplate(template.id));

  const { currentTemplateId } = useAppSelector(({ templates }) => templates);

  return (
    <Container isSelected={currentTemplateId === template.id} onClick={onSelect}>
      {template.name}
      <i className="fas fa-angle-right" />
    </Container>
  );
};
