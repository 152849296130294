import styled from 'styled-components';

interface IInput {
  error?: string;
  hasLeadingElement?: boolean;
  hasTrailingElement?: boolean;
  success?: boolean;
}

export const Input = styled.input<IInput>`
  margin: 0px;
  padding: 20px;
  flex-grow: 1;
  background-color: white;
  border-radius: 60px;
  font-family: ${({ theme }) => theme.secondaryFont};
  font-size: 16px;
  line-height: 25px;
  color: ${({ theme }) => theme.primaryBlack};
  outline: none;
  max-height: 60px;

  ${({ theme, error, success }) =>
    error
      ? `
        border-top: 1px solid  ${theme.colorDanger};
        border-bottom: 1px solid  ${theme.colorDanger};
        border-left: none;
        border-right: none;
      `
      : success
      ? `
        border-top: 1px solid  ${theme.primaryGreen};
        border-bottom: 1px solid  ${theme.primaryGreen};
        border-left: none;
        border-right: none;`
      : `border: 2px solid ${theme.backgroundColor};`}

  ${({ hasTrailingElement }) =>
    hasTrailingElement &&
    `
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-right: 0;
  `}

  ${({ hasLeadingElement }) =>
    hasLeadingElement &&
    `
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-left: 0;
  `}

  &::placeholder {
    color: ${({ theme }) => theme.primaryBlack};
  }

  &:disabled {
    background-color: #fff5;
    cursor: not-allowed;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &:-internal-autofill-previewed,
  &:-internal-autofill-selected {
    background-color: white !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    max-height: 50px;
    font-size: 11px;
  }
`;

export const ErrorMessage = styled.div`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-size: 14px;
  color: ${({ theme }) => theme.colorDanger};

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-size: 15px;
  line-height: 18px;
  font-weight: ${({ theme }) => theme.weightBold};
  color: ${({ theme }) => theme.secondaryBlack};

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 15px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
`;
