import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useEmployeeCards } from 'contexts';
import { useAppSelector } from 'state/hooks';
import { formatName } from 'utils/user.utils';
import useDimensions from 'hooks/useDimensions';
import useGenerateIds from 'data/data-hooks/mutations/card/useGenerateIds';

import { Card, SendCard } from 'components/icons';
import { CardTrackerSuccessToast } from 'components/toasts/card-tracker-toasts';
import { ConfirmDenyModal, Prompt } from 'components/modals';

import { Bold } from '../../card-management/components/card-updates/components/action-modals/SharedStyles';
import { IconButtonWrapper } from '../sharedStyles';

interface Props {
  children: (props: { handleOpen: () => void; isLoading: boolean }) => JSX.Element;
  onSuccess?: () => void;
  selectedCardsIds: string | string[];
}

export const GenerateCardModal: React.FC<Props> = ({
  children,
  onSuccess,
  selectedCardsIds: _selectedCardsIds,
}: Props) => {
  const selectedCardsIds = Array.isArray(_selectedCardsIds)
    ? _selectedCardsIds
    : [_selectedCardsIds];
  const count = selectedCardsIds.length;

  const [shouldShow, setShouldShow] = useState(false);
  const { isInMobile } = useDimensions();
  const navigate = useNavigate();

  const handleOpen = () => setShouldShow(true);
  const handleClose = () => setShouldShow(false);
  const { currentCompany } = useAppSelector(({ company }) => company);
  const { employeeCards = [] } = useEmployeeCards();

  const { sendCardId, isGeneratingCard } = useGenerateIds({
    onSuccess: () => {
      CardTrackerSuccessToast('ID cards submitted for generation', navigate);
      onSuccess && onSuccess();
      handleClose();
    },
  });

  const onConfirm = () => {
    sendCardId({
      cardIds: [...selectedCardsIds],
    });
  };

  const { t } = useTranslation();
  const tGenerateCard = t(`Generate card${count === 1 ? '' : 's'}`);
  const entity = currentCompany?.isSchool ? 'student' : 'employee';
  const tPrompt = t(
    `You are going to generate and send card${count === 1 ? '' : 's'} for`,
  );

  const tUsers = (() => {
    if (count === 1) {
      const employee = employeeCards.find(({ id }) => id === selectedCardsIds[0]);
      if (employee) return `${formatName(employee)}`;
    }
    return t(`${count} ${entity}${count === 1 ? '' : 's'}`);
  })();

  return (
    <Fragment>
      {!isInMobile ? (
        children({ handleOpen, isLoading: isGeneratingCard })
      ) : (
        <IconButtonWrapper onClick={handleOpen} isDisabled={isGeneratingCard}>
          <SendCard />
        </IconButtonWrapper>
      )}

      <ConfirmDenyModal
        cancelLabel="Cancel"
        closeModal={handleClose}
        confirmLabel={tGenerateCard}
        icon={<Card green />}
        isLoading={isGeneratingCard}
        onConfirm={onConfirm}
        onDeny={handleClose}
        prompt={
          <Prompt>
            {tPrompt} <Bold>{tUsers}</Bold>
          </Prompt>
        }
        shouldShow={shouldShow}
        intent="success"
      />
    </Fragment>
  );
};
