import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { useAppDispatch } from 'state/hooks';
import { toggleSelection } from 'state/admin-verification/AdminVerficationActions';
import { PhotoRequestsProvider } from 'contexts/PhotoRequestsContext';

import { PageContentContainer, PageTitle } from 'domains/navigation';
import { PhotoPreview } from './components/photo-preview/PhotoPreview';
import { PhotosStats } from './components/photos-stats/PhotosStats';
import { RequestsTable } from './components/requests-table/RequestsTable';
import { TransitionWrapper } from 'components/transition-wrapper/TransitionWrapper';

import { Inner, TopContainer } from './AdminPhotoVerificationStyles';

export const AdminPhotoVerification: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const tTitle = t('Photo Verification');
  const queryClient = useQueryClient();

  useEffect(() => {
    dispatch(toggleSelection([]));
    async function fetch() {
      await queryClient.refetchQueries(['photo_reqs']);
    }

    fetch();
  }, []);

  return (
    <TransitionWrapper delay={500}>
      <PhotoRequestsProvider>
        <PageContentContainer>
          <PageTitle>{tTitle}</PageTitle>

          <TopContainer>
            <PhotosStats />
          </TopContainer>

          <Inner>
            <RequestsTable />
            <PhotoPreview />
          </Inner>
        </PageContentContainer>
      </PhotoRequestsProvider>
    </TransitionWrapper>
  );
};
