import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`;

export const PaginationContainer = styled.tfoot`
  border-top: 1px solid ${({ theme }) => theme.backgroundColor};
  display: grid;
  grid-template-columns: 1fr;
  justify-self: flex-end;
  padding: 10px 20px 10px;
`;
