import styled from 'styled-components';

export const Inner = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1.1fr 1fr;
  gap: 30px;

  @media (max-width: ${({ theme }) => theme.sizeRanges.tabletLarge.min}px) {
    grid-template-columns: 1fr;
  }
`;

export const Empty = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.lightGrayBg};
`;
