export default {
  audits: 'Audits',
  cards: 'Cards',
  cardTrackers: 'CardTrackers',
  company: 'Company',
  corporate: 'Corporate',
  digiPass: 'DigiPass',
  employeeCards: 'EmployeeCards',
  employees: 'Employees',
  notifications: 'Notifications',
  orgLevels: 'OrgLevels',
  pendingAccount: 'PendingAccount',
  photorequest: 'PhotoRequest',
  role: 'Role',
  settings: 'Settings',
  students: 'Students',
  templates: 'Templates',
  testimonials: '/Corporate/Settings/Testimonials',
  user: 'User',
};
