import React from 'react';
import { Row as RowObject } from 'react-table';
import { Trans, useTranslation } from 'react-i18next';

import { Loader } from 'components/loader';

import { Data, EmptyRow, Message, SubMessage, TableRow } from './RowStyles';

type RowProps = {
  columnsSpans?: string;
  loading?: boolean;
  checkboxCol?: boolean;
  placeholder?: string;
  row?: RowObject;
};

export const Row = React.memo(
  ({ columnsSpans, loading, checkboxCol, placeholder, row }: RowProps) => {
    const { t } = useTranslation();

    const tNoDataText = t('No data yet');

    if (loading || !row)
      return (
        <EmptyRow>
          <Data full={true}>
            {loading ? (
              <Loader size={20} />
            ) : (
              <>
                <Message>{tNoDataText}</Message>
                {placeholder && <SubMessage>{t(placeholder)}</SubMessage>}
              </>
            )}
          </Data>
        </EmptyRow>
      );

    return (
      <TableRow
        {...row.getRowProps()}
        columnsSpans={columnsSpans}
        length={row.cells.length}
        checkboxCol={checkboxCol}
      >
        {row.cells.map((cell, index) => (
          <Data {...cell.getCellProps()} key={`data-${index}`}>
            <Trans>
              {cell.value !== undefined || cell.value !== null
                ? cell.render('Cell')
                : 'N/A'}
            </Trans>
          </Data>
        ))}
      </TableRow>
    );
  },
);
