import { endOfDay, startOfDay, subMonths, subWeeks, subYears } from 'date-fns';
import * as Actions from './CardManagementActions.types';

export const launchCardManagement: Actions.TLaunchCardManagement =
  () => async (dispatch) => {
    await dispatch({
      type: Actions.CARDS__LAUNCH,
    });
  };

export const updateFilter: Actions.TSetDateRange =
  ({ filter, startDate, endDate }) =>
  (dispatch, getState) => {
    const { startDate: defaultStartDate, endDate: defaultEndDate } =
      getState().cardManagement;
    const today = endOfDay(new Date());
    const weekAgo = startOfDay(subWeeks(today, 1));
    const monthAgo = startOfDay(subMonths(today, 1));
    const yearAgo = startOfDay(subYears(today, 1));

    switch (filter) {
      case 'week':
        dispatch({
          filter: 'week',
          startDate: weekAgo,
          endDate: today,
          type: Actions.CARDS__UPDATE_FILTER,
        });
        break;
      case 'month':
        dispatch({
          filter: 'month',
          startDate: monthAgo,
          endDate: today,
          type: Actions.CARDS__UPDATE_FILTER,
        });
        break;
      case 'year':
        dispatch({
          filter: 'year',
          startDate: yearAgo,
          endDate: today,
          type: Actions.CARDS__UPDATE_FILTER,
        });
        break;
      case 'custom':
      case 'all':
      default:
        dispatch({
          filter,
          startDate: startDate || defaultStartDate,
          endDate: endDate || defaultEndDate,
          type: Actions.CARDS__UPDATE_FILTER,
        });
        break;
    }
  };

export const setIsLoading: Actions.TSetIsLoading = (isLoading) => ({
  isLoading,
  type: Actions.CARDS__SET_IS_LOADING,
});

export const setPageSize: Actions.TSetPageSize = (size) => ({
  size,
  type: Actions.CARDS__SET_PAGE_SIZE,
});

export const setPassStatusFilter: Actions.TSetPassStatusFilter = (status) => ({
  status,
  type: Actions.CARDS__SET_PASS_STATUS_FILTER,
});

export const toggleCardSelection: Actions.TToggleCardSelection = (id) => ({
  type: Actions.CARDS__TOGGLE_CARD_SELECTION,
  id,
});

export const clearSelection: Actions.TClearSelection = () => ({
  type: Actions.CARDS__CLEAR_SELECTION,
});

export const toggleSelection: Actions.TToggleSelection = (allCards) => ({
  type: Actions.CARDS__TOGGLE_SELECTION,
  allCards,
});
