import React, { useMemo } from 'react';
import { Navigate, NavigateProps, Outlet } from 'react-router-dom';

import { useAppSelector } from 'state/hooks';
import { parseValue } from 'utils/utils';

import { AccessRules } from 'types/role.types';

interface Props {
  accessRules: AccessRules[] | AccessRules.openAccess;
  children?: React.ReactNode;
  onUnauthorized?: NavigateProps;
}

export const RolesProvider: React.FC<Props> = ({
  accessRules,
  children,
  onUnauthorized,
}: Props) => {
  const { role, isLoadingUser } = useAppSelector(({ auth }) => auth);

  const hasPermission = useMemo(() => {
    if (accessRules === AccessRules.openAccess) return true;
    if (!role) return false;
    for (const rule of accessRules) {
      const value = parseValue(role.features, rule);
      if (value) return true;
    }
    return false;
  }, [role]);

  if (!isLoadingUser) {
    if (hasPermission) {
      return <>{children ? children : <Outlet />}</>;
    }

    if (onUnauthorized) {
      return <Navigate {...onUnauthorized} />;
    }
  }

  return null;
};
