export interface IPhotoRequest {
  companyId: string;
  createdOn: Date;
  employeeId: string;
  cardId?: string;
  expirationDate?: Date;
  id: string;
  modifiedOn: Date;
  photoUrl: string;
  status: EPhotoRequestStatus;
  token: string;
}

export interface ICompanyPhotoRequest extends IPhotoRequest {
  emailAddress: string;
  employeeNumber?: string;
  firstName: string;
  lastName: string;
}

export enum EPhotoRequestStatus {
  accepted = 'accepted',
  expired = 'expired',
  pending = 'pending',
  rejected = 'rejected',
  uploaded = 'uploaded',
}
