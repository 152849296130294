import styled from 'styled-components';

export const Container = styled.div`
  grid-column: 1 / span 2;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10%;
`;
