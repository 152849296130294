import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { IUser } from 'types/user.types';

export const ACC__SET_ERROR = 'ACC__SET_ERROR';
export const ACC__SET_IS_LOADING = 'ACC__SET_IS_LOADING';
export const ACC__SET_TOKEN = 'ACC__SET_TOKEN';
export const ACC__SET_USER_DATA = 'ACC__SET_USER_DATA';

interface SetErrorAction {
  type: typeof ACC__SET_ERROR;
  error: string;
}

interface SetIsLoadingAction {
  type: typeof ACC__SET_IS_LOADING;
  isLoading: boolean;
}

interface SetTokenAction {
  type: typeof ACC__SET_TOKEN;
  token: string;
}

interface VerifyTokenAction {
  type: typeof ACC__SET_USER_DATA;
  data: IUser;
}

export type PhotoVerificationActionTypes =
  | SetErrorAction
  | SetIsLoadingAction
  | SetTokenAction
  | VerifyTokenAction;

export type TLaunchCreateAccount = (
  token: string,
) => ThunkAction<void, RootState, unknown, PhotoVerificationActionTypes>;

export type TSetToken = (
  token: string,
) => ThunkAction<Promise<void>, RootState, unknown, PhotoVerificationActionTypes>;

export type TSetIsLoading = (isLoading: boolean) => SetIsLoadingAction;

export type TCreateAccount = (
  password: string,
) => ThunkAction<
  Promise<{ success: boolean }>,
  RootState,
  unknown,
  PhotoVerificationActionTypes
>;
