import React from 'react';

import { formatName } from 'utils/user.utils';
import { useAppSelector } from 'state/hooks';

import { AvatarCircle } from 'components/avatar-circle/AvatarCircle';
import { Modal } from 'components/modals/modal';
import { UpdatePasswordForm } from './components/update-password-form/UpdatePasswordForm';

import {
  ContactDetails,
  EmailAddress,
  Header,
  Inner,
  Line,
} from './ProfilePageModalStyles';

interface Props {
  shouldShow: boolean;
  handleClose: () => void;
}

export const ProfilePageModal: React.FC<Props> = ({
  shouldShow,
  handleClose,
}: Props) => {
  const { user } = useAppSelector(({ auth }) => auth);

  if (!user) return null;

  return (
    <Modal width={'600px'} dark shouldShow={shouldShow} handleClose={handleClose}>
      <Inner>
        <Header>
          <AvatarCircle
            size={100}
            photoUrl={user?.photoUrl}
            name={formatName(user)}
          />
          <ContactDetails>
            <h1>{formatName(user)}</h1>
            <EmailAddress>{user.emailAddress}</EmailAddress>
          </ContactDetails>
        </Header>

        <Line />

        <UpdatePasswordForm handleClose={handleClose} shouldShow={shouldShow} />
      </Inner>
    </Modal>
  );
};
