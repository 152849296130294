import React, { createContext, useContext, useMemo } from 'react';
import { RefetchOptions, RefetchQueryFilters } from 'react-query';
import { addMinutes, isBefore } from 'date-fns';

import { checkPhoto } from 'utils/photo.utils';
import { useAppSelector } from 'state/hooks';
import { useEmployeeCards } from './EmployeeCardsContext';
import useGetCardsGenerations from 'data/data-hooks/queries/cards/useGetCardsGenerations';

import { EGenerationStatus, ICardGeneration } from 'types/card.types';

const CardTrackingContext = createContext<{
  error: string | null;
  generatedCards: ICardGeneration[];
  inQueue: ICardGeneration[];
  isLoading: boolean;
  refetch: (
    options?: (RefetchOptions & RefetchQueryFilters<unknown>) | undefined,
  ) => Promise<unknown>;
}>({
  error: null,
  generatedCards: [],
  inQueue: [],
  isLoading: false,
  refetch: async () => null,
});

export const useCardTracker = () => {
  return useContext(CardTrackingContext);
};

export const CardTrackingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { data, error, isLoading, refetch } = useGetCardsGenerations();
  const { employeeCards } = useEmployeeCards();
  const currentCompany = useAppSelector(({ company }) => company.currentCompany);

  const generatedCards = useMemo(
    () =>
      data.map((card) => {
        const { id, status, modifiedOn, PersonId } = card;
        const result = { ...card };

        if (
          (status === EGenerationStatus.pending ||
            status === EGenerationStatus.sendingEmail) &&
          isBefore(addMinutes(modifiedOn, 5), new Date())
        ) {
          result['status'] =
            status === EGenerationStatus.pending
              ? EGenerationStatus.failedToGenerate
              : EGenerationStatus.failedToEmail;
        }

        const employee = employeeCards.find((emp) =>
          currentCompany?.isSchool
            ? emp.employeeNumber === PersonId
            : emp.employeeId === PersonId,
        );

        if (employee) {
          if (
            employee.currentCardInstance?.id !== id &&
            result['status'] !== EGenerationStatus.pending &&
            result['status'] !== EGenerationStatus.sendingEmail
          )
            result['passStatus'] = 'invalid';
          else if (employee.isPassDownloaded) result['passStatus'] = 'downloaded';
          else if (employee.isPassDeleted) result['passStatus'] = 'deleted';
          else if (result['status'] === EGenerationStatus.completed)
            result['passStatus'] = 'received';
        }

        result['PersonPhotoUrl'] = checkPhoto(employee?.photoUrl);
        return result;
      }),
    [employeeCards, data],
  );

  const inQueue = useMemo(
    () =>
      generatedCards.filter(
        ({ status }) =>
          status === EGenerationStatus.pending ||
          status === EGenerationStatus.sendingEmail,
      ),
    [data],
  );

  return (
    <CardTrackingContext.Provider
      value={{
        error,
        generatedCards,
        inQueue,
        isLoading: isLoading || (!!data.length && !generatedCards.length),
        refetch,
      }}
    >
      {children}
    </CardTrackingContext.Provider>
  );
};
