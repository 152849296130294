import React from 'react';
import { Theme } from 'assets/styles';

export const Show: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ cursor: 'pointer' }}
  >
    <g clipPath="url(#clip0_96_590)">
      <path
        d="M9.34203 18.7821L7.41103 18.2641L8.19803 15.3251C7.01999 14.8906 5.92514 14.2575 4.96103 13.4531L2.80803 15.6071L1.39303 14.1921L3.54703 12.0391C2.3311 10.5829 1.51411 8.83587 1.17603 6.96911L3.14403 6.61011C3.90303 10.8121 7.57903 14.0001 12 14.0001C16.42 14.0001 20.097 10.8121 20.856 6.61011L22.824 6.96811C22.4864 8.83512 21.6697 10.5825 20.454 12.0391L22.607 14.1921L21.192 15.6071L19.039 13.4531C18.0749 14.2575 16.9801 14.8906 15.802 15.3251L16.589 18.2651L14.658 18.7821L13.87 15.8421C12.6324 16.0542 11.3677 16.0542 10.13 15.8421L9.34203 18.7821Z"
        fill={Theme.primaryBlack}
      />
    </g>
    <defs>
      <clipPath id="clip0_96_590">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
