import React from 'react';
import { Theme } from 'assets/styles';

export const RequestPhoto: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 1 511 450"
      enableBackground="new 0 1 511 511"
    >
      <g>
        <path
          fill={Theme.secondaryBlack}
          d="M205.6,146.9c-22.2,0-40.2,18-40.2,40.2c0,22.2,18,40.2,40.2,40.2c22.2,0,40.2-18,40.2-40.2   C245.9,165,227.8,146.9,205.6,146.9z M205.6,206.6c-10.7,0-19.3-8.7-19.3-19.3c0-10.7,8.7-19.3,19.3-19.3   c10.6,0,19.3,8.7,19.3,19.3C225,197.9,216.3,206.6,205.6,206.6z"
        />
        <path
          fill={Theme.secondaryBlack}
          d="M406.6,178V70.3c0-5.8-4.2-10.1-10-10.1H10.6C4.8,60.3,0,64.6,0,70.3v304.9c0,5.8,4.8,10.9,10.6,10.9h242   c24.5,39.8,68.7,66.7,119.1,66.7c77,0,139.4-62.8,139.4-139.7C511,248.4,467.4,193.7,406.6,178z M21,365.2v-284h364.7v92.9   c-4.2-0.5-9.1-0.7-14-0.7c-0.3,0-0.6,0-0.6,0v-66.4c0-5.8-5-10-10.9-10H47.2c-5.8,0-10.5,4.2-10.5,10v231.8   c0,5.8,4.7,10.8,10.5,10.8h189.7c1.5,5.2,3.1,10.5,5.2,15.7H21z M232.9,328.6H57.6v-40.4l61.5-54.3c2.8-2.5,7-2.6,10-0.2l63.3,50.6   c10.7,8.6,26,8.4,36.5-0.5l7.6-6.5c-3,11.4-4.6,23.6-4.6,35.8C232,318.3,232.3,323.3,232.9,328.6z M215.6,267.8   c-2.8,2.4-7,2.4-10,0.1l-63.4-50.6c-10.9-8.7-26.5-8.4-37,0.9l-47.6,42V117.9H350v57.2c-37.7,6-70.1,26.8-91.5,56.4L215.6,267.8z    M371.6,431.7C306.2,431.7,253,378.4,253,313s53.2-118.6,118.6-118.6c65.4,0,118.6,53.2,118.6,118.6   C490.4,378.4,437.1,431.7,371.6,431.7z"
        />
        <path
          fill={Theme.secondaryBlack}
          d="M459,243.7h-174c-5.8,0-10.5,4.7-10.5,10.5v117.4c0,5.8,4.7,10.5,10.5,10.5h174c5.8,0,10.5-4.7,10.5-10.5V254.1   C469.5,248.4,464.8,243.7,459,243.7z M432.1,264.6l-59.6,52.7L313,264.6H432.1z M295.5,277.4l30,26.6l-30,37.6V277.4z M307.2,361   l34.5-43.2l23.9,21.3c2,1.8,4.5,2.6,6.9,2.6c2.5,0,4.9-0.8,6.9-2.6l23.6-21l34.7,42.9H307.2z M449.6,342l-30.5-37.6l30.5-27V342z"
        />
      </g>
    </svg>
  );
};
