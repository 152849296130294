import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import AvatarEditor from 'react-avatar-editor';

import { formatName } from 'utils/user.utils';
import { checkPhoto } from 'utils/photo.utils';
import useGetUser from 'data/data-hooks/queries/user/useGetUser';

import { AvatarCircle } from 'components/avatar-circle/AvatarCircle';
import { PhotoPicker } from 'components/photo-picker/PhotoPicker';
import { SubmitButton } from 'components/buttons';
import Modal from 'components/modals';

import { IEmployee } from 'types/employee.types';
import { UserRolesEnum } from 'types/user.types';
import {
  Container,
  IconContainer,
  UploadButton,
  FileField,
  ModalInner,
} from './AvatarContainerStyles';

interface Props {
  employee: IEmployee;

  canUpdatePhoto: boolean;
  closeEditingModal: () => void;
  confirmPhoto: () => void;
  handleUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isUploading: boolean;
  photo?: string;
  setEditorRef: (editor: AvatarEditor | null) => AvatarEditor | null;
  showEditingModal: boolean;
}

export const AvatarContainer: React.FC<Props> = ({
  employee,
  canUpdatePhoto,
  closeEditingModal,
  confirmPhoto,
  handleUpload,
  isUploading,
  photo,
  setEditorRef,
  showEditingModal,
}: Props) => {
  const { t } = useTranslation();
  const tUpload = t('Update Photo');

  const { user } = useGetUser({ emailAddress: employee.emailAddress });

  return (
    <Container
      isAdmin={user?.role === UserRolesEnum.admin}
      isHR={user?.role === UserRolesEnum.HRAdmin}
    >
      <AvatarCircle
        size={125}
        photoUrl={checkPhoto(employee.photoUrl)}
        name={employee ? formatName(employee) : ''}
      />

      <IconContainer
        isAdmin={user?.role === UserRolesEnum.admin}
        isHR={user?.role === UserRolesEnum.HRAdmin}
      >
        <i className="fas fa-user-tie" />
      </IconContainer>

      {!!canUpdatePhoto && (
        <Fragment>
          <UploadButton>
            <FileField className="custom-file-upload">
              <input
                type="file"
                onChange={handleUpload}
                accept="image/png, image/jpeg, image/jpg"
              />
              <i className="fas fa-upload" />
            </FileField>
          </UploadButton>

          <Modal handleClose={closeEditingModal} shouldShow={showEditingModal}>
            <ModalInner>
              <PhotoPicker
                setEditorRef={setEditorRef}
                photoUrl={photo}
              ></PhotoPicker>

              <SubmitButton isLoading={isUploading} onClick={confirmPhoto}>
                {tUpload}
              </SubmitButton>
            </ModalInner>
          </Modal>
        </Fragment>
      )}
    </Container>
  );
};
