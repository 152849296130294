import React from 'react';

export const CardManagement: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_322_228)">
        <path d="M5.83333 5.00001V2.50001C5.83333 2.27899 5.92113 2.06703 6.07741 1.91075C6.23369 1.75447 6.44565 1.66667 6.66667 1.66667H16.6667C16.8877 1.66667 17.0996 1.75447 17.2559 1.91075C17.4122 2.06703 17.5 2.27899 17.5 2.50001V14.1667C17.5 14.3877 17.4122 14.5996 17.2559 14.7559C17.0996 14.9122 16.8877 15 16.6667 15H14.1667V17.5C14.1667 17.96 13.7917 18.3333 13.3275 18.3333H3.33917C3.22927 18.334 3.12033 18.3129 3.0186 18.2714C2.91687 18.2298 2.82436 18.1685 2.74638 18.0911C2.6684 18.0137 2.60649 17.9216 2.56421 17.8201C2.52193 17.7187 2.50011 17.6099 2.5 17.5L2.5025 5.83334C2.5025 5.37334 2.8775 5.00001 3.34167 5.00001H5.83333ZM7.5 5.00001H14.1667V13.3333H15.8333V3.33334H7.5V5.00001ZM5.83333 9.16667V10.8333H10.8333V9.16667H5.83333ZM5.83333 12.5V14.1667H10.8333V12.5H5.83333Z" />
      </g>
      <defs>
        <clipPath id="clip0_322_228">
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  );
};
