import styled from 'styled-components';

interface IContainer {
  isMenuOpen: boolean;
}
interface IInner {
  isOnTop?: boolean;
}

export const Container = styled.div<IContainer>`
  background-color: transparent;
`;

export const Inner = styled.div<IInner>`
  display: flex;
  gap: 30px;
  flex-direction: column;
  transition: margin 0.2s ease-in-out;
`;
