import React from 'react';
import { AvatarCircle } from 'components/avatar-circle/AvatarCircle';
import { RatingStar } from '../rating-star/RatingStar';

import {
  Body,
  Company,
  Container,
  Footer,
  Name,
  Rate,
  Review,
  Text,
} from './TestimonialSlideStyle';
import { ITestimonial } from 'types/testimonial.types';

export const TestimonialSlide: React.FC<ITestimonial> = ({
  companyName,
  firstName,
  id,
  lastName,
  notes,
  photoUrl,
  rating,
}: ITestimonial) => {
  const name = `${firstName} ${lastName}`;
  return (
    <Container>
      <AvatarCircle size={70} photoUrl={photoUrl} name={name} />

      <Body>
        <Review>
          <Rate>
            {[1, 2, 3, 4, 5].map((value, i) => (
              <RatingStar key={`star-${id}${i}`} full={value <= rating} />
            ))}
          </Rate>

          <Text>{notes}</Text>
        </Review>

        <Footer>
          <Name>{name}</Name>
          <Company>{companyName}</Company>
        </Footer>
      </Body>
    </Container>
  );
};
