import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {
  startOfToday,
  endOfToday,
  format,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  sub,
  isSameDay,
  isSameMonth,
  isThisYear,
} from 'date-fns';
import getPastNYearsRanges from './utils/getPastNYearsRanges';

import {
  Container,
  InnerTop,
  DateSelectDiv,
  DateRange,
  DatePickerStyles,
  MoreDatePickerStyles,
} from './DatePickerStyles';

interface IPicker {
  endDate: {
    toDate: () => Date;
  };
  startDate: {
    toDate: () => Date;
  };
}

interface IDate {
  endDate: Date;
  startDate: Date;
}

interface Props {
  children?: JSX.Element;
  endDate?: Date;
  onDateChange: (date: IDate) => void;
  startDate?: Date;
}

const DatePicker: React.FC<Props> = ({
  endDate,
  onDateChange,
  startDate,
}: Props) => {
  const now = new Date();
  const oneMonthAgo = sub(now, { months: 1 });
  const oneWeekAgo = sub(now, { weeks: 1 });

  const datePickerSettings = {
    startDate,
    endDate,
    locale: {
      format: 'ddd, MMM DD, YYYY',
    },
    ranges: {
      Today: [startOfToday(), endOfToday()],
      ['This Week']: [startOfWeek(now), endOfWeek(now)],
      ['This Month']: [startOfMonth(now), endOfMonth(now)],
      ['Last Week']: [startOfWeek(oneWeekAgo), endOfWeek(oneWeekAgo)],
      ['Last Month']: [startOfMonth(oneMonthAgo), endOfMonth(oneMonthAgo)],
      ...getPastNYearsRanges(3),
    },
  };

  const handleDateRangeChange = (selectedDate: [Date, Date]) => {
    onDateChange({
      startDate: startOfDay(selectedDate[0]),
      endDate: endOfDay(selectedDate[1]),
    });
  };

  const onApply = (_: unknown, picker: IPicker) => {
    const startDate = picker.startDate.toDate() as Date;
    const endDate = picker.endDate.toDate() as Date;
    handleDateRangeChange([startDate, endDate]);
  };

  let formattedDateRange = '';

  if (!startDate || !endDate) {
    formattedDateRange = 'Pick a date';
  } else {
    const isStartDateThisYear = isThisYear(startDate);
    const isEndDateThisYear = isThisYear(endDate);
    const showYears = !isStartDateThisYear || !isEndDateThisYear;

    if (isSameMonth(startDate, endDate)) {
      const formattedStartDate = format(
        startDate,
        `MMM do${showYears ? ' ’yy' : ''}`,
      );

      if (isSameDay(startDate, endDate)) {
        formattedDateRange = `${formattedStartDate}`;
      } else {
        const formattedEndDate = format(endDate, `MMM do${showYears ? ' ’yy' : ''}`);

        formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
      }
    } else {
      const formattedStartDate = format(startDate, `MMMM${showYears ? ' ’yy' : ''}`);
      const formattedEndDate = format(endDate, `MMMM${showYears ? ' ’yy' : ''}`);

      formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
    }
  }

  return (
    <Container>
      <DatePickerStyles />
      <MoreDatePickerStyles />
      <InnerTop>
        {/* @ts-expect-error ignore roperty 'children' does not exist on DateRangePicker */}
        <DateRangePicker initialSettings={datePickerSettings} onApply={onApply}>
          <DateSelectDiv>
            <i className="far fa-calendar" />
            <DateRange>{formattedDateRange}</DateRange>
          </DateSelectDiv>
        </DateRangePicker>
      </InnerTop>
    </Container>
  );
};

export default DatePicker;
