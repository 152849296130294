import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getPerformance } from 'firebase/performance';
import {
  getFunctions,
  httpsCallable,
  HttpsCallableOptions,
} from 'firebase/functions';

const hostname = window.location.hostname;
const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const appId = process.env.REACT_APP_FIREBASE_APP_ID;
const measurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;

export const ENVs = {
  development: process.env.NODE_ENV === 'development',
  local: hostname.includes('localhost'),
  staging: hostname.includes('sandbox'),
  demo: hostname.includes('demo'),
  production: !hostname.includes('sandbox'),
};

const firebaseConfigSandbox = {
  apiKey,
  authDomain: 'digiprnt-sandbox.firebaseapp.com',
  databaseURL: 'https://digiprnt-sandbox.firebaseio.com',
  projectId: 'digiprnt-sandbox',
  storageBucket: 'digiprnt-sandbox.appspot.com',
  messagingSenderId: '539324176661',
  appId,
  measurementId,
};

const firebaseConfigProduction = {
  apiKey,
  authDomain: 'digiprint-prod.firebaseapp.com',
  databaseURL: 'https://digiprint-prod.firebaseio.com',
  projectId: 'digiprint-prod',
  storageBucket: 'digiprint-prod.appspot.com',
  messagingSenderId: '1093863575877',
  appId,
  measurementId,
};

export const HOSTS = {
  development: firebaseConfigSandbox,
  local: firebaseConfigSandbox,
  staging: firebaseConfigSandbox,
  demo: firebaseConfigSandbox,
  production: firebaseConfigProduction,
};

const hostIndex = Math.min(Object.values(ENVs).findIndex((isStage) => isStage));
export const ENVIRONMENT_NAME = Object.keys(HOSTS)[hostIndex];
export const HOST = Object.values(HOSTS)[hostIndex] || {};

const app = initializeApp(HOST);

const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);
const performance = getPerformance(app);

const _httpsCallable = (name: string, options?: HttpsCallableOptions) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  httpsCallable<unknown, { success?: boolean; results?: any }>(
    functions,
    name,
    options,
  );

// active host's storage bucket will be used to fetch files from Storage, since we store only the file path for sensitive files and don't want to expose a public URL using getDownloadURL()
const storageBucket = Object.values(HOSTS)?.[hostIndex]?.storageBucket;

export {
  auth,
  firestore,
  functions,
  storage,
  storageBucket,
  performance,
  _httpsCallable as httpsCallable,
};
