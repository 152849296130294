import styled from 'styled-components';

interface IInner {
  isVisible: boolean;
}

export const Inner = styled.div<IInner>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

export const Page = styled.div`
  min-height: 100vh; // used to keep footer at the bottom
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgroundColor};
`;

export const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
