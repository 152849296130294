import styled from 'styled-components';

interface IBtn {
  isDisabled?: boolean;
}

export const IconButtonWrapper = styled.div<IBtn>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  border: 1px solid ${({ theme }) => theme.secondaryBlack};
  border-radius: 60px;
  padding: 3px 17px;
  height: 25px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
