import styled from 'styled-components';

interface IColor {
  fill: 'violet' | 'green';
}

export const Container = styled.div`
  background-color: ${({ theme }) => theme.primaryBlack};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0px;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 15px;
  line-height: 15px;
  color: white;
`;

export const ChartKeys = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

export const Key = styled.div`
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 12px;
  line-height: 12px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const Color = styled.div<IColor>`
  width: 15px;
  height: 15px;
  border-radius: 4px;
  background-color: ${({ theme, fill }) =>
    fill === 'green' ? theme.primaryGreen : theme.secondaryViolet};
`;
