import React, { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from 'state/hooks';
import useDimensions from 'hooks/useDimensions';

import { CardsControlBar } from './components/cards-table-control-bar/cardsControlBar';
import { PaginationRow } from 'components/pagination';
import { TableHeader } from 'components/table';
import { Title } from './components/Title/Title';
import {
  TableBody,
  TableContent,
  TableWrapper,
  useAppTable,
} from 'components/table';

import { IDateRange } from 'types/shared.types';
import { ITableProps, TIstFilterActive } from 'components/table/types';
import { Container, PaginationContainer } from './CardsTableStyles';

interface Props extends ITableProps {
  headerProps: ITableProps['headerProps'] & {
    startDate: Date;
    endDate: Date;
    setCustomDateRange: ({ startDate, endDate }: IDateRange) => void;
  };
}

export const CardsTable: React.FC<Props> = ({ headerProps, ...props }: Props) => {
  const [colSpans, setColSpans] = useState<string>();
  const [isFilterActive, setIsFilterActive] = useState<TIstFilterActive>({
    hireDate: false,
  });
  const { dimensions, isInDesktop, isInLargeTablet, isInMobile, isInTablet } =
    useDimensions();
  const { pageSize } = useAppSelector(({ cardManagement }) => cardManagement);

  useEffect(() => {
    if (isInDesktop) setColSpans('50px 1.6fr 1.2fr 1.3fr 0.7fr 0.6fr 1fr');
    else if (isInLargeTablet) setColSpans('40px 1.4fr 1.1fr 1fr 0.7fr 0.6fr 0.5fr');
    else if (isInTablet) setColSpans('40px 1.7fr 1.4fr 0.7fr 0.4fr');
    else if (isInMobile) setColSpans('30px 1.2fr 0.7fr 0.4fr');
  }, [isInDesktop, isInLargeTablet, isInMobile, isInTablet]);

  const hiddenColumns = useMemo(() => {
    const cols = [
      'modifiedOn',
      'passStatus',
      'photoStatus',
      'orgLevel1',
      'orgLevel2',
      'orgLevel3',
      'orgLevel4',
    ];
    if (isInTablet) return [...cols, 'title', 'hireDate'];
    if (isInMobile) return [...cols, 'title', 'hireDate', 'emailAddress'];
    return cols;
  }, [dimensions]);

  const {
    getPaginationProps,
    getTableBodyProps,
    getTableProps,
    headerGroups,
    onToggleSort,
    renderRows,
    setFilter,
    state: { filters },
  } = useAppTable({
    ...props,
    columnsSpans: colSpans,
    canSelect: true,
    defaultSortBy: 'modifiedOn',
    hiddenColumns: hiddenColumns,
    headerProps: { ...headerProps, showFilters: true },
    filters: [
      {
        id: 'hireDate',
        value: { startDate: undefined, endDate: undefined },
      },
    ],
    options: {
      usePagination: true,
      pageSize,
      useFilters: true,
    },
  });

  return (
    <Container>
      <TableWrapper>
        <Title {...headerProps} data={props.data} />

        <CardsControlBar data={props.data} filters={filters} setFilter={setFilter} />

        <TableContent {...getTableProps()}>
          <TableHeader
            checkboxCol
            columnsSpans={colSpans}
            headerGroups={headerGroups}
            isFilterActive={isFilterActive}
            onToggleSort={onToggleSort}
            rowsCount={(props.data || []).length}
            setIsFilterActive={setIsFilterActive}
            {...(props.selectProps || {})}
          />

          <TableBody {...getTableBodyProps()}>{renderRows()}</TableBody>

          <PaginationContainer>
            <PaginationRow {...getPaginationProps()} />
          </PaginationContainer>
        </TableContent>
      </TableWrapper>
    </Container>
  );
};
