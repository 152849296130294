/* eslint-disable @typescript-eslint/no-explicit-any */

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IUseForm<T extends Record<string, any>> {
  initialValues: {
    key: keyof T;
    value?: any;
    required: boolean;
  }[];
  onSubmit: (
    data: T,
    helpers: {
      setFormData: Dispatch<SetStateAction<T>>;
      setFormErrors: Dispatch<SetStateAction<Partial<T>>>;
      resetForm: () => void;
    },
  ) => void;
}

export type TUseFormSubmit<T extends Record<string, any>> = IUseForm<T>['onSubmit'];

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export default <T extends Record<string, any>>(
  { initialValues, onSubmit }: IUseForm<T>,
  triggers: any[],
) => {
  const initialFormData = initialValues.reduce((data, value) => {
    data[value.key] = value.value;
    return data;
  }, {} as T);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [formData, setFormData] = useState<T>(initialFormData);
  const [formErrors, setFormErrors] = useState<Partial<T>>({});

  useEffect(() => {
    setFormData(initialFormData);
  }, triggers);

  const resetForm = () => {
    setIsDirty(false);
    setFormData(initialFormData);
  };

  const setValue = (name: keyof T, value: any, type?: string) => {
    setIsDirty(true);
    setFormData((data: any) => ({
      ...(data || {}),
      [name]: value,
    }));

    if (value) {
      if (type === 'email') {
        const isValid = /\S+@\S+\.\S+/.test(value);
        setFormErrors({
          ...formErrors,
          [name]: isValid ? '' : incorrectEmail,
        });
      } else setFormErrors({ ...formErrors, [name]: '' });
    } else {
      setFormErrors({ ...formErrors, [name]: mustBeFilledText });
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setValue(e.target.name, e.target.value, e.target.type);
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement>,
  ) => {
    setIsSubmitting(true);
    e.preventDefault();

    let inValid = false;
    for (const field of initialValues) {
      const { key, required } = field;
      if (required && !formData[key]) {
        inValid = true;
        setFormErrors((errs) => ({
          ...errs,
          [key]: mustBeFilledText,
        }));
      }
    }

    if (inValid) return;

    setIsDirty(false);
    try {
      await onSubmit(formData, { setFormData, setFormErrors, resetForm });
    } finally {
      setIsSubmitting(false);
    }
  };

  const { t } = useTranslation();
  const mustBeFilledText = t('Must be filled');
  const incorrectEmail = t('Incorrect email format');

  return {
    formData,
    formErrors,
    handleChange,
    handleSubmit,
    isDirty,
    isSubmitting,
    resetForm,
    setFormErrors,
    setValue,
  };
};
