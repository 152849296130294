export interface ICard {
  cardId: string;
  employeeId: string;
  firstName: string;
  id: string;
  isActive: boolean;
  isPassDeleted: boolean;
  isPassDownloaded: boolean;
  lastName: string;
  modifiedOn: Date;
}

export interface IEmployeeCard {
  cardId: string;
  currentCardInstance?: {
    id: string;
    createdOn: Date | undefined;
  };
  emailAddress: string;
  employeeId: string;
  employeeNumber?: string;
  employeeStatus: string;
  firstName: string;
  gender: 'M' | 'F';
  grade?: string;
  hireDate?: Date;
  id: string;
  isActive: boolean;
  isPassDeleted: boolean;
  isPassDownloaded: boolean;
  lastName: string;
  lastPhotoRequest?: Date;
  modifiedOn: Date;
  photoUrl?: string;
  preferredName?: string;
  terminationDate?: Date;
  title: string;

  orgLevel1Code?: string;
  orgLevel1Description?: string;
  orgLevel2Code?: string;
  orgLevel2Description?: string;
  orgLevel3Code?: string;
  orgLevel3Description?: string;
  orgLevel4Code?: string;
  orgLevel4Description?: string;
}

export interface ICardConfig {
  backgroundColor?: string;
  foregroundColor?: string;
}

export interface IDigiPass {
  authorizationToken: string;
  deviceAndroidId?: string;
  deviceIOSId?: string;
  id: string;
  isPassDeleted: boolean;
  isDeviceAndroid?: boolean;
  isDeviceDeletedOnAndroid?: boolean;
  isDeviceDeletedOnIOS?: boolean;
  isDeviceIOS?: boolean;
  isPassDownloaded: boolean;
  lastUpdatedOn: string;
  passTypeId?: string;
  pushToken?: string;
  serialNumber: string;
}

export interface ICardToGenerate {
  id: string;
  cardId: string;
  CompanyLogoText: string;
  CompanyLogoUrl?: string;
  CompanyName: string;
  createdOn: Date;
  Description: string;
  DistrictName: string;
  EmailAddress: string;
  EmployeeHireDate?: string;
  employeeNumber: string;
  EmployeeTitle: string;
  ExpirationDate: Date;
  Grade: string;
  modifiedOn: Date;
  PersonId: string;
  PersonName: string;
  PersonPhotoUrl?: string;
  PersonTitle: string;
  QrCodeId: string;
  QRCodeSubText: string;
  SchoolTerm?: Date;
}

export enum EGenerationStatus {
  completed = 'COMPLETED',
  failedToEmail = 'FAILED_TO_EMAIL',
  failedToGenerate = 'FAILED_TO_GENERATE',
  pending = 'PENDING',
  sendingEmail = 'SENDING_EMAIL',
}

export interface ICardGeneration extends ICardToGenerate {
  downloadUrlApple?: string;
  downloadUrlGoogle?: string;
  passStatus?: 'received' | 'downloaded' | 'deleted' | 'invalid';
  status: EGenerationStatus;
}

export enum EPassStatusFilter {
  All = 'All',
  Downloaded = 'Downloaded',
  Deleted = 'Deleted',
  Unknown = 'Unknown',
}
