import { firestore } from 'data/firebase';
import { collection, getDocs, QueryDocumentSnapshot } from 'firebase/firestore';
import { parseError } from 'utils/errors.utils';

import dbLabels from 'data/db.labels';
import { Adapter } from '../../types';
import { ICompany } from 'types/company.types';

const comapanyConverter = {
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as ICompany,
  toFirestore: (data: ICompany) => data,
};

export default async (): Promise<Adapter<ICompany[]>> => {
  try {
    const ref = collection(firestore, dbLabels.company).withConverter(
      comapanyConverter,
    );
    const companies = await getDocs(ref).then((snapshot) =>
      snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })).filter((c) => !!c),
    );

    return { success: true, data: companies || [] };
  } catch (err) {
    const stringError = parseError(err);

    return { success: false, error: stringError };
  }
};
