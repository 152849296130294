import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { useLocation } from 'react-router-dom';

import { formatName } from 'utils/user.utils';
import { logout } from 'state/auth/AuthActions';
import { PATHS } from 'routes';

import { AvatarCircle } from 'components/avatar-circle/AvatarCircle';
import { MenuToolTip } from 'components/menu-tootip';
import { NotificationsDropdown } from 'domains/notifications/notifications-dropdown/NotificationsDropdown';
import { ProfilePageModal } from 'domains/profile/ProfilePageModal';
import { SidePanel } from '../side-panel/SidePandel';
import HamburgerIcon from './icons/hamburger-icon/HamburgerIcon';
import SearchBar from 'components/search-bar';
import useDimensions from 'hooks/useDimensions';
import useUserRole from 'hooks/useUserRole';

import {
  Actions,
  Container,
  HamburgerContainer,
  Inner,
  MobilePanel,
  SearchBarContainer,
  UserContainer,
  UserName,
} from './PrivateHeaderNavStyles';

export const PrivateHeaderNav: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [shouldShowProfileModal, setShouldShowProfileModal] = useState(false);
  const handleOpen = () => setShouldShowProfileModal(true);
  const handleClose = () => setShouldShowProfileModal(false);

  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ auth }) => auth);
  const { features } = useUserRole();
  const { isInMobile } = useDimensions();
  const { pathname } = useLocation();
  const showNotifications =
    features?.hasNotifications && pathname !== PATHS.notifications;

  const handleLogout = () => dispatch(logout());

  return (
    <Container>
      <Inner>
        <SearchBarContainer>
          <HamburgerContainer>
            <HamburgerIcon onClick={() => setIsMenuOpen((o) => !o)} />
          </HamburgerContainer>
          <SearchBar placeholder="Search" />
        </SearchBarContainer>

        <Actions>
          {!!showNotifications && <NotificationsDropdown />}

          {!!user && (
            <UserContainer>
              <AvatarCircle
                size={24}
                photoUrl={user?.photoUrl}
                name={formatName(user)}
              />
              {!isInMobile && <UserName>{formatName(user)}</UserName>}
              <MenuToolTip
                menuOptions={[
                  { label: 'Profile', onClick: handleOpen },
                  { label: 'Log Out', onClick: handleLogout },
                ]}
                id="header-nav"
              />
            </UserContainer>
          )}
        </Actions>

        <ProfilePageModal
          shouldShow={shouldShowProfileModal}
          handleClose={handleClose}
        />
      </Inner>

      {isMenuOpen && (
        <MobilePanel>
          <SidePanel />
        </MobilePanel>
      )}
    </Container>
  );
};
