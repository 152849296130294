import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    backgroundColor: string;
    colorDanger: string;
    colorInactive: string;
    gray: string;
    lightGrayBg: string;
    primaryBlack: string;
    primaryBlackTrans: string;
    primaryFont: string;
    primaryGreen: string;
    primaryYellow: string;
    secondaryBlack: string;
    secondaryFont: string;
    secondaryViolet: string;
    secondaryVioletTrans: string;
    sizeRanges: {
      [x: string]: {
        min: number;
        max: number;
      };
    };
    weightBold: string;
    weightExtraBold: string;
    weightRegular: string;
    weightSemiBold: string;
    widthFull: string;
    widthMobile: string;
    widthTablet: string;
  }
}

export const Theme: DefaultTheme = {
  backgroundColor: '#F5F5F5',
  colorDanger: '#FC473F',
  colorInactive: '#F18484',
  gray: '#919CA3',
  lightGrayBg: '#D1D1D5',
  primaryBlack: '#07081F',
  primaryBlackTrans: '#07081F10',
  primaryFont: 'Poppins',
  primaryGreen: '#42BD6A',
  primaryYellow: '#FFF142',
  secondaryBlack: '#23222A',
  secondaryFont: 'Manrope',
  secondaryViolet: '#6B57E1',
  secondaryVioletTrans: '#E2E0EE',
  sizeRanges: {
    mobile: {
      min: 0,
      max: 370,
    },
    mobileLarge: {
      min: 370,
      max: 562,
    },
    tablet: {
      min: 562,
      max: 900,
    },
    tabletLarge: {
      min: 900,
      max: 1190,
    },
    desktop: {
      min: 1190,
      max: 1609,
    },
    desktopLarge: {
      min: 1609,
      max: Infinity,
    },
  },
  weightBold: '700',
  weightExtraBold: '800',
  weightRegular: '500',
  weightSemiBold: '600',
  widthFull: '100%',
  widthMobile: '425px',
  widthTablet: '1024px',
};

export default Theme;
