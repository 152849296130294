import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';
import { IRole } from 'types/role.types';
import { IUser } from 'types/user.types';

export const AUTH__CLEAR_ERROR = 'AUTH__CLEAR_ERROR';
export const AUTH__FAIL = 'AUTH__FAIL';
export const AUTH__FORGOT_PASSWORD = 'AUTH__FORGOT_PASSWORD';
export const AUTH__LOGIN = 'AUTH__LOGIN';
export const AUTH__LOGOUT = 'AUTH__LOGOUT';
export const AUTH__SET_IS_LOADING = 'AUTH__SET_IS_LOADING';
export const AUTH__SET_IS_LOADING_USER = 'AUTH__SET_IS_LOADING_USER';
export const AUTH__SET_USER_DATA = 'AUTH__SET_USER_DATA';
export const AUTH__UPDATE_PASSWORD = 'AUTH__UPDATE_PASSWORD';

interface AuthFailAction {
  error: string;
  type: typeof AUTH__FAIL;
}

interface ClearErrorAction {
  type: typeof AUTH__CLEAR_ERROR;
}

interface ForgotPasswordAction {
  type: typeof AUTH__FORGOT_PASSWORD;
}

interface LoginSuccessAction {
  type: typeof AUTH__LOGIN;
}

interface LogoutSuccessAction {
  type: typeof AUTH__LOGOUT;
}

interface GetUserAuthSuccessAction {
  type: typeof AUTH__SET_USER_DATA;
  user: IUser;
  role: IRole;
}

interface SetIsLoadingAction {
  type: typeof AUTH__SET_IS_LOADING;
  isLoading: boolean;
}

interface SetIsLoadingUserAction {
  type: typeof AUTH__SET_IS_LOADING_USER;
  isLoadingUser: boolean;
}

interface UpdateUserPasswordAction {
  type: typeof AUTH__UPDATE_PASSWORD;
}

export type AuthActionTypes =
  | AuthFailAction
  | ClearErrorAction
  | ForgotPasswordAction
  | GetUserAuthSuccessAction
  | LoginSuccessAction
  | LogoutSuccessAction
  | SetIsLoadingAction
  | SetIsLoadingUserAction
  | UpdateUserPasswordAction;

export type TLogin = (args: {
  email: string;
  password: string;
}) => ThunkAction<
  Promise<{ success: boolean; error?: string }>,
  RootState,
  unknown,
  AuthActionTypes
>;

export type TForgotPassword = (args: {
  email: string;
}) => ThunkAction<
  Promise<{ success: boolean; error?: string }>,
  RootState,
  unknown,
  AuthActionTypes
>;

export type TAuthThunkAction = () => ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  AuthActionTypes
>;

export type TGetUserAuth = () => ThunkAction<
  Promise<{
    success: boolean;
    error?: string;
  }>,
  RootState,
  unknown,
  AuthActionTypes
>;

export type TUpdateUserPassword = (args: {
  oldPassword: string;
  newPassword: string;
}) => ThunkAction<
  Promise<{
    success: boolean;
    error?: string;
  }>,
  RootState,
  unknown,
  AuthActionTypes
>;

export type TClearError = () => AuthActionTypes;

export type TSetIsLoading = (isLoading: boolean) => SetIsLoadingAction;

export type TSetIsLoadingUser = (isLoadingUser: boolean) => SetIsLoadingUserAction;
