import React from 'react';
import { Theme } from 'assets/styles';

export const Digital: React.FC = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.25884 21.8272C5.25884 23.1442 4.17668 24.2377 2.86361 24.2564H2.82948C1.50106 24.2564 0.4 23.1555 0.4 21.8266V10.374C0.4 4.86898 4.86935 0.399548 10.3745 0.4H10.3745H21.8271C23.1555 0.4 24.2566 1.50104 24.2566 2.82948C24.2566 4.15793 23.1555 5.25897 21.8271 5.25897H10.3745C7.52624 5.25897 5.25884 7.52636 5.25884 10.3747V21.8272Z"
      fill="#42BD6A"
      stroke="#EFEFEF"
      strokeWidth="0.8"
    />
    <path
      d="M58.7411 42.1727C58.7411 40.8522 59.829 39.7564 61.1468 39.7434H61.1705C62.4989 39.7434 63.6 40.8444 63.6 42.1729V53.6254C63.6 59.1305 59.1311 63.5999 53.6254 63.5999H42.1729C40.8444 63.5999 39.7434 62.4989 39.7434 61.1705C39.7434 59.842 40.8444 58.741 42.1729 58.741H53.6254C56.4737 58.741 58.7411 56.4736 58.7411 53.6253V42.1727Z"
      fill="#42BD6A"
      stroke="#EFEFEF"
      strokeWidth="0.8"
    />
    <path
      d="M53.4568 0.4L53.4569 0.40012H53.6254C59.1305 0.40012 63.6 4.86947 63.6 10.3747V21.8272C63.6 23.1556 62.4989 24.2567 61.1705 24.2567C59.842 24.2567 58.741 23.1556 58.741 21.8272V10.3747C58.741 7.52636 56.4736 5.25897 53.6253 5.25897H42.1727C40.8443 5.25897 39.7433 4.15793 39.7433 2.82948C39.7433 1.50104 40.8443 0.4 42.1727 0.4H53.4568Z"
      fill="#42BD6A"
      stroke="#EFEFEF"
      strokeWidth="0.8"
    />
    <path
      d="M21.8273 58.741H21.852C23.2291 58.7539 24.2567 59.8436 24.2567 61.1703C24.2567 62.4988 23.1556 63.5998 21.8272 63.5998H10.3747C4.86958 63.5998 0.400122 59.1309 0.400122 53.6253V42.1727C0.400122 40.8443 1.50116 39.7433 2.82961 39.7433C4.15805 39.7433 5.25909 40.8443 5.25909 42.1727V53.6253C5.25909 56.4736 7.52648 58.741 10.3748 58.741H21.8273Z"
      fill="#42BD6A"
      stroke="#EFEFEF"
      strokeWidth="0.8"
    />
    <path
      d="M42.9216 44.4755L43.1454 44.207C44.0125 43.1664 43.8349 41.6069 42.8029 40.8041L42.7923 40.7959L42.7924 40.7957C41.7519 39.9286 40.1924 40.1062 39.3896 41.1382L39.3851 41.144L39.385 41.1439C37.8633 43.0275 34.7566 44.122 31.4611 44.122C28.1581 44.122 25.0595 42.9562 23.542 41.1498L23.541 41.1486C22.6648 40.0972 21.1639 39.9335 20.1298 40.7956L20.1298 40.7957C19.0783 41.6719 18.9146 43.1728 19.7768 44.2069L19.7785 44.209L19.7785 44.209C22.1791 47.1286 26.6353 49.0484 31.4611 49.0484L42.9216 44.4755ZM42.9216 44.4755C40.478 47.302 36.1476 49.048 31.4612 49.0484L42.9216 44.4755Z"
      fill={Theme.secondaryViolet}
      stroke="#EFEFEF"
      strokeWidth="0.8"
    />
    <path
      d="M31.9165 29.975L32.1754 29.4087L34.3314 24.693L34.334 24.6873L34.3341 24.6874C34.9051 23.4883 34.3407 22.0344 33.1083 21.448L33.1082 21.448C31.9102 20.8774 30.4576 21.4404 29.8703 22.6708L26.7725 29.4717L26.77 29.4771L26.77 29.4771C26.2268 30.6242 26.3417 31.9637 27.019 33.0718L31.9165 29.975ZM31.9165 29.975H32.5392M31.9165 29.975H32.5392M32.5392 29.975H33.2802C34.6087 29.975 35.7097 31.0761 35.7097 32.4045C35.7097 33.733 34.6087 34.834 33.2802 34.834H30.1811M32.5392 29.975L30.1811 34.834M30.1811 34.834C30.181 34.834 30.181 34.834 30.181 34.834M30.1811 34.834H30.181M30.181 34.834C28.9035 34.834 27.6867 34.1634 27.0191 33.0719L30.181 34.834Z"
      fill={Theme.secondaryViolet}
      stroke="#EFEFEF"
      strokeWidth="0.8"
    />
    <path
      d="M17.4441 21.1538V17.3812C17.4441 16.0528 18.5452 14.9518 19.8736 14.9518C21.2021 14.9518 22.3031 16.0528 22.3031 17.3812V21.1538C22.3031 22.4822 21.2021 23.5832 19.8736 23.5832C18.5452 23.5832 17.4441 22.4822 17.4441 21.1538Z"
      fill={Theme.secondaryViolet}
      stroke="#EFEFEF"
      strokeWidth="0.8"
    />
    <path
      d="M46.5558 17.3812V21.1538C46.5558 22.4822 45.4547 23.5832 44.1263 23.5832C42.7979 23.5832 41.6968 22.4822 41.6968 21.1538V17.3812C41.6968 16.0528 42.7979 14.9518 44.1263 14.9518C45.4547 14.9518 46.5558 16.0528 46.5558 17.3812Z"
      fill={Theme.secondaryViolet}
      stroke="#EFEFEF"
      strokeWidth="0.8"
    />
  </svg>
);
