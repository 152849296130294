import { useCallback, useMemo } from 'react';
import { isAfter, isBefore } from 'date-fns';

import { useAppSelector } from 'state/hooks';

import { formatName } from 'utils/user.utils';
import { includes } from 'utils/text.utils';

import { useEmployeeCards } from 'contexts';
import useGetCardConfig from 'domains/hooks/useCardConfig';
import useSearchBar from 'hooks/useSearchBar';

import { IEmployeeCard } from 'types/card.types';

export default () => {
  const { searchValue } = useSearchBar();
  const { startDate, endDate, filter, passStatusFilter } = useAppSelector(
    ({ cardManagement }) => cardManagement,
  );

  const { employeeCards, isLoading: isLoadingCardUpdates } = useEmployeeCards();
  const { cardConfig, isLoading: isLoadingCardConfig } = useGetCardConfig();

  const shouldAppear = useCallback(
    (card: IEmployeeCard) => {
      let shouldAppear = true;
      if (passStatusFilter !== 'All') {
        if (passStatusFilter === 'Downloaded')
          shouldAppear = !!card.isPassDownloaded && !card.isPassDeleted;
        else if (passStatusFilter === 'Deleted') shouldAppear = !!card.isPassDeleted;
        else if (passStatusFilter === 'Unknown')
          shouldAppear = !card.isPassDownloaded && !card.isPassDeleted;
      }

      if (filter !== 'all') {
        shouldAppear &&=
          isBefore(startDate, card.modifiedOn) && isAfter(endDate, card.modifiedOn);
      }

      if (shouldAppear)
        return (
          !searchValue ||
          includes(formatName(card), searchValue) ||
          includes(card.employeeNumber, searchValue)
        );

      return false;
    },
    [filter, passStatusFilter, startDate, endDate, searchValue],
  );

  const filteredCards = useMemo(() => {
    if (filter === 'all' && passStatusFilter === 'All' && !searchValue) {
      return employeeCards;
    }

    return employeeCards.filter((card) => {
      return shouldAppear(card);
    });
  }, [filter, passStatusFilter, startDate, endDate, employeeCards, searchValue]);

  return {
    cardConfig,
    filteredCards,
    isLoading: isLoadingCardUpdates,
    isLoadingCardConfig,
    shouldAppear,
  };
};
