import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from 'data/firebase';
import { Adapter } from '../../types';
import { parseError } from 'utils/errors.utils';
import dbLabels from 'data/db.labels';

interface IArgs {
  token: string;
}

export default async ({ token }: IArgs): Promise<Adapter<Date>> => {
  try {
    const ref = query(
      collection(firestore, dbLabels.pendingAccount),
      where('token', '==', token),
    );
    const snapshot = await getDocs(ref);
    if (!snapshot.docs.length) throw 'Request does not exist';

    const pendingAccount = snapshot.docs[0].data();

    if (!pendingAccount.isCreated) {
      return { success: true, data: pendingAccount.expiresOn };
    }
    return { success: false, error: '@password-already-set' };
  } catch (err) {
    const stringError = parseError(err);

    return { success: false, error: stringError };
  }
};
