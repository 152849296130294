import React, { useEffect, useState } from 'react';
import { isBefore } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'state/hooks';
import useDimensions from 'hooks/useDimensions';

import { ContentBoxHeader, ContentBoxTitle } from 'domains/navigation';
import { DatePicker } from 'components/date-picker';

import { IDateRange } from 'types/shared.types';
import { TFilter } from 'types/shared.types';
import { IEmployee } from 'types/employee.types';

import { HeaderButton, HeaderBtnsContainer, TitleAndActions } from './TitleStyles';

interface Props {
  activeFilter?: TFilter;
  endDate: Date;
  handleFilterChange?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  setCustomDateRange: ({ startDate, endDate }: IDateRange) => void;
  startDate: Date;
  data: IEmployee[];
}

export const Title: React.FC<Props> = React.memo(
  ({
    activeFilter,
    endDate,
    handleFilterChange,
    setCustomDateRange,
    startDate,
    data,
  }: Props) => {
    const [dateRange, setDateRange] = useState<IDateRange>({
      startDate,
      endDate,
    });

    const { t } = useTranslation();
    const { currentCompany } = useAppSelector(({ company }) => company);
    const tTitle = currentCompany?.isSchool
      ? t('Students and Cards')
      : t('Employees and Cards');
    const tAll = t('All');
    const tWeek = t('Week');
    const tMonth = t('30d');
    const tYear = t('1y');

    const { isInMobile } = useDimensions();

    useEffect(() => {
      if (activeFilter === 'all') {
        const sorted = data.sort((a, b) =>
          isBefore(a.modifiedOn, b.modifiedOn) ? 1 : -1,
        );

        setDateRange({
          startDate: sorted[0]?.modifiedOn || startDate,
          endDate: sorted[sorted.length - 1]?.modifiedOn || endDate,
        });
      } else setDateRange({ startDate, endDate });
    }, [activeFilter]);

    return (
      <ContentBoxHeader>
        <TitleAndActions>
          <ContentBoxTitle>{tTitle}</ContentBoxTitle>
        </TitleAndActions>

        <HeaderBtnsContainer>
          {!isInMobile && (
            <>
              <HeaderButton
                name="all"
                isActive={activeFilter === 'all'}
                onClick={handleFilterChange}
              >
                {tAll}
              </HeaderButton>
              <HeaderButton
                name="week"
                isActive={activeFilter === 'week'}
                onClick={handleFilterChange}
              >
                {tWeek}
              </HeaderButton>
              <HeaderButton
                name="month"
                isActive={activeFilter === 'month'}
                onClick={handleFilterChange}
              >
                {tMonth}
              </HeaderButton>
              <HeaderButton
                name="year"
                isActive={activeFilter === 'year'}
                onClick={handleFilterChange}
              >
                {tYear}
              </HeaderButton>
            </>
          )}

          <DatePicker {...dateRange} onDateChange={setCustomDateRange} />
        </HeaderBtnsContainer>
      </ContentBoxHeader>
    );
  },
);
