import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import useCardsFiltersModal from './hooks/useCardsFiltersModal';

import { Button, FlatButton } from 'components/buttons';
import { DatePicker } from 'components/date-picker';
import { Filter } from 'components/icons';
import { RadioButtonToggle } from 'components/radio-button-toggle/RadioButtonToggle';
import { SelectDropdown } from 'components/select-dropdown';
import Modal from 'components/modals';

import { Actions, ActiveDot, ButtonContainer, ButtonWrapper, FilterGroup, Form, Label, LevelsGrid, MainGrid, PickerWrapper } from './CardsFiltersModalStyles';

interface Props {
  filters: { id: string; value: unknown }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilter: (columnId: string, updater: any) => void;
}

export const CardsFiltersModal: React.FC<Props> = ({ filters, setFilter }: Props) => {
  const { t } = useTranslation();
  const tHireDate = t('Hire Date');
  const tPassStatus = t('Pass Status');
  const tCardStatus = t('ID Card Status');
  const tPhotoStatus = t('Photo Upload Status');
  const tOrgLevel1 = t('Organization Level 1');
  const tOrgLevel2 = t('Organization Level 2');
  const tOrgLevel3 = t('Organization Level 3');
  const tOrgLevel4 = t('Organization Level 4');
  const tApply = t('Apply');
  const tReset = t('Reset');

  const { data, formData, handleClose, handleOpen, handleSubmit, isFilteringAcive, parseValue, resetFilters, setValue, shouldShow, showOrgLevels } = useCardsFiltersModal({
    filters,
    setFilter,
  });

  return (
    <Fragment>
      <ButtonContainer>
        <ButtonWrapper>
          <FlatButton onClick={handleOpen}>{<Filter />}</FlatButton>
          {!!isFilteringAcive && <ActiveDot />}
        </ButtonWrapper>
      </ButtonContainer>

      <Modal headerMessage="Filter Cards" width="650px" shouldShow={shouldShow} handleClose={handleClose}>
        <Form onSubmit={handleSubmit}>
          <MainGrid>
            <FilterGroup>
              <Label>{tHireDate}:</Label>
              <PickerWrapper>
                <DatePicker startDate={formData.hireDate?.startDate} endDate={formData.hireDate?.endDate} onDateChange={(range) => setValue('hireDate', range)} />
              </PickerWrapper>
            </FilterGroup>

            <FilterGroup>
              <Label>{tPassStatus}:</Label>
              <RadioButtonToggle id="passStatus" data={data['passStatus']} value={parseValue(formData.passStatus)} onChange={({ value }) => setValue('passStatus', value)} />
            </FilterGroup>

            <FilterGroup>
              <Label>{tCardStatus}:</Label>
              <RadioButtonToggle id="cardStatus" data={data['cardStatus']} value={parseValue(formData.status)} onChange={({ value }) => setValue('status', value)} />
            </FilterGroup>

            <FilterGroup>
              <Label>{tPhotoStatus}:</Label>
              <RadioButtonToggle
                id="photoStatus"
                data={data['photoStatus']}
                value={parseValue(formData.photoStatus)}
                onChange={({ value }) => setValue('photoStatus', value)}
              />
            </FilterGroup>
          </MainGrid>

          {showOrgLevels && (
            <LevelsGrid>
              <FilterGroup>
                <Label>{tOrgLevel1}:</Label>
                <SelectDropdown
                  placeholder="Organization Level 1"
                  data={data['orgLevel1']}
                  value={data['orgLevel1'].filter(({ value }) => formData.orgLevel1 === value)}
                  onChange={({ value }) => setValue('orgLevel1', value)}
                />
              </FilterGroup>
              <FilterGroup>
                <Label>{tOrgLevel2}:</Label>
                <SelectDropdown
                  placeholder="Organization Level 2"
                  data={data['orgLevel2']}
                  value={data['orgLevel2'].filter(({ value }) => formData.orgLevel2 === value)}
                  onChange={({ value }) => setValue('orgLevel2', value)}
                />
              </FilterGroup>
              <FilterGroup>
                <Label>{tOrgLevel3}:</Label>
                <SelectDropdown
                  placeholder="Organization Level 3"
                  data={data['orgLevel3']}
                  value={data['orgLevel3'].filter(({ value }) => formData.orgLevel3 === value)}
                  onChange={({ value }) => setValue('orgLevel3', value)}
                />
              </FilterGroup>
              <FilterGroup>
                <Label>{tOrgLevel4}:</Label>
                <SelectDropdown
                  placeholder="Organization Level 4"
                  data={data['orgLevel4']}
                  value={data['orgLevel4'].filter(({ value }) => formData.orgLevel4 === value)}
                  onChange={({ value }) => setValue('orgLevel4', value)}
                />
              </FilterGroup>
            </LevelsGrid>
          )}

          <Actions>
            <FlatButton isDisabled={!isFilteringAcive} onClick={resetFilters} icon="far fa-times-circle">
              {tReset}
            </FlatButton>
            <Button size="medium" intent="success" type="submit">
              {tApply}
            </Button>
          </Actions>
        </Form>
      </Modal>
    </Fragment>
  );
};
