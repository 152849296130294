import { UserRolesEnum } from 'types/user.types';

const { admin, employee, superAdmin } = UserRolesEnum;

export const anyRole = [admin, employee, superAdmin];

export default {
  dashboardRead: anyRole,
  dashboardWrite: anyRole,
  notificationsRead: [admin, superAdmin],
  notificationsWrite: [admin, superAdmin],
};
