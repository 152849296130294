import React from 'react';
import { Theme } from 'assets/styles';

export const Security: React.FC = () => (
  <svg
    width="46"
    height="64"
    viewBox="0 0 46 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.6667 0C13.8267 0 6.8 7.08571 6.8 16V22.8571C2.94667 22.8571 0 25.8286 0 29.7143V41.1429C0 53.7143 10.2 64 22.6667 64C35.1333 64 45.3333 53.7143 45.3333 41.1429V29.7143C45.3333 25.8286 42.3867 22.8571 38.5333 22.8571V16C38.5333 7.08571 31.5067 0 22.6667 0ZM40.8 29.7143V41.1429C40.8 51.2 32.64 59.4286 22.6667 59.4286C12.6933 59.4286 4.53333 51.2 4.53333 41.1429V29.7143C4.53333 28.3429 5.44 27.4286 6.8 27.4286H38.5333C39.8933 27.4286 40.8 28.3429 40.8 29.7143ZM11.3333 22.8571V16C11.3333 9.6 16.32 4.57143 22.6667 4.57143C29.0133 4.57143 34 9.6 34 16V22.8571H11.3333Z"
      fill="#42BD6A"
    />
    <path
      d="M22.6666 36.4448C21.3333 36.4448 20.4444 37.3337 20.4444 38.667V43.1115C20.4444 44.4448 21.3333 45.3337 22.6666 45.3337C24 45.3337 24.8888 44.4448 24.8888 43.1115V38.667C24.8888 37.3337 24 36.4448 22.6666 36.4448Z"
      fill={Theme.secondaryViolet}
    />
  </svg>
);
