import React from 'react';
import { Theme } from 'assets/styles';

interface Props {
  dark?: boolean;
}

export const InactiveAlert: React.FC<Props> = ({ dark }: Props) => {
  return dark ? (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00001 23V15.5C2.00001 12.8478 3.05358 10.3043 4.92894 8.42893C6.80431 6.55357 9.34785 5.5 12 5.5C14.6522 5.5 17.1957 6.55357 19.0711 8.42893C20.9464 10.3043 22 12.8478 22 15.5V23H23.25V25.5H0.75001V23H2.00001ZM4.50001 15.5H7.00001C7.00001 14.1739 7.52679 12.9022 8.46448 11.9645C9.40216 11.0268 10.6739 10.5 12 10.5V8C10.0109 8 8.10323 8.79018 6.69671 10.1967C5.29019 11.6032 4.50001 13.5109 4.50001 15.5V15.5ZM10.75 0.5H13.25V4.25H10.75V0.5ZM21.7225 4.01L23.49 5.7775L20.84 8.42875L19.0713 6.66125L21.7225 4.01ZM0.51001 5.7775L2.27751 4.01L4.92876 6.66L3.16251 8.43L0.51001 5.7775Z"
        fill="#23222A"
      />
    </svg>
  ) : (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66667 21.3333V14.3333C1.66667 11.858 2.65 9.48399 4.40034 7.73365C6.15068 5.98331 8.52465 4.99998 11 4.99998C13.4754 4.99998 15.8493 5.98331 17.5997 7.73365C19.35 9.48399 20.3333 11.858 20.3333 14.3333V21.3333H21.5V23.6666H0.500001V21.3333H1.66667ZM4 21.3333H18V14.3333C18 12.4768 17.2625 10.6963 15.9497 9.38357C14.637 8.07081 12.8565 7.33331 11 7.33331C9.14348 7.33331 7.36301 8.07081 6.05025 9.38357C4.7375 10.6963 4 12.4768 4 14.3333V21.3333ZM9.83333 0.333313H12.1667V3.83331H9.83333V0.333313ZM20.0743 3.60931L21.724 5.25898L19.2507 7.73348L17.5998 6.08381L20.0743 3.60931ZM0.276001 5.25898L1.92567 3.60931L4.40017 6.08265L2.75167 7.73465L0.276001 5.25898ZM5.16667 14.3333C5.16667 12.7862 5.78125 11.3025 6.87521 10.2085C7.96917 9.11456 9.4529 8.49998 11 8.49998V10.8333C10.0717 10.8333 9.1815 11.2021 8.52513 11.8584C7.86875 12.5148 7.5 13.4051 7.5 14.3333H5.16667Z"
        fill={Theme.primaryBlack}
      />
    </svg>
  );
};
