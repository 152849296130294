import React, { Fragment, useState } from 'react';
import { CellProps } from 'react-table';
import { useTranslation } from 'react-i18next';

import useDimensions from 'hooks/useDimensions';
import useMutatePhotoRequest from 'data/data-hooks/mutations/photo-request/useMutatePhotoRequest';

import { DeleteRequestModal } from '../../action-modals/delete-request-modal/DeleteRequestModal';
import { DenyRequestModal } from '../../action-modals';
import { FlatButton } from 'components/buttons';
import { SuccessToast } from 'components/toasts';

import { EPhotoRequestStatus, ICompanyPhotoRequest } from 'types/photoRequest.types';
import { Container } from './ActionsCellStyles';

export const ActionsCell: React.FC<CellProps<ICompanyPhotoRequest>> = ({
  value: request,
}: CellProps<ICompanyPhotoRequest>) => {
  const [showDeny, setShowDeny] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const { isInDesktop } = useDimensions();
  const { approveRequests, isApproving, isSendingReminder, sendReminder } =
    useMutatePhotoRequest({
      onAprroveSuccess: () => SuccessToast(tApproveSuccess),
    });

  const { t } = useTranslation();
  const tApprove = t('Approve');
  const tApproveSuccess = t('Request approved successfully');
  const tDeny = t('Deny');
  const tReminder = t('send reminder');

  const onRemind = () => sendReminder(request);
  const onApprove = () => approveRequests(request);

  return (
    <Container>
      {request.status === EPhotoRequestStatus.pending ||
      request.status === EPhotoRequestStatus.rejected ? (
        <Fragment>
          <FlatButton
            icon="fas fa-envelope-open-text"
            intent="success"
            isLoading={isSendingReminder}
            onClick={onRemind}
          >
            {!!isInDesktop && tReminder}
          </FlatButton>
        </Fragment>
      ) : (
        <Fragment>
          <FlatButton
            icon="far fa-check-circle"
            intent="secondary"
            isLoading={isApproving}
            onClick={onApprove}
          >
            {tApprove}
          </FlatButton>
          <FlatButton icon="far fa-times-circle" onClick={() => setShowDeny(true)}>
            {tDeny}
          </FlatButton>
        </Fragment>
      )}

      <FlatButton
        icon="fas fa-trash"
        intent="danger"
        onClick={() => setShowDelete(true)}
      >
        {}
      </FlatButton>

      <DenyRequestModal
        handleClose={() => setShowDeny(false)}
        requests={[request]}
        shouldShow={showDeny}
      />

      <DeleteRequestModal
        handleClose={() => setShowDelete(false)}
        requests={[request]}
        shouldShow={showDelete}
      />
    </Container>
  );
};
