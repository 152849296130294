import styled from 'styled-components';

export const Container = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px;
  cursor: pointer;

  :hover {
    transform: scale(1.05);
  }
`;
