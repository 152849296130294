import React from 'react';

import { Header } from './components/header/Header';
import { SummerizedCardsList } from './components/summerized-cards-list/SummerizedCardsList';

import { Container } from './CardUpdatesStyles';

export interface IDateRange {
  startDate: Date;
  endDate: Date;
}

export const CardUpdates: React.FC = () => {
  return (
    <Container>
      <Header />
      <SummerizedCardsList />
    </Container>
  );
};
