import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ToggleInput = styled.input`
  background-color: white;
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;

  &:checked + label {
    background-color: ${({ theme }) => theme.primaryGreen};
    font-weight: ${({ theme }) => theme.weightBold};
    color: #fff;
    box-shadow: none;
  }
`;

export const Label = styled.label`
  display: inline-block;
  background-color: white;
  color: ${({ theme }) => theme.secondaryBlack};
  font-size: 15px;
  text-align: center;
  text-shadow: none;
  padding: 10px 14px;
  border: 1px solid ${({ theme }) => theme.primaryBlack}40;
  border-left: none;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  :first-of-type {
    border-radius: 60px 0 0 60px;
    border-left: 1px solid ${({ theme }) => theme.primaryBlack}40;
  }

  :last-of-type {
    border-radius: 0 60px 60px 0;
  }
`;
