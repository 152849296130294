import styled from 'styled-components';

export const Inner = styled.div`
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  gap: 30px;
  width: 100%;
  flex-grow: 1;
  margin-top: 30px;

  @media (max-width: ${({ theme }) => theme.widthTablet}) {
    grid-template-columns: 1fr;
  }
`;
