import { firestore } from 'data/firebase';
import { collection, orderBy, query } from 'firebase/firestore';

import { useAppSelector } from 'state/hooks';
import useAppQuery from 'hooks/useAppQuery';

import dbLabels from 'data/db.labels';
import { ICardGeneration } from 'types/card.types';

export default () => {
  const companyId = useAppSelector(({ company }) => company.currentCompany?.id);
  const companyRef = query(
    collection(
      firestore,
      dbLabels.company,
      companyId || 'NO_ID_PROVIDED',
      dbLabels.cardTrackers,
    ),
    orderBy('createdOn', 'desc'),
  );

  const { data = [], ...queryData } = useAppQuery<ICardGeneration[]>(
    ['card_generations', companyId],
    companyRef,
    {
      refetchInterval: 1000,
    },
  );

  return { data, ...queryData };
};
