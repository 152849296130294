import React, { useEffect, useState } from 'react';
import { useSwiper } from 'swiper/react';

import { Container, SwiperButton } from './SwiperButtonsStyles';

export const SwiperButtons: React.FC = () => {
  const [slideConfig, setSlideConfig] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const swiper = useSwiper();

  useEffect(() => {
    swiper.on('slideChange', (swipe) => {
      setSlideConfig({ isBeginning: swipe.isBeginning, isEnd: swipe.isEnd });
    });
  }, [swiper]);

  return (
    <Container>
      <SwiperButton
        className="fas fa-chevron-left"
        onClick={() => swiper.slidePrev()}
        isDisabled={slideConfig.isBeginning}
      />
      <SwiperButton
        className="fas fa-chevron-right"
        onClick={() => swiper.slideNext()}
        isDisabled={slideConfig.isEnd}
      />
    </Container>
  );
};
