import React from 'react';
import { Theme } from 'assets/styles';

interface Props {
  green?: boolean;
}

export const Photo: React.FC<Props> = ({ green }: Props) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={green ? { backgroundColor: Theme.primaryGreen } : {}}
  >
    <g clipPath="url(#clip0_314_357)">
      <path
        d="M17 22C20.662 22 23.865 23.575 25.607 25.925L23.765 26.796C22.347 25.116 19.847 24 17 24C14.153 24 11.653 25.116 10.235 26.796L8.39404 25.924C10.136 23.574 13.338 22 17 22ZM17 7C18.3261 7 19.5979 7.52678 20.5356 8.46447C21.4733 9.40215 22 10.6739 22 12V15C22 16.2885 21.5025 17.5272 20.6114 18.4578C19.7203 19.3884 18.5043 19.9391 17.217 19.995L17 20C15.674 20 14.4022 19.4732 13.4645 18.5355C12.5268 17.5979 12 16.3261 12 15V12C12.0001 10.7115 12.4976 9.47284 13.3887 8.54222C14.2798 7.61161 15.4958 7.06092 16.783 7.005L17 7ZM17 9C16.2348 8.99996 15.4985 9.29233 14.9418 9.81728C14.385 10.3422 14.0499 11.0601 14.005 11.824L14 12V15C13.9993 15.7809 14.3031 16.5313 14.8468 17.0918C15.3906 17.6523 16.1314 17.9787 16.912 18.0016C17.6926 18.0245 18.4513 17.7422 19.027 17.2145C19.6027 16.6869 19.95 15.9556 19.995 15.176L20 15V12C20 11.2044 19.684 10.4413 19.1214 9.87868C18.5588 9.31607 17.7957 9 17 9Z"
        fill={Theme.primaryBlack}
      />
    </g>
    <rect
      x="1"
      y="1"
      width="32"
      height="32"
      rx="3"
      stroke={Theme.primaryBlack}
      strokeWidth="2"
    />
    <defs>
      <clipPath id="clip0_314_357">
        <rect x="5" y="5" width="24" height="24" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
