import { firestore } from 'data/firebase';
import { doc, getDoc, QueryDocumentSnapshot } from 'firebase/firestore';
import getCurrentUser from 'data/firebase/functions/getCurrentUser';
import dbLabels from 'data/db.labels';

import { Adapter } from 'data/types';
import { IUser } from 'types/user.types';
import { IRole } from 'types/role.types';

const userConverter = {
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as IUser,
  toFirestore: (data: IUser) => data,
};

const roleConverter = {
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as IRole,
  toFirestore: (data: IRole) => data,
};

export default async (): Promise<
  Adapter<{
    user: IUser;
    role: IRole;
  }>
> => {
  try {
    const user = await getCurrentUser();
    if (!user) return { success: true };

    const docRef = doc(firestore, dbLabels.user, user.uid).withConverter(
      userConverter,
    );
    const userDoc = await getDoc(docRef);
    if (!userDoc.exists) throw 'User not found';

    const userDocData = userDoc.data();
    if (!userDocData) throw 'No data for this user';

    const userRole = userDocData.role;

    const roleRef = doc(firestore, dbLabels.role, userRole).withConverter(
      roleConverter,
    );
    const role = await getDoc(roleRef).then((doc) => doc.data());
    if (!role) throw 'Could not load user role';

    return { success: true, data: { user: { ...userDocData, id: user.uid }, role } };
  } catch (err) {
    const errorMessage = err as string;

    return { success: false, error: errorMessage };
  }
};
