import styled from 'styled-components';

interface IIconContianer {
  leading?: boolean;
  trailing?: boolean;
  error?: string;
  success?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0px;
`;

export const IconContianer = styled.div<IIconContianer>`
  padding: 15px;
  width: 60px;
  font-size: 18px;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    padding: 10px;
    width: 50px;
    font-size: 11px;
    max-height: 50px;
  }
`;

export const ElementContianer = styled.div<IIconContianer>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  background-color: white;
  max-height: 60px;

  ${({ theme, leading, error, success }) =>
    error
      ? `
        border: 1px solid  ${theme.colorDanger};
        ${leading ? 'border-right: none;' : 'border-left: none;'}
      `
      : success
      ? `
        border-top: 1px solid  ${theme.primaryGreen};
        border-bottom: 1px solid  ${theme.primaryGreen};
        border-left: none;
        border-right: none;`
      : 'border: none;'}

  ${({ leading }) =>
    leading &&
    `
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
  `}

  ${({ trailing }) =>
    trailing &&
    `
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
  `}



  @media (max-width: ${(props) => props.theme.widthTablet}) {
    max-height: 50px;
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  padding: 5px 20px 0px 0px;
`;

export const InnerBottomContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  height: 0px;
`;
