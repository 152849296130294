import React from 'react';
import { CheckIcon, IconContainer, Input, Label, MinusIcon } from './CheckboxStyles';

interface Props {
  handleCheckClick: React.ChangeEventHandler<HTMLInputElement>;
  icon?: 'check' | 'minus';
  isDisabled?: boolean;
  isRowDisabled?: (row: unknown) => boolean;
  isSelected: boolean;
  row?: unknown;
  value?: string;
}

const PersonResult: React.FC<Props> = ({
  handleCheckClick,
  icon = 'check',
  isDisabled = false,
  isRowDisabled = () => false,
  isSelected,
  row,
  value,
}: Props) => {
  let iconComponent: JSX.Element | undefined;
  if (icon === 'check') iconComponent = <CheckIcon />;
  if (icon === 'minus') iconComponent = <MinusIcon />;

  const shouldDisable =
    isDisabled ||
    isRowDisabled(row) ||
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (!!value && typeof value !== 'string' && (value as any).disableCheckbox);

  return (
    <Label isDisabled={shouldDisable} isChecked={!!isSelected}>
      <Input
        checked={isSelected}
        disabled={shouldDisable}
        onChange={handleCheckClick}
        type="checkbox"
        value={value}
      />

      <div className="result-check-mark">
        {isSelected && <IconContainer>{iconComponent}</IconContainer>}
      </div>
    </Label>
  );
};

export default PersonResult;
