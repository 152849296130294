import * as Actions from './SearchBarActions.types';

export const clearValue: Actions.TClearValue = () => ({
  type: Actions.SEARCH__CLEAR_VALUE,
});

export const setIsLoading: Actions.TSetIsLoading = (isLoading) => ({
  isLoading,
  type: Actions.SEARCH__SET_IS_LOADING,
});

export const setValue: Actions.TSetValue = (value) => ({
  type: Actions.SEARCH__SET_VALUE,
  value,
});
