import React from 'react';

export const SampleCode: React.FC = () => (
  <svg
    width="140"
    height="140"
    viewBox="0 0 119 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.8349"
      y="0.77887"
      width="117.265"
      height="118.168"
      rx="6.23017"
      fill="white"
    />
    <path d="M16.0432 12.2566H12.222V16.0778H16.0432V12.2566Z" fill="black" />
    <path d="M19.8644 12.2566H16.0432V16.0778H19.8644V12.2566Z" fill="black" />
    <path d="M23.6856 12.2566H19.8644V16.0778H23.6856V12.2566Z" fill="black" />
    <path d="M27.5067 12.2566H23.6856V16.0778H27.5067V12.2566Z" fill="black" />
    <path d="M31.3279 12.2566H27.5067V16.0778H31.3279V12.2566Z" fill="black" />
    <path d="M35.1491 12.2566H31.3279V16.0778H35.1491V12.2566Z" fill="black" />
    <path d="M38.9702 12.2566H35.1491V16.0778H38.9702V12.2566Z" fill="black" />
    <path d="M46.6126 12.2566H42.7914V16.0778H46.6126V12.2566Z" fill="black" />
    <path d="M50.4338 12.2566H46.6126V16.0778H50.4338V12.2566Z" fill="black" />
    <path d="M54.2549 12.2566H50.4338V16.0778H54.2549V12.2566Z" fill="black" />
    <path d="M58.0761 12.2566H54.2549V16.0778H58.0761V12.2566Z" fill="black" />
    <path d="M61.8973 12.2566H58.0761V16.0778H61.8973V12.2566Z" fill="black" />
    <path d="M65.7184 12.2566H61.8973V16.0778H65.7184V12.2566Z" fill="black" />
    <path d="M69.5396 12.2566H65.7184V16.0778H69.5396V12.2566Z" fill="black" />
    <path d="M73.3608 12.2566H69.5396V16.0778H73.3608V12.2566Z" fill="black" />
    <path d="M77.1819 12.2566H73.3608V16.0778H77.1819V12.2566Z" fill="black" />
    <path d="M84.8243 12.2566H81.0031V16.0778H84.8243V12.2566Z" fill="black" />
    <path d="M88.6455 12.2566H84.8243V16.0778H88.6455V12.2566Z" fill="black" />
    <path d="M92.4666 12.2566H88.6455V16.0778H92.4666V12.2566Z" fill="black" />
    <path d="M96.2878 12.2566H92.4666V16.0778H96.2878V12.2566Z" fill="black" />
    <path d="M100.109 12.2566H96.2878V16.0778H100.109V12.2566Z" fill="black" />
    <path d="M103.93 12.2566H100.109V16.0778H103.93V12.2566Z" fill="black" />
    <path d="M107.751 12.2566H103.93V16.0778H107.751V12.2566Z" fill="black" />
    <path d="M16.0432 16.0778H12.222V19.8989H16.0432V16.0778Z" fill="black" />
    <path
      d="M38.9702 16.0778H35.1491L35.1491 19.8989H38.9702V16.0778Z"
      fill="black"
    />
    <path d="M58.0761 16.0778H54.2549V19.8989H58.0761V16.0778Z" fill="black" />
    <path d="M61.8973 16.0778H58.0761V19.8989H61.8973V16.0778Z" fill="black" />
    <path d="M84.8243 16.0778H81.0031V19.8989H84.8243V16.0778Z" fill="black" />
    <path d="M107.751 16.0778H103.93V19.8989H107.751V16.0778Z" fill="black" />
    <path d="M16.0432 19.8989H12.222V23.7201H16.0432V19.8989Z" fill="black" />
    <path d="M23.6856 19.8989H19.8644V23.7201H23.6856V19.8989Z" fill="black" />
    <path d="M27.5067 19.8989H23.6856V23.7201H27.5067V19.8989Z" fill="black" />
    <path d="M31.3279 19.8989H27.5067V23.7201H31.3279V19.8989Z" fill="black" />
    <path d="M38.9702 19.8989H35.1491V23.7201H38.9702V19.8989Z" fill="black" />
    <path d="M46.6126 19.8989H42.7914V23.7201H46.6126V19.8989Z" fill="black" />
    <path d="M50.4338 19.8989H46.6126V23.7201H50.4338V19.8989Z" fill="black" />
    <path d="M54.2549 19.8989H50.4338V23.7201H54.2549V19.8989Z" fill="black" />
    <path d="M61.8973 19.8989H58.0761V23.7201H61.8973V19.8989Z" fill="black" />
    <path d="M69.5396 19.8989H65.7184V23.7201H69.5396V19.8989Z" fill="black" />
    <path d="M84.8243 19.8989H81.0031V23.7201H84.8243V19.8989Z" fill="black" />
    <path d="M92.4666 19.8989H88.6455V23.7201H92.4666V19.8989Z" fill="black" />
    <path d="M96.2878 19.8989H92.4666V23.7201H96.2878V19.8989Z" fill="black" />
    <path d="M100.109 19.8989H96.2878V23.7201H100.109V19.8989Z" fill="black" />
    <path d="M107.751 19.8989H103.93V23.7201H107.751V19.8989Z" fill="black" />
    <path d="M16.0432 23.7201H12.222V27.5413H16.0432V23.7201Z" fill="black" />
    <path d="M23.6856 23.7201H19.8644V27.5413H23.6856V23.7201Z" fill="black" />
    <path d="M27.5067 23.7201H23.6856V27.5413H27.5067V23.7201Z" fill="black" />
    <path d="M31.3279 23.7201H27.5067V27.5413H31.3279V23.7201Z" fill="black" />
    <path d="M38.9702 23.7201H35.1491V27.5413H38.9702V23.7201Z" fill="black" />
    <path d="M46.6126 23.7201H42.7914V27.5413H46.6126V23.7201Z" fill="black" />
    <path d="M54.2549 23.7201H50.4338V27.5413H54.2549V23.7201Z" fill="black" />
    <path
      d="M73.3608 23.7201H69.5396L69.5396 27.5413H73.3608V23.7201Z"
      fill="black"
    />
    <path d="M84.8243 23.7201H81.0031V27.5413H84.8243V23.7201Z" fill="black" />
    <path d="M92.4666 23.7201H88.6455V27.5413H92.4666V23.7201Z" fill="black" />
    <path d="M96.2878 23.7201H92.4666V27.5413H96.2878V23.7201Z" fill="black" />
    <path d="M100.109 23.7201H96.2878V27.5413H100.109V23.7201Z" fill="black" />
    <path d="M107.751 23.7201H103.93V27.5413H107.751V23.7201Z" fill="black" />
    <path d="M16.0432 27.5413H12.222V31.3624H16.0432V27.5413Z" fill="black" />
    <path d="M23.6856 27.5413H19.8644V31.3624H23.6856V27.5413Z" fill="black" />
    <path d="M27.5067 27.5413H23.6856V31.3624H27.5067V27.5413Z" fill="black" />
    <path d="M31.3279 27.5413H27.5067V31.3624H31.3279V27.5413Z" fill="black" />
    <path d="M38.9702 27.5413H35.1491V31.3624H38.9702V27.5413Z" fill="black" />
    <path d="M46.6126 27.5413H42.7914V31.3624H46.6126V27.5413Z" fill="black" />
    <path d="M50.4338 27.5413H46.6126V31.3624H50.4338V27.5413Z" fill="black" />
    <path d="M54.2549 27.5413H50.4338V31.3624H54.2549V27.5413Z" fill="black" />
    <path d="M58.0761 27.5413H54.2549V31.3624H58.0761V27.5413Z" fill="black" />
    <path d="M65.7184 27.5413H61.8973V31.3624H65.7184V27.5413Z" fill="black" />
    <path d="M73.3608 27.5413H69.5396V31.3624H73.3608V27.5413Z" fill="black" />
    <path d="M84.8243 27.5413H81.0031V31.3624H84.8243V27.5413Z" fill="black" />
    <path d="M92.4666 27.5413H88.6455V31.3624H92.4666V27.5413Z" fill="black" />
    <path d="M96.2878 27.5413H92.4666V31.3624H96.2878V27.5413Z" fill="black" />
    <path d="M100.109 27.5413H96.2878V31.3624H100.109V27.5413Z" fill="black" />
    <path d="M107.751 27.5413H103.93V31.3624H107.751V27.5413Z" fill="black" />
    <path d="M16.0432 31.3624H12.222V35.1836H16.0432V31.3624Z" fill="black" />
    <path d="M38.9702 31.3624H35.1491V35.1836H38.9702V31.3624Z" fill="black" />
    <path d="M46.6126 31.3624H42.7914V35.1836H46.6126V31.3624Z" fill="black" />
    <path d="M50.4338 31.3624H46.6126V35.1836H50.4338V31.3624Z" fill="black" />
    <path d="M54.2549 31.3624H50.4338V35.1836H54.2549V31.3624Z" fill="black" />
    <path d="M61.8973 31.3624H58.0761V35.1836H61.8973V31.3624Z" fill="black" />
    <path d="M65.7184 31.3624H61.8973V35.1836H65.7184V31.3624Z" fill="black" />
    <path d="M77.1819 31.3624H73.3608V35.1836H77.1819V31.3624Z" fill="black" />
    <path d="M84.8243 31.3624H81.0031V35.1836H84.8243V31.3624Z" fill="black" />
    <path d="M107.751 31.3624H103.93V35.1836H107.751V31.3624Z" fill="black" />
    <path d="M16.0432 35.1836H12.222V39.0048H16.0432V35.1836Z" fill="black" />
    <path
      d="M19.8644 35.1836L16.0432 35.1836V39.0048H19.8644V35.1836Z"
      fill="black"
    />
    <path d="M23.6856 35.1836H19.8644V39.0048H23.6856V35.1836Z" fill="black" />
    <path d="M27.5067 35.1836H23.6856V39.0048H27.5067V35.1836Z" fill="black" />
    <path d="M31.3279 35.1836H27.5067V39.0048H31.3279V35.1836Z" fill="black" />
    <path
      d="M35.1491 35.1836L31.3279 35.1836V39.0048H35.1491L35.1491 35.1836Z"
      fill="black"
    />
    <path
      d="M38.9702 35.1836H35.1491L35.1491 39.0048H38.9702V35.1836Z"
      fill="black"
    />
    <path d="M46.6126 35.1836H42.7914V39.0048H46.6126V35.1836Z" fill="black" />
    <path d="M54.2549 35.1836H50.4338V39.0048H54.2549V35.1836Z" fill="black" />
    <path d="M61.8973 35.1836H58.0761V39.0048H61.8973V35.1836Z" fill="black" />
    <path
      d="M69.5396 35.1836L65.7184 35.1836V39.0048H69.5396V35.1836Z"
      fill="black"
    />
    <path d="M77.1819 35.1836H73.3608V39.0048H77.1819V35.1836Z" fill="black" />
    <path d="M84.8243 35.1836H81.0031V39.0048H84.8243V35.1836Z" fill="black" />
    <path
      d="M88.6455 35.1836L84.8243 35.1836V39.0048H88.6455V35.1836Z"
      fill="black"
    />
    <path d="M92.4666 35.1836H88.6455V39.0048H92.4666V35.1836Z" fill="black" />
    <path d="M96.2878 35.1836H92.4666V39.0048H96.2878V35.1836Z" fill="black" />
    <path d="M100.109 35.1836H96.2878V39.0048H100.109V35.1836Z" fill="black" />
    <path d="M103.93 35.1836L100.109 35.1836V39.0048H103.93V35.1836Z" fill="black" />
    <path d="M107.751 35.1836H103.93V39.0048H107.751V35.1836Z" fill="black" />
    <path d="M50.4338 39.0048H46.6126V42.826H50.4338V39.0048Z" fill="black" />
    <path d="M58.0761 39.0048H54.2549V42.826H58.0761V39.0048Z" fill="black" />
    <path d="M61.8973 39.0048H58.0761V42.826H61.8973V39.0048Z" fill="black" />
    <path d="M65.7184 39.0048H61.8973V42.826H65.7184V39.0048Z" fill="black" />
    <path d="M69.5396 39.0048H65.7184V42.826H69.5396V39.0048Z" fill="black" />
    <path d="M77.1819 39.0048H73.3608V42.826H77.1819V39.0048Z" fill="black" />
    <path d="M23.6856 42.826H19.8644V46.6471H23.6856V42.826Z" fill="black" />
    <path d="M27.5067 42.826H23.6856V46.6471H27.5067V42.826Z" fill="black" />
    <path d="M31.3279 42.826H27.5067V46.6471H31.3279V42.826Z" fill="black" />
    <path d="M35.1491 42.826H31.3279V46.6471H35.1491V42.826Z" fill="black" />
    <path d="M38.9702 42.826H35.1491V46.6471H38.9702V42.826Z" fill="black" />
    <path d="M42.7914 42.826H38.9702V46.6471H42.7914V42.826Z" fill="black" />
    <path d="M50.4338 42.826H46.6126V46.6471H50.4338V42.826Z" fill="black" />
    <path d="M61.8973 42.826H58.0761V46.6471H61.8973V42.826Z" fill="black" />
    <path d="M69.5396 42.826H65.7184V46.6471H69.5396V42.826Z" fill="black" />
    <path d="M77.1819 42.826H73.3608V46.6471H77.1819V42.826Z" fill="black" />
    <path d="M81.0031 42.826H77.1819V46.6471H81.0031V42.826Z" fill="black" />
    <path d="M88.6455 42.826H84.8243V46.6471H88.6455V42.826Z" fill="black" />
    <path d="M92.4666 42.826H88.6455V46.6471H92.4666V42.826Z" fill="black" />
    <path d="M96.2878 42.826H92.4666V46.6471H96.2878V42.826Z" fill="black" />
    <path d="M100.109 42.826H96.2878V46.6471H100.109V42.826Z" fill="black" />
    <path d="M107.751 42.826H103.93V46.6471H107.751V42.826Z" fill="black" />
    <path d="M19.8644 46.6471H16.0432V50.4683H19.8644V46.6471Z" fill="black" />
    <path d="M23.6856 46.6471H19.8644V50.4683H23.6856V46.6471Z" fill="black" />
    <path d="M31.3279 46.6471H27.5067V50.4683H31.3279V46.6471Z" fill="black" />
    <path d="M35.1491 46.6471H31.3279V50.4683H35.1491V46.6471Z" fill="black" />
    <path d="M46.6126 46.6471H42.7914V50.4683H46.6126V46.6471Z" fill="black" />
    <path d="M50.4338 46.6471H46.6126V50.4683H50.4338V46.6471Z" fill="black" />
    <path d="M54.2549 46.6471H50.4338V50.4683H54.2549V46.6471Z" fill="black" />
    <path d="M58.0761 46.6471H54.2549V50.4683H58.0761V46.6471Z" fill="black" />
    <path d="M61.8973 46.6471H58.0761V50.4683H61.8973V46.6471Z" fill="black" />
    <path d="M69.5396 46.6471H65.7184V50.4683H69.5396V46.6471Z" fill="black" />
    <path d="M73.3608 46.6471H69.5396V50.4683H73.3608V46.6471Z" fill="black" />
    <path d="M77.1819 46.6471H73.3608V50.4683H77.1819V46.6471Z" fill="black" />
    <path d="M92.4666 46.6471H88.6455V50.4683H92.4666V46.6471Z" fill="black" />
    <path d="M96.2878 46.6471H92.4666V50.4683H96.2878V46.6471Z" fill="black" />
    <path d="M100.109 46.6471H96.2878V50.4683H100.109V46.6471Z" fill="black" />
    <path d="M103.93 46.6471H100.109V50.4683H103.93V46.6471Z" fill="black" />
    <path d="M19.8644 50.4683H16.0432V54.2895H19.8644V50.4683Z" fill="black" />
    <path d="M27.5067 50.4683H23.6856V54.2895H27.5067V50.4683Z" fill="black" />
    <path d="M31.3279 50.4683H27.5067V54.2895H31.3279V50.4683Z" fill="black" />
    <path
      d="M38.9702 50.4683H35.1491L35.1491 54.2895H38.9702V50.4683Z"
      fill="black"
    />
    <path d="M50.4338 50.4683H46.6126V54.2895H50.4338V50.4683Z" fill="black" />
    <path d="M54.2549 50.4683H50.4338V54.2895H54.2549V50.4683Z" fill="black" />
    <path d="M61.8973 50.4683H58.0761V54.2895H61.8973V50.4683Z" fill="black" />
    <path d="M65.7184 50.4683H61.8973V54.2895H65.7184V50.4683Z" fill="black" />
    <path
      d="M73.3608 50.4683H69.5396L69.5396 54.2895H73.3608V50.4683Z"
      fill="black"
    />
    <path d="M84.8243 50.4683H81.0031V54.2895H84.8243V50.4683Z" fill="black" />
    <path d="M88.6455 50.4683H84.8243V54.2895H88.6455V50.4683Z" fill="black" />
    <path d="M96.2878 50.4683H92.4666V54.2895H96.2878V50.4683Z" fill="black" />
    <path d="M100.109 50.4683H96.2878V54.2895H100.109V50.4683Z" fill="black" />
    <path d="M107.751 50.4683H103.93V54.2895H107.751V50.4683Z" fill="black" />
    <path d="M16.0432 54.2895H12.222V58.1106H16.0432V54.2895Z" fill="black" />
    <path d="M23.6856 54.2895H19.8644V58.1106H23.6856V54.2895Z" fill="black" />
    <path d="M27.5067 54.2895H23.6856V58.1106H27.5067V54.2895Z" fill="black" />
    <path d="M31.3279 54.2895H27.5067V58.1106H31.3279V54.2895Z" fill="black" />
    <path
      d="M35.1491 54.2895H31.3279V58.1106H35.1491L35.1491 54.2895Z"
      fill="black"
    />
    <path d="M42.7914 54.2895H38.9702V58.1106H42.7914V54.2895Z" fill="black" />
    <path d="M46.6126 54.2895H42.7914V58.1106H46.6126V54.2895Z" fill="black" />
    <path d="M50.4338 54.2895H46.6126V58.1106H50.4338V54.2895Z" fill="black" />
    <path d="M84.8243 54.2895H81.0031V58.1106H84.8243V54.2895Z" fill="black" />
    <path d="M88.6455 54.2895H84.8243V58.1106H88.6455V54.2895Z" fill="black" />
    <path d="M92.4666 54.2895H88.6455V58.1106H92.4666V54.2895Z" fill="black" />
    <path d="M27.5067 58.1106H23.6856V61.9318H27.5067V58.1106Z" fill="black" />
    <path
      d="M38.9702 58.1106H35.1491L35.1491 61.9318H38.9702V58.1106Z"
      fill="black"
    />
    <path d="M42.7914 58.1106H38.9702V61.9318H42.7914V58.1106Z" fill="black" />
    <path d="M46.6126 58.1106H42.7914V61.9318H46.6126V58.1106Z" fill="black" />
    <path d="M65.7184 58.1106H61.8973V61.9318H65.7184V58.1106Z" fill="black" />
    <path d="M73.3608 58.1106H69.5396V61.9318H73.3608V58.1106Z" fill="black" />
    <path d="M77.1819 58.1106H73.3608V61.9318H77.1819V58.1106Z" fill="black" />
    <path d="M84.8243 58.1106H81.0031V61.9318H84.8243V58.1106Z" fill="black" />
    <path d="M96.2878 58.1106H92.4666V61.9318H96.2878V58.1106Z" fill="black" />
    <path d="M103.93 58.1106H100.109V61.9318H103.93V58.1106Z" fill="black" />
    <path d="M107.751 58.1106H103.93V61.9318H107.751V58.1106Z" fill="black" />
    <path d="M31.3279 61.9318H27.5067V65.753H31.3279V61.9318Z" fill="black" />
    <path
      d="M35.1491 61.9318H31.3279V65.753H35.1491L35.1491 61.9318Z"
      fill="black"
    />
    <path d="M42.7914 61.9318H38.9702V65.753H42.7914V61.9318Z" fill="black" />
    <path d="M65.7184 61.9318H61.8973V65.753H65.7184V61.9318Z" fill="black" />
    <path
      d="M69.5396 61.9318H65.7184V65.753H69.5396L69.5396 61.9318Z"
      fill="black"
    />
    <path
      d="M73.3608 61.9318H69.5396L69.5396 65.753H73.3608V61.9318Z"
      fill="black"
    />
    <path d="M96.2878 61.9318H92.4666V65.753H96.2878V61.9318Z" fill="black" />
    <path d="M100.109 61.9318H96.2878V65.753H100.109V61.9318Z" fill="black" />
    <path d="M103.93 61.9318H100.109V65.753H103.93V61.9318Z" fill="black" />
    <path d="M23.6856 65.753H19.8644V69.5742H23.6856V65.753Z" fill="black" />
    <path d="M31.3279 65.753H27.5067V69.5742H31.3279V65.753Z" fill="black" />
    <path d="M35.1491 65.753H31.3279V69.5742H35.1491V65.753Z" fill="black" />
    <path d="M38.9702 65.753H35.1491V69.5742H38.9702V65.753Z" fill="black" />
    <path d="M46.6126 65.753H42.7914V69.5742H46.6126V65.753Z" fill="black" />
    <path d="M58.0761 65.753H54.2549V69.5742H58.0761V65.753Z" fill="black" />
    <path d="M65.7184 65.753H61.8973V69.5742H65.7184V65.753Z" fill="black" />
    <path d="M69.5396 65.753H65.7184V69.5742H69.5396V65.753Z" fill="black" />
    <path d="M77.1819 65.753H73.3608V69.5742H77.1819V65.753Z" fill="black" />
    <path d="M88.6455 65.753H84.8243V69.5742H88.6455V65.753Z" fill="black" />
    <path d="M96.2878 65.753H92.4666V69.5742H96.2878V65.753Z" fill="black" />
    <path d="M100.109 65.753H96.2878V69.5742H100.109V65.753Z" fill="black" />
    <path d="M107.751 65.753H103.93V69.5742H107.751V65.753Z" fill="black" />
    <path d="M16.0432 69.5742H12.222V73.3953H16.0432V69.5742Z" fill="black" />
    <path d="M31.3279 69.5742H27.5067V73.3953H31.3279V69.5742Z" fill="black" />
    <path d="M35.1491 69.5742H31.3279V73.3953H35.1491V69.5742Z" fill="black" />
    <path d="M42.7914 69.5742H38.9702V73.3953H42.7914V69.5742Z" fill="black" />
    <path d="M46.6126 69.5742H42.7914V73.3953H46.6126V69.5742Z" fill="black" />
    <path
      d="M50.4338 69.5742L46.6126 69.5742V73.3953H50.4338V69.5742Z"
      fill="black"
    />
    <path d="M65.7184 69.5742H61.8973V73.3953H65.7184V69.5742Z" fill="black" />
    <path d="M69.5396 69.5742H65.7184V73.3953H69.5396V69.5742Z" fill="black" />
    <path d="M73.3608 69.5742H69.5396V73.3953H73.3608V69.5742Z" fill="black" />
    <path d="M77.1819 69.5742H73.3608V73.3953H77.1819V69.5742Z" fill="black" />
    <path
      d="M81.0031 69.5742L77.1819 69.5742V73.3953H81.0031V69.5742Z"
      fill="black"
    />
    <path d="M92.4666 69.5742H88.6455V73.3953H92.4666V69.5742Z" fill="black" />
    <path d="M96.2878 69.5742H92.4666V73.3953H96.2878V69.5742Z" fill="black" />
    <path d="M103.93 69.5742H100.109V73.3953H103.93V69.5742Z" fill="black" />
    <path d="M16.0432 73.3953H12.222V77.2165H16.0432V73.3953Z" fill="black" />
    <path d="M23.6856 73.3953H19.8644V77.2165H23.6856V73.3953Z" fill="black" />
    <path d="M27.5067 73.3953H23.6856V77.2165H27.5067V73.3953Z" fill="black" />
    <path d="M35.1491 73.3953H31.3279V77.2165H35.1491V73.3953Z" fill="black" />
    <path d="M38.9702 73.3953H35.1491V77.2165H38.9702V73.3953Z" fill="black" />
    <path d="M50.4338 73.3953H46.6126V77.2165H50.4338V73.3953Z" fill="black" />
    <path d="M58.0761 73.3953H54.2549V77.2165H58.0761V73.3953Z" fill="black" />
    <path d="M69.5396 73.3953H65.7184V77.2165H69.5396V73.3953Z" fill="black" />
    <path d="M73.3608 73.3953H69.5396V77.2165H73.3608V73.3953Z" fill="black" />
    <path d="M77.1819 73.3953H73.3608V77.2165H77.1819V73.3953Z" fill="black" />
    <path d="M81.0031 73.3953H77.1819V77.2165H81.0031V73.3953Z" fill="black" />
    <path d="M84.8243 73.3953H81.0031V77.2165H84.8243V73.3953Z" fill="black" />
    <path d="M88.6455 73.3953H84.8243V77.2165H88.6455V73.3953Z" fill="black" />
    <path d="M92.4666 73.3953H88.6455V77.2165H92.4666V73.3953Z" fill="black" />
    <path d="M103.93 73.3953H100.109V77.2165H103.93V73.3953Z" fill="black" />
    <path d="M61.8973 77.2165H58.0761V81.0377H61.8973V77.2165Z" fill="black" />
    <path
      d="M73.3608 77.2165H69.5396L69.5396 81.0377H73.3608V77.2165Z"
      fill="black"
    />
    <path d="M77.1819 77.2165H73.3608V81.0377H77.1819V77.2165Z" fill="black" />
    <path d="M92.4666 77.2165H88.6455V81.0377H92.4666V77.2165Z" fill="black" />
    <path d="M96.2878 77.2165H92.4666V81.0377H96.2878V77.2165Z" fill="black" />
    <path d="M103.93 77.2165H100.109V81.0377H103.93V77.2165Z" fill="black" />
    <path d="M107.751 77.2165H103.93V81.0377H107.751V77.2165Z" fill="black" />
    <path d="M16.0432 81.0377H12.222V84.8588H16.0432V81.0377Z" fill="black" />
    <path d="M19.8644 81.0377H16.0432V84.8588H19.8644V81.0377Z" fill="black" />
    <path d="M23.6856 81.0377H19.8644V84.8588H23.6856V81.0377Z" fill="black" />
    <path d="M27.5067 81.0377H23.6856V84.8588H27.5067V81.0377Z" fill="black" />
    <path d="M31.3279 81.0377H27.5067V84.8588H31.3279V81.0377Z" fill="black" />
    <path d="M35.1491 81.0377H31.3279V84.8588H35.1491V81.0377Z" fill="black" />
    <path d="M38.9702 81.0377H35.1491V84.8588H38.9702V81.0377Z" fill="black" />
    <path d="M46.6126 81.0377H42.7914V84.8588H46.6126V81.0377Z" fill="black" />
    <path d="M54.2549 81.0377H50.4338V84.8588H54.2549V81.0377Z" fill="black" />
    <path d="M58.0761 81.0377H54.2549V84.8588H58.0761V81.0377Z" fill="black" />
    <path d="M61.8973 81.0377H58.0761V84.8588H61.8973V81.0377Z" fill="black" />
    <path
      d="M69.5396 81.0377H65.7184V84.8588H69.5396L69.5396 81.0377Z"
      fill="black"
    />
    <path d="M77.1819 81.0377H73.3608V84.8588H77.1819V81.0377Z" fill="black" />
    <path d="M84.8243 81.0377H81.0031V84.8588H84.8243V81.0377Z" fill="black" />
    <path d="M92.4666 81.0377H88.6455V84.8588H92.4666V81.0377Z" fill="black" />
    <path d="M96.2878 81.0377H92.4666V84.8588H96.2878V81.0377Z" fill="black" />
    <path d="M16.0432 84.8588H12.222V88.68H16.0432V84.8588Z" fill="black" />
    <path d="M38.9702 84.8588H35.1491L35.1491 88.68H38.9702V84.8588Z" fill="black" />
    <path d="M46.6126 84.8588H42.7914V88.68H46.6126V84.8588Z" fill="black" />
    <path d="M61.8973 84.8588H58.0761V88.68H61.8973V84.8588Z" fill="black" />
    <path d="M65.7184 84.8588H61.8973V88.68H65.7184V84.8588Z" fill="black" />
    <path d="M69.5396 84.8588H65.7184V88.68H69.5396V84.8588Z" fill="black" />
    <path d="M77.1819 84.8588H73.3608V88.68H77.1819V84.8588Z" fill="black" />
    <path d="M92.4666 84.8588H88.6455V88.68H92.4666V84.8588Z" fill="black" />
    <path d="M96.2878 84.8588H92.4666V88.68H96.2878V84.8588Z" fill="black" />
    <path d="M100.109 84.8588H96.2878V88.68H100.109V84.8588Z" fill="black" />
    <path d="M16.0432 88.68H12.222V92.5012H16.0432V88.68Z" fill="black" />
    <path d="M23.6856 88.68H19.8644V92.5012H23.6856V88.68Z" fill="black" />
    <path d="M27.5067 88.68H23.6856V92.5012H27.5067V88.68Z" fill="black" />
    <path d="M31.3279 88.68H27.5067V92.5012H31.3279V88.68Z" fill="black" />
    <path d="M38.9702 88.68H35.1491V92.5012H38.9702V88.68Z" fill="black" />
    <path d="M46.6126 88.68H42.7914V92.5012H46.6126V88.68Z" fill="black" />
    <path d="M54.2549 88.68H50.4338V92.5012H54.2549V88.68Z" fill="black" />
    <path d="M61.8973 88.68H58.0761V92.5012H61.8973V88.68Z" fill="black" />
    <path d="M77.1819 88.68H73.3608V92.5012H77.1819V88.68Z" fill="black" />
    <path d="M81.0031 88.68H77.1819V92.5012H81.0031V88.68Z" fill="black" />
    <path d="M84.8243 88.68H81.0031V92.5012H84.8243V88.68Z" fill="black" />
    <path d="M88.6455 88.68H84.8243V92.5012H88.6455V88.68Z" fill="black" />
    <path d="M92.4666 88.68H88.6455V92.5012H92.4666V88.68Z" fill="black" />
    <path d="M96.2878 88.68H92.4666V92.5012H96.2878V88.68Z" fill="black" />
    <path d="M16.0432 92.5012H12.222V96.3224H16.0432V92.5012Z" fill="black" />
    <path d="M23.6856 92.5012H19.8644V96.3224H23.6856V92.5012Z" fill="black" />
    <path d="M27.5067 92.5012H23.6856V96.3224H27.5067V92.5012Z" fill="black" />
    <path d="M31.3279 92.5012H27.5067V96.3224H31.3279V92.5012Z" fill="black" />
    <path d="M38.9702 92.5012H35.1491V96.3224H38.9702V92.5012Z" fill="black" />
    <path d="M46.6126 92.5012H42.7914V96.3224H46.6126V92.5012Z" fill="black" />
    <path d="M65.7184 92.5012H61.8973V96.3224H65.7184V92.5012Z" fill="black" />
    <path d="M69.5396 92.5012H65.7184V96.3224H69.5396V92.5012Z" fill="black" />
    <path d="M77.1819 92.5012H73.3608V96.3224H77.1819V92.5012Z" fill="black" />
    <path d="M84.8243 92.5012H81.0031V96.3224H84.8243V92.5012Z" fill="black" />
    <path d="M92.4666 92.5012H88.6455V96.3224H92.4666V92.5012Z" fill="black" />
    <path d="M103.93 92.5012H100.109V96.3224H103.93V92.5012Z" fill="black" />
    <path d="M107.751 92.5012H103.93V96.3224H107.751V92.5012Z" fill="black" />
    <path d="M16.0432 96.3224H12.222V100.144H16.0432V96.3224Z" fill="black" />
    <path d="M23.6856 96.3224H19.8644V100.144H23.6856V96.3224Z" fill="black" />
    <path d="M27.5067 96.3224H23.6856V100.144H27.5067V96.3224Z" fill="black" />
    <path d="M31.3279 96.3224H27.5067V100.144H31.3279V96.3224Z" fill="black" />
    <path d="M38.9702 96.3224H35.1491V100.144H38.9702V96.3224Z" fill="black" />
    <path d="M46.6126 96.3224H42.7914V100.144H46.6126V96.3224Z" fill="black" />
    <path d="M50.4338 96.3224H46.6126V100.144H50.4338V96.3224Z" fill="black" />
    <path d="M61.8973 96.3224H58.0761V100.144H61.8973V96.3224Z" fill="black" />
    <path d="M77.1819 96.3224H73.3608V100.144H77.1819V96.3224Z" fill="black" />
    <path d="M81.0031 96.3224H77.1819V100.144H81.0031V96.3224Z" fill="black" />
    <path d="M92.4666 96.3224H88.6455V100.144H92.4666V96.3224Z" fill="black" />
    <path d="M107.751 96.3224H103.93V100.144H107.751V96.3224Z" fill="black" />
    <path d="M16.0432 100.144H12.222V103.965H16.0432V100.144Z" fill="black" />
    <path d="M38.9702 100.144H35.1491V103.965H38.9702V100.144Z" fill="black" />
    <path d="M54.2549 100.144H50.4338V103.965H54.2549V100.144Z" fill="black" />
    <path d="M58.0761 100.144H54.2549V103.965H58.0761V100.144Z" fill="black" />
    <path d="M69.5396 100.144H65.7184V103.965H69.5396V100.144Z" fill="black" />
    <path d="M73.3608 100.144H69.5396V103.965H73.3608V100.144Z" fill="black" />
    <path d="M81.0031 100.144H77.1819V103.965H81.0031V100.144Z" fill="black" />
    <path d="M84.8243 100.144H81.0031V103.965H84.8243V100.144Z" fill="black" />
    <path d="M92.4666 100.144H88.6455V103.965H92.4666V100.144Z" fill="black" />
    <path d="M107.751 100.144H103.93V103.965H107.751V100.144Z" fill="black" />
    <path d="M16.0432 103.965H12.222V107.786H16.0432V103.965Z" fill="black" />
    <path d="M19.8644 103.965H16.0432V107.786H19.8644V103.965Z" fill="black" />
    <path d="M23.6856 103.965H19.8644V107.786H23.6856V103.965Z" fill="black" />
    <path d="M27.5067 103.965H23.6856V107.786H27.5067V103.965Z" fill="black" />
    <path d="M31.3279 103.965H27.5067V107.786H31.3279V103.965Z" fill="black" />
    <path
      d="M35.1491 103.965H31.3279V107.786H35.1491L35.1491 103.965Z"
      fill="black"
    />
    <path
      d="M38.9702 103.965H35.1491L35.1491 107.786H38.9702V103.965Z"
      fill="black"
    />
    <path d="M54.2549 103.965H50.4338V107.786H54.2549V103.965Z" fill="black" />
    <path d="M58.0761 103.965H54.2549V107.786H58.0761V103.965Z" fill="black" />
    <path d="M61.8973 103.965H58.0761V107.786H61.8973V103.965Z" fill="black" />
    <path d="M69.5396 103.965H65.7184V107.786H69.5396V103.965Z" fill="black" />
    <path d="M77.1819 103.965H73.3608V107.786H77.1819V103.965Z" fill="black" />
    <path d="M84.8243 103.965H81.0031V107.786H84.8243V103.965Z" fill="black" />
    <path d="M96.2878 103.965H92.4666V107.786H96.2878V103.965Z" fill="black" />
    <path d="M100.109 103.965H96.2878V107.786H100.109V103.965Z" fill="black" />
    <path d="M103.93 103.965H100.109V107.786H103.93V103.965Z" fill="black" />
    <path d="M107.751 103.965H103.93V107.786H107.751V103.965Z" fill="black" />
    <rect
      x="0.8349"
      y="0.77887"
      width="117.265"
      height="118.168"
      rx="6.23017"
      stroke="#EFEFF7"
      strokeWidth="0.830689"
    />
  </svg>
);
