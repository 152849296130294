import React from 'react';
import { useTranslation } from 'react-i18next';

import { AvatarContainer } from '../avatar-container/AvatarContainer';
import { Button, CloseButton } from 'components/buttons';
import { ContactDetails } from '../contact-details/ContactDetails';
import { GenerateCardModal } from 'domains/cards/components/generate-card-modal';
import { RequestPhotoModal } from 'domains/cards/components/request-photo-modal';
import { RoleActions } from '../role-actions/RoleActions';
import useCardPanel from '../../hooks/useCardPanel';

import { IEmployeeCard } from 'types/card.types';
import {
  Container,
  TitleHead,
  Overlay,
  Body,
  Inner,
  Header,
  Details,
  ModificationDate,
  DateLabel,
  Actions,
} from '../../EditCardPanelStyles';

interface Props {
  shouldShow: boolean;
  handleClose: () => void;
  cardInstance: IEmployeeCard;
}

export const Panel: React.FC<Props> = React.memo(
  ({ shouldShow, handleClose, cardInstance }: Props) => {
    const { t } = useTranslation();
    const tHead = t('User details');
    const tLastUpdate = t('Last Updated:');
    const tLastGenerated = t('Last Generated:');
    const tLastPhotoRequest = t('Last Photo Request:');
    const tGenerateCard = t(`Generate card`);
    const tRequestPhoto = t(`Request photo`);

    const {
      canGenerateCard,
      canRequestPhoto,
      isUploading,
      lastGenerated,
      lastMod,
      lastRequested,
      ...photoUploadProps
    } = useCardPanel({ cardInstance });

    const employeeHasPhoto = !!cardInstance.photoUrl;

    return (
      <Container shouldShow={shouldShow}>
        <Overlay onClick={handleClose} />
        <Body shouldShow={shouldShow}>
          <TitleHead>
            <span>{tHead}</span>
            <CloseButton onClick={handleClose} />
          </TitleHead>

          <Inner>
            <Header>
              <AvatarContainer
                employee={cardInstance}
                isUploading={isUploading}
                {...photoUploadProps}
              />

              <Details>
                <ModificationDate>
                  <DateLabel>{tLastUpdate}</DateLabel>
                  <span>{lastMod}</span>

                  <DateLabel>{tLastGenerated}</DateLabel>
                  <span>{lastGenerated}</span>

                  <DateLabel>{tLastPhotoRequest}</DateLabel>
                  <span>{lastRequested}</span>
                </ModificationDate>

                <RoleActions employee={cardInstance} />
              </Details>
            </Header>
            <ContactDetails cardInstance={cardInstance} isLoading={isUploading} />

            <Actions>
              {!!canRequestPhoto && (
                <RequestPhotoModal selectedCardsIds={cardInstance.id}>
                  {({ handleOpen, isLoading }) => (
                    <Button
                      size="medium"
                      intent="dark"
                      onClick={handleOpen}
                      isLoading={isLoading}
                    >
                      {tRequestPhoto}
                    </Button>
                  )}
                </RequestPhotoModal>
              )}
              {!!canGenerateCard && employeeHasPhoto && (
                <GenerateCardModal selectedCardsIds={cardInstance.id}>
                  {({ handleOpen, isLoading }) => (
                    <Button
                      size="medium"
                      intent="success"
                      onClick={handleOpen}
                      isLoading={isLoading}
                    >
                      {tGenerateCard}
                    </Button>
                  )}
                </GenerateCardModal>
              )}
            </Actions>
          </Inner>
        </Body>
      </Container>
    );
  },
);
