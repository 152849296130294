import { useMutation } from 'react-query';
import { firestore } from 'data/firebase';
import { useAppSelector } from 'state/hooks';
import { doc, writeBatch } from 'firebase/firestore';
import useGetNotifications from 'data/data-hooks/queries/useGetNotifications';

import dbLabels from 'data/db.labels';
import { INotification } from 'types/notification.types';

interface IArgs {
  onSuccess?: () => void;
}

export default (args?: IArgs) => {
  const companyId = useAppSelector(({ company }) => company.currentCompany?.id);
  const { refresh } = useGetNotifications();

  const mutation = useMutation(
    async (input: INotification | INotification[]) => {
      const list = Array.isArray(input) ? input : [input];
      const notifications = list.filter(({ status }) => status !== 'seen');

      const _writeBatch = writeBatch(firestore);
      notifications.forEach(({ id }) => {
        const ref = doc(
          firestore,
          dbLabels.company,
          companyId || '',
          dbLabels.notifications,
          id,
        );

        _writeBatch.update(ref, { status: 'seen' });
      });
      return _writeBatch.commit();
    },
    {
      onSuccess: async () => {
        return Promise.all([refresh(), args?.onSuccess && args.onSuccess()]);
      },
    },
  );

  return { markAsSeen: mutation.mutateAsync, ...mutation };
};
