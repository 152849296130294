import React, { useCallback, useEffect, useMemo } from 'react';

import useCards from 'domains/hooks/useCards';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  launchCardManagement,
  setPageSize,
  toggleCardSelection,
  toggleSelection,
} from 'state/card-management/CardManagementActions';
import useCardsFilters from 'domains/hooks/useCardsFilters';

import { CardsTable } from './components/table/CardsTable';
import COLUMNS from './assets/columns';

import { ICard, IEmployeeCard } from 'types/card.types';
import { TFilter } from 'types/shared.types';

export const CardUpdatesList: React.FC = () => {
  const { activeFilter, handleFilterChange, setCustomDateRange } = useCardsFilters();
  const { startDate, endDate, selectedCardsIds } = useAppSelector(
    ({ cardManagement }) => cardManagement,
  );

  const dispatch = useAppDispatch();
  const { isLoading, filteredCards } = useCards();

  const selectedRows = useMemo(() => [...selectedCardsIds], [selectedCardsIds]);

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => {
    return filteredCards;
  }, [filteredCards]);

  const toggleSelectAll = useCallback(
    () => dispatch(toggleSelection(data as ICard[])),
    [data],
  );
  const onSelect = useCallback(
    (row: IEmployeeCard) => dispatch(toggleCardSelection(row.cardId)),
    [],
  );
  const onPageSizeChange = useCallback(
    (size: number) => dispatch(setPageSize(size)),
    [],
  );

  useEffect(() => {
    dispatch(launchCardManagement());
  }, []);

  return (
    <CardsTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      onPageSizeChange={onPageSizeChange}
      selectProps={{
        onSelect,
        selectedRows,
        toggleSelectAll,
      }}
      headerProps={{
        activeFilter: activeFilter as TFilter,
        endDate,
        handleFilterChange,
        setCustomDateRange,
        startDate,
      }}
    />
  );
};
