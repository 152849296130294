export const PATHS = {
  adminPhotoVerification: '/verify-photos',
  cardConfiguration: '/card-management/config',
  cardManagement: '/card-management',
  cardTracker: '/card-tracker',
  contactUs: '/contact-us',
  createAccount: '/create-account/:token',
  dashboard: '/dashboard',
  emailTemplates: '/email-templates',
  forgotPassword: '/reset-password',
  home: '/home',
  login: '/login',
  notifications: '/notifications',
  pageNotFound: '/page-not-found',
  photoIdUpload: '/photo-verification/:token',
  privacyPolicy: '/privacy-policy',
  settings: '/settings',
  termsOfService: '/terms-of-service',
};
