import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.primaryBlack};
  min-height: fit-content;

  @media (max-width: ${(props) =>
      props.theme.sizeRanges.desktop.max}px) and (min-width: ${(props) =>
      props.theme.sizeRanges.desktop.min}px) {
    min-height: calc(100vh - 85px);
  }

  @media (max-width: ${(props) => props.theme.sizeRanges.tablet.max}px) {
    flex-direction: column;
  }
`;

export const LeftInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin-top: 55px;
`;

export const MainText = styled.p`
  color: white;
  opacity: 0.6;
`;

export const Section = styled.div`
  flex: 1;
  padding: 90px;

  @media (max-width: ${(props) => props.theme.sizeRanges.desktop.min}px) {
    padding: 50px 90px;
    min-height: fit-content;
  }

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    padding: 50px 45px;
  }
`;

export const SubText = styled.h3`
  color: white;
  margin-bottom: 20px;
`;

export const Text = styled.div``;

export const Title = styled.h1`
  margin-bottom: 40px;
`;
