import React, { Fragment, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { toggleSelection } from 'state/admin-verification/AdminVerficationActions';
import { useAppSelector } from 'state/hooks';
import { usePhotoRequests } from 'contexts';

import { ApproveRequestModal } from '../approve-request-modal/ApproveRequestModal';
import { Button, FlatButton } from 'components/buttons';
import { DeleteRequestModal } from '../delete-request-modal/DeleteRequestModal';
import { DenyRequestModal } from '../deny-request-modal/DenyRequestModal';
import { SendRemindersModal } from '../send-reminders-modal/SendRemindersModal';

import { EPhotoRequestStatus } from 'types/photoRequest.types';
import { Container, EndContainer, Sync } from './GroupActionModalsStyles';

interface Props {
  showActions: boolean;
}

export const GroupActionModals: React.FC<Props> = ({ showActions }: Props) => {
  const [showApprove, setShowApprove] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDeny, setShowDeny] = useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const dispatch = useDispatch();

  const unselectAll = () => dispatch(toggleSelection([]));
  const { requests, refetch, isLoading, isFetching } = usePhotoRequests();
  const { selectedRequestsIds } = useAppSelector(
    ({ adminVerification }) => adminVerification,
  );

  const selectedReqs = useMemo(
    () => requests.filter(({ id }) => selectedRequestsIds.has(id)),
    [selectedRequestsIds],
  );

  const { t } = useTranslation();
  const tApprove = t('Approve photos');
  const tDeny = t('Deny photos');
  const tReminder = t('Send reminders');

  const resync = () => refetch();

  const showAllActions = !selectedReqs.filter(
    (req) => req.status !== EPhotoRequestStatus.uploaded,
  ).length;
  const showReminderAction = !selectedReqs.filter(
    (req) =>
      req.status === EPhotoRequestStatus.uploaded ||
      req.status === EPhotoRequestStatus.accepted,
  ).length;

  return (
    <Container>
      {!!showActions && (
        <Fragment>
          {showAllActions ? (
            <>
              <Button intent="secondary" onClick={() => setShowApprove(true)}>
                {tApprove}
              </Button>
              <Button intent="dark" onClick={() => setShowDeny(true)}>
                {tDeny}
              </Button>
            </>
          ) : (
            showReminderAction && (
              <Button intent="success" onClick={() => setShowReminder(true)}>
                {tReminder}
              </Button>
            )
          )}

          <ApproveRequestModal
            handleClose={() => setShowApprove(false)}
            onConfirm={unselectAll}
            requests={selectedReqs}
            shouldShow={showApprove}
          />

          <DenyRequestModal
            handleClose={() => setShowDeny(false)}
            onConfirm={unselectAll}
            requests={selectedReqs}
            shouldShow={showDeny}
          />

          <DeleteRequestModal
            handleClose={() => setShowDelete(false)}
            onConfirm={unselectAll}
            requests={selectedReqs}
            shouldShow={showDelete}
          />

          <SendRemindersModal
            handleClose={() => setShowReminder(false)}
            onConfirm={unselectAll}
            requests={selectedReqs}
            shouldShow={showReminder}
          />

          <EndContainer>
            <FlatButton
              icon="fas fa-trash"
              intent="danger"
              onClick={() => setShowDelete(true)}
            >
              {}
            </FlatButton>
          </EndContainer>
        </Fragment>
      )}

      <Sync
        isLoading={isLoading || isFetching}
        onClick={resync}
        className="fas fa-sync-alt"
      />
    </Container>
  );
};
