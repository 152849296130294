import React from 'react';
import { Footer, PublicHeaderNav } from 'domains/navigation';
import { Container, H1, H2, Inner, Li, P, Ul } from './PrivacyPolicyStyles';

export const PrivacyPolicy: React.FC = () => {
  return (
    <Container>
      <PublicHeaderNav />

      <Inner>
        <H1>Privacy policy</H1>
        <P>
          {' '}
          This privacy policy (&quot;Policy&quot;) describes how the personally
          identifiable information (&quot;Personal Information&quot;) you may provide
          on the{' '}
          <a target="_blank" rel="noreferrer" href="https://digiprnt.com">
            www.digiprnt.com
          </a>{' '}
          website (&quot;Website&quot;), &quot;Digi Prnt&quot; mobile application
          (&quot;Mobile Application&quot;) and any of their related products and
          services (collectively, &quot;Services&quot;) is collected, protected and
          used. It also describes the choices available to you regarding our use of
          your Personal Information and how you can access and update this
          information. This Policy is a legally binding agreement between you
          (&quot;User&quot;, &quot;you&quot; or &quot;your&quot;) and Digi Prnt, Inc.
          (&quot;Digi Prnt, Inc.&quot;, &quot;we&quot;, &quot;us&quot; or
          &quot;our&quot;). By accessing and using the Services, you acknowledge that
          you have read, understood, and agree to be bound by the terms of this
          Policy. This Policy does not apply to the practices of companies that we do
          not own or control, or to individuals that we do not employ or manage.
        </P>
        <H2>Automatic collection of information</H2>
        <P>
          Our top priority is customer data security and, as such, we exercise the no
          logs policy. We may process only minimal user data, only as much as it is
          absolutely necessary to maintain the Services. Information collected
          automatically is used only to identify potential cases of abuse and
          establish statistical information regarding the usage and traffic of the
          Services. This statistical information is not otherwise aggregated in such
          a way that would identify any particular user of the system.
        </P>
        <H2>Collection of personal information</H2>
        <P>
          You can access and use the Services without telling us who you are or
          revealing any information by which someone could identify you as a
          specific, identifiable individual. If, however, you wish to use some of the
          features on the Services, you may be asked to provide certain Personal
          Information (for example, your name and e-mail address). We receive and
          store any information you knowingly provide to us when you make a purchase,
          or fill any online forms on the Services. When required, this information
          may include the following:
        </P>
        <Ul>
          <Li>Personal details such as name, country of residence, etc.</Li>
          <Li>Contact information such as email address, address, etc.</Li>
          <Li>Account details such as user name, unique user ID, password, etc.</Li>
          <Li>Geolocation data such as latitude and longitude.</Li>
        </Ul>
        <P>
          {' '}
          Some of the information we collect is directly from you via the Services.
          However, we may also collect Personal Information about you from other
          sources such as public databases and our joint marketing partners. You can
          choose not to provide us with your Personal Information, but then you may
          not be able to take advantage of some of the features on the Services.
          Users who are uncertain about what information is mandatory are welcome to
          contact us.
        </P>
        <H2>Use and processing of collected information</H2>
        <P>
          In order to make the Services available to you, or to meet a legal
          obligation, we may need to collect and use certain Personal Information. If
          you do not provide the information that we request, we may not be able to
          provide you with the requested products or services. Any of the information
          we collect from you may be used for the following purposes:
        </P>
        <Ul>
          <Li>Create and manage user accounts</Li>
          <Li>Send administrative information</Li>
          <Li>Request user feedback</Li>
          <Li>Improve user experience</Li>
          <Li>Post customer testimonials</Li>
          <Li>Enforce terms and conditions and policies</Li>
          <Li>Protect from abuse and malicious users</Li>
          <Li>Run and operate the Services</Li>
        </Ul>
        <P>
          Processing your Personal Information depends on how you interact with the
          Services, where you are located in the world and if one of the following
          applies: (i) you have given your consent for one or more specific purposes;
          this, however, does not apply, whenever the processing of Personal
          Information is subject to California Consumer Privacy Act or European data
          protection law; (ii) provision of information is necessary for the
          performance of an agreement with you and/or for any pre-contractual
          obligations thereof; (iii) processing is necessary for compliance with a
          legal obligation to which you are subject; (iv) processing is related to a
          task that is carried out in the public interest or in the exercise of
          official authority vested in us; (v) processing is necessary for the
          purposes of the legitimate interests pursued by us or by a third party.
        </P>
        <P>
          {' '}
          Note that under some legislations we may be allowed to process information
          until you object to such processing (by opting out), without having to rely
          on consent or any other of the following legal bases below. In any case, we
          will be happy to clarify the specific legal basis that applies to the
          processing, and in particular whether the provision of Personal Information
          is a statutory or contractual requirement, or a requirement necessary to
          enter into a contract.
        </P>
        <H2>Billing and payments</H2>
        <P>
          We use third party payment processors to assist us in processing your
          payment information securely. Such third party processors&apos; use of your
          Personal Information is governed by their respective privacy policies which
          may or may not contain privacy protections as protective as this Policy. We
          suggest that you review their respective privacy policies.
        </P>
        <H2>Disclosure of information</H2>
        <P>
          {' '}
          Depending on the requested Services or as necessary to complete any
          transaction or provide any service you have requested, we may share your
          information with your consent with our trusted third parties that work with
          us, any other affiliates and subsidiaries we rely upon to assist in the
          operation of the Services available to you. We do not share Personal
          Information with unaffiliated third parties. These service providers are
          not authorized to use or disclose your information except as necessary to
          perform services on our behalf or comply with legal requirements. We may
          share your Personal Information for these purposes only with third parties
          whose privacy policies are consistent with ours or who agree to abide by
          our policies with respect to Personal Information. These third parties are
          given Personal Information they need only in order to perform their
          designated functions, and we do not authorize them to use or disclose
          Personal Information for their own marketing or other purposes.
        </P>
        <P>
          We will disclose any Personal Information we collect, use or receive if
          required or permitted by law, such as to comply with a subpoena, or similar
          legal process, and when we believe in good faith that disclosure is
          necessary to protect our rights, protect your safety or the safety of
          others, investigate fraud, or respond to a government request.
        </P>
        <P>
          In the event we go through a business transition, such as a merger or
          acquisition by another company, or sale of all or a portion of its assets,
          your user account, and Personal Information will likely be among the assets
          transferred.
        </P>
        <H2>Retention of information</H2>
        <P>
          We will retain and use your Personal Information for the period necessary
          to comply with our legal obligations, resolve disputes, and enforce our
          agreements unless a longer retention period is required or permitted by
          law. We may use any aggregated data derived from or incorporating your
          Personal Information after you update or delete it, but not in a manner
          that would identify you personally. Once the retention period expires,
          Personal Information shall be deleted. Therefore, the right to access, the
          right to erasure, the right to rectification and the right to data
          portability cannot be enforced after the expiration of the retention
          period.
        </P>
        <H2>The rights of users</H2>
        <P>
          You may exercise certain rights regarding your information processed by us.
          In particular, you have the right to do the following: (i) you have the
          right to withdraw consent where you have previously given your consent to
          the processing of your information; (ii) you have the right to object to
          the processing of your information if the processing is carried out on a
          legal basis other than consent; (iii) you have the right to learn if
          information is being processed by us, obtain disclosure regarding certain
          aspects of the processing and obtain a copy of the information undergoing
          processing; (iv) you have the right to verify the accuracy of your
          information and ask for it to be updated or corrected; (v) you have the
          right, under certain circumstances, to restrict the processing of your
          information, in which case, we will not process your information for any
          purpose other than storing it; (vi) you have the right, under certain
          circumstances, to obtain the erasure of your Personal Information from us;
          (vii) you have the right to receive your information in a structured,
          commonly used and machine readable format and, if technically feasible, to
          have it transmitted to another controller without any hindrance. This
          provision is applicable provided that your information is processed by
          automated means and that the processing is based on your consent, on a
          contract which you are part of or on pre-contractual obligations thereof.
        </P>
        <H2>California privacy rights</H2>
        <P>
          In addition to the rights as explained in this Policy, California residents
          who provide Personal Information (as defined in the statute) to obtain
          products or services for personal, family, or household use are entitled to
          request and obtain from us, once a calendar year, information about the
          Personal Information we shared, if any, with other businesses for marketing
          uses. If applicable, this information would include the categories of
          Personal Information and the names and addresses of those businesses with
          which we shared such personal information for the immediately prior
          calendar year (e.g., requests made in the current year will receive
          information about the prior year). To obtain this information please
          contact us.
        </P>
        <H2>How to exercise these rights</H2>
        <P>
          Any requests to exercise your rights can be directed to Digi Prnt, Inc.
          through the contact details provided in this document. Please note that we
          may ask you to verify your identity before responding to such requests.
          Your request must provide sufficient information that allows us to verify
          that you are the person you are claiming to be or that you are the
          authorized representative of such person. You must include sufficient
          details to allow us to properly understand the request and respond to it.
          We cannot respond to your request or provide you with Personal Information
          unless we first verify your identity or authority to make such a request
          and confirm that the Personal Information relates to you.
        </P>
        <H2>Privacy of children</H2>
        <P>
          We do not knowingly collect any Personal Information from children under
          the age of 18. If you are under the age of 18, please do not submit any
          Personal Information through the Services. We encourage parents and legal
          guardians to monitor their children&apos;s Internet usage and to help
          enforce this Policy by instructing their children never to provide Personal
          Information through the Services without their permission. If you have
          reason to believe that a child under the age of 18 has provided Personal
          Information to us through the Services, please contact us. You must also be
          old enough to consent to the processing of your Personal Information in
          your country (in some countries we may allow your parent or guardian to do
          so on your behalf).
        </P>
        <H2>Cookies</H2>
        <P>
          The Services use &quot;cookies&quot; to help personalize your online
          experience. A cookie is a text file that is placed on your hard disk by a
          web page server. Cookies cannot be used to run programs or deliver viruses
          to your computer. Cookies are uniquely assigned to you, and can only be
          read by a web server in the domain that issued the cookie to you.
        </P>
        <P>
          We may use cookies to collect, store, and track information for statistical
          purposes to operate the Services. You have the ability to accept or decline
          cookies. Most web browsers automatically accept cookies, but you can
          usually modify your browser setting to decline cookies if you prefer. If
          you choose to decline cookies, you will not be able to use and experience
          the features of the Services. You may learn more about cookies and how they
          work in this{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.websitepolicies.com/blog/cookies"
          >
            guide
          </a>
          .
        </P>
        <H2>Do Not Track signals</H2>
        <P>
          Some browsers incorporate a Do Not Track feature that signals to websites
          you visit that you do not want to have your online activity tracked.
          Tracking is not the same as using or collecting information in connection
          with a website. For these purposes, tracking refers to collecting
          personally identifiable information from consumers who use or visit a
          website or online service as they move across different websites over time.
          The Services do not track its visitors over time and across third party
          websites. However, some third party sites may keep track of your browsing
          activities when they serve you content, which enables them to tailor what
          they present to you.
        </P>
        <H2>Email marketing</H2>
        <P>
          We offer electronic newsletters to which you may voluntarily subscribe at
          any time. We are committed to keeping your e-mail address confidential and
          will not disclose your email address to any third parties except as allowed
          in the information use and processing section or for the purposes of
          utilizing a third party provider to send such emails. We will maintain the
          information sent via e-mail in accordance with applicable laws and
          regulations.
        </P>
        <P>
          In compliance with the CAN-SPAM Act, all e-mails sent from us will clearly
          state who the e-mail is from and provide clear information on how to
          contact the sender. You may choose to stop receiving our newsletter or
          marketing emails by following the unsubscribe instructions included in
          these emails or by contacting us. However, you will continue to receive
          essential transactional emails.
        </P>
        <H2>Links to other resources</H2>
        <P>
          The Services contain links to other resources that are not owned or
          controlled by us. Please be aware that we are not responsible for the
          privacy practices of such other resources or third parties. We encourage
          you to be aware when you leave the Services and to read the privacy
          statements of each and every resource that may collect Personal
          Information.
        </P>
        <H2>Information security</H2>
        <P>
          We secure information you provide on computer servers in a controlled,
          secure environment, protected from unauthorized access, use, or disclosure.
          We maintain reasonable administrative, technical, and physical safeguards
          in an effort to protect against unauthorized access, use, modification, and
          disclosure of Personal Information in its control and custody. However, no
          data transmission over the Internet or wireless network can be guaranteed.
          Therefore, while we strive to protect your Personal Information, you
          acknowledge that (i) there are security and privacy limitations of the
          Internet which are beyond our control; (ii) the security, integrity, and
          privacy of any and all information and data exchanged between you and the
          Services cannot be guaranteed; and (iii) any such information and data may
          be viewed or tampered with in transit by a third party, despite best
          efforts.
        </P>
        <H2>Data breach</H2>
        <P>
          In the event we become aware that the security of the Services has been
          compromised or users Personal Information has been disclosed to unrelated
          third parties as a result of external activity, including, but not limited
          to, security attacks or fraud, we reserve the right to take reasonably
          appropriate measures, including, but not limited to, investigation and
          reporting, as well as notification to and cooperation with law enforcement
          authorities. In the event of a data breach, we will make reasonable efforts
          to notify affected individuals if we believe that there is a reasonable
          risk of harm to the user as a result of the breach or if notice is
          otherwise required by law. When we do, we will send you an email, get in
          touch with you over the phone, mail you a letter.
        </P>
        <H2>Changes and amendments</H2>
        <P>
          We reserve the right to modify this Policy or its terms relating to the
          Services from time to time in our discretion and will notify you of any
          material changes to the way in which we treat Personal Information. When we
          do, we will revise the updated date at the bottom of this page. We may also
          provide notice to you in other ways in our discretion, such as through
          contact information you have provided. Any updated version of this Policy
          will be effective immediately upon the posting of the revised Policy unless
          otherwise specified. Your continued use of the Services after the effective
          date of the revised Policy (or such other act specified at that time) will
          constitute your consent to those changes. However, we will not, without
          your consent, use your Personal Information in a manner materially
          different than what was stated at the time your Personal Information was
          collected.
        </P>
        <H2>Acceptance of this policy</H2>
        <P>
          You acknowledge that you have read this Policy and agree to all its terms
          and conditions. By accessing and using the Services you agree to be bound
          by this Policy. If you do not agree to abide by the terms of this Policy,
          you are not authorized to access or use the Services.
        </P>
        <H2>Contacting us</H2>
        <P>
          If you would like to contact us to understand more about this Policy or
          wish to contact us concerning any matter relating to individual rights and
          your Personal Information, you may send an email to services@digiprnt.com.
        </P>
        <P>This document was last updated on October 14, 2021</P>
      </Inner>

      <Footer />
    </Container>
  );
};
