import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPersonInitials } from 'utils/user.utils';

import { IEmployeeCard } from 'types/card.types';
import {
  Container,
  AvatarCircleContainer,
  AvatarsWrapper,
  Initials,
  Label,
} from './SelectedAvatarsStyles';

interface Props {
  selectedCards: IEmployeeCard[];
}

export const SelectedAvatars: React.FC<Props> = ({ selectedCards }: Props) => {
  const { t } = useTranslation();
  const topThreeSelected = [...selectedCards].slice(0, 3);

  return (
    <Container>
      <AvatarsWrapper count={topThreeSelected.length}>
        {topThreeSelected.map((card, index) => (
          <AvatarCircleContainer key={`${card.id}-${index}`} order={index}>
            <Initials>{card && getPersonInitials(card)}</Initials>
          </AvatarCircleContainer>
        ))}
      </AvatarsWrapper>
      <Label>{`${selectedCards.length} ${t('cards selected')}`}</Label>
    </Container>
  );
};
