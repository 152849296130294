import styled from 'styled-components';

export const ButtonContainer = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
`;

export const ActiveDot = styled.div`
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  top: -4px;
  right: -4px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primaryGreen};
  box-shadow: -1px 3px 7px 2px rgba(0, 0, 0, 0.2);
`;

export const Form = styled.form`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

export const MainGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 20px;
  row-gap: 30px;
`;

export const LevelsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 30px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid ${({ theme }) => theme.lightGrayBg}50;
`;

export const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  overflow: visible;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.secondaryBlack};
  font-weight: ${({ theme }) => theme.weightBold};
  text-align: left;
`;

export const PickerWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.primaryBlack}40;
  border-radius: 10px;
  width: fit-content;
  padding: 0px 1px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  grid-column: 1 / span 2;
  padding-top: 30px;
`;
