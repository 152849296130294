import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Element, Popup } from './PassStatusFilterStyles';
import { TIstFilterActive } from 'components/table/types';

export interface Props {
  column: {
    filterValue?:
      | 'all'
      | 'received'
      | 'downloaded'
      | 'deleted'
      | 'invalid'
      | 'unknown';
    id: string;
    setFilter: (filterValue?: string) => void;
  };
  setIsFilterActive: (
    value: ((prev: TIstFilterActive) => TIstFilterActive) | boolean,
  ) => void;
}

export const PassStatusFilter: React.FC<Props> = ({
  column,
  setIsFilterActive,
}: Props) => {
  const { t } = useTranslation();
  const tAll = t('All');
  const tReceived = t('Received');
  const tDownloaded = t('Downloaded');
  const tDeleted = t('Deleted');
  const tInvalid = t('Invalid');
  const tUnknown = t('Unknown');

  const { id, filterValue, setFilter } = column;

  const handleClose = () => setIsFilterActive((prev) => ({ ...prev, [id]: false }));
  const onSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    const { status } = (e.target as HTMLDivElement).dataset;
    setFilter(status);
    handleClose();
  };

  return (
    <Container>
      <Popup>
        <Element
          data-status={'all'}
          isSelected={!filterValue || filterValue === 'all'}
          onClick={onSelect}
        >
          {tAll}
        </Element>
        <Element
          data-status={'received'}
          isSelected={filterValue === 'received'}
          onClick={onSelect}
        >
          {tReceived}
        </Element>
        <Element
          data-status={'downloaded'}
          isSelected={filterValue === 'downloaded'}
          onClick={onSelect}
        >
          {tDownloaded}
        </Element>
        <Element
          data-status={'deleted'}
          isSelected={filterValue === 'deleted'}
          onClick={onSelect}
        >
          {tDeleted}
        </Element>
        <Element
          data-status={'invalid'}
          isSelected={filterValue === 'invalid'}
          onClick={onSelect}
        >
          {tInvalid}
        </Element>
        <Element
          data-status={'unknown'}
          isSelected={filterValue === 'unknown'}
          onClick={onSelect}
        >
          {tUnknown}
        </Element>
      </Popup>
    </Container>
  );
};
