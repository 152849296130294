import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'state/hooks';
import { useEmployeeCards, usePhotoRequests } from 'contexts';

import { formatName } from 'utils/user.utils';
import useDimensions from 'hooks/useDimensions';

import { AvatarCircle } from 'components/avatar-circle/AvatarCircle';
import { Checkbox } from 'components/checkbox';
import {
  ContentBox,
  ContentBoxContainer,
  ContentBoxHeader,
  ContentBoxTitle,
} from 'domains/navigation';

import { IEmployee } from 'types/employee.types';
import {
  AvatarContainer,
  Empty,
  ImagesContainer,
  Inner,
  Wrapper,
  Footer,
  Container,
} from './PhotoPreviewStyles';

export const PhotoPreview: React.FC = () => {
  const { t } = useTranslation();
  const tTitle = t('Photo');
  const tPlaceholder = t('Select a request to preview');
  const tToggle = t('Generate and Send Digital Cards on Approval');

  const { isInMobile } = useDimensions();
  const { employeeCards = [] } = useEmployeeCards();
  const { requests = [] } = usePhotoRequests();
  const { shouldSendIDs, setShouldSendIDs } = usePhotoRequests();
  const { selectedRequestsIds } = useAppSelector(
    ({ adminVerification }) => adminVerification,
  );

  const inDisplay = [...selectedRequestsIds].slice(0, 3).reduce((list, id) => {
    const request = requests.find((req) => req.id === id);
    const employee = employeeCards.find(
      ({ employeeId }) => employeeId === request?.employeeId,
    );
    if (employee && request)
      return [...list, { ...employee, photoUrl: request.photoUrl }];
    return list;
  }, [] as IEmployee[]);

  const toggleShouldSendIDs = () => setShouldSendIDs(!shouldSendIDs);

  return (
    <Container>
      <ContentBoxContainer>
        <ContentBoxHeader>
          <ContentBoxTitle>{tTitle}</ContentBoxTitle>
        </ContentBoxHeader>
        <Wrapper>
          <ContentBox>
            <Inner>
              {inDisplay.length ? (
                <ImagesContainer>
                  {inDisplay.map((employee, index) => {
                    const isOnlyChild = inDisplay.length === 1;
                    const onlyTwo = inDisplay.length === 2;
                    const isThirdChild = index === 2;
                    const bigCircle = isOnlyChild || isThirdChild;
                    return (
                      <AvatarContainer
                        key={employee.id}
                        index={index}
                        count={inDisplay.length}
                      >
                        <AvatarCircle
                          size={
                            bigCircle
                              ? isInMobile
                                ? 250
                                : 350
                              : onlyTwo
                              ? 250
                              : 200
                          }
                          photoUrl={employee.photoUrl}
                          name={formatName(employee)}
                        />
                      </AvatarContainer>
                    );
                  })}
                </ImagesContainer>
              ) : (
                <Empty>{tPlaceholder}</Empty>
              )}
            </Inner>
            <Footer>
              <Checkbox
                isSelected={shouldSendIDs}
                handleCheckClick={toggleShouldSendIDs}
              />
              <span>{tToggle}</span>
            </Footer>
          </ContentBox>
        </Wrapper>
      </ContentBoxContainer>
    </Container>
  );
};
