import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'state/hooks';

import { AvatarCircle } from 'components/avatar-circle/AvatarCircle';
import { SampleCode } from './assets/SampleCode';
import defaultLogo from 'assets/images/logo.svg';

import { ICardConfig } from 'types/card.types';
import {
  Card,
  Company,
  Container,
  Details,
  FullName,
  Inner,
  Logo,
  QRContainer,
  Title,
  TopContainer,
} from './SampleCardSectionStyles';
import { checkPhoto } from 'utils/photo.utils';

interface Props {
  config?: ICardConfig;
  img?: string | null;
}

export const SampleCardSection: React.FC<Props> = ({ config, img }: Props) => {
  const { currentCompany } = useAppSelector(({ company }) => company);

  const { t } = useTranslation();
  const tTitle = t('Employee Title');
  const tFullName = t('Employee Full Name');
  const tCompanyName = t('Company Name');

  const companyLogo = checkPhoto(currentCompany?.logoURL);

  return (
    <Container>
      <Card {...config}>
        <Inner>
          <Logo src={img || companyLogo || defaultLogo} />
          <TopContainer>
            <Details>
              <FullName>{tFullName}</FullName>
              <div>
                <Company>{currentCompany?.companyName || tCompanyName}</Company>
                <Title>{tTitle}</Title>
              </div>
            </Details>
            <AvatarCircle size={125} />
          </TopContainer>
          <QRContainer>
            <SampleCode />
          </QRContainer>
        </Inner>
      </Card>
    </Container>
  );
};
