/* eslint-disable @typescript-eslint/no-explicit-any */

import sign from 'jwt-encode';
import * as jose from 'jose';

const JWT_SECRET = process.env.REACT_APP_JWT_SECRET || 'jwt_secret';

export const encodeToken = (data: any, secretField?: string) => {
  return sign(
    data,
    `${JWT_SECRET}${secretField ? '.' + data[secretField].toLowerCase() : ''}`,
  );
};

export const decodeToken = async (token: string, secretValue?: string) => {
  const jwt = await jose.jwtVerify(
    token,
    Uint8Array.from(
      `${JWT_SECRET}${secretValue ? '.' + secretValue.toLowerCase() : ''}`
        .split('')
        .map((letter) => letter.charCodeAt(0)),
    ),
  );

  return jwt.payload;
};
