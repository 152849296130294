import { useMemo } from 'react';
import { collection, query, Timestamp, where } from 'firebase/firestore';
import { firestore } from 'data/firebase';
import { isBefore } from 'date-fns';

import { useAppSelector } from 'state/hooks';
import useAppQuery from 'hooks/useAppQuery';
import dbLabels from 'data/db.labels';

import { IPendingAccount } from 'types/user.types';

interface IArgs {
  employeeId: string;
}

type FirestorePendingAcc = IPendingAccount & { expiresOn: Timestamp };

export default ({ employeeId }: IArgs) => {
  const companyId = useAppSelector(({ company }) => company.currentCompany?.id);
  const userDocRef = query(
    collection(firestore, dbLabels.pendingAccount),
    where('employeeId', '==', employeeId),
    where('companyId', '==', companyId),
  );

  const { data = [], ...queryData } = useAppQuery<FirestorePendingAcc[]>(
    ['pending_acc', employeeId],
    userDocRef,
  );

  const pendingAccount = useMemo(() => {
    const acc = data.find(
      (pa) => !pa.isCreated && isBefore(pa.expiresOn.toDate(), new Date()),
    );
    if (acc) return { ...acc, expiresOn: acc.expiresOn.toDate() };
  }, [data]);

  return { pendingAccount, ...queryData };
};
