import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Element, Popup } from './GenerationStatusFilterStyles';
import { TIstFilterActive } from 'components/table/types';

export interface Props {
  column: {
    filterValue?: 'all' | 'completed' | 'failed' | 'inProgress';
    id: string;
    setFilter: (filterValue?: string) => void;
  };
  setIsFilterActive: (
    value: ((prev: TIstFilterActive) => TIstFilterActive) | boolean,
  ) => void;
}

export const GenerationStatusFilter: React.FC<Props> = ({
  column,
  setIsFilterActive,
}: Props) => {
  const { t } = useTranslation();
  const tAll = t('All');
  const tCompleted = t('Completed');
  const tInProgress = t('In progress');
  const tFailed = t('Failed');

  const { id, filterValue, setFilter } = column;

  const handleClose = () => setIsFilterActive((prev) => ({ ...prev, [id]: false }));
  const onSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    const { status } = (e.target as HTMLDivElement).dataset;
    setFilter(status);
    handleClose();
  };

  return (
    <Container>
      <Popup>
        <Element
          data-status={'all'}
          isSelected={!filterValue || filterValue === 'all'}
          onClick={onSelect}
        >
          {tAll}
        </Element>
        <Element
          data-status={'completed'}
          isSelected={filterValue === 'completed'}
          onClick={onSelect}
        >
          {tCompleted}
        </Element>
        <Element
          data-status={'inProgress'}
          isSelected={filterValue === 'inProgress'}
          onClick={onSelect}
        >
          {tInProgress}
        </Element>
        <Element
          data-status={'failed'}
          isSelected={filterValue === 'failed'}
          onClick={onSelect}
        >
          {tFailed}
        </Element>
      </Popup>
    </Container>
  );
};
