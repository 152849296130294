import { parseError } from 'utils/errors.utils';
import { PATHS } from 'routes';

import { getCurrentCompany, setCurrentCompany } from 'utils/company.utils';
import { launchCardManagement } from 'state/card-management/CardManagementActions';
import getAllCompanies from 'data/adapters/company/getAllCompanies';
import getCompany from 'data/adapters/company/getCompany';
import getOrgnizationLevels from 'data/adapters/company/getOrgnizationLevels';

import { IOrgLevel } from 'types/company.types';
import { UserRolesEnum } from 'types/user.types';
import * as Actions from './CompanyActions.types';

export const getCompaniesList: Actions.TGetCompaniesList =
  () => async (dispatch) => {
    try {
      const { success, error, data = [] } = await getAllCompanies();
      if (!success) throw error;

      dispatch({
        type: Actions.CMPNY__SET_COMPANIES_LIST,
        list: data,
      });
    } catch (err) {
      dispatch({
        type: Actions.CMPNY__FAIL,
        error: parseError(err),
      });
    }
  };

export const launchCompanies: Actions.TLaunchCompanies =
  (user) => async (dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const companyId = getCurrentCompany() || user.companyId;
      const promises = [dispatch(updateCurrentCompany(companyId))];
      if (user.role === UserRolesEnum.superAdmin) {
        promises.push(dispatch(getCompaniesList()));
      }

      await Promise.all(promises);
    } catch (err) {
      dispatch({
        type: Actions.CMPNY__FAIL,
        error: parseError(err),
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const updateCurrentCompany: Actions.TSetCurrentCompany =
  (companyId) => async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));

      let currentId = companyId || getState().company.currentCompany?.id;
      const userRole = getState().auth.user?.role;
      if (
        (!!userRole && userRole !== UserRolesEnum.superAdmin) ||
        (!companyId && !currentId)
      ) {
        currentId = getState().auth.user?.companyId;
      }

      if (!currentId) throw 'Invalid company Id';

      const { success, error, data } = await getCompany({
        id: currentId || '',
      });
      if (!success || !data) throw error;

      setCurrentCompany(data.id);

      let orgLevels: IOrgLevel[] = [];
      if (data.configuration.orgLevels) {
        const {
          success: lvlsSuccess,
          error: lvlsError,
          data: orgLevelsData = [],
        } = await getOrgnizationLevels({
          companyId: data.id,
        });

        if (!lvlsSuccess || !orgLevelsData) throw lvlsError;

        orgLevels = orgLevelsData;
      }

      await dispatch(launchCardManagement());
      dispatch({
        type: Actions.CMPNY__SET_CURRENT_COMPANY,
        company: data,
        orgLevels,
      });

      // smooth out the transition
      await new Promise((res) => setTimeout(res, 500));
    } catch (err) {
      dispatch({
        type: Actions.CMPNY__FAIL,
        error: parseError(err),
      });
      location.replace(PATHS.pageNotFound);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const setIsLoading: Actions.TSetIsLoading = (isLoading) => ({
  isLoading,
  type: Actions.CMPNY__SET_IS_LOADING,
});
