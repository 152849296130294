import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { createAccount } from 'state/create-account/CreateAccountActions';
import useAppForm from 'hooks/useAppForm';

import { SubmitButton } from 'components/buttons';
import { Input } from 'components/inputs';
import { PATHS } from 'routes';

import { Actions, Container } from './FormStyles';

interface FormData {
  password: string;
  confirmPassword: string;
}

export const Form: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = async () => {
    const { success } = await dispatch(createAccount(formData.password));
    if (success) navigate(PATHS.dashboard);
  };

  const { isLoading } = useAppSelector(({ createAccount }) => createAccount);
  const { formData, formErrors, setFormErrors, handleChange, handleSubmit } =
    useAppForm<FormData>(
      {
        initialValues: [
          { key: 'password', required: true, value: '' },
          { key: 'confirmPassword', required: true, value: '' },
        ],
        onSubmit,
      },
      [],
    );

  useEffect(() => {
    const { password, confirmPassword } = formData;

    if (!!confirmPassword && password !== confirmPassword)
      setFormErrors({ confirmPassword: 'Passwords do not match.' });
  }, [formData]);

  const { t } = useTranslation();
  const buttonText = !isLoading ? t('Set password') : t('Loading...');

  return (
    <Container onSubmit={handleSubmit}>
      <Input
        error={formErrors.password}
        name="password"
        onChange={handleChange}
        placeholder="Password"
        type="password"
        value={formData.password}
        success={!!formData.password && !formErrors.password}
      />
      <Input
        error={formErrors.confirmPassword}
        name="confirmPassword"
        onChange={handleChange}
        placeholder="Confirm Password"
        type="password"
        value={formData.confirmPassword}
        success={!!formData.confirmPassword && !formErrors.confirmPassword}
      />

      <Actions>
        <SubmitButton isLoading={isLoading} type="submit">
          {buttonText}
        </SubmitButton>
      </Actions>
    </Container>
  );
};
