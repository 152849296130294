import { auth } from 'data/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';

import { Adapter } from 'data/types';

interface ILoginUserDataArgs {
  email: string;
  password: string;
}

export default async ({
  email,
  password,
}: ILoginUserDataArgs): Promise<Adapter<string>> => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);

    if (!user) {
      return {
        success: false,
        error: 'Something went wrong with logging in',
      };
    }

    return { success: true, data: user.uid };
  } catch (err) {
    const errorMessage = err as string;

    return { success: false, error: errorMessage };
  }
};
