import { firestore, httpsCallable } from 'data/firebase';
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';

import { encodeToken } from 'utils/token.utils';
import dbLabels from 'data/db.labels';

import { EPhotoRequestStatus } from 'types/photoRequest.types';
import { IPhotoReqTokenPayload } from 'types/token.types';
import { parseError } from 'utils/errors.utils';
import { Adapter } from 'data/types';

export default async (
  data: IPhotoReqTokenPayload | IPhotoReqTokenPayload[],
  reason: string,
): Promise<Adapter> => {
  try {
    const users = Array.isArray(data) ? data : [data];

    const usersDetails = users.map((cardDetails) => {
      const token = encodeToken(cardDetails);
      const url = `${window.location.origin}/photo-verification/${token}`;

      return { ...cardDetails, url, token };
    });

    const sendResubmissionEmail = httpsCallable('sendPhotoResubmissionEmail');
    await sendResubmissionEmail({ usersDetails, reason });

    const ref = collection(firestore, dbLabels.photorequest);
    for (const cardDetails of usersDetails) {
      const requestObject = await getDocs(
        query(ref, where('employeeId', '==', cardDetails.employeeId)),
      );

      updateDoc(doc(ref, requestObject.docs[0].id), {
        companyId: cardDetails.companyId,
        employeeId: cardDetails.employeeId,
        modifiedOn: new Date(),
        photoUrl: '',
        status: EPhotoRequestStatus.rejected,
        token: cardDetails.token,
      });
    }

    return { success: true };
  } catch (error) {
    const stringError = parseError(error);
    return { success: false, error: stringError };
  }
};
