import React from 'react';
import { ColoredBox } from '../../../colored-box/ColoredBox';
import { MobileAppDemo } from '../mobile-app-demo/MobileAppDemo';

import { Bold, CardText, CardTitle } from '../../HowItWorksStyles';
import { ImageContainer, ImageText, Inner, TextContainer } from './MobileAppStyles';

export const MobileApp: React.FC = () => {
  return (
    <ColoredBox color="green" title="MOBILE APPLICATION">
      <Inner>
        <CardTitle>Dynamic content update on Digital ID Cards</CardTitle>
        <ImageContainer>
          <CardText>
            <Bold>Employees can upload photos</Bold> to use for their photo on the ID
            card and employee photo. Photo uploads will be done through a web link
            sent via email to each employee and uploaded to the ID card management
            system.
          </CardText>

          <MobileAppDemo />

          <TextContainer>
            <ImageText>
              <Bold>Administrators will have the ability to approve</Bold> uploaded
              photos before they are displayed on the ID cards.
            </ImageText>
          </TextContainer>
        </ImageContainer>
      </Inner>
    </ColoredBox>
  );
};
