import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AvatarEditor from 'react-avatar-editor';

import { Actions } from './actions/Actions';
import useGetCardConfig from 'domains/hooks/useCardConfig';
import useUpdateCardConfig from 'data/data-hooks/mutations/card/useUpdateCardConfig';
import useUpdateCompanyLogo from 'data/data-hooks/mutations/company/useUpdateCompanyLogo';

import { SettingsSection } from './components/settings-section/SettingsSection';
import { SampleCardSection } from './components/sample-card-section/SampleCardSection';
import {
  ContentBox,
  ContentBoxContainer,
  ContentBoxHeader,
  ContentBoxTitle,
  PageContentContainer,
  PageTitle,
} from 'domains/navigation';

import { ICardConfig } from 'types/card.types';
import { BackBtnContainer, FormContainer, Inner } from './CardConfigurationStyles';

export const CardConfiguration: React.FC = () => {
  const navigate = useNavigate();
  const [config, setConfig] = useState<ICardConfig>({});
  const [isTouched, setIsTouched] = useState(false);
  const [imageDidChange, setImageDidChange] = useState(false);
  const [imageBlob, setImageBlob] = useState<string | null>();

  const notificationsRef = useRef<AvatarEditor>();
  const setEditorRef = (editorRef: AvatarEditor | null) => {
    if (editorRef) return (notificationsRef.current = editorRef);
    return null;
  };

  const { cardConfig: initialConfig } = useGetCardConfig();
  const { updateCompanyLogo } = useUpdateCompanyLogo();
  const { updateCardConfig, isLoading } = useUpdateCardConfig({
    onSuccess: async () => {
      if (imageDidChange) {
        let image: File;
        if (notificationsRef.current) {
          const canvas = notificationsRef.current.getImage();
          await new Promise<void>((resolve) => {
            canvas.toBlob((blob: Blob | null) => {
              if (blob) {
                image = new File([blob], 'image.png', { type: 'image/png' });
                updateCompanyLogo(image).then(resolve);
              } else resolve();
            });
          });
        }
      }

      navigate('..');
    },
  });

  useEffect(() => {
    reset();
  }, [initialConfig]);

  const getImg = async () => {
    try {
      if (notificationsRef.current) {
        const canvas = notificationsRef.current.getImage();
        await canvas.toBlob((blob: Blob | null) => {
          if (blob) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = function () {
              setImageBlob(reader.result?.toString());
            };
          }
        });
      }
    } catch {
      //
    } finally {
      setTimeout(() => getImg(), 2000);
    }
  };

  useEffect(() => {
    getImg();
  }, []);

  const { t } = useTranslation();
  const tTitle = t('Configuration');
  const tSubTitle = t('Card ID');

  const reset = async () => {
    updateConfig(initialConfig);
    setIsTouched(false);
  };

  const updateConfig = (data: Partial<ICardConfig>) => {
    setConfig((prev) => ({ ...prev, ...data }));
    setIsTouched(true);
  };

  const onSubmit = async () => {
    updateCardConfig(config);
  };

  return (
    <PageContentContainer>
      <PageTitle>
        <BackBtnContainer onClick={() => navigate('..')}>
          <i className="fas fa-arrow-left" />
        </BackBtnContainer>
        {tTitle}
      </PageTitle>

      <ContentBoxContainer>
        <ContentBoxHeader>
          <ContentBoxTitle>{tSubTitle}</ContentBoxTitle>
        </ContentBoxHeader>
        <ContentBox>
          <Inner>
            <SampleCardSection img={imageBlob} config={config} />
            <FormContainer>
              <SettingsSection
                setEditorRef={setEditorRef}
                config={config}
                updateConfig={updateConfig}
                setImageDidChange={setImageDidChange}
              />
              <Actions
                isLoading={isLoading}
                isTouched={isTouched || imageDidChange}
                canReset={isTouched}
                onSubmit={onSubmit}
                reset={reset}
              />
            </FormContainer>
          </Inner>
        </ContentBox>
      </ContentBoxContainer>
    </PageContentContainer>
  );
};
