import React, { Fragment, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';

import { FlatButton } from 'components/buttons';
import { Photo } from 'components/icons';

import { ErrorMessage, TopContainer } from '../SharedStyles';
import {
  BtnContainer,
  CommandsContainer,
  Container,
  Inner,
  Label,
  SubText,
  Text,
} from './FileInputStyles';

interface Props {
  label?: string;
  name?: string;
  onChange: (file: File) => void;
  types?: string[];
  value?: File | null;
}

export const FileInput: React.FC<Props> = ({
  label = '',
  name,
  onChange,
  types = ['JPEG', 'JPG', 'PNG'],
  value: controlledValue,
}: Props) => {
  const [value, setValue] = useState<File | null>(null);

  const { t } = useTranslation();
  const tClick = t('Click Here');
  const tDrop = t('or drop file here');
  const tError = t('File is too big');
  const tHoverTitle = t('Drop here');
  const tInnerLabel = t('Upload or drop a file right here');
  const tLabel = t(label);
  const tSize = t('Max size - 5Mb');
  const tUpload = t('Upload ');
  const tUploadAnother = t('to change this file');

  const handleChange = (file: File) => {
    setValue(file);
    onChange(file);
  };

  useEffect(() => {
    setValue(controlledValue || null);
  }, [controlledValue]);

  return (
    <Container>
      {/* label */}
      {label && (
        <TopContainer>
          <Label htmlFor={name}>{tLabel}</Label>
        </TopContainer>
      )}

      {/* file input */}
      <FileUploader
        handleChange={handleChange}
        name={name}
        types={types}
        hoverTitle={tHoverTitle}
        label={tInnerLabel}
        maxSize={5}
        classes="file_uploader"
        file={controlledValue === undefined ? value : controlledValue}
      >
        <Inner>
          {value ? (
            <Fragment>
              <CommandsContainer>
                <Text>{value.name}</Text>
                {value.size / 1024 / 1024 > 5 && (
                  <ErrorMessage>{tError}</ErrorMessage>
                )}
              </CommandsContainer>
              <Text>
                <BtnContainer>
                  <FlatButton>{tClick}</FlatButton>
                </BtnContainer>
                {tUploadAnother}
              </Text>
            </Fragment>
          ) : (
            <Fragment>
              <Photo />
              <CommandsContainer>
                <Text>
                  <BtnContainer>
                    <FlatButton>{tUpload}</FlatButton>
                  </BtnContainer>
                  {tDrop}
                </Text>
                <SubText>{tSize}</SubText>
              </CommandsContainer>
            </Fragment>
          )}
        </Inner>
      </FileUploader>
    </Container>
  );
};
