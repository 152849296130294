import { Reducer } from 'redux';
import { endOfDay, startOfDay, subMonths } from 'date-fns';

import { TFilter } from 'types/shared.types';
import * as Actions from './CardTrackerActions.types';

export interface State {
  endDate: Date;
  filter: TFilter;
  pageSize: number;
  selectedCardIds: Set<string>;
  startDate: Date;
}

const today = endOfDay(new Date());
const monthAgo = startOfDay(subMonths(today, 1));
export const initialState: State = {
  endDate: today,
  filter: 'all',
  pageSize: 20,
  selectedCardIds: new Set([]),
  startDate: monthAgo,
};

const cardManagementReducer: Reducer<State, Actions.CardsTrackerActionTypes> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case Actions.TRACKER__LAUNCH: {
      return {
        ...initialState,
        pageSize: state.pageSize,
      };
    }

    case Actions.TRACKER__UPDATE_FILTER: {
      return {
        ...state,
        filter: action.filter,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    }

    case Actions.TRACKER__SELECT_CARD: {
      const currentlySelected = [...state.selectedCardIds];
      const isAlreadySelected = currentlySelected.find((id) => id === action.id);
      const newselectedCards = isAlreadySelected
        ? currentlySelected.filter((id) => id !== action.id)
        : [...currentlySelected, action.id];

      return {
        ...state,
        selectedCardIds: new Set(newselectedCards),
      };
    }
    case Actions.TRACKER__SET_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.size,
      };
    }

    case Actions.TRACKER__TOGGLE_SELECT_ALL: {
      return {
        ...state,
        selectedCardIds: state.selectedCardIds.size
          ? new Set()
          : new Set(action.allCards.map(({ id }) => id)),
      };
    }
    default:
      return state;
  }
};

export default cardManagementReducer;
