import styled from 'styled-components';

interface IContainer {
  header?: boolean;
}

export const ListRowContainer = styled.div<IContainer>`
  height: ${({ header }) => (header ? '40px' : '60px')};
  border-bottom: 1px solid ${({ theme }) => theme.backgroundColor};
  display: grid;
  grid-template-columns: 5fr 1.5fr 1fr 1fr;
  padding: 10px 20px;
  align-items: center;

  :nth-child(6) {
    border-bottom: none;
  }

  @media (max-width: ${({ theme }) => theme.sizeRanges.mobileLarge.max}px) {
    grid-template-columns: 4fr 1.5fr 1fr;
  }
`;
