import styled from 'styled-components';

interface IDetailsText {
  email?: boolean;
}

export const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const ContactDetails = styled.div`
  background-color: ${({ theme }) => theme.primaryBlack};
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 35px 10px 35px 90px;
  gap: 40px;

  @media (max-width: ${({ theme }) => theme.sizeRanges.tabletLarge.min}px) {
    padding: 20px 40px;
    grid-template-columns: 1fr 1fr;

    > :nth-child(1) {
      grid-column: 1 / span 2;
    }
  }

  @media (max-width: ${({ theme }) => theme.sizeRanges.tablet.min}px) {
    padding: 20px;
    grid-template-columns: 1fr;
    gap: 20px;

    > div {
      grid-column: 1 / span 1;
    }
  } ;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  font-family: ${({ theme }) => theme.secondaryBlack};
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 16px;
  line-height: 28px;
`;

export const DetailsHeader = styled.div`
  color: #fff6;
`;

export const DetailsText = styled.div<IDetailsText>`
  color: ${({ theme, email }) => (email ? theme.secondaryViolet : 'white')};
`;

export const FormContainer = styled.div`
  flex-grow: 1;
  display: flex;
`;

export const WelcomeText = styled.div`
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightSemiBold};
  font-size: 30px;
  line-height: 45px;
  color: white;
`;
