import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from 'components/date-picker';
import useDimensions from 'hooks/useDimensions';

import { TFilter } from 'types/shared.types';
import { HeaderButton, HeaderBtnsContainer, TitleAndActions } from './TitleStyles';
import { ContentBoxHeader, ContentBoxTitle } from 'domains/navigation';

export interface IDateRange {
  startDate: Date;
  endDate: Date;
}

interface Props {
  actions?: JSX.Element;
  activeFilter?: TFilter;
  endDate?: Date;
  handleFilterChange?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  setCustomDateRange?: ({ startDate, endDate }: IDateRange) => void;
  showActions?: boolean;
  showFilters?: boolean;
  startDate?: Date;
  tabs?: { label: string; onSelect: () => void; isActive: boolean }[];
  title?: string;
}

export const Title: React.FC<Props> = React.memo(
  ({
    actions,
    activeFilter,
    endDate,
    handleFilterChange,
    setCustomDateRange,
    showActions = false,
    showFilters = false,
    startDate,
    tabs,
    title,
  }: Props) => {
    const { isInMobile } = useDimensions();
    const { t } = useTranslation();
    const tTitle = t(title || '');
    const tAll = t('All');
    const tWeek = t('Week');
    const tMonth = t('30d');
    const tYear = t('1y');

    return (
      <ContentBoxHeader>
        <TitleAndActions>
          {title && <ContentBoxTitle>{tTitle}</ContentBoxTitle>}
          {!!tabs && (
            <HeaderBtnsContainer>
              {tabs.map(({ label, isActive, onSelect }, i) => (
                <HeaderButton
                  key={`tab+${i}`}
                  name={label}
                  isActive={isActive}
                  onClick={onSelect}
                >
                  {t(label)}
                </HeaderButton>
              ))}
            </HeaderBtnsContainer>
          )}
          {!!showActions && <Fragment>{actions}</Fragment>}
        </TitleAndActions>

        {!!showFilters && (
          <HeaderBtnsContainer>
            {!isInMobile && (
              <>
                <HeaderButton
                  name="all"
                  isActive={activeFilter === 'all'}
                  onClick={handleFilterChange}
                >
                  {tAll}
                </HeaderButton>
                <HeaderButton
                  name="week"
                  isActive={activeFilter === 'week'}
                  onClick={handleFilterChange}
                >
                  {tWeek}
                </HeaderButton>
                <HeaderButton
                  name="month"
                  isActive={activeFilter === 'month'}
                  onClick={handleFilterChange}
                >
                  {tMonth}
                </HeaderButton>
                <HeaderButton
                  name="year"
                  isActive={activeFilter === 'year'}
                  onClick={handleFilterChange}
                >
                  {tYear}
                </HeaderButton>
              </>
            )}

            {!!startDate && !!endDate && !!setCustomDateRange && (
              <DatePicker
                startDate={startDate}
                endDate={endDate}
                onDateChange={setCustomDateRange}
              />
            )}
          </HeaderBtnsContainer>
        )}
      </ContentBoxHeader>
    );
  },
);
