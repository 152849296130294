import { format, formatDistance } from 'date-fns';

export const timeAgo = (date: Date) => {
  const dist = formatDistance(date, new Date(), {
    addSuffix: true,
  });

  if (dist.includes('less than a minute')) return 'Just now';
  return dist
    .split(' ')
    .filter((word) => word !== 'about')
    .map((word) => {
      switch (word) {
        case 'year':
        case 'years':
        case 'month':
        case 'months':
          return format(date, 'MM/dd/yyy');
        case 'day':
        case 'days':
          return 'd';
        case 'hour':
        case 'hours':
          return 'h';
        case 'minute':
        case 'minutes':
          return 'm';
        case 'second':
        case 'seconds':
          return 's';
        default:
          return word;
      }
    })
    .join(' ');
};
