import { httpsCallable } from 'data/firebase';

interface IRequestDetails {
  companyId: string;
  emailAddress: string;
  employeeName: string;
  token: string;
}

export default async (data: IRequestDetails | IRequestDetails[]) => {
  const users = Array.isArray(data) ? data : [data];

  const usersDetails = users.map(({ token, ...cardDetails }) => {
    const url = `${window.location.origin}/photo-verification/${token}`;

    return { ...cardDetails, url, token };
  });

  const sendVerificationEmail = httpsCallable('sendPhotoVerificationEmail');
  await sendVerificationEmail({ usersDetails });
};
