import styled from 'styled-components';

interface IContainer {
  isOn: boolean;
  isDisabled: boolean;
}

interface ICircle {
  isOn: boolean;
}

const transitionTime = 0.12;

export const Container = styled.div<IContainer>`
  width: 30px;
  height: 20px;
  position: relative;
  display: flex;
  background-color: ${({ isOn, theme }) =>
    isOn ? theme.primaryGreen : theme.colorInactive + '30'};
  border-radius: 9999px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  transition: background-color ${transitionTime}s ease-in-out;
`;

export const Circle = styled.div<ICircle>`
  margin: 4px;
  width: 12px;
  height: 12px;
  position: absolute;
  left: ${({ isOn }) => (isOn ? '10px' : '0')};
  background-color: ${({ theme, isOn }) => (isOn ? 'white' : theme.colorInactive)};
  border-radius: 13px;
  transition: all ${transitionTime}s ease-in-out;
`;

export const Label = styled.label<ICircle>`
  font-size: 12px;
  line-height: 16px;
  font-weight: ${({ theme }) => theme.weightSemiBold};
  color: ${({ theme, isOn }) => (isOn ? theme.primaryGreen : theme.colorInactive)};
  transition: all ${transitionTime}s ease-in-out;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 15px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;
