import styled from 'styled-components';

interface IContainer {
  intent: 'primary' | 'secondary' | 'danger';
}

export const Container = styled.div<IContainer>`
  height: 14px;
  font-size: 10px;
  line-height: 10px;
  color: white;
  background-color: ${({ theme, intent }) =>
    intent === 'primary'
      ? theme.primaryGreen
      : intent === 'secondary'
      ? theme.secondaryViolet
      : theme.colorDanger};
  padding: 2px;
  border-radius: 3px;
`;
