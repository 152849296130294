import { UserRolesEnum } from 'types/user.types';

interface GenericUser {
  firstName: string;
  lastName: string;
  preferredName?: string;
}

export const formatName = <T extends GenericUser>(user: T): string => {
  if (user.preferredName && user.preferredName.trim() !== '') {
    return `${user.firstName} (${user.preferredName}) ${user.lastName}`;
  }
  return [user.firstName, user.lastName].filter((n) => !!n).join(' ');
};

export const getPersonInitials = <T extends GenericUser>(user: T) => {
  const name = [user.firstName, user.lastName].filter((n) => !!n).join(' ');
  if (!name) return '';

  return name
    .split(' ')
    .map((n) => n[0])
    .join('');
};

export const compareUserRole = (role1: UserRolesEnum, role2: UserRolesEnum) => {
  const { superAdmin, HRAdmin, admin, employee } = UserRolesEnum;
  const roleHeirarchy = [employee, admin, HRAdmin, superAdmin];

  const index1 = roleHeirarchy.findIndex((r) => r === role1);
  const index2 = roleHeirarchy.findIndex((r) => r === role2);

  return index1 - index2;
};
