export const setCurrentCompany = (id: string) => {
  localStorage.setItem('currentCompanyId', id);
};

export const getCurrentCompany = () => {
  return localStorage.getItem('currentCompanyId') || undefined;
};

export const removeCurrentCompany = () => {
  localStorage.removeItem('currentCompanyId');
};
