import React, { createContext, useContext, useMemo, useState } from 'react';
import { RefetchOptions, RefetchQueryFilters } from 'react-query';

import { useAppSelector } from 'state/hooks';
import useGetPhotoRequests from 'data/data-hooks/queries/photo-requests/useGetPhotoRequests';

import { EPhotoRequestStatus, ICompanyPhotoRequest } from 'types/photoRequest.types';

const PhotoRequestsContext = createContext<{
  accepted: ICompanyPhotoRequest[];
  isFetching: boolean;
  isLoading: boolean;
  notAccepted: ICompanyPhotoRequest[];
  refetch: (
    options?: (RefetchOptions & RefetchQueryFilters<unknown>) | undefined,
  ) => Promise<unknown>;
  requests: ICompanyPhotoRequest[];
  setShouldSendIDs: (bool: boolean) => void;
  shouldSendIDs: boolean;
  uploaded: ICompanyPhotoRequest[];
  waitingForPhoto: ICompanyPhotoRequest[];
}>({
  accepted: [],
  isFetching: false,
  isLoading: false,
  notAccepted: [],
  refetch: async () => null,
  requests: [],
  setShouldSendIDs: () => null,
  shouldSendIDs: false,
  uploaded: [],
  waitingForPhoto: [],
});

export const usePhotoRequests = () => {
  return useContext(PhotoRequestsContext);
};

export const PhotoRequestsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [shouldSendIDs, _setShouldSendIDs] = useState(true);

  const companyId = useAppSelector(({ company }) => company.currentCompany?.id);
  const {
    requests = [],
    isLoading,
    refetch,
    isFetching,
  } = useGetPhotoRequests({ companyId });

  const accepted = useMemo(
    () => requests.filter(({ status }) => status === EPhotoRequestStatus.accepted),
    [requests],
  );
  const uploaded = useMemo(
    () => requests.filter(({ status }) => status === EPhotoRequestStatus.uploaded),
    [requests],
  );

  const notAccepted = useMemo(
    () => requests.filter(({ status }) => status !== EPhotoRequestStatus.accepted),
    [requests],
  );

  const waitingForPhoto = useMemo(
    () =>
      requests.filter(
        ({ status }) =>
          status === EPhotoRequestStatus.rejected ||
          status === EPhotoRequestStatus.pending,
      ),
    [requests],
  );

  const setShouldSendIDs = (bool: boolean) => {
    _setShouldSendIDs(bool);
  };

  return (
    <PhotoRequestsContext.Provider
      value={{
        accepted,
        isFetching,
        isLoading,
        notAccepted,
        refetch,
        requests,
        setShouldSendIDs,
        shouldSendIDs,
        uploaded,
        waitingForPhoto,
      }}
    >
      {children}
    </PhotoRequestsContext.Provider>
  );
};
