import React from 'react';
import { Container } from './CardTrackerStyles';

export const CardTracker: React.FC = () => {
  return (
    <Container>
      <i className="fas fa-mail-bulk" />
    </Container>
  );
};
