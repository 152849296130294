import React from 'react';
import { Loader } from 'components/loader';

import { Container } from './LoadingPageStyles';

interface Props {
  isVisible?: boolean;
}

const LoadingPage: React.FC<Props> = ({ isVisible }: Props) => {
  return isVisible ? (
    <Container>
      <Loader />
    </Container>
  ) : null;
};

export default LoadingPage;
