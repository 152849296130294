import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getYear } from 'date-fns';

import { FlatButton } from 'components/buttons';
import { PATHS } from 'routes';
import { useCorporateSettings } from 'data/data-hooks/queries/corporate';

import { Container, LinksContaier } from './FooterStyles';

export const Footer: React.FC = () => {
  const navigate = useNavigate();

  const showTOS = location.pathname !== PATHS.termsOfService;
  const showPolicy = location.pathname !== PATHS.privacyPolicy;
  const toTermsOS = () => navigate(PATHS.termsOfService);
  const toPrivacyPolicy = () => navigate(PATHS.privacyPolicy);

  const { settings } = useCorporateSettings();

  return (
    <Container>
      <span>
        © Copyright {getYear(new Date())} {settings?.companyName}
      </span>
      <LinksContaier>
        {showTOS && (
          <>
            <FlatButton onClick={toTermsOS} dark>
              Terms of Service
            </FlatButton>
            {showPolicy && <span>|</span>}
          </>
        )}
        {showPolicy && (
          <FlatButton onClick={toPrivacyPolicy} dark>
            Privacy Policy
          </FlatButton>
        )}
      </LinksContaier>
    </Container>
  );
};
