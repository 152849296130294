import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageContentContainer, PageTitle } from 'domains/navigation';
import { TemplateForm } from './components/template-form/TemplateForm';
import { TemplatesTable } from './components/templates-table/TemplatesTable';
import { TransitionWrapper } from 'components/transition-wrapper/TransitionWrapper';

import { Inner } from './EmailTemplatesStyles';

export const EmailTemplates: React.FC = () => {
  const { t } = useTranslation();
  const tTitle = t('Email Templates');

  return (
    <TransitionWrapper delay={500}>
      <PageContentContainer>
        <PageTitle>{tTitle}</PageTitle>

        <Inner>
          <TemplatesTable />
          <TemplateForm />
        </Inner>
      </PageContentContainer>
    </TransitionWrapper>
  );
};
