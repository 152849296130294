import React from 'react';
import { ColoredBox } from 'pages/landing/components/colored-box/ColoredBox';
import { CustomizationDemo } from '../customization-demo/CustomizationDemo';

import { Bold, CardText, CardTitle } from '../../HowItWorksStyles';
import { Inner } from './CustomizationStyles';

export const Customization: React.FC = () => {
  return (
    <ColoredBox color="yellow" title="Customization">
      <Inner>
        <CardTitle>Email delivery and ID Card customization</CardTitle>
        <CardText>
          <Bold>New and existing employees will receive an email</Bold> with a
          call-to-action button that will allow them to verify their employee ID and
          install the digital ID after verification is completed.
        </CardText>
        <CustomizationDemo />
        <CardText>
          Administrators can{' '}
          <Bold>change the background color, font color, and logo</Bold> on the
          digital ID card.
        </CardText>
      </Inner>
    </ColoredBox>
  );
};
