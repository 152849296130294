import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import COLUMNS from './assets/columns';

import { FlatButton } from 'components/buttons';
import { TransitionWrapper } from 'components/transition-wrapper/TransitionWrapper';
import Table from 'components/table';
import useGetNotifications from 'data/data-hooks/queries/useGetNotifications';
import useMutateNotifications from 'data/data-hooks/mutations/notifications/useMutateNotifications';
import useSearchBar from 'hooks/useSearchBar';

import { INotification } from 'types/notification.types';
import { PageContentContainer, PageTitle } from 'domains/navigation';
import { Actions } from './NotificationsPageStyles';
import { includes } from 'utils/text.utils';

export const NotificationsPage: React.FC = () => {
  const [showAll, setShowAll] = useState(true);
  const { t } = useTranslation();
  const tTitle = t('Notifications');

  const { searchValue } = useSearchBar();
  const { notifications = [], isLoading } = useGetNotifications();
  const { markAsSeen, isLoading: isMarking } = useMutateNotifications();
  const notificationsRef = useRef<INotification[]>([]);
  notificationsRef.current = notifications;

  const data = useMemo(() => {
    const _notifications = notifications.filter(
      ({ message, emailFrom, id }) =>
        includes(message, searchValue) ||
        includes(emailFrom, searchValue) ||
        includes(id, searchValue),
    );
    if (!showAll) return _notifications.filter(({ status }) => status !== 'seen');
    return _notifications;
  }, [searchValue, notifications, showAll]);
  const columns = useMemo(() => COLUMNS, []);

  const readAll = () => {
    markAsSeen(notificationsRef.current);
  };

  useEffect(() => readAll, []);

  return (
    <TransitionWrapper delay={500}>
      <PageContentContainer>
        <PageTitle>{tTitle}</PageTitle>
        <Table
          columns={columns}
          isLoading={isLoading}
          data={data}
          columnsSpans="1fr 1.2fr 3fr 0.5fr"
          placeholder={
            showAll ? 'No notification messages yet' : 'You read all messages!'
          }
          headerProps={{
            showActions: !!notifications.find(({ status }) => status !== 'seen'),
            actions: (
              <Actions>
                <FlatButton
                  icon="far fa-check-circle"
                  intent="secondary"
                  onClick={readAll}
                  isLoading={isMarking}
                >
                  Mark all as read
                </FlatButton>
              </Actions>
            ),
            tabs: [
              {
                label: 'Unread',
                onSelect: () => setShowAll(false),
                isActive: !showAll,
              },
              {
                label: 'All',
                onSelect: () => setShowAll(true),
                isActive: showAll,
              },
            ],
          }}
        />
      </PageContentContainer>
    </TransitionWrapper>
  );
};
