import React from 'react';

import { Check } from 'pages/landing/icons/Check';
import { ColoredBox } from '../../../colored-box/ColoredBox';
import { PlatformDemoImg } from '../platform-demo/PlatformDemoImg';

import { Bold, CardSubheading, CardText, CardTitle } from '../../HowItWorksStyles';
import { Col, Inner, Line, Row, Side } from './PlatformStyles';

export const Platform: React.FC = () => (
  <ColoredBox color="violet" title="PLATFORM">
    <Inner>
      <Side>
        <CardTitle>Card Management System and Analytics Dashboard</CardTitle>
        <CardText>
          <Bold>The management system</Bold> will allow administrators to issue ID
          cards to new employees, expire existingcards, view analytics, and manage
          the graphical components of the digital ID cards.
        </CardText>
        <CardText>
          <Bold>The analytics dashboard</Bold> will provide a holistic view of the
          digital ID card system. The administrator can see the number of IDs
          installed and audit reports of what employees have installed the digital ID
          card on their mobile devices.
        </CardText>
      </Side>
      <Side>
        <PlatformDemoImg />
      </Side>
    </Inner>
    <Line />
    <Inner>
      <Side>
        <Row>
          <Check />
          <Col>
            <CardSubheading>HR System Integration</CardSubheading>
            <CardText>
              We integrate with your HR system to get all of the needed employee
              information to display on the digital ID card.
            </CardText>
          </Col>
        </Row>
      </Side>
      <Side>
        <Row>
          <Check />
          <Col>
            <CardSubheading>Administrator Login</CardSubheading>
            <CardText>
              Administrators will have the ability to log into the web portal to
              manage the ID card management system.
            </CardText>
          </Col>
        </Row>
      </Side>
    </Inner>
  </ColoredBox>
);
