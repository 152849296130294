import { doc, updateDoc } from 'firebase/firestore';
import { useMutation } from 'react-query';
import { firestore } from 'data/firebase';
import { SuccessToast } from 'components/toasts';

import { useAppSelector } from 'state/hooks';
import { formatName } from 'utils/user.utils';
import sendSetPasswordEmail from 'data/adapters/user/sendSetPasswordEmail';

import dbLabels from 'data/db.labels';
import { IUser, UserRolesEnum } from 'types/user.types';
import { IEmployee } from 'types/employee.types';

interface IArgs {
  onSuccess?: () => void;
}

interface IMutateArgs {
  user?: IUser;
  employee: IEmployee;
  role: UserRolesEnum;
}

export default (args?: IArgs) => {
  const currentCompany = useAppSelector(({ company }) => company.currentCompany);

  const mutation = useMutation(
    async ({ user, employee, role }: IMutateArgs) => {
      while (!currentCompany) continue;

      const { firstName, lastName, emailAddress, employeeId } = employee;

      if (user) {
        await updateDoc(doc(firestore, dbLabels.user, user.id), { role });
        SuccessToast('User role updated successfully');
      } else {
        await sendSetPasswordEmail({
          company: currentCompany,
          employeeId: employeeId,
          user: {
            companyId: currentCompany.id,
            emailAddress,
            firstName,
            lastName,
            role,
          },
        });

        let roleName;
        switch (role) {
          case UserRolesEnum.HRAdmin:
            roleName = 'HR Admin';
            break;
          case UserRolesEnum.admin:
            roleName = 'Admin';
            break;
          case UserRolesEnum.employee:
            roleName = 'Employee';
            break;
        }
        SuccessToast(
          `${formatName(
            employee,
          )} has been successfully made an ${roleName}. An email with instructions on how to login has been sent to them.`,
        );
      }
    },
    {
      onSuccess: async () => {
        args?.onSuccess && (await args.onSuccess());
      },
    },
  );

  return { updateRole: mutation.mutateAsync, ...mutation };
};
