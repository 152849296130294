import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { updatePassword } from 'state/auth/AuthActions';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import useAppForm, { TUseFormSubmit } from 'hooks/useAppForm';

import { SubmitButton } from 'components/buttons';
import { Input } from 'components/inputs';
import { SuccessToast } from 'components/toasts';

import { Actions, Container, Title } from './UpdatePasswordFormStyles';

interface IFormData {
  oldPassword: string;
  newPassword: string;
}

interface Props {
  shouldShow: boolean;
  handleClose: () => void;
}

export const UpdatePasswordForm: React.FC<Props> = ({
  shouldShow,
  handleClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(({ auth }) => auth);
  const { t } = useTranslation();
  const tChangePassword = t('Change Password');
  const tOldPassword = t('Old Password');
  const tNewPassword = t('New Password');

  const initialValues = [
    {
      key: 'oldPassword' as keyof IFormData,
      required: true,
      value: '',
    },
    {
      key: 'newPassword' as keyof IFormData,
      required: true,
      value: '',
    },
  ];

  const onSubmit: TUseFormSubmit<IFormData> = async (
    { oldPassword, newPassword },
    { setFormErrors },
  ) => {
    if (newPassword === oldPassword) {
      setFormErrors((errs) => ({
        ...errs,
        newPassword: 'New password cannot be the same as your old password',
      }));
      return;
    }

    const { success, error } = await dispatch(
      updatePassword({ oldPassword, newPassword }),
    );

    if (error === 'auth/wrong-password') {
      setFormErrors((errs) => ({
        ...errs,
        old: 'Invalid password',
      }));
    } else if (success) {
      SuccessToast('Password updated successfully');
      handleClose();
    }
  };

  const { formData, formErrors, handleChange, handleSubmit, resetForm } =
    useAppForm<IFormData>({ initialValues, onSubmit }, []);

  useEffect(() => {
    resetForm();
  }, [shouldShow]);

  return (
    <Container onSubmit={handleSubmit}>
      <Title>{tChangePassword}</Title>
      <Input
        autoComplete="off"
        error={formErrors.oldPassword}
        name="oldPassword"
        onChange={handleChange}
        placeholder={tOldPassword}
        success={!!formData.oldPassword}
        type="password"
        value={formData.oldPassword}
      />
      <Input
        autoComplete="off"
        error={formErrors.newPassword}
        name="newPassword"
        onChange={handleChange}
        placeholder={tNewPassword}
        success={!!formData.newPassword}
        type="password"
        value={formData.newPassword}
      />
      <Actions>
        <SubmitButton type="submit" isLoading={isLoading}>
          {tChangePassword}
        </SubmitButton>
      </Actions>
    </Container>
  );
};
