import { Reducer } from 'redux';
import { IPhotoReqTokenPayload } from 'types/token.types';

import * as Actions from './PhotoVerificationActions.types';

export interface State {
  isExpired?: boolean;
  isLoading: boolean;
  isNameInvalid: boolean;
  photo?: string;
  stepIndex: number;
  token?: string;
  userData?: IPhotoReqTokenPayload;
}

export const initialState: State = {
  isLoading: false,
  isNameInvalid: false,
  stepIndex: 0,
};

const PhotoVerificationReducer: Reducer<
  State,
  Actions.PhotoVerificationActionTypes
> = (state = initialState, action): State => {
  switch (action.type) {
    case Actions.PV__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    case Actions.PV__SET_IS_NAME_INVALID: {
      return {
        ...state,
        isNameInvalid: action.isInvalid,
      };
    }

    case Actions.PV__SET_PHOTO: {
      return {
        ...state,
        photo: action.photo,
        stepIndex: 3,
      };
    }

    case Actions.PV__SET_STEP: {
      return {
        ...state,
        stepIndex: action.step,
        isExpired: action.isExpired,
      };
    }

    case Actions.PV__SET_TOKEN: {
      return {
        ...state,
        token: action.token,
      };
    }

    case Actions.PV__UPLOAD_PHOTO: {
      return {
        ...state,
        stepIndex: 5,
      };
    }

    case Actions.PV__VERIFY_TOKEN: {
      return {
        ...state,
        userData: action.data,
        stepIndex: state.stepIndex + 1,
      };
    }

    default:
      return state;
  }
};

export default PhotoVerificationReducer;
