import { useEffect, useMemo, useState } from 'react';
import { firestore } from 'data/firebase';
import { isBefore } from 'date-fns';
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  orderBy,
  query,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';

import { useAppSelector } from 'state/hooks';
import useAppQuery from 'hooks/useAppQuery';
import dbLabels from 'data/db.labels';

import { INotification } from 'types/notification.types';

type IFirebaseNotification = Omit<INotification, 'employeeId'> & {
  employeeId: DocumentReference<DocumentData>;
};
type IFirebaseEmployee = Omit<INotification, 'modifiedOn'> & {
  modifiedOn: Timestamp;
};
const employeeConverter = {
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as IFirebaseEmployee,
  toFirestore: (data: IFirebaseEmployee) => data,
};

export default () => {
  const [isLoading, setIsloading] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const companyId = useAppSelector(({ company }) => company.currentCompany?.id);
  const notificationsRef = query(
    collection(
      doc(firestore, dbLabels.company, companyId || ''),
      dbLabels.notifications,
    ),
    orderBy('createdOn', 'desc'),
  );

  const { data = [], ...queryData } = useAppQuery<IFirebaseNotification[]>(
    ['notifications', companyId],
    notificationsRef,
  );

  const dataPromise = useMemo(() => {
    return Promise.all(
      data.map(async (elem) => {
        const employeeRef = await getDoc(
          elem.employeeId.withConverter(employeeConverter),
        );
        const employeeData = await employeeRef.data();
        const employee = employeeData
          ? {
              ...(employeeData || {}),
              id: employeeRef.id,
              modifiedOn: employeeData?.modifiedOn.toDate() || new Date(),
            }
          : undefined;

        return { ...elem, employee } as INotification;
      }),
    );
  }, [data]);

  useEffect(() => {
    !notifications.length && setIsloading(true);
    dataPromise.then((results) => {
      setNotifications(
        results
          .filter(({ deliveryType }) => deliveryType === 'in-app')
          .sort((a, b) => (isBefore(a.createdOn, b.createdOn) ? 1 : -1)),
      );
      setIsloading(false);
    });
  }, [dataPromise]);

  return {
    ...queryData,
    notifications,
    refresh: queryData.refetch,
    isLoading: queryData.isLoading || isLoading,
  };
};
