import { Reducer } from 'redux';
import { endOfDay, startOfDay } from 'date-fns';

import * as Actions from './AdminVerficationActions.types';

export interface State {
  endDate: Date;
  isLoading: boolean;
  selectedRequestsIds: Set<string>;
  startDate: Date;
}

const today = new Date();
export const initialState: State = {
  endDate: endOfDay(today),
  isLoading: false,
  selectedRequestsIds: new Set(),
  startDate: startOfDay(today),
};

const adminVerficationReducer: Reducer<
  State,
  Actions.EmployeesManagementActionTypes
> = (state = initialState, action): State => {
  switch (action.type) {
    case Actions.APV__REMOVE_FROM_SELECTION: {
      const newselectedCards = [...state.selectedRequestsIds].filter(
        (id) => id !== action.id,
      );

      return {
        ...state,
        selectedRequestsIds: new Set(newselectedCards),
      };
    }

    case Actions.APV__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    case Actions.APV__TOGGLE_EMPLOYEE_SELECTION: {
      const currentlySelected = [...state.selectedRequestsIds];
      const isAlreadySelected = currentlySelected.find((id) => id === action.id);
      const newselectedCards = isAlreadySelected
        ? currentlySelected.filter((id) => id !== action.id)
        : [...currentlySelected, action.id];

      return {
        ...state,
        selectedRequestsIds: new Set(newselectedCards),
      };
    }

    case Actions.APV__TOGGLE_SELECTION: {
      return {
        ...state,
        selectedRequestsIds: state.selectedRequestsIds.size
          ? new Set()
          : new Set(action.allRequests.map(({ id }) => id)),
      };
    }

    default:
      return state;
  }
};

export default adminVerficationReducer;
