import React from 'react';
import { CellProps } from 'react-table';
import { useTranslation } from 'react-i18next';
import useDimensions from 'hooks/useDimensions';

import { useAppSelector } from 'state/hooks';
import useToggleCardStatus from 'data/data-hooks/mutations/card/useToggleCardStatus';
import useUserRole from 'hooks/useUserRole';

import { EditCardPanel } from 'domains/cards/components/edit-card-panel';
import { FlatButton } from 'components/buttons';

import { IEmployeeCard } from 'types/card.types';
import { Actions } from '../../CardUpdatesListStyles';

interface Props extends CellProps<IEmployeeCard> {
  value: IEmployeeCard;
}

export const ActionsCell: React.FC<Props> = ({ value: row }: Props) => {
  const { isInDesktop } = useDimensions();

  const { mutateAsync: toggleStatus, isLoading } = useToggleCardStatus();

  const { isActive, id: cardId } = row;
  const { features } = useUserRole();
  const canDisable = !!features?.hasCardManagementAccess.canDisable;
  const canEdit = !!features?.hasCardManagementAccess.canEdit;

  const changeStatus = () => {
    if (!cardId) return;
    toggleStatus({ id: cardId, isCurrentlyActive: isActive });
  };

  const { t } = useTranslation();
  const tDisable = t('DISABLE');
  const tEnable = t('ENABLE');

  const company = useAppSelector(({ company }) => company.currentCompany);
  const allowDisable = company?.configuration?.allowDisable ?? false;
  const allowEdit = company?.configuration?.allowEdit ?? false;

  return (
    <Actions>
      {!!canEdit && allowEdit && <EditCardPanel cardInstance={row} />}

      {!!cardId && allowDisable && !!canDisable && isInDesktop && (
        <FlatButton
          isLoading={isLoading}
          onClick={changeStatus}
          icon={isActive ? 'far fa-times-circle' : 'far fa-check-circle'}
        >
          {isActive ? tDisable : tEnable}
        </FlatButton>
      )}
    </Actions>
  );
};
