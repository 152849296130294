import styled from 'styled-components';

interface IIcon {
  isPassDownloaded?: boolean;
  isPassDeleted?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const Icon = styled.i<IIcon>`
  font-size: 18px;
  color: ${({ theme, isPassDownloaded, isPassDeleted }) =>
    isPassDownloaded
      ? theme.primaryGreen
      : isPassDeleted
      ? theme.colorInactive
      : theme.secondaryViolet};
`;
