import React from 'react';
import DefaultAvatar from 'assets/images/DefaultAvatar.svg';
import { Container, Initials, Photo } from './AvatarCircleStyles';

interface Props {
  name?: string;
  photoUrl?: string;
  size: string | number;
}

export const AvatarCircle: React.FC<Props> = ({ name, photoUrl, size }: Props) => {
  const getInitials = () => {
    if (!name) return '';

    return name
      .split(' ')
      .map((n) => n[0])
      .join('');
  };

  return (
    <Container {...{ size }}>
      {!!(photoUrl || !name) && <Photo src={photoUrl || DefaultAvatar} />}
      {!!(!photoUrl && name) && <Initials {...{ size }}>{getInitials()}</Initials>}
    </Container>
  );
};
