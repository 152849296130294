import React from 'react';
import { Theme } from 'assets/styles';

export const Key: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_96_565)">
      <path
        d="M10.313 11.566L18.253 3.62598L20.374 5.74698L18.96 7.16098L21.081 9.28198L17.546 12.818L15.425 10.697L12.435 13.687C12.9855 14.7463 13.1391 15.9675 12.8679 17.1302C12.5966 18.2928 11.9185 19.32 10.9561 20.0264C9.99363 20.7327 8.81029 21.0715 7.61985 20.9815C6.42942 20.8916 5.31041 20.3789 4.46499 19.536C3.61914 18.6915 3.1038 17.5719 3.01235 16.3802C2.92089 15.1884 3.25938 14.0034 3.96647 13.0397C4.67356 12.076 5.70246 11.3976 6.86673 11.1272C8.031 10.8568 9.25361 11.0125 10.313 11.566ZM9.41399 17.414C9.60501 17.2295 9.75737 17.0088 9.86219 16.7648C9.96701 16.5208 10.0222 16.2583 10.0245 15.9928C10.0268 15.7272 9.97619 15.4639 9.87563 15.2181C9.77507 14.9723 9.62656 14.749 9.43878 14.5612C9.25099 14.3734 9.02769 14.2249 8.7819 14.1243C8.5361 14.0238 8.27274 13.9732 8.00718 13.9755C7.74163 13.9778 7.47919 14.033 7.23518 14.1378C6.99117 14.2426 6.77048 14.395 6.58599 14.586C6.22167 14.9632 6.02008 15.4684 6.02464 15.9928C6.0292 16.5172 6.23953 17.0188 6.61035 17.3896C6.98117 17.7604 7.48279 17.9708 8.00718 17.9753C8.53158 17.9799 9.03678 17.7783 9.41399 17.414Z"
        fill={Theme.primaryBlack}
      />
    </g>
    <defs>
      <clipPath id="clip0_96_565">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
