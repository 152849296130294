import React from 'react';

export const DragPhoto: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0L14.243 4.243L12.828 5.657L10 2.828L7.172 5.657L5.757 4.243L10 0ZM0 10L4.243 5.757L5.657 7.172L2.828 10L5.657 12.828L4.243 14.243L0 10ZM20 10L15.757 14.243L14.343 12.828L17.172 10L14.343 7.172L15.757 5.757L20 10ZM10 12C9.46957 12 8.96086 11.7893 8.58579 11.4142C8.21071 11.0391 8 10.5304 8 10C8 9.46957 8.21071 8.96086 8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8C10.5304 8 11.0391 8.21071 11.4142 8.58579C11.7893 8.96086 12 9.46957 12 10C12 10.5304 11.7893 11.0391 11.4142 11.4142C11.0391 11.7893 10.5304 12 10 12ZM10 20L5.757 15.757L7.172 14.343L10 17.172L12.828 14.343L14.243 15.757L10 20Z"
      fill="white"
    />
  </svg>
);
