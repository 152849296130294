import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCorporateSettings } from 'data/data-hooks/queries/corporate';
import contactUs from 'data/adapters/contactUs';
import useAppForm, { TUseFormSubmit } from 'hooks/useAppForm';

import { SubmitButton } from 'components/buttons';
import { FailToast, SuccessToast } from 'components/toasts';
import { Input, TextArea } from 'components/inputs';

import {
  Container,
  Grid,
  FormActions,
  Header,
  Inner,
  Note,
  SubHeader,
} from './FormStyles';

interface FormData {
  email: string;
  subject?: string;
  company: string;
  text: string;
}

export const ContactUsForm: React.FC = () => {
  const { t } = useTranslation();
  const { settings } = useCorporateSettings();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit: TUseFormSubmit<FormData> = async (
    { company, email, text, subject },
    { resetForm },
  ) => {
    try {
      setIsLoading(true);

      if (!settings) throw 'Failed to get corporate settings';

      const { success } = await contactUs({
        body: text,
        company,
        email,
        subject,
        to: settings.sendEmail.to,
        cc: settings.sendEmail.cc,
      });

      if (!success) throw 'Failed to send email';

      SuccessToast("You're email has been sent to one of out team members");
      resetForm();
    } catch {
      FailToast('Failed to send email');
    } finally {
      setIsLoading(false);
    }
  };

  const { formData, formErrors, handleChange, handleSubmit } = useAppForm<FormData>(
    {
      initialValues: [
        { key: 'email', required: true, value: '' },
        { key: 'subject', required: false, value: '' },
        { key: 'company', required: true, value: '' },
        { key: 'text', required: true, value: '' },
      ],
      onSubmit,
    },
    [],
  );

  const tHeader = t('Please submit your request');
  const tSubHeader = t('and we will follow up with you as soon as we can.');
  const tEmail = t('Email address *');
  const tSubject = t('Subject ');
  const tCompany = t('Company name *');
  const tText = t('Enter text *');
  const tSubmitRequest = t('Submit request');

  return (
    <Container>
      <Inner onSubmit={handleSubmit}>
        <Header>{tHeader}</Header>
        <SubHeader>{tSubHeader}</SubHeader>

        <Grid>
          <Input
            error={formErrors.email}
            name="email"
            onChange={handleChange}
            placeholder={tEmail}
            success={!!formData.email && !formErrors.email}
            type="email"
            value={formData.email}
          />
          <Input
            name="subject"
            onChange={handleChange}
            placeholder={tSubject}
            value={formData.subject}
            success={!!formData.subject && !formErrors.subject}
          />
          <Input
            error={formErrors.company}
            name="company"
            onChange={handleChange}
            placeholder={tCompany}
            success={!!formData.company && !formErrors.company}
            value={formData.company}
          />
          <TextArea
            error={formErrors.text}
            name="text"
            onChange={handleChange}
            placeholder={tText}
            success={!!formData.text && !formErrors.text}
            value={formData.text}
          />
        </Grid>

        <FormActions>
          <Note>
            By clicking submit, I acknowledge receipt of the digiprnt.com Privacy
            policy
          </Note>
          <SubmitButton isLoading={isLoading} type="submit">
            {tSubmitRequest}
          </SubmitButton>
        </FormActions>
      </Inner>
    </Container>
  );
};
