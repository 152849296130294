import { format } from 'date-fns';
import React from 'react';
import { CellProps } from 'react-table';

import { IPhotoRequest } from 'types/photoRequest.types';
import { Container } from './DateSentCellStyles';

export const DateSentCell: React.FC<CellProps<IPhotoRequest>> = ({
  cell: { value },
}: CellProps<IPhotoRequest>) => {
  return <Container>{value ? format(value, 'MMM dd') : 'N/A'}</Container>;
};
