import { combineReducers } from 'redux';

import adminVerficationReducer from './admin-verification/AdminVerficationReducer';
import authReducer from './auth/AuthReducer';
import cardManagementReducer from './card-management/CardManagementReducer';
import CardTrackerReducer from './card-tracker/CardTrackerReducer';
import CompanyReducer from './company/CompanyReducer';
import CreateAccountReducer from './create-account/CreateAccountReducer';
import EmailTemplatesReducer from './email-templates/EmailTemplatesReducer';
import PhotoVerificationReducer from './photo-verification/PhotoVerificationReducer';
import SearchBarReducer from './search-bar/SearchBarReducer';

export default combineReducers({
  adminVerification: adminVerficationReducer,
  auth: authReducer,
  cardManagement: cardManagementReducer,
  cardTracker: CardTrackerReducer,
  company: CompanyReducer,
  createAccount: CreateAccountReducer,
  photoVerification: PhotoVerificationReducer,
  searchBar: SearchBarReducer,
  templates: EmailTemplatesReducer,
});
