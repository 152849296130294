import React from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  setStepIndex,
  uploadPhoto,
} from 'state/photo-verification/PhotoVerificationActions';

import { SubmitButton, FlatButton } from 'components/buttons';
import { PhotoPicker } from 'components/photo-picker/PhotoPicker';

import { Actions } from '../SharedStyles';
import { Container } from './EditPhotoStepStyles';

export const EditPhotoStep: React.FC = () => {
  const dispatch = useAppDispatch();

  const { photo, userData, isLoading } = useAppSelector(
    ({ photoVerification }) => photoVerification,
  );
  let _editorRef: AvatarEditor | null = null;
  const setEditorRef = (editor: AvatarEditor | null) => (_editorRef = editor);

  const handleReturn = () => dispatch(setStepIndex(1));

  const { t } = useTranslation();
  const tUpload = t('Upload Photo');
  const tReturn = t('Return');

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (_editorRef) {
      let image: File;
      const canvas = _editorRef.getImage();
      canvas.toBlob((blob: Blob | null) => {
        if (blob) {
          image = new File([blob], `${userData?.employeeId || 'photo-id'}.png`, {
            type: 'image/png',
            lastModified: new Date().getTime(),
          });

          dispatch(uploadPhoto(image));
        }
      });
    }
  };

  return (
    <Container onSubmit={onSubmit}>
      <PhotoPicker setEditorRef={setEditorRef} photoUrl={photo}></PhotoPicker>
      <Actions>
        <FlatButton dark onClick={handleReturn}>
          {tReturn}
        </FlatButton>
        <SubmitButton isLoading={isLoading} type="submit">
          {tUpload}
        </SubmitButton>
      </Actions>
    </Container>
  );
};
