import styled from 'styled-components';

export const Bold = styled.span`
  font-weight: ${({ theme }) => theme.weightBold};
`;

export const Inner = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const IconContainer = styled.div`
  width: 30px;
  height: 30px;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 25px;
`;

export const PromptContianer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const TextAreaContainer = styled.div`
  width: 100%;
  height: 120px;
`;
