import { format } from 'date-fns';
import { collection, doc, writeBatch } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { firestore, storage } from 'data/firebase';
import dbLabels from 'data/db.labels';

import { checkPhoto } from 'utils/photo.utils';
import { formatName } from 'utils/user.utils';
import { parseError } from 'utils/errors.utils';

import { Adapter } from '../../types';
import { ICardToGenerate, IEmployeeCard } from 'types/card.types';
import { ICompany } from 'types/company.types';

interface ISendCardArgs {
  company: ICompany;
  cards: IEmployeeCard[];
}

export default async ({
  cards,
  company,
}: ISendCardArgs): Promise<
  Adapter<{
    failedCards?: IEmployeeCard[];
    submittedCards: ICardToGenerate[];
  }>
> => {
  try {
    let allSucceeded = true;
    const defaultAvatarUrl = await getDownloadURL(ref(storage, 'default.png'));
    let logoURL = checkPhoto(company.logoURL);
    if (!logoURL || logoURL.includes('undefined')) {
      logoURL = await getDownloadURL(ref(storage, 'digi-prnts-logo.png'));
    }

    const cardsRef = collection(
      firestore,
      dbLabels.company,
      company.id,
      dbLabels.cardTrackers,
    );

    const employeeCardsRef = collection(
      firestore,
      dbLabels.company,
      company.id,
      dbLabels.employeeCards,
    );

    const failedCards: IEmployeeCard[] = [];
    const dataToGenerate = (cards || [])
      .map(({ cardId, ...employee }) => {
        if (employee.employeeNumber) {
          const id = doc(cardsRef).id;

          return {
            id,
            CompanyLogoText: company.companyName || '',
            CompanyLogoUrl: logoURL || '',
            CompanyName: company.companyName || '',
            Description: company.isSchool
              ? 'SchoolDay.Ai Mobile ID'
              : 'Digital Mobile ID',
            DistrictName: company.companyName || '',
            employeeNumber: employee.employeeNumber || '',
            EmployeeTitle: employee.title || '',
            EmailAddress: employee.emailAddress || '',
            ExpirationDate: new Date(
              new Date().setFullYear(new Date().getFullYear() + 1),
            ),
            Grade: employee.grade ? employee.grade : 'N/A',
            cardId: cardId || '',
            PersonId: company.isSchool
              ? employee.employeeNumber
              : employee.employeeId || '',
            PersonName: formatName(employee),
            PersonPhotoUrl: checkPhoto(employee.photoUrl) || defaultAvatarUrl,
            PersonTitle: employee.title || '',
            QrCodeId: company.isSchool ? employee.employeeNumber : cardId || '',
            QRCodeSubText: 'Scan QR Code',
            ...(employee.hireDate && {
              SchoolTerm: employee.hireDate,
              EmployeeHireDate: format(new Date(employee.hireDate), 'yyy-MM-dd'),
            }),
            createdOn: new Date(),
            modifiedOn: new Date(),
          };
        }
        allSucceeded = false;
        failedCards.push({ cardId, ...employee });
        return null;
      })
      .filter((c) => !!c) as ICardToGenerate[];

    let _writeBatch = writeBatch(firestore);

    for (const [index, card] of dataToGenerate.entries()) {
      const newRef = doc(cardsRef, card.id);
      _writeBatch.set(newRef, { ...card, status: 'PENDING' });

      const employeeRef = doc(employeeCardsRef, card.cardId);
      _writeBatch.update(employeeRef, {
        currentCardInstance: {
          id: card.id,
          createdOn: card.createdOn,
        },
        isPassDeleted: false,
        isPassDownloaded: false,
      });

      if (index % 249 === 0 || index == dataToGenerate.length - 1) {
        await _writeBatch.commit().finally(() => {
          _writeBatch = writeBatch(firestore);
        });
      }
    }

    if (!allSucceeded) {
      return {
        success: false,
        error: '@Error/some-failed-cards',
        data: {
          failedCards,
          submittedCards: dataToGenerate,
        },
      };
    }

    return {
      success: true,
      data: { submittedCards: dataToGenerate },
    };
  } catch (err) {
    const stringError = parseError(err);

    return { success: false, error: stringError };
  }
};
