import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.primaryBlack};
`;

export const Inner = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  max-width: ${({ theme }) => theme.sizeRanges.desktop.max}px;
  margin: 0px auto;
`;
