import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px;
  border-radius: 0px 40px 40px 40px;
  background-color: white;
  height: 265px;
  margin: 35px 0px;
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  max-width: calc(100% - 90px);
`;

export const Company = styled.div`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.secondaryBlack};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.primaryGreen};
`;

export const Rate = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
`;

export const Review = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Text = styled.p`
  display: -webkit-box;
  font-family: ${({ theme }) => theme.secondaryFont};
  font-style: normal;
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.secondaryBlack};
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
`;
