import styled from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: ${({ theme }) => theme.sizeRanges.tablet.min}px) {
    flex-direction: column;
  }
`;
