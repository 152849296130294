import React from 'react';

import { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';

import { Container, Icon, ToolTipContent } from './PassStatusCellStyles';

interface Props {
  digiPass: {
    id: string;
    isPassDownloaded: boolean;
    isPassDeleted: boolean;
  };
}

export const PassStatusCell: React.FC<Props> = React.memo(({ digiPass }: Props) => {
  const { id, isPassDownloaded, isPassDeleted } = digiPass;

  const theme = useTheme();

  return (
    <Container>
      {isPassDeleted ? (
        <>
          <Icon
            data-tip
            data-for={`status-dlt-${id}`}
            isPassDeleted
            className="fas fa-minus-circle"
          />
          <ReactTooltip
            id={`status-dlt-${id}`}
            place="top"
            effect="solid"
            backgroundColor={theme.colorInactive}
            className="tool-tip"
          >
            <ToolTipContent>Card was deleted from phone</ToolTipContent>
          </ReactTooltip>
        </>
      ) : isPassDownloaded ? (
        <>
          <Icon
            data-tip
            data-for={`status-dwn-${id}`}
            isPassDownloaded
            className="fas fa-cloud-download-alt"
          />
          <ReactTooltip
            id={`status-dwn-${id}`}
            place="top"
            effect="solid"
            backgroundColor={theme.primaryGreen}
            className="tool-tip"
          >
            <ToolTipContent>Card was downloaded to phone</ToolTipContent>
          </ReactTooltip>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
});
