import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 60px 90px;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    padding: 25px 45px;
  }
`;

export const Inner = styled.div`
  width: 100%;
  // display: flex;
  // flex-direction: row;
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 45px;
  row-gap: 45px;

  @media (max-width: ${(props) => props.theme.sizeRanges.tablet.max}px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${(props) => props.theme.sizeRanges.tablet.min}px) {
    grid-template-columns: 1fr;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
`;

export const Text = styled.div`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-style: normal;
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.secondaryBlack};
`;

export const Title = styled.span`
  font-family: ${({ theme }) => theme.primaryFont};
  font-style: italic;
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 30px;
  line-height: 60px;
  color: ${({ theme }) => theme.primaryBlack};
`;
