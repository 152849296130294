import styled from 'styled-components';

interface IContainer {
  isExpanded?: boolean;
  isVisible?: boolean;
}

export const ControlBarContainer = styled.div<IContainer>`
  width: 100%;
  background-color: ${({ theme }) => theme.secondaryVioletTrans};
  border-radius: 15px;
  color: white;
  transition: height 500ms;

  ${({ isVisible = true, isExpanded = false }) =>
    isExpanded ? `height: 80px;` : isVisible ? 'height: 40px;' : 'height: 0px;'};
`;
