import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmitButton } from 'components/buttons';
import { Hide, Key, Show } from '../icons';

import { ErrorMessage, Input, Label, TopContainer } from '../SharedStyles';
import {
  Container,
  ElementContianer,
  IconContianer,
  InnerBottomContainer,
  InputContainer,
  BottomContainer,
} from './InputStyles';

interface Props {
  autoComplete?: string;
  button?: {
    text: string;
    onClick: (value: string) => void;
    isDisabled?: boolean;
    isLoading?: boolean;
  };
  disabled?: boolean;
  error?: string;
  label?: string;
  leadingIcon?: JSX.Element;
  maxLength?: number;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder?: string;
  success?: boolean;
  trailingIcon?: JSX.Element;
  type?: 'email' | 'number' | 'password' | 'tel' | 'text' | 'textarea';
  value?: string;
}

export const InputComponent: React.FC<Props> = ({
  autoComplete,
  button,
  error,
  label = '',
  leadingIcon,
  disabled = false,
  maxLength = undefined,
  name,
  onChange,
  placeholder = '...',
  success = false,
  trailingIcon,
  type = 'text',
  value: controlledValue,
}: Props) => {
  const [value, setValue] = useState('');
  const [shouldShowPassword, setShouldShowPassword] = useState(false);

  const { t } = useTranslation();

  const tError = error && t(error);
  const tPlaceholder = t(placeholder);
  const tLabel = t(label);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setValue(e.target.value);
    onChange && onChange(e);
  };

  const handleTogglePassword = () => {
    setShouldShowPassword(!shouldShowPassword);
  };

  const hasLeadingElement = !!leadingIcon || type === 'password';
  const hasTrailingElement = !!trailingIcon || !!button || type === 'password';

  return (
    <Container>
      {/* label */}
      {label && (
        <TopContainer>
          <Label htmlFor={name}>{tLabel}</Label>
        </TopContainer>
      )}

      <InputContainer>
        {/* leading icon */}
        {!!hasLeadingElement && (
          <ElementContianer error={tError} success={success} leading>
            <IconContianer>
              {leadingIcon ? leadingIcon : type === 'password' && <Key />}
            </IconContianer>
          </ElementContianer>
        )}

        {/* input field */}
        <Input
          disabled={disabled}
          error={tError}
          hasLeadingElement={hasLeadingElement}
          hasTrailingElement={hasTrailingElement}
          maxLength={maxLength}
          name={name}
          onChange={handleChange}
          placeholder={tPlaceholder}
          success={success}
          type={type === 'password' && shouldShowPassword ? 'text' : type}
          value={controlledValue === undefined ? value : controlledValue}
          autoComplete={autoComplete}
        />

        {/* trailing icon or button */}
        {!!hasTrailingElement && (
          <ElementContianer error={tError} success={success} trailing>
            {!!button && (
              <SubmitButton
                onClick={() => button.onClick(value)}
                isLoading={button.isLoading}
                isDisabled={button.isDisabled}
              >
                {button.text}
              </SubmitButton>
            )}
            {!!trailingIcon && <IconContianer>{trailingIcon}</IconContianer>}
            {type === 'password' && (
              <IconContianer onClick={handleTogglePassword}>
                {shouldShowPassword ? <Show /> : <Hide />}
              </IconContianer>
            )}
          </ElementContianer>
        )}
      </InputContainer>

      {/* bottom error text holder */}
      {
        <BottomContainer>
          <InnerBottomContainer>
            {error && <ErrorMessage>{tError}</ErrorMessage>}
          </InnerBottomContainer>
        </BottomContainer>
      }
    </Container>
  );
};
