import styled from 'styled-components';

interface IEditorContainer {
  darken: boolean;
}

export const FileField = styled.input`
  ::-webkit-file-upload-button {
    visibility: hidden;
    width: 0px;
    padding: 0px;
    margin: 0px;
  }
  ::file-selector-button {
    visibility: hidden;
    width: 0px;
    padding: 0px;
    margin: 0px;
  }
  ::-ms-browse {
    visibility: hidden;
    width: 0px;
    padding: 0px;
    margin: 0px;
  }

  ::before {
    content: 'Upload Image File';
    display: inline-block;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    font-size: 20px;
    font-weight: ${({ theme }) => theme.weightBold};
    background: ${({ theme }) => `linear-gradient(
      224.33deg,
      rgba(0, 0, 0, 0.2) 5.75%,
      rgba(255, 255, 255, 2e-5) 71.65%
    ),
    ${theme.primaryYellow}`};
    background-blend-mode: overlay, normal;
    color: ${({ theme }) => theme.primaryBlack};
    border-radius: 10px;
    width: fit-content;
    margin-bottom: 10px;

    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 18px;
    padding: 15px 40px;
    z-index: 1;
    user-select: none;
    width: fit-content;
    line-height: 20px;
    height: 100%;
    max-height: 50px;
    transition: all 0.2s;
    min-width: min(100%, 230px);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-family: ${({ theme }) => theme.secondaryFont};
    font-size: 18px;
    font-weight: ${({ theme }) => theme.weightRegular};
    border-radius: 60px;
  }

  :hover::before {
    border-color: black;
    background: ${({ theme }) => theme.primaryYellow};
  }

  :active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
`;

export const FillButton = styled.i`
  margin: 1px 5px;
  cursor: pointer;
  border-radius: 100px;
  padding: 1px;
  box-shadow: 0px 1px 3px 0px ${({ theme }) => theme.lightGrayBg};

  :hover {
    box-shadow: 0px 3px 2px 0px ${({ theme }) => theme.lightGrayBg};
  }
`;

export const Label = styled.div`
  font-size: 15px;
  line-height: 15px;
  font-weight: ${({ theme }) => theme.weightSemiBold};
  color: ${({ theme }) => theme.lightGrayBg};

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 15px;
  }
`;

export const PhotoControls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
`;

export const PhotoPicker = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.lightGrayBg};
  border-radius: 5px;
  padding: 10px;
  grid-column: 1 / span 2;
`;

export const PhotoPreview = styled.div``;

export const EditorContainer = styled.div<IEditorContainer>`
  background-color: ${({ theme, darken }) =>
    darken ? theme.lightGrayBg + '80' : 'transparent'};
  height: 125px;
  width: 225px;
  border-radius: 10px;
`;

export const ZoomScale = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .rangeslider {
    width: 205px !important;
    margin: 5px 0px;
  }

  .rangeslider__handle {
    width: 18px !important;
    height: 18px !important;
  }
`;
