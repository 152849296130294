import styled from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    padding: 20px;
  }
`;

export const Inner = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1160px;
  margin: auto;
`;

export const Header = styled.h2`
  color: ${({ theme }) => theme.primaryBlack};
  text-align: center;
`;

export const SubHeader = styled.span`
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Grid = styled.div`
  margin: 40px 0px;
  display: grid;
  grid-template-columns: 550px 550px;
  column-gap: 60px;
  row-gap: 30px;
  width: 100%;

  > :nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }

  > :nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }

  > :nth-child(3) {
    grid-column: 1;
    grid-row: 3;
  }

  > :nth-child(4) {
    grid-column: 2;
    grid-row: 1 / span 3;
  }

  @media (max-width: ${({ theme }) => theme.sizeRanges.desktop.min}px) {
    grid-template-columns: 100%;
    row-gap: 20px;

    > :nth-child(1),
    > :nth-child(2),
    > :nth-child(3) {
      grid-column: 1;
    }

    > :nth-child(4) {
      grid-column: 1;
      grid-row: 4 / span 3;
    }
  } ;
`;

export const FormActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(50% - 30px);
  margin-left: auto;

  @media (max-width: ${({ theme }) => theme.sizeRanges.desktop.min}px) {
    width: 100%;
    max-width: 550px;
  }
`;

export const Note = styled.div`
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 12px;
  line-height: 21px;
  color: ${({ theme }) => theme.primaryBlack};
  width: 50%;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 10px;
    line-height: 15px;
  }

  @media (max-width: ${(props) => props.theme.widthMobile}) {
    font-size: 9px;
    line-height: 10px;
  }
`;
