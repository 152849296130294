import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardsStats } from './components/cards-stats/CardsStats';
import { CardUpdatesList } from './components/card-updates/CardUpdatesList';
import { PageContentContainer, PageTitle } from 'domains/navigation';
import { TransitionWrapper } from 'components/transition-wrapper/TransitionWrapper';

import { Inner, PageHeader, TopContainer } from './CardManagementStyles';

export const CardManagement: React.FC = () => {
  const { t } = useTranslation();
  const tTitle = t('Card Management');

  return (
    <TransitionWrapper>
      <PageContentContainer>
        <PageHeader>
          <PageTitle>{tTitle}</PageTitle>
        </PageHeader>
        <TopContainer>
          <CardsStats />
        </TopContainer>
        <Inner>
          <CardUpdatesList />
        </Inner>
      </PageContentContainer>
    </TransitionWrapper>
  );
};
