import React, { useEffect, useState } from 'react';
import useGetUser from 'data/data-hooks/queries/user/useGetUser';
import { UserRolesEnum } from 'types/user.types';
import { Container } from './RoleBadgeStyles';

interface Props {
  emailAddress: string;
}

export const RoleBadge: React.FC<Props> = ({ emailAddress }: Props) => {
  const [roleName, setRoleName] = useState('');
  const [intent, setIntent] = useState<'primary' | 'secondary' | 'danger'>(
    'primary',
  );

  const { user } = useGetUser({ emailAddress });

  useEffect(() => {
    if (user) {
      switch (user.role) {
        case UserRolesEnum.superAdmin:
          setRoleName('Super Admin');
          setIntent('danger');
          break;
        case UserRolesEnum.HRAdmin:
          setRoleName('HR Admin');
          setIntent('primary');
          break;
        case UserRolesEnum.admin:
          setRoleName('Admin');
          setIntent('secondary');
          break;
        default:
          setRoleName('');
      }
    }
  }, [user?.role]);

  if (!roleName) return null;
  return <Container intent={intent}>{roleName}</Container>;
};
