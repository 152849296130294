export const checkPhoto = (photoUrl: string | undefined) => {
    if (photoUrl?.includes('.png')) {
      return (
        photoUrl?.split('.png')[0] + '_450x450.png' + photoUrl?.split('.png')[1]
      );
    } else if (photoUrl?.includes('.jpg')) {
      return (
        photoUrl?.split('.jpg')[0] + '_450x450.jpg' + photoUrl?.split('.jpg')[1]
      );
    } else if (photoUrl?.includes('.jpeg')) {
      return (
        photoUrl?.split('.jpeg')[0] + '_450x450.jpeg' + photoUrl?.split('.jpeg')[1]
      );
    } else if (photoUrl?.includes('.gif')) {
      return (
        photoUrl?.split('.gif')[0] + '_450x450.gif' + photoUrl?.split('.gif')[1]
      );
    } else if (photoUrl?.includes('.webp')) {
      return (
        photoUrl?.split('.webp')[0] + '_450x450.webp' + photoUrl?.split('.webp')[1]
      );
    } else if (photoUrl?.includes('.svg')) {
      return (
        photoUrl?.split('.svg')[0] + '_450x450.svg' + photoUrl?.split('.svg')[1]
      );
    } else if (photoUrl?.includes('.bmp')) {
      return (
        photoUrl?.split('.bmp')[0] + '_450x450.bmp' + photoUrl?.split('.bmp')[1]
      );
    } else if (photoUrl?.includes('.tiff')) {
      return (
        photoUrl?.split('.tiff')[0] + '_450x450.tiff' + photoUrl?.split('.tiff')[1]
      );
    } else if (photoUrl?.includes('.tif')) {
      return (
        photoUrl?.split('.tif')[0] + '_450x450.tif' + photoUrl?.split('.tif')[1]
      );
    } else if (photoUrl?.includes('.jfif')) {
      return (
        photoUrl?.split('.jfif')[0] + '_450x450.jfif' + photoUrl?.split('.jfif')[1]
      );
    } else if (photoUrl?.includes('.pjpeg')) {
      return (
        photoUrl?.split('.pjpeg')[0] +
        '_450x450.pjpeg' +
        photoUrl?.split('.pjpeg')[1]
      );
    } else if (photoUrl?.includes('.pjp')) {
      return (
        photoUrl?.split('.pjp')[0] + '_450x450.pjp' + photoUrl?.split('.pjp')[1]
      );
    } else {
      return photoUrl;
    }
  };