import styled from 'styled-components';

interface IFiller {
  value: number;
  intent: 'success' | 'danger';
}

export const Container = styled.div`
  height: 5px;
  max-width: 150px;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 50px;
`;

export const Filler = styled.div<IFiller>`
  height: 100%;
  width: ${({ value }) => value}%;
  max-width: 100%;
  border-radius: inherit;
  transition: width 1s ease-in-out;
  background-color: ${({ theme, intent }) =>
    intent === 'success' ? theme.primaryGreen : theme.colorDanger};
`;
