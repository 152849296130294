import { parseError } from 'utils/errors.utils';
import { firestore } from 'data/firebase';
import { doc, getDoc, QueryDocumentSnapshot } from 'firebase/firestore';
import dbLabels from 'data/db.labels';

import { Adapter } from '../../types';
import { ICompany } from 'types/company.types';

interface IGetCompanyArgs {
  id: string;
}

const comapanyConverter = {
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as ICompany,
  toFirestore: (data: ICompany) => data,
};

export default async ({ id }: IGetCompanyArgs): Promise<Adapter<ICompany>> => {
  try {
    const company = await getDoc(
      doc(firestore, dbLabels.company, id).withConverter(comapanyConverter),
    ).then((doc) => {
      if (!doc.exists) throw "Couldn't find company";
      return { ...doc.data(), id: doc.id } as ICompany;
    });

    if (!company.configuration.photoRequestSettings?.expirationPeriod) {
      company.configuration.photoRequestSettings = {
        ...(company.configuration.photoRequestSettings || {}),
        expirationPeriod: 10,
      };
    }

    return { success: true, data: company };
  } catch (err) {
    const stringError = parseError(err);

    return { success: false, error: stringError };
  }
};
