import styled from 'styled-components';

export const Inner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.primaryYellow};
  opacity: 0.4;
`;

export const ContactDetails = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 10px;
  text-align: left;
`;

export const EmailAddress = styled.p`
  color: white;
  opacity: 0.6;
`;
