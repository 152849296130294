import { firestore } from 'data/firebase';
import { collection, doc, query, where } from 'firebase/firestore';
import useAppQuery from 'hooks/useAppQuery';
import dbLabels from 'data/db.labels';

import { IUser } from 'types/user.types';
import { useMemo } from 'react';

interface IArgs {
  id?: string;
  emailAddress?: string;
}

export default ({ id, emailAddress }: IArgs) => {
  const keys = ['users'];
  let userDocRef;
  if (id) {
    keys.push(id);
    userDocRef = doc(firestore, dbLabels.user, id);
  } else {
    keys.push(emailAddress || '');
    userDocRef = query(
      collection(firestore, dbLabels.user),
      where('emailAddress', '==', emailAddress || ''),
    );
  }

  const { data, ...queryData } = useAppQuery<IUser | IUser[]>(keys, userDocRef);

  if (!id && !emailAddress) return { user: undefined, ...queryData };

  const user = useMemo(() => {
    if (Array.isArray(data)) {
      return data[0];
    }
    return data;
  }, [data]);

  return { user, ...queryData };
};
