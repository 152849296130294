import styled from 'styled-components';

export const Container = styled.form`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 550px;
  width: 100%;
`;

export const Actions = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
