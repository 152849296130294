import styled from 'styled-components';

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;

export const TextContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 0;
  max-width: 35%;
`;

export const ImageText = styled.div`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-style: normal;
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.secondaryBlack};

  @media (max-width: ${({ theme }) => theme.sizeRanges.tablet.min}px) {
    font-size: 12px;
    line-height: 14px;
  }

  @media (max-width: ${({ theme }) => theme.sizeRanges.mobile.max}px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
