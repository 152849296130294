import React from 'react';
import { useTranslation } from 'react-i18next';

import { Footer, PublicHeaderNav } from 'domains/navigation';
import { ContactUsForm } from './components/Form';
import { useCorporateSettings } from 'data/data-hooks/queries/corporate';

import {
  ContactDetails,
  Container,
  Details,
  DetailsHeader,
  DetailsText,
  FormContainer,
  WelcomeText,
} from './ContactUsStyles';

export const ContactUs: React.FC = () => {
  const { t } = useTranslation();
  const { settings } = useCorporateSettings();

  const tGetInTouch = t('👋 Get in touch with us');
  const tAddress = t('Address');
  const tEmail = t('Email');

  const formatAddress = () => {
    if (!settings) return '';
    const {
      address: { address1, address2, city, state },
    } = settings;

    return [address1, address2, city, state].filter((l) => !!l).join(', ');
  };

  return (
    <Container>
      <PublicHeaderNav />

      <ContactDetails>
        <WelcomeText>{tGetInTouch}</WelcomeText>
        <Details>
          <DetailsHeader>{tAddress}</DetailsHeader>
          <DetailsText>{formatAddress()}</DetailsText>
        </Details>

        <Details>
          <DetailsHeader>{tEmail}</DetailsHeader>
          <DetailsText email>{settings?.emailAddress || ''}</DetailsText>
        </Details>
      </ContactDetails>

      <FormContainer>
        <ContactUsForm />
      </FormContainer>

      <Footer />
    </Container>
  );
};
