import React from 'react';
import { Theme } from 'assets/styles';

export const SendCard: React.FC = () => {
  return (
    <svg viewBox="0 0 282 147" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M264.946 0H68.1047C58.8049 0 51.2429 7.07537 51.2429 15.7824V131.218C51.2429 139.92 58.8101 147 68.1047 147H264.946C274.251 146.995 281.812 139.92 281.812 131.213V15.7824C281.812 7.07537 274.251 0 264.946 0ZM271.563 131.213C271.563 134.628 268.593 137.411 264.94 137.411H68.1047C64.4568 137.411 61.4873 134.633 61.4873 131.213V15.7824C61.4873 12.3672 64.4568 9.58408 68.1047 9.58408H264.946C268.593 9.58408 271.568 12.3623 271.568 15.7824L271.563 131.213Z"
        fill={Theme.secondaryBlack}
      />
      <path
        d="M83.2234 69.3082H108.185C113.597 69.3082 118.006 65.1874 118.006 60.1259V37.9247C118.006 32.8583 113.597 28.7375 108.185 28.7375H83.2234C77.8064 28.7375 73.4017 32.8583 73.4017 37.9247V60.1259C73.3965 65.1874 77.8064 69.3082 83.2234 69.3082ZM83.6461 38.3216H107.752V59.7192H83.6461V38.3216Z"
        fill={Theme.secondaryBlack}
      />
      <path
        d="M109.568 85.022H73.3965V94.606H109.568V85.022Z"
        fill={Theme.secondaryBlack}
      />
      <path
        d="M141.736 109.065H73.3965V118.654H141.736V109.065Z"
        fill={Theme.secondaryBlack}
      />
      <path
        d="M154.705 85.022H118.533V94.606H154.705V85.022Z"
        fill={Theme.secondaryBlack}
      />
      <path
        d="M199.831 85.022H163.655V94.606H199.831V85.022Z"
        fill={Theme.secondaryBlack}
      />
      <path
        d="M244.963 85.022H208.792V94.606H244.963V85.022Z"
        fill={Theme.secondaryBlack}
      />
      <path
        d="M40.9881 91.0243H14.5186V100.608H40.9881V91.0243Z"
        fill={Theme.secondaryBlack}
      />
      <path
        d="M40.9933 109.065H8.3291V118.654H40.9933V109.065Z"
        fill={Theme.secondaryBlack}
      />
      <path
        d="M40.9881 127.102H0V136.691H40.9881V127.102Z"
        fill={Theme.secondaryBlack}
      />
    </svg>
  );
};
