import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { Arrow } from './icons/Arrow';

import {
  Container,
  IconContainer,
  ButtonContainer,
  ToolTipItem,
  ToolTipItems,
  ToolTipText,
} from './MenuToolTipStyles';

interface Props {
  dark?: boolean;
  id: number | string;
  isDisabled?: boolean;
  menuOptions: { label: string; icon?: JSX.Element; onClick: () => void }[];
}

export const MenuToolTip: React.FC<Props> = ({
  dark = false,
  id,
  isDisabled = false,
  menuOptions,
}: Props) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [toolTipWidth, setToolTipWidth] = useState<number>(0);

  const toolTipInner = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    toolTipInner.current && setToolTipWidth(toolTipInner.current.clientWidth || 0);
  }, [toolTipInner.current]);

  const handleAfterHide = () => setIsOpen(false);
  const handleAfterShow = () => setIsOpen(true);

  return (
    <Container>
      <ButtonContainer
        isDisabled={isDisabled}
        data-event="click"
        data-for={'options' + id}
        data-tip
      >
        <Arrow isDisabled={isDisabled} isActive={isOpen} dark={dark} />
      </ButtonContainer>

      <ReactTooltip
        afterHide={handleAfterHide}
        afterShow={handleAfterShow}
        backgroundColor="#fff"
        className="tooltip"
        clickable={true}
        disable={isDisabled}
        effect="solid"
        globalEventOff="click"
        id={'options' + id}
        offset={{ left: toolTipWidth / 2 - 25 }}
        place="bottom"
        scrollHide={true}
      >
        <ToolTipItems ref={toolTipInner}>
          {menuOptions.length &&
            menuOptions.map(({ label, icon, onClick }) => (
              <ToolTipItem onClick={onClick} key={`option-${label}`}>
                {icon && <IconContainer>{icon}</IconContainer>}
                <ToolTipText>{t(label)}</ToolTipText>
              </ToolTipItem>
            ))}
        </ToolTipItems>
      </ReactTooltip>
    </Container>
  );
};
