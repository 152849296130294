import styled from 'styled-components';

export const Empty = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SeeAllRow = styled.div`
  height: calc(100% / 6);
  margin-top: auto;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.backgroundColor};
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 12px;
  gap: 10px;

  :hover {
    * {
      transform: scale(1.03);
    }
  }
`;
