import React, { Fragment } from 'react';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { CompanyPicker } from '../company-picker/CompanyPicker';
import NavOptions from '../nav-options/NavOptions';

export const SidePanel: React.FC = () => {
  return (
    <Fragment>
      <Logo />
      <CompanyPicker />
      <NavOptions />
    </Fragment>
  );
};
