import styled from 'styled-components';

interface IInput {
  isInFocus: boolean;
  value: string;
}

interface IInputContainer {
  isInFocus: boolean;
  isDisabled?: boolean;
}

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 420px;
`;

export const Input = styled.input<IInput>`
  width: 100%;
  padding: 10px 0;
  padding-left: ${({ value }) => (value.length ? '15px' : '45px')};
  padding-right: ${({ value }) => (value.length ? '45px' : '15px')};
  background-color: white;
  border-radius: 10px;
  border: 1px solid
    ${({ isInFocus, theme }) => (isInFocus ? theme.secondaryViolet : 'white')};

  color: ${({ theme }) => theme.secondaryBlack};
  font-family: ${({ theme }) => theme.secondaryFont};
  font-size: 12px;
  outline: none;
  transition: background-color 0.2s, border 0.2s;

  &:hover:not([disabled]) {
    border: 1px solid ${({ theme }) => theme.secondaryViolet};
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &::placeholder {
    color: ${({ theme }) => theme.primaryBlack};
  }
`;

export const InputContainer = styled.div<IInputContainer>`
  position: relative;

  ${({ isInFocus, theme }) =>
    isInFocus &&
    `
    fill: ${theme.secondaryViolet}
  `};

  &:hover {
    fill: ${({ theme, isDisabled }) => (!isDisabled ? theme.secondaryViolet : '')};
  }
`;
