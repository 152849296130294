import styled from 'styled-components';

interface IStatus {
  isActive: boolean;
}

export const EmployeeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const Details = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.div`
  font-weight: ${({ theme }) => theme.weightExtraBold};
  font-size: 14px;
  line-height: 20px;
`;

export const UserId = styled.div`
  font-size: 12px;
  line-height: 17px;
`;

export const Status = styled.div<IStatus>`
  padding: 10px 20px;
  border-radius: 50px;
  width: fit-content;
  height: fit-content;
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 12px;
  line-height: 16px;

  ${({ theme, isActive }) =>
    isActive
      ? `
        background-color: ${theme.primaryGreen}20;
        color: ${theme.primaryGreen};
      `
      : `
        background-color: ${theme.colorDanger}20;
        color: ${theme.colorDanger};
      `}

  @media (max-width: ${({ theme }) => theme.sizeRanges.tablet.max}px) {
    padding: 5px 10px;
    font-size: 10px;
  }
`;
