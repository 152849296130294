import { FirebaseError } from '@firebase/util';
import { PATHS } from 'routes';

// import { logout } from 'state/auth/AuthActions';

export const parseError = (err: unknown, defaultMessage = ''): string => {
  if (!err) return 'Unknown error';

  let errorMessage = defaultMessage;

  if (err instanceof FirebaseError) {
    // Firebase errors
    errorMessage = parseFirebaseErrorInstance(err);
  } else if (err instanceof Error && err.message) {
    // Error object
    errorMessage = parseErrorInstance(err);
  } else if (typeof err === 'string') {
    // strings
    errorMessage = err;
  }

  return errorMessage;
};

// const logoutUser = () => {
//   if (
//     location.pathname !== PATHS.login &&
//     location.pathname !== PATHS.forgotPassword &&
//     !location.pathname.startsWith('/oauth') &&
//     !location.pathname.startsWith(PATHS.forgotPassword)
//   ) {
//     const dispatch = store.dispatch as ThunkDispatch<
//       RootState,
//       void,
//       AnyAction
//     >;
//     dispatch(logout());
//   }
// };

const parseErrorInstance = (err: Error) => {
  if (err.message.includes('status code 403')) {
    // logoutUser();
    return `User not found`;
  } else if (err.message.includes('status code 401')) {
    location.replace(PATHS.pageNotFound);
    return `Unauthorized Access`;
  } else {
    return err.message;
  }
};

const parseFirebaseErrorInstance = (err: FirebaseError) => {
  switch (err.code) {
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return 'The email or password is incorrect';
    case 'auth/too-many-requests':
    default:
      return err.message;
  }
};
