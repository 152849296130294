import { useMemo } from 'react';
import { format } from 'date-fns';
import { firestore } from 'data/firebase';
import {
  collection,
  doc,
  orderBy,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';

import { useAppSelector } from 'state/hooks';
import useAppQuery from 'hooks/useAppQuery';
import dbLabels from 'data/db.labels';

import { IEmployeeCard } from 'types/card.types';

interface IArgs {
  startDate?: Date;
  endDate?: Date;
}

interface IFirestoreEmployeeCard
  extends Omit<IEmployeeCard, 'currentCardInstance' | 'lastPhotoRequest'> {
  currentCardInstance?: {
    id: string;
    createdOn: Timestamp;
  };
  lastPhotoRequest?: Timestamp;
}

export default (args?: IArgs) => {
  const companyId = useAppSelector(({ company }) => company.currentCompany?.id);
  const keys = ['employee_cards', companyId];
  let companyRef = query(
    collection(
      doc(firestore, dbLabels.company, companyId || 'INVALID_ID'),
      dbLabels.employeeCards,
    ),
    orderBy('modifiedOn', 'desc'),
  );

  if (args) {
    const { startDate, endDate } = args;
    if (startDate) {
      keys.push(`from-${format(startDate, 'dd-MM-yyy')}`);
      const start = Timestamp.fromDate(startDate);
      companyRef = query(companyRef, where('modifiedOn', '>=', start));
    }
    if (endDate) {
      keys.push(`to-${format(endDate, 'dd-MM-yyy')}`);
      const end = Timestamp.fromDate(endDate);
      companyRef = query(companyRef, where('modifiedOn', '<=', end));
    }
  }

  const { data = [], ...queryData } = useAppQuery<IFirestoreEmployeeCard[]>(
    keys,
    companyRef,
    {
      refetchInterval: 15000,
      refetchIntervalInBackground: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
  );

  const employeeCards = useMemo<IEmployeeCard[]>(
    () =>
      data.map((doc) => ({
        ...doc,
        currentCardInstance: doc.currentCardInstance
          ? {
              id: doc.currentCardInstance?.id,
              createdOn: doc.currentCardInstance?.createdOn?.toDate(),
            }
          : undefined,
        lastPhotoRequest: doc.lastPhotoRequest
          ? doc.lastPhotoRequest?.toDate()
          : undefined,
        hireDate: doc.hireDate ? new Date(doc.hireDate) : undefined,
        terminationDate: doc.terminationDate
          ? new Date(doc.terminationDate)
          : undefined,
      })),
    [data],
  );

  return { employeeCards, ...queryData };
};
