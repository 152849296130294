import React from 'react';
import { Container, Title } from './ColoredBoxStyles';

interface Props {
  children: React.ReactNode;
  color: 'violet' | 'green' | 'yellow';
  title?: string;
}

export const ColoredBox: React.FC<Props> = ({ children, color, title }: Props) => {
  return (
    <Container color={color}>
      {!!title && <Title color={color}>{title.toUpperCase()}</Title>}
      {children}
    </Container>
  );
};
