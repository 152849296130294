import { isBefore } from 'date-fns';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from 'data/firebase';

import { parseError } from 'utils/errors.utils';
import dbLabels from 'data/db.labels';

import { Adapter } from '../../types';
import { EPhotoRequestStatus } from 'types/photoRequest.types';

interface IUploadPhotoArgs {
  token: string;
}

export default async ({ token }: IUploadPhotoArgs): Promise<Adapter<boolean>> => {
  try {
    const ref = collection(firestore, dbLabels.photorequest);
    const snapshot = await getDocs(query(ref, where('token', '==', token)));

    if (!snapshot.docs.length) throw 'Request does not exist';

    const req = snapshot.docs[0].data();
    const expirationDate = req.expirationDate.toDate();
    const status = req.status;

    const userHasNotResponded =
      status === EPhotoRequestStatus.pending ||
      status === EPhotoRequestStatus.rejected;

    if (
      status === EPhotoRequestStatus.expired ||
      isBefore(new Date(expirationDate), new Date())
    ) {
      throw 'EXPIRED_TOKEN';
    }

    if (userHasNotResponded) return { success: true, data: true };
    return { success: true, data: false };
  } catch (err) {
    let stringError;

    if (err === 'EXPIRED_TOKEN') stringError = 'EXPIRED_TOKEN';
    else stringError = parseError(err);

    return { success: false, error: stringError };
  }
};
