import { ICompanyPhotoRequest } from 'types/photoRequest.types';

export const APV__REMOVE_FROM_SELECTION = 'APV__REMOVE_FROM_SELECTION';
export const APV__SET_IS_LOADING = 'APV__SET_IS_LOADING';
export const APV__TOGGLE_EMPLOYEE_SELECTION = 'APV__TOGGLE_EMPLOYEE_SELECTION';
export const APV__TOGGLE_SELECTION = 'APV__TOGGLE_SELECTION';

interface RemoveFromSelectionAction {
  type: typeof APV__REMOVE_FROM_SELECTION;
  id: string;
}

interface SetIsLoadingAction {
  type: typeof APV__SET_IS_LOADING;
  isLoading: boolean;
}

interface ToggleRequestSelectionAction {
  type: typeof APV__TOGGLE_EMPLOYEE_SELECTION;
  id: string;
}

interface ToggleSelectionAction {
  type: typeof APV__TOGGLE_SELECTION;
  allRequests: ICompanyPhotoRequest[];
}

export type EmployeesManagementActionTypes =
  | RemoveFromSelectionAction
  | SetIsLoadingAction
  | ToggleRequestSelectionAction
  | ToggleSelectionAction;

export type TRemoveFromSelectionAction = (id: string) => RemoveFromSelectionAction;

export type TSetIsLoading = (isLoading: boolean) => SetIsLoadingAction;

export type TtoggleRequestSelection = (id: string) => ToggleRequestSelectionAction;

export type TToggleSelection = (
  AllEmployees: ICompanyPhotoRequest[],
) => ToggleSelectionAction;
