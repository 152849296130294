import React from 'react';
import { Loader } from 'components/loader';
import { useTranslation } from 'react-i18next';

import { Arrow } from 'components/icons';

import { Container, LeadingIconContainer } from './SubmitButtonStyles';

export interface ISubmitButtonProps {
  children: React.ReactNode;
  fill?: boolean;
  intent?: 'primary' | 'secondary' | 'success' | 'danger' | 'warn' | 'dark';
  isDisabled?: boolean;
  isLoading?: boolean;
  leadingIcon?: JSX.Element;
  onClick?: (e: React.MouseEvent) => void;
  showArrow?: boolean;
  transparent?: boolean;
  type?: 'button' | 'submit' | 'reset';
  size?: 'small' | 'large' | 'medium';
}

export const SubmitButton: React.FC<ISubmitButtonProps> = ({
  children,
  fill = false,
  intent,
  isDisabled = false,
  isLoading = false,
  leadingIcon,
  onClick,
  showArrow = true,
  transparent = false,
  type = 'button',
  size = 'large',
}: ISubmitButtonProps) => {
  const { t } = useTranslation();

  const getContent = () => {
    if (typeof children === 'string') {
      const text = t(children);

      return text;
    }

    return children;
  };

  const renderArrow = (() => {
    if (showArrow === undefined) {
      if (type === 'submit') return true;
      else return false;
    } else return showArrow;
  })();

  return (
    <Container
      disabled={isDisabled || isLoading}
      fill={fill ? 'true' : undefined}
      intent={intent}
      size={size}
      onClick={onClick}
      transparent={transparent}
      type={type}
    >
      {!isLoading && !!leadingIcon && (
        <LeadingIconContainer>{leadingIcon}</LeadingIconContainer>
      )}
      {isLoading ? (
        <Loader
          dark={intent === 'success'}
          size={size === 'large' ? 11 : size === 'medium' ? 9 : 7}
        />
      ) : (
        getContent()
      )}
      {!isLoading && renderArrow ? <Arrow /> : ''}
    </Container>
  );
};
