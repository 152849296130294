import styled from 'styled-components';

export const Inner = styled.div`
  display: flex;
  flex-direction: row;
  gap: 80px;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.secondaryViolet};
  opacity: 0.4;
  margin: 40px 0px;
`;

export const Side = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
`;
