import { isBefore } from 'date-fns';
import { decodeToken } from 'utils/token.utils';

import { FailToast } from 'components/toasts';
import { parseError } from 'utils/errors.utils';
import { PATHS } from 'routes';

import { getUserAuth } from 'state/auth/AuthActions';
import confirmAccountCreation from 'data/adapters/user/confirmAccountCreation';
import createUser from 'data/adapters/user/createUser';
import getPendingAccountExpDate from 'data/adapters/user/getPendingAccountExpDate';

import { IUser } from 'types/user.types';
import * as Actions from './CreateAccountActions.types';

export const launchCreateAccount: Actions.TLaunchCreateAccount =
  (token) => async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const payload = await decodeToken(token)
        .then((data) => data as unknown as IUser)
        .catch(() => null);
      if (!payload) throw 'Invalid token';

      dispatch({
        type: Actions.ACC__SET_USER_DATA,
        data: payload,
      });

      const {
        data: expirationDate,
        success,
        error,
      } = await getPendingAccountExpDate({ token });
      if (!success) throw error;

      if (isBefore(new Date(expirationDate || ''), new Date())) {
        throw 'Epired token';
      }
    } catch (error) {
      if (error === '@password-already-set')
        dispatch({
          type: Actions.ACC__SET_ERROR,
          error: 'Account was already created',
        });
      else window.location.assign(PATHS.login);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const setIsLoading: Actions.TSetIsLoading = (isLoading) => ({
  isLoading,
  type: Actions.ACC__SET_IS_LOADING,
});

export const setToken: Actions.TSetToken = (token) => async (dispatch) => {
  await dispatch(launchCreateAccount(token));
  await dispatch({
    token,
    type: Actions.ACC__SET_TOKEN,
  });
};

export const createAccount: Actions.TCreateAccount =
  (password) => async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));
      const userData = getState().createAccount.userData;
      const token = getState().createAccount.token || '';
      if (!userData) throw 'Invalid user data';

      const { success, error } = await createUser({ userData, password });
      if (!success) throw error;

      const { success: confirmSuccess, error: confirmErr } =
        await confirmAccountCreation({
          token,
        });
      if (!confirmSuccess) throw confirmErr;

      await dispatch(getUserAuth());

      return { success: true };
    } catch (error) {
      const errorMessage = parseError(error);
      FailToast(errorMessage);
      return { success: false };
    } finally {
      dispatch(setIsLoading(false));
    }
  };
