import React from 'react';
import Select, { OptionProps, OptionTypeBase } from 'react-select';
import useDimensions from 'hooks/useDimensions';

import { Pagination } from './Pagination';
import Option from 'components/select-dropdown/components/option/Option';
import ValueContainer from 'components/select-dropdown/components/value-container/ValueContainer';

import { PaginationProps } from '.';
import pageSizeDropdownStyles from './pageSizeDropdownStyles';
import {
  RowWrapper,
  RowContainer,
  SelectorContainer,
  RowContainerInner,
  SelectedNumber,
} from './PaginationStyles';

interface Props extends PaginationProps {
  data: unknown[];
  extraItems?: JSX.Element[];
  onPageSizeChange?: (size: number) => void;
  pageSize?: number;
  selectedRows?: string[];
  showSelectedNumber: boolean;
}

export const PaginationRow: React.FC<Props> = React.memo(
  ({
    extraItems = [],
    onPageSizeChange,
    pageSize = 5,
    data,
    selectedRows,
    showSelectedNumber = false,
    ...props
  }: Props) => {
    const { ref, dimensions } = useDimensions();

    const WrappedOption = (props: OptionProps<OptionTypeBase, boolean>) => {
      return <Option {...props} dimensions={dimensions} />;
    };

    const handleChange = (option: OptionTypeBase | null) => {
      if (!option || !onPageSizeChange) return;

      onPageSizeChange(option.value);
    };

    const components = {
      ValueContainer,
      Option: WrappedOption,
    };

    return (
      <RowWrapper>
        <RowContainer>
          <SelectedNumber>
            {showSelectedNumber &&
              `${(selectedRows || []).length} out of ${data.length} selected`}
          </SelectedNumber>
          <RowContainerInner>
            {extraItems}
            <SelectorContainer ref={ref}>
              {onPageSizeChange && (
                <Select
                  components={components}
                  defaultValue={{ label: pageSize, value: pageSize }}
                  isDisabled={!!props.loading}
                  menuPlacement="top"
                  menuPortalTarget={document.body}
                  onChange={handleChange}
                  styles={pageSizeDropdownStyles}
                  options={[
                    { label: 20, value: 20 },
                    { label: 50, value: 50 },
                    { label: 100, value: 100 },
                  ]}
                />
              )}
            </SelectorContainer>
            <Pagination {...props} />
          </RowContainerInner>
        </RowContainer>
      </RowWrapper>
    );
  },
);
