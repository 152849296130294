import styled, { keyframes } from 'styled-components';

interface IButtonContainer {
  isDisabled?: boolean;
}

const ring = keyframes`
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(20deg); }
  3% { -webkit-transform: rotateZ(-18deg); }
  5% { -webkit-transform: rotateZ(24deg); }
  7% { -webkit-transform: rotateZ(-22deg); }
  9% { -webkit-transform: rotateZ(20deg); }
  11% { -webkit-transform: rotateZ(-18deg); }
  13% { -webkit-transform: rotateZ(16deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }
  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
`;

export const ButtonContainer = styled.button<IButtonContainer>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  :hover {
    animation: ${ring} 4s ease-in-out infinite;
  }
`;

export const ToolTipItems = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
`;

export const NotificationItems = styled.div`
  width: 400px;
  min-height: 65px;
  padding: 10px;
  display: grid;
  grid-template-columns: 24px 3fr 65px;
  gap: 10px;

  * {
    transition: none;
  }

  &:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-of-type {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const EmployeeName = styled.div`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-weight: ${({ theme }) => theme.weightBold};
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 14px;
  line-height: 19px;
`;

export const Message = styled.div`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-weight: ${({ theme }) => theme.weightRegular};
  font-size: 13px;
  color: ${({ theme }) => theme.primaryBlack};
`;

export const Time = styled.div`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-weight: ${({ theme }) => theme.weightRegular};
  color: ${({ theme }) => theme.primaryBlack}40;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1px;
`;

export const Dot = styled.div`
  content: ' ';
  background-color: ${({ theme }) => theme.secondaryViolet};
  border-radius: 100%;
  width: 7px;
  height: 7px;
  margin-top: 5px;
`;

export const EmptyElement = styled.div`
  width: 400px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.lightGrayBg};
`;

export const SeeAllRow = styled.div`
  margin-top: auto;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme }) => theme.weightBold};
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 12px;
  gap: 10px;
  min-height: 40px;

  :hover {
    * {
      transform: scale(1.03);
    }
  }
`;
