import styled from 'styled-components';

interface IContainer {
  header?: boolean;
}

interface IStatus {
  isActive: boolean;
}

export const ListRowContainer = styled.div<IContainer>`
  height: ${({ header }) => (header ? '40px' : '60px')};
  border-bottom: 1px solid ${({ theme }) => theme.backgroundColor};
  display: grid;
  grid-template-columns: 0.3fr 1.7fr 1.7fr 1fr 0.7fr 0.5fr 0.5fr;
  padding: 10px 20px;
  align-items: center;
`;

export const Status = styled.div<IStatus>`
  padding: 10px 20px;
  border-radius: 50px;
  width: fit-content;
  height: fit-content;
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 12px;
  line-height: 16px;

  ${({ theme, isActive }) =>
    isActive
      ? `
        background-color: ${theme.primaryGreen}20;
        color: ${theme.primaryGreen};
      `
      : `
        background-color: ${theme.colorDanger}20;
        color: ${theme.colorDanger};
      `}

  @media (max-width: ${({ theme }) => theme.sizeRanges.tablet.max}px) {
    padding: 5px 10px;
    font-size: 10px;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  padding: 0px 2px;
`;

export const FileField = styled.label`
  ::-webkit-file-upload-button {
    visibility: hidden;
    width: 0px;
    padding: 0px;
    margin: 0px;
  }
  ::file-selector-button {
    visibility: hidden;
    width: 0px;
    padding: 0px;
    margin: 0px;
  }
  ::-ms-browse {
    visibility: hidden;
    width: 0px;
    padding: 0px;
    margin: 0px;
  }

  content: 'Upload Image File';
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  user-select: none;
  width: fit-content;
  height: fit-content;
  transition: all 0.2s;
  gap: 7px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-family: ${({ theme }) => theme.secondaryFont};
  font-size: 15px;
  font-weight: ${({ theme }) => theme.weightRegular};
  color: ${({ theme }) => theme.colorDanger};

  input {
    display: none;
  }

  &:hover {
    transform: scale(1.03);
  }
`;
