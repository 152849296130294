import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'state/hooks';
import { usePhotoRequests } from 'contexts';
import useMutatePhotoRequest from 'data/data-hooks/mutations/photo-request/useMutatePhotoRequest';

import { Card } from 'components/icons';
import { ConfirmDenyModal, Prompt } from 'components/modals';
import { SuccessToast } from 'components/toasts';

import { IPhotoRequest } from 'types/photoRequest.types';
import { Bold } from 'domains/SharedStyles';

interface Props {
  handleClose: () => void;
  onConfirm?: () => void;
  requests: IPhotoRequest[];
  shouldShow: boolean;
}

export const ApproveRequestModal: React.FC<Props> = ({
  handleClose,
  onConfirm,
  requests,
  shouldShow,
}: Props) => {
  const { currentCompany } = useAppSelector(({ company }) => company);
  const { shouldSendIDs } = usePhotoRequests();
  const { approveRequests, isApproving } = useMutatePhotoRequest({
    onAprroveSuccess: async () => {
      await (onConfirm && onConfirm());
      SuccessToast(tApproveSuccess);
      handleClose();
    },
  });
  const count = requests.length;
  const handleConfirm = () => approveRequests(requests);

  const { t } = useTranslation();
  const tApproveSuccess = t('Requests approved successfully');
  const tPrompt = t(
    `You are going to verify the photos ${
      shouldSendIDs ? 'and send digital cards to' : 'for'
    }`,
  );
  const entity = currentCompany?.isSchool ? 'students' : 'employees';
  const tUsers = t(`${count} ${entity}`);
  const tConfirm = shouldSendIDs ? t('Approve and Send Cards') : t('Approve');

  return (
    <ConfirmDenyModal
      closeModal={handleClose}
      shouldShow={shouldShow}
      onConfirm={handleConfirm}
      prompt={
        <Prompt>
          {tPrompt} <Bold>{tUsers}</Bold>
        </Prompt>
      }
      intent="success"
      onDeny={handleClose}
      cancelLabel="Cancel"
      confirmLabel={tConfirm}
      isLoading={isApproving}
      icon={<Card green />}
    />
  );
};
