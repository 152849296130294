import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { login } from 'state/auth/AuthActions';
import useAppForm from 'hooks/useAppForm';

import { SubmitButton, FlatButton } from 'components/buttons';
import { Input } from 'components/inputs';
import { PATHS } from 'routes';
import { FailToast } from 'components/toasts';

import { Actions, Container } from './FormStyles';

interface FormData {
  email: string;
  password: string;
}

export const Form: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = async () => {
    const { success } = await dispatch(login(formData));
    if (!success) FailToast('An error occurred. Try again later.');
  };

  const { isLoading } = useAppSelector(({ auth }) => auth);
  const { formData, formErrors, handleChange, handleSubmit } = useAppForm<FormData>(
    {
      initialValues: [
        { key: 'email', required: true, value: '' },
        { key: 'password', required: true, value: '' },
      ],
      onSubmit,
    },
    [],
  );

  const { t } = useTranslation();
  const buttonText = !isLoading ? t('Log in') : t('Loading...');
  const forgortPasswordText = t('Forgot password?');

  return (
    <Container onSubmit={handleSubmit}>
      <Input
        error={formErrors.email}
        leadingIcon={<i className="fas fa-envelope" />}
        name="email"
        onChange={handleChange}
        placeholder="Email Address..."
        type="email"
        value={formData.email}
        success={!!formData.email && !formErrors.email}
      />
      <Input
        error={formErrors.password}
        name="password"
        onChange={handleChange}
        placeholder="Password"
        type="password"
        value={formData.password}
        success={!!formData.password && !formErrors.password}
      />

      <Actions>
        <FlatButton
          intent="primary"
          large
          onClick={() => navigate(PATHS.forgotPassword)}
        >
          {forgortPasswordText}
        </FlatButton>
        <SubmitButton isLoading={isLoading} type="submit">
          {buttonText}
        </SubmitButton>
      </Actions>
    </Container>
  );
};
