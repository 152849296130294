import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCardTracker } from 'contexts';
import useRetryFailedGeneration from 'data/data-hooks/mutations/card/useRetryFailedGeneration';

import { Button } from 'components/buttons';
import { Card } from 'components/icons';
import { ConfirmDenyModal, Prompt } from 'components/modals';

import { Bold } from 'domains/SharedStyles';

interface Props {
  onSuccess?: () => void;
  selectedCardsIds: string[];
}

export const RetryModal: React.FC<Props> = ({
  onSuccess,
  selectedCardsIds,
}: Props) => {
  const count = selectedCardsIds.length;

  const [shouldShow, setShouldShow] = useState(false);

  const handleOpen = () => setShouldShow(true);
  const handleClose = () => setShouldShow(false);

  const { generatedCards } = useCardTracker();
  const { resendFailedCard, isLoading } = useRetryFailedGeneration({
    onSuccess: () => {
      onSuccess && onSuccess();
      handleClose();
    },
  });

  const onConfirm = () => {
    resendFailedCard(
      generatedCards.filter(({ id }) => !!selectedCardsIds.includes(id)),
    );
  };

  const { t } = useTranslation();
  const tRetry = t('Retry');
  const tGenerateCard = t(`Retry generating card${count === 1 ? '' : 's'}`);
  const tPrompt = t(`You are going to retry generating and sending`);
  const tCards = t(`${count} card${count === 1 ? '' : 's'}`);

  return (
    <Fragment>
      <Button
        onClick={handleOpen}
        intent="success"
        leadingIcon={<i className="fas fa-redo" />}
      >
        {tRetry}
      </Button>

      <ConfirmDenyModal
        cancelLabel="Cancel"
        closeModal={handleClose}
        confirmLabel={tGenerateCard}
        icon={<Card green />}
        isLoading={isLoading}
        onConfirm={onConfirm}
        onDeny={handleClose}
        prompt={
          <Prompt>
            {tPrompt} <Bold>{tCards}</Bold>
          </Prompt>
        }
        shouldShow={shouldShow}
        intent="success"
      />
    </Fragment>
  );
};
