import { useMemo } from 'react';
import { useAppSelector } from 'state/hooks';
import { ICardConfig } from 'types/card.types';

export default () => {
  const { currentCompany, isLoading } = useAppSelector(({ company }) => company);

  const cardConfig = useMemo<ICardConfig>(() => {
    return {
      backgroundColor:
        currentCompany?.configuration?.cardConfig?.backgroundColor || '#ffffff',
      foregroundColor:
        currentCompany?.configuration?.cardConfig?.foregroundColor || '#000000',
    };
  }, [currentCompany]);

  return { cardConfig, isLoading, isFetched: !!currentCompany };
};
