import { firestore } from 'data/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { parseError } from 'utils/errors.utils';
import dbLabels from 'data/db.labels';

import { Adapter } from '../../types';
import { ICardConfig } from 'types/card.types';
import { ICompany } from 'types/company.types';

interface IUpdateConfigArgs {
  cardConfig: ICardConfig;
  companyConfig?: ICompany['configuration'];
  companyId?: string;
}

export default async ({
  cardConfig,
  companyConfig,
  companyId,
}: IUpdateConfigArgs): Promise<Adapter> => {
  try {
    if (!companyId) throw 'Invalid company ID';

    await updateDoc(doc(firestore, dbLabels.company, companyId), {
      configuration: {
        ...(companyConfig || {}),
        cardConfig: {
          ...(companyConfig?.cardConfig || {}),
          ...cardConfig,
        },
      },
    });

    return { success: true };
  } catch (err) {
    const stringError = parseError(err);

    return { success: false, error: stringError };
  }
};
