import styled from 'styled-components';

interface IContainer {
  shouldShow: boolean;
}

interface IContentContainer {
  dark?: boolean;
  width: string;
}

export const Container = styled.div<IContainer>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${({ shouldShow }) => (shouldShow ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 1000;

  @media only screen and (max-width: ${({ theme }) => theme.widthTablet}) {
    align-items: unset;
    max-width: 100%;
    min-height: 100vh;
    border-radius: 0px;
  }
`;

export const ContentContainer = styled.div<IContentContainer>`
  position: relative;
  padding: 30px;
  background-color: ${({ theme, dark }) => (dark ? theme.primaryBlack : 'white')};
  border: 1px solid ${({ theme }) => theme.primaryBlack};
  width: ${({ width }) => width};
  border-radius: 10px;
  overflow-y: auto;
  z-index: 1000;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: visible;
  gap: 20px;
  overflow: hidden;

  @media only screen and (max-width: ${({ theme }) => theme.widthTablet}) {
    padding: 30px 30px 150px 30px;
    min-width: 100vw;
    min-height: 100vh;
    max-height: none;
    border-radius: 0;
  }
`;

export const HeaderText = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 40px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 95vh;
  width: 100%;
  gap: 20px;
`;

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.primaryBlackTrans};
  backdrop-filter: blur(8.15485px);
  cursor: pointer;
`;

export const TopContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
