import styled from 'styled-components';

export const PhotoPreview = styled.div`
  position: relative;
  canvas {
    border-radius: 20px;
  }
`;

export const Instructions = styled.div`
  position: absolute;
  padding: 5px;
  top: calc(50% - 20px);
  left: calc(50% - 70px);
  width: 140px;
  height: 40px;
  background-color: ${({ theme }) => theme.primaryBlack}30;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: white;
  font-size: 13px;
  font-weight: ${({ theme }) => theme.weightBold};
`;
