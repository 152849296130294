import React from 'react';
import Triangle from './components/triangle/Triangle';
import { IndicatorProps, OptionTypeBase } from 'react-select';

const DropdownIndicator: React.FC<IndicatorProps<OptionTypeBase, boolean>> = (
  props: IndicatorProps<OptionTypeBase, boolean>,
) => {
  const { selectProps } = props || {};
  const { isMenuOpen } = selectProps || {};
  return <Triangle down={isMenuOpen} isActive={isMenuOpen} />;
};

export default DropdownIndicator;
