import { parseError } from 'utils/errors.utils';
import { firestore } from 'data/firebase';
import { collection, getDocs } from 'firebase/firestore';
import dbLabels from 'data/db.labels';

import { Adapter } from '../../types';
import { IOrgLevel } from 'types/company.types';

interface IGetOrgLevelArgs {
  companyId: string;
}

export default async ({
  companyId,
}: IGetOrgLevelArgs): Promise<Adapter<IOrgLevel[]>> => {
  try {
    const orgLevels = await getDocs(
      collection(firestore, dbLabels.company, companyId, dbLabels.orgLevels),
    ).then((ss) => {
      return ss.docs.map((doc) => {
        if (!doc.exists) throw "Couldn't find level";
        return { ...doc.data(), id: doc.id } as IOrgLevel;
      });
    });

    return { success: true, data: orgLevels };
  } catch (err) {
    const stringError = parseError(err);

    return { success: false, error: stringError };
  }
};
