import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .file_uploader {
    height: 180px;

    input ~ div {
      left: -100%;
    }
  }
`;

export const Inner = styled.div`
  padding: 40px;
  width: 100%;
  background-color: white;
  border: 1px dashed ${({ theme }) => theme.primaryBlack};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const CommandsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 25px;
`;

export const BtnContainer = styled.div`
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    font-size: 18px;
  }
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-size: 20px;
  line-height: 28px;
  font-weight: ${({ theme }) => theme.weightRegular};
  color: white;
  opacity: 0.6;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    font-size: 15px;
  }
`;

export const Text = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.primaryBlack};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const SubText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.secondaryBlack}40;
`;
