import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActiveCardsChart } from './components/active-cards-chart/ActiveCardsChart';
import { CardUpdates } from './components/card-updates/CardUpdates';
import { PageContentContainer, PageTitle } from 'domains/navigation';
import { StatsGrid } from './components/stats-grid/StatsGrid';
import { TransitionWrapper } from 'components/transition-wrapper/TransitionWrapper';

import { Inner } from './DashboardStyles';

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const tTitle = t('Dashboard');

  return (
    <TransitionWrapper>
      <PageContentContainer>
        <PageTitle>{tTitle}</PageTitle>
        <StatsGrid />
        <Inner>
          <CardUpdates />
          <ActiveCardsChart />
        </Inner>
      </PageContentContainer>
    </TransitionWrapper>
  );
};
