import React from 'react';
import { useTheme } from 'styled-components';

interface Props {
  full?: boolean;
}

export const RatingStar: React.FC<Props> = ({ full = false }: Props) => {
  const theme = useTheme();

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_33_318)">
        <path
          d="M9 13.695L3.71025 16.656L4.8915 10.71L0.440247 6.594L6.4605 5.88L9 0.375L11.5395 5.88L17.5597 6.594L13.1085 10.71L14.2897 16.656L9 13.695Z"
          fill={full ? theme.primaryYellow : undefined}
          stroke="#FFF142"
        />
      </g>
      <defs>
        <clipPath id="clip0_33_318">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
