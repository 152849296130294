import styled from 'styled-components';

interface ITableHeader {
  canFilter: boolean;
  canSort: boolean;
  checkboxCol?: boolean;
}

export const Container = styled.thead``;

export const HeaderCell = styled.th<ITableHeader>`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  cursor: ${({ canFilter, canSort }) => (canFilter || canSort) && 'pointer'};
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.primaryBlack}60;
  position: relative;
  user-select: none;
  flex: 1;
  width: 100%;

  ${({ checkboxCol }) =>
    checkboxCol &&
    `&:last-of-type {
      flex: unset;
    }`}
`;

export const TextContainer = styled.div``;

export const ArrowContainer = styled.span`
  flex: 1;
`;

export const FilterContainer = styled.span`
  display: flex;
  flex: 1;
  margin-right: 25px;
  justify-content: space-around;
`;

export const FilterIcon = styled.i`
  color: ${({ color }) => color};
`;
