import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.primaryBlack};
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  flex-grow: 1;
  gap: 20px;

  @media (max-width: ${(props) => props.theme.widthTablet}) {
    padding: 0px 20px;
  }
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 30px;
  line-height: 45px;
  color: white;
`;

export const LoaderContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
