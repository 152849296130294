import styled from 'styled-components';

interface IContainer {
  color: 'violet' | 'green' | 'yellow';
}

export const Container = styled.div<IContainer>`
  width: 100%;
  padding: 40px;
  border-radius: 20px;
  text-align: left;
  background-color: ${({ color }) =>
    color === 'green' ? '#C5EBDE' : color === 'violet' ? '#E2E0EE' : '#FFF1C0'};
  height: 100%;
`;

export const Title = styled.div<IContainer>`
  width: 100%;
  margin-bottom: 20px;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;

  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightBold};
  color: ${({ theme, color }) =>
    color === 'green'
      ? theme.primaryGreen
      : color === 'violet'
      ? theme.secondaryViolet
      : '#D3C517'};
`;
