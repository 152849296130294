import { useMemo } from 'react';
import { Timestamp, collection, orderBy, query } from 'firebase/firestore';

import { firestore } from 'data/firebase';
import useAppQuery from 'hooks/useAppQuery';

import dbLabels from 'data/db.labels';

import { EPhotoRequestStatus, ICompanyPhotoRequest } from 'types/photoRequest.types';

interface IFirestorePhotoRequest
  extends Omit<ICompanyPhotoRequest, 'expirationDate'> {
  expirationDate?: Timestamp;
}

interface Args {
  companyId?: string;
}

export default ({ companyId }: Args) => {
  const companyRef = query(
    collection(
      firestore,
      dbLabels.company,
      companyId || 'NO_ID_PROVIDED',
      dbLabels.photorequest,
    ),
    orderBy('modifiedOn', 'desc'),
  );

  const { data = [], ...queryData } = useAppQuery<IFirestorePhotoRequest[]>(
    ['photo_reqs', companyId],
    companyRef,
  );

  const requests = useMemo(
    () =>
      data
        .filter((req) => req.status !== EPhotoRequestStatus.expired)
        .map((req) => ({
          ...req,
          expirationDate: req.expirationDate?.toDate(),
        })),
    [data],
  );

  return { requests, ...queryData };
};
