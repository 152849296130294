import React, { useEffect, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Slider from 'react-rangeslider';

import defaultLogo from 'assets/images/logo.svg';

import {
  EditorContainer,
  FileField,
  FillButton,
  Label,
  PhotoControls,
  PhotoPicker,
  PhotoPreview,
  ZoomScale,
} from './PhotoPickerSectionStyles';

type Props = {
  photoUrl?: string;
  setEditorRef: (editor: AvatarEditor | null) => AvatarEditor | null;
  setImageDidChange: (b: boolean) => void;
};

export const PhotoPickerSection: React.FC<Props> = ({
  photoUrl,
  setEditorRef,
  setImageDidChange,
}: Props) => {
  const [darkBG, setDarkBG] = useState(true);
  const [image, setImage] = useState<File | string>('');
  const [scale, setScale] = useState(1);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      setImage(e.target.files?.[0]);
      setImageDidChange(true);
    }
  };

  useEffect(() => {
    if (photoUrl) setImage(photoUrl);
    else setImage(defaultLogo);
  }, [photoUrl]);

  return (
    <PhotoPicker>
      <PhotoControls>
        <Label>Company Logo:</Label>
        <FileField
          type="file"
          onChange={handleUpload}
          accept="image/png, image/jpeg, image/jpg"
        />
      </PhotoControls>
      <PhotoPreview>
        <EditorContainer darken={darkBG}>
          <AvatarEditor
            height={125}
            image={image}
            ref={setEditorRef}
            scale={scale}
            width={225}
            border={0}
            onMouseMove={() => setImageDidChange(true)}
            crossOrigin="anonymous"
          />
        </EditorContainer>
        <ZoomScale>
          <Slider
            max={5}
            min={0}
            onChange={(val) => {
              setScale(val);
              setImageDidChange(true);
            }}
            step={0.05}
            value={scale}
          />
          <FillButton
            className={`${darkBG ? 'fas' : 'far'} fa-circle`}
            onClick={() => setDarkBG((dbg) => !dbg)}
          />
        </ZoomScale>
      </PhotoPreview>
    </PhotoPicker>
  );
};
