import styled from 'styled-components';

export const Inner = styled.div<{
  showTopBorder: boolean;
}>`
  display: flex;
  border-top: ${({ showTopBorder, theme }) =>
    showTopBorder ? `1px solid ${theme.lightGrayBg}` : 'none'};
  width: 100%;
`;

export const Text = styled.div<{
  maxWidth: number;
}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'unset')};
  padding: 16px 0px 16px 0px;
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 12px;
  color: ${({ theme }) => theme.secondaryBlack};
`;

export const CheckContainer = styled.div<{
  isSelected: boolean;
}>`
  display: ${({ isSelected }) => (isSelected ? 'flex' : 'none')};
  flex-grow: 1;
  justify-content: flex-end;
  max-height: 45px;
`;
