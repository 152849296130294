import { endOfDay, startOfDay, subMonths, subWeeks, subYears } from 'date-fns';
import * as Actions from './CardTrackerActions.types';

export const launchCardTracker: Actions.TLaunchCardTracker =
  () => async (dispatch) => {
    await dispatch({
      type: Actions.TRACKER__LAUNCH,
    });
  };

export const updateFilter: Actions.TSetDateRange =
  ({ filter, startDate, endDate }) =>
  (dispatch, getState) => {
    const { startDate: defaultStartDate, endDate: defaultEndDate } =
      getState().cardManagement;
    const today = endOfDay(new Date());
    const weekAgo = startOfDay(subWeeks(today, 1));
    const monthAgo = startOfDay(subMonths(today, 1));
    const yearAgo = startOfDay(subYears(today, 1));

    switch (filter) {
      case 'week':
        dispatch({
          filter: 'week',
          startDate: weekAgo,
          endDate: today,
          type: Actions.TRACKER__UPDATE_FILTER,
        });
        break;
      case 'month':
        dispatch({
          filter: 'month',
          startDate: monthAgo,
          endDate: today,
          type: Actions.TRACKER__UPDATE_FILTER,
        });
        break;
      case 'year':
        dispatch({
          filter: 'year',
          startDate: yearAgo,
          endDate: today,
          type: Actions.TRACKER__UPDATE_FILTER,
        });
        break;
      case 'custom':
      case 'all':
      default:
        dispatch({
          filter,
          startDate: startDate || defaultStartDate,
          endDate: endDate || defaultEndDate,
          type: Actions.TRACKER__UPDATE_FILTER,
        });
        break;
    }
  };


export const toggleSelectCard: Actions.TToggleSelectCard = (id) => ({
  id,
  type: Actions.TRACKER__SELECT_CARD,
});

export const setPageSize: Actions.TSetPageSize = (size) => ({
  size,
  type: Actions.TRACKER__SET_PAGE_SIZE,
});

export const toggleSelectAll: Actions.TToggleSelectAll = (allCards = []) => ({
  allCards,
  type: Actions.TRACKER__TOGGLE_SELECT_ALL,
});

