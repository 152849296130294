import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;

  @media (max-width: ${({ theme }) => theme.widthTablet}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.sizeRanges.tablet.min}px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`;
