import React, { useState } from 'react';
import { Cell } from 'react-table';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { toggleSelectCard } from 'state/card-tracker/CardTrackerActions';

import { Loader } from 'components/loader';
import useRetryFailedGeneration from 'data/data-hooks/mutations/card/useRetryFailedGeneration';

import { EGenerationStatus, ICardGeneration } from 'types/card.types';
import { Container } from './ActionsCellStyles';

interface Props extends Cell {
  value: ICardGeneration;
}

export const ActionsCell: React.FC<Props> = ({ value: card }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const { selectedCardIds } = useAppSelector(({ cardTracker }) => cardTracker);
  const dispatch = useAppDispatch();

  const unSelectCard = () => {
    const isSelected = selectedCardIds.has(card.id);
    if (isSelected) dispatch(toggleSelectCard(card.id));
  };

  const { resendFailedCard } = useRetryFailedGeneration({
    onSuccess: () => {
      setIsLoading(false);
      unSelectCard();
    },
    onError: () => setIsLoading(false),
  });

  const hasFailed =
    card.passStatus !== 'invalid' &&
    (card.status === EGenerationStatus.failedToEmail ||
      card.status === EGenerationStatus.failedToGenerate);

  const onClick = () => {
    setIsLoading(true);
    resendFailedCard(card);
  };

  if (!hasFailed) return null;
  return (
    <Container onClick={onClick}>
      {isLoading ? <Loader size={5} /> : <i className="fas fa-redo" />}
    </Container>
  );
};
