import { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { useTheme } from 'styled-components';

export interface IDimensions {
  height?: number;
  width?: number;
}

type argsType = {
  initialDimension?: IDimensions;
};

const INITIAL_DIMENSIONS = {
  width: 0,
  height: 0,
};

const useDimensions = ({ initialDimension = INITIAL_DIMENSIONS }: argsType = {}) => {
  const [dimensions, setDimensions] = useState<IDimensions>(initialDimension);
  const ref = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const reset = async () => {
    setDimensions(initialDimension);
  };

  const handleResize = _.throttle(() => {
    reset().then(() => {
      setDimensions({
        width:
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth,
        height:
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight,
      });
    });
  }, 20);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isInDesktop = (dimensions.width || 0) >= theme.sizeRanges.desktop.min;
  const isInLargeTablet =
    !isInDesktop && (dimensions.width || 0) >= theme.sizeRanges.tabletLarge.min;
  const isInTablet =
    !isInDesktop &&
    !isInLargeTablet &&
    (dimensions.width || 0) >= theme.sizeRanges.tablet.min;
  const isInMobile = (dimensions.width || 0) < theme.sizeRanges.tablet.min;

  return {
    dimensions,
    handleResize,
    isInDesktop,
    isInLargeTablet,
    isInMobile,
    isInTablet,
    ref,
  };
};

export default useDimensions;
