import { Reducer } from 'redux';

import * as Actions from './EmailTemplatesActions.types';

export interface State {
  currentTemplateId?: string;
  isLoading: boolean;
}

export const initialState: State = {
  isLoading: false,
};

const EmailTemplatesReducer: Reducer<State, Actions.EmailTemplatesActionTypes> = (
  state = initialState,
  action,
): State => {
  switch (action.type) {
    case Actions.TEMPS__SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }

    case Actions.TEMPS__SET_CURRENT_TEMP: {
      return {
        ...state,
        currentTemplateId: action.templateId,
      };
    }

    default:
      return state;
  }
};

export default EmailTemplatesReducer;
