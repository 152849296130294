import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MoreButton = styled.div`
  color: ${({ theme }) => theme.secondaryViolet};
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
`;
