import React from 'react';
import { NextSteps } from './components/next-steps/NextSteps';

import { Footer, PublicHeaderNav } from '../../domains/navigation';
import { Hero } from './components/hero/Hero';
import { HowItWorks } from './components/how-it-works/HowItWorks';
import { Security } from './components/security/Security';
import { Testimonials } from './components/testimonials/Testimonials';
import { WhyUs } from './components/why-us/WhyUs';

import { Container, Inner } from './LandingPageStyles';

export const LandingPage: React.FC = () => {
  return (
    <Container>
      <Inner>
        <PublicHeaderNav />
        <Hero />
        <WhyUs />
        <HowItWorks />
        <Testimonials />
        <Security />
        <NextSteps />
        <Footer />
      </Inner>
    </Container>
  );
};
