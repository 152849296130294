import { decodeToken } from 'utils/token.utils';

import checkValidRequest from 'data/adapters/photo-ids/checkValidRequest';
import uploadPhotoId from 'data/adapters/photo-ids/uploadPhotoId';

import { IPhotoReqTokenPayload } from 'types/token.types';
import * as Actions from './PhotoVerificationActions.types';

export const launchPhotoUpload: Actions.TLaunchPhotoUpload =
  (token) => async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { data: isValid, success, error } = await checkValidRequest({ token });
      if (!success || !!error) throw error;

      if (!isValid) {
        dispatch({
          type: Actions.PV__SET_STEP,
          step: 5,
        });
        return;
      }

      const payload = await decodeToken(token)
        .then((data) => data as unknown as IPhotoReqTokenPayload)
        .catch(() => null);

      if (!payload) {
        dispatch(setIsNameInvalid(true));
        return;
      }

      dispatch({
        type: Actions.PV__VERIFY_TOKEN,
        data: payload,
      });
    } catch (error) {
      dispatch({
        type: Actions.PV__SET_STEP,
        step: -1,
        isExpired: (error as string) === 'EXPIRED_TOKEN',
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const setPhoto: Actions.TSetPhoto = (photo) => ({
  type: Actions.PV__SET_PHOTO,
  photo,
});

export const setStepIndex: Actions.TSetStepIndex = (step) => ({
  type: Actions.PV__SET_STEP,
  step,
});

export const setIsLoading: Actions.TSetIsLoading = (isLoading) => ({
  isLoading,
  type: Actions.PV__SET_IS_LOADING,
});

export const setIsNameInvalid: Actions.TSetIsNameInvalid = (isInvalid) => ({
  isInvalid,
  type: Actions.PV__SET_IS_NAME_INVALID,
});

export const setToken: Actions.TSetToken = (token) => (dispatch) => {
  dispatch(launchPhotoUpload(token));
  dispatch({
    token,
    type: Actions.PV__SET_TOKEN,
  });
};

export const uploadPhoto: Actions.TUploadPhoto =
  (photo) => async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));

      const { userData } = getState().photoVerification;
      if (!userData) throw 'Invalid user data';

      const { success, error } = await uploadPhotoId({ photo, userData });

      if (!success) throw error;

      dispatch({
        type: Actions.PV__UPLOAD_PHOTO,
      });
    } catch {
      dispatch({
        type: Actions.PV__SET_STEP,
        step: -1,
      });
    } finally {
      dispatch(setIsLoading(false));
    }
  };
