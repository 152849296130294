import React from 'react';

import { Customization } from './components/customization/Customization';
import { MobileApp } from './components/mobile-app/MobileApp';
import { Platform } from './components/platform/Platform';

import {
  Card1,
  Card2,
  Card3,
  Grid,
  Container,
  MainTitle,
  SubText,
} from './HowItWorksStyles';

export const HowItWorks: React.FC = () => {
  return (
    <Container>
      <MainTitle>{"Here's how it works"}</MainTitle>
      <SubText>
        Easy to use employee digitial ID cards available on mobile devices
      </SubText>

      <Grid>
        <Card1>
          <Platform />
        </Card1>
        <Card2>
          <MobileApp />
        </Card2>
        <Card3>
          <Customization />
        </Card3>
      </Grid>
    </Container>
  );
};
