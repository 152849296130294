import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'state/hooks';

import { Footer, PublicHeaderNav } from 'domains/navigation';
import { Form } from './components/Form';
import { PATHS } from 'routes';

import { Container, ContentContainer, Title } from './LoginStyles';

export const Login: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const headingText = t('Log In');
  const paragraphText = t('Enter your details below');

  const { user, isAuthenticated } = useAppSelector(({ auth }) => auth);

  useEffect(() => {
    if (isAuthenticated && user) navigate(PATHS.dashboard);
  }, [isAuthenticated, user]);

  return (
    <Container>
      <PublicHeaderNav />

      <ContentContainer>
        <Title>{headingText}</Title>
        <h1>{paragraphText}</h1>

        <Form />
      </ContentContainer>

      <Footer />
    </Container>
  );
};
