import styled from 'styled-components';

export const Inner = styled.form`
  padding: 30px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Name = styled.div`
  font-family: ${({ theme }) => theme.primaryFont};
  font-weight: ${({ theme }) => theme.weightBold};
  font-size: 23px;
  line-height: 28px;
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 10px;
`;

export const Tip = styled.div`
  font-family: ${({ theme }) => theme.secondaryFont};
  font-size: 10px;
  line-height: 12px;
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
