import React from 'react';
import { useTranslation } from 'react-i18next';

import { DatePicker } from 'components/date-picker';
import useCardsFilters from 'domains/hooks/useCardsFilters';
import useDimensions from 'hooks/useDimensions';

import { FilterButton, Filters, TitleAndActions } from './HeaderStyles';
import { ContentBoxHeader, ContentBoxTitle } from 'domains/navigation';

export const Header: React.FC = () => {
  const {
    activeFilter,
    endDate,
    handleFilterChange,
    setCustomDateRange,
    startDate,
  } = useCardsFilters();
  const { isInMobile } = useDimensions();

  const { t } = useTranslation();
  const tTitle = t('Latest card updates');
  const tAll = t('All');
  const tWeek = t('Week');
  const tMonth = t('30d');
  const tYear = t('1y');

  return (
    <ContentBoxHeader>
      <TitleAndActions>
        <ContentBoxTitle>{tTitle}</ContentBoxTitle>
      </TitleAndActions>

      <Filters>
        {!isInMobile && (
          <>
            <FilterButton
              name="all"
              isActive={activeFilter === 'all'}
              onClick={handleFilterChange}
            >
              {tAll}
            </FilterButton>
            <FilterButton
              name="week"
              isActive={activeFilter === 'week'}
              onClick={handleFilterChange}
            >
              {tWeek}
            </FilterButton>
            <FilterButton
              name="month"
              isActive={activeFilter === 'month'}
              onClick={handleFilterChange}
            >
              {tMonth}
            </FilterButton>
            <FilterButton
              name="year"
              isActive={activeFilter === 'year'}
              onClick={handleFilterChange}
            >
              {tYear}
            </FilterButton>
          </>
        )}

        <DatePicker
          startDate={startDate}
          endDate={endDate}
          onDateChange={setCustomDateRange}
        />
      </Filters>
    </ContentBoxHeader>
  );
};
