import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { useEmployeeCards } from 'contexts';
import { clearSelection } from 'state/card-management/CardManagementActions';
import useDimensions from 'hooks/useDimensions';
import useUserRole from 'hooks/useUserRole';

import { CardsFiltersModal } from '../cards-filters-modal/CardsFiltersModal';
import {
  ControlBarButton,
  ControlBarContainer,
  SelectedAvatars,
} from 'components/table-control-bar';
import { DisableAllModal, EnableAllModal } from '../../../action-modals';
import { FlatButton } from 'components/buttons';
import { GenerateCardModal } from 'domains/cards/components/generate-card-modal';
import { RequestPhotoModal } from 'domains/cards/components/request-photo-modal';
import { PATHS } from 'routes';

import { IEmployeeCard } from 'types/card.types';
import { Actions, Controllers, Inner } from './cardsControlBarStyles';

interface Props {
  data: IEmployeeCard[];
  filters: { id: string; value: unknown }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFilter: (columnId: string, updater: any) => void;
}

export const CardsControlBar: React.FC<Props> = ({
  data,
  filters,
  setFilter,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isInDesktop } = useDimensions();
  const { t } = useTranslation();

  const tGenerate = t('Generate Card(s)');
  const tRequest = t('Request photo(s)');

  const { features } = useUserRole();
  const { employeeCards } = useEmployeeCards();
  const { currentCompany } = useAppSelector(({ company }) => company);
  const { selectedCardsIds } = useAppSelector(
    ({ cardManagement }) => cardManagement,
  );

  const canRequestPhoto = !!features?.hasCardManagementAccess.canRequestPhoto;
  const canDisable =
    !!features?.hasCardManagementAccess.canDisable &&
    currentCompany?.configuration.allowDisable;

  const allSelectedCardsHasPhotoUrl = useMemo(() => {
    const selectedCards = data.filter((card) => selectedCardsIds.has(card.id));
    return selectedCards.every((card) => !!card.photoUrl);
  }, [data, selectedCardsIds]);

  const canGenerateCard =
    !!features?.hasCardManagementAccess.canGenerateCard &&
    allSelectedCardsHasPhotoUrl;

  const clearSelected = () => dispatch(clearSelection());

  useEffect(() => {
    clearSelected();
  }, [filters]);

  return (
    <ControlBarContainer>
      <Inner>
        {!!isInDesktop && (
          <SelectedAvatars
            selectedCards={employeeCards.filter(({ id }) =>
              selectedCardsIds.has(id),
            )}
          />
        )}

        <Actions>
          {!!canGenerateCard && !!selectedCardsIds.size && (
            <GenerateCardModal
              onSuccess={clearSelected}
              selectedCardsIds={[...selectedCardsIds]}
            >
              {({ handleOpen, isLoading }) => (
                <ControlBarButton
                  isDisabled={!selectedCardsIds.size}
                  onClick={handleOpen}
                  isLoading={isLoading}
                >
                  {tGenerate}
                </ControlBarButton>
              )}
            </GenerateCardModal>
          )}
          {!!canRequestPhoto && !!selectedCardsIds.size && (
            <RequestPhotoModal
              onSuccess={clearSelected}
              selectedCardsIds={[...selectedCardsIds]}
            >
              {({ handleOpen, isLoading }) => (
                <ControlBarButton
                  isDisabled={!selectedCardsIds.size}
                  onClick={handleOpen}
                  isLoading={isLoading}
                >
                  {tRequest}
                </ControlBarButton>
              )}
            </RequestPhotoModal>
          )}
          {!!canDisable && selectedCardsIds.size > 1 && (
            <>
              <EnableAllModal />
              <DisableAllModal />
            </>
          )}
        </Actions>

        <Controllers>
          <CardsFiltersModal filters={filters} setFilter={setFilter} />
          <FlatButton
            icon="fas fa-paint-roller"
            onClick={() => navigate(PATHS.cardConfiguration)}
          >
            {}
          </FlatButton>
        </Controllers>
      </Inner>
    </ControlBarContainer>
  );
};
