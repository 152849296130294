import React from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components/loader';
import { Card, Email, Employees, Students, InactiveAlert } from 'components/icons';

import {
  Circle,
  CircleContainer,
  Container,
  IconContainer,
  Inner,
  StatsTitle,
  StatsValue,
} from './DashboardStatsSlideStyles';

interface Props {
  isLoading?: boolean;
  type: 'employees' | 'students' | 'cards-active' | 'requests' | 'cards-inactive';
  value: number;
}

export const DashboardStatsSlide: React.FC<Props> = ({
  isLoading = false,
  type,
  value,
}: Props) => {
  const { t } = useTranslation();
  const tCardsActive = t(`ACTIVE CARD${value === 1 ? '' : 'S'}`);
  const tCardsInactive = t(`INACTIVE CARD${value === 1 ? '' : 'S'}`);
  const tPhotoRequests = t(`PHOTO REQUEST${value === 1 ? '' : 'S'} SENT`);
  const tEmployees = t(`EMPLOYEE${value === 1 ? '' : 'S'}`);
  const tStudents = t(`STUDENT${value === 1 ? '' : 'S'}`);

  const getIcon = () => {
    switch (type) {
      case 'cards-active':
        return <Card />;
      case 'cards-inactive':
        return <InactiveAlert />;
      case 'requests':
        return <Email />;
      case 'employees':
        return <Employees />;
      case 'students':
        return <Students />;
      default:
        return <Employees />;
    }
  };

  const getTitle = () => {
    switch (type) {
      case 'cards-active':
        return tCardsActive;
      case 'cards-inactive':
        return tCardsInactive;
      case 'requests':
        return tPhotoRequests;
      case 'employees':
        return tEmployees;
      case 'students':
        return tStudents;
      default:
        return '';
    }
  };

  return (
    <Container>
      <CircleContainer>
        <Circle type={type} />
        <IconContainer>{getIcon()}</IconContainer>
      </CircleContainer>

      <Inner>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <StatsValue>{value}</StatsValue>
            <StatsTitle>{getTitle()}</StatsTitle>
          </>
        )}
      </Inner>
    </Container>
  );
};
