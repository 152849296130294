import { ThunkAction } from 'redux-thunk';
import { RootState } from 'state/store';

import { ICardGeneration } from 'types/card.types';
import { TFilter } from 'types/shared.types';

export const TRACKER__LAUNCH = 'TRACKER__LAUNCH';
export const TRACKER__SELECT_CARD = 'TRACKER__SELECT_CARD';
export const TRACKER__SET_PAGE_SIZE = 'TRACKER__SET_PAGE_SIZE';
export const TRACKER__TOGGLE_SELECT_ALL = 'TRACKER__TOGGLE_SELECT_ALL';
export const TRACKER__UPDATE_FILTER = 'TRACKER__UPDATE_FILTER';

interface LaunchCardTrackerAction {
  type: typeof TRACKER__LAUNCH;
}

interface SetFilterAction {
  type: typeof TRACKER__UPDATE_FILTER;
  filter: TFilter;
  startDate: Date;
  endDate: Date;
}


interface SelectCardAction {
  type: typeof TRACKER__SELECT_CARD;
  id: string;
}

interface SetPageSizeAction {
  type: typeof TRACKER__SET_PAGE_SIZE;
  size: number;
}

interface ToggleSelectAllAction {
  type: typeof TRACKER__TOGGLE_SELECT_ALL;
  allCards: ICardGeneration[];
}

export type CardsTrackerActionTypes =
  | LaunchCardTrackerAction
  | SelectCardAction
  | SetFilterAction
  | SetPageSizeAction
  | ToggleSelectAllAction;


export type TSetDateRange = (args: {
  filter: TFilter;
  startDate?: Date;
  endDate?: Date;
}) => ThunkAction<void, RootState, unknown, CardsTrackerActionTypes>;

export type TLaunchCardTracker = () => ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  CardsTrackerActionTypes
>;


export type TToggleSelectCard = (id: string) => SelectCardAction;

export type TSetPageSize = (size: number) => SetPageSizeAction;

export type TToggleSelectAll = (
  cardIds?: ICardGeneration[],
) => ToggleSelectAllAction;

